import get from "lodash-es/get";

// Icons
import audioIcon from "../assets/icons/audio_ico.svg";
import docIcon from "../assets/icons/doc_ico.svg";
import genericIcon from "../assets/icons/generic_ico.svg";
import htmlIcon from "../assets/icons/html_ico.svg";
import pdfIcon from "../assets/icons/pdf_ico.svg";
import txtIcon from "../assets/icons/txt_ico.svg";
import videoIcon from "../assets/icons/video_ico.svg";
import xlsIcon from "../assets/icons/xls_ico.svg";

// Utils
import { getFileExtension } from "../utils/images";

export const devices = {
  ANDROID: "Android",
  IOS: "iOS"
};

export const userTypes = {
  MedicalProfessional: "Medical Professional",
  PoliceOfficer: "Police Officer",
  Victim: "Victim",
  Relative: "Relative",
  Witness: "Witness"
};

export const submissionStatus = {
  NONE: "None",
  IN_PROGRESS: "InProgress",
  ACCEPTED: "Accepted"
};

export const userTypesOptions = Object.keys(userTypes).map(value => ({
  value,
  text: `common.userTypes.${value}`
}));

export const regex = {
  separateEmail:
    /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/
};

// Sensitive Areas
export const sensitiveSections = [
  "FrontRightGroin",
  "FrontLeftGroin",
  "FrontLeftChest",
  "FrontRightChest",
  "BackLeftButtock",
  "BackRightButtock"
];

// Extensions and Media Types
export const videoExtensions = [
  "3gp",
  "avi",
  "mov",
  "mp4",
  "mpg4",
  "mp4v",
  "ogg",
  "webm",
  "wmv"
];

export const audioExtensions = ["mp3", "wav"];
export const imageExtensions = ["jpg", "jpeg", "svg", "png", "bmp"];
export const documentExtensions = [
  "doc",
  "docx",
  "xls",
  "xlsx",
  "ppt",
  "pptx",
  "pdf",
  "txt",
  "psd",
  "ai",
  "odf",
  "zip",
  "eps",
  "vtt",
  "mkv",
  "xltx",
  "pages",
  "htm"
];

export const defaultAccept = `audio/*, video/*, image/*, ${documentExtensions
  .map(ext => `.${ext}`)
  .join(", ")}`;

const placeholders = {
  pdf: pdfIcon,
  html: htmlIcon,
  txt: txtIcon,
  xls: xlsIcon,
  xlsx: xlsIcon,
  doc: docIcon,
  docx: docIcon,
  default: genericIcon
};

videoExtensions.forEach(ext => {
  placeholders[ext] = videoIcon;
});

audioExtensions.forEach(ext => {
  placeholders[ext] = audioIcon;
});

export const iconPlaceholders = placeholders;

export const getPlaceholder = filename => {
  const extension = getFileExtension(filename);
  return iconPlaceholders[extension] || iconPlaceholders.default;
};

export const getFormat = filename => {
  const extension = getFileExtension(filename);

  return {
    isVideo: videoExtensions.indexOf(extension) !== -1,
    isImage: imageExtensions.indexOf(extension) !== -1,
    extension
  };
};

export const mediaStatus = {
  ERROR: "Error",
  PROCESSING: "Processing",
  WAITING_FOR_UPLOAD: "WaitingForUpload"
};

const mediaTypes = {
  Image: "image",
  Video: "video",
  Audio: "video",
  Document: "document",
  Other: "file"
};

export const getMediaType = type => mediaTypes[type] || mediaTypes.Other;

// Invitations
export const backendTexts = {
  userAlreadyInvited: "User already invited"
};

export const invitationTypes = {
  match: "match",
  noMatch: "no-match",
  expired: "expired",
  alreadyInvited: "already-invited",
  error: "error"
};

export const getInvitationValues = async response => {
  const values = {};

  if (response) {
    values.attempts = response.headers.get("attempts-remaining");
    values.allowed = response.headers.get("attempts-allowed");
    values.retry = response.headers.get("Retry-After");

    if (response.ok) values.type = invitationTypes.match;
    if (response.status === 403) values.type = invitationTypes.expired;
    if (response.status === 400) {
      values.invitation = await response.json();
      values.invitation.message = get(values.invitation, "Invitation[0]");

      if (values.invitation.message === backendTexts.userAlreadyInvited) {
        values.type = invitationTypes.alreadyInvited;
      } else {
        values.type = values.invitation.id
          ? invitationTypes.noMatch
          : invitationTypes.error;
      }
    }
  }

  return values;
};

const mapValues = {
  cases: "caseId",
  media: "itemId",
  medicalRecords: "itemId",
  injuries: "injuryId"
};

const parseObjectKeys = s => {
  return s.replace(
    /cases|injuries|media|medicalRecords/gi,
    matched => mapValues[matched]
  );
};

export const getResourceObject = resId => {
  const obj = {};

  parseObjectKeys(resId)
    .split("/")
    .filter(s => s !== "")
    .reduce((a, b, c) => {
      if (c % 2) obj[a] = b;
      return b;
    });

  return obj;
};

// Contact Preferences
export const contactPreferences = {
  startTime: { name: "startTime" },
  endTime: { name: "endTime" },
  phoneNumber: { name: "phoneNumber" },
  notes: { name: "notes" },
  email: { name: "emailAddress" },
  channel: {
    name: "preferredChannel",
    values: {
      EMAIL: "Email",
      PHONE: "Phone"
    }
  }
};

// Media Upload Error Validation
// time offset in seconds
export const MEDIA_LOADING_TIME_OFFSET = 60 * 5;
export const getTimeDifferenceInSeconds = time =>
  (new Date() - new Date(time)) / 1000;
