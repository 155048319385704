import React, { useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// Formik
import * as Yup from "yup";

// Material
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";

// Config
import colors from "../../config/colors";

// Actions
import {
  updateInjury,
  uploadDescriptions,
  uploadTreatment
} from "../../state/injuries/actions";

// Hooks
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import useTranslate from "../../hooks/useTranslate";

// Components
import Button from "../Button";
import Close from "../Close";
import CustomField from "../CustomField";

const buttonStyles = {
  display: "flex",
  alignSelf: "flex-end",
  width: "auto",
  textTransform: "uppercase",
  fontWeight: "normal"
};

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    position: "relative",
    marginBottom: theme.spacing(3)
  },
  buttonAdd: {
    ...buttonStyles,
    padding: theme.spacing(2),
    color: colors.dodgerBlue
  },
  buttonSend: {
    ...buttonStyles,
    padding: theme.spacing(2)
  },
  loadingTreatment: {
    alignSelf: "flex-end",
    marginRight: theme.spacing(2)
  },
  treatmentField: {
    marginBottom: theme.spacing(1)
  },
  close: {
    position: "absolute",
    right: 0,
    top: -10,
    cursor: "pointer"
  }
}));

const initialValues = (value = "") => ({
  value
});

const validationSchema = Yup.object().shape({
  value: Yup.string()
});

const InjuryTreatment = ({
  caseId,
  injuryId,
  isDraft = false,
  value = "",
  name = "",
  btnText = "",
  title = ""
}) => {
  const [addingTraetment, setAddingTreatment] = useState(isDraft);
  const { data, isUploadingTreatment } = useSelector(state => state.injuries);
  const { trackException } = useAppInsights();
  const classes = useStyles();
  const dispatch = useDispatch();
  const getText = useTranslate();

  const handleTreatmentSubmit = (
    { value: val },
    { setSubmitting, resetForm }
  ) => {
    if (isDraft) {
      const description = data.descriptions[0] && data.descriptions[0].text;
      const treatment = data.treatments[0] && data.treatments[0].text;
      dispatch(
        updateInjury({
          caseId,
          injuryId,
          form: {
            ...data,
            treatment,
            description,
            [name]: val
          },
          onFinish: wasSuccessful => {
            setAddingTreatment(!wasSuccessful || isDraft);
            setSubmitting(false);
            if (!wasSuccessful)
              trackException(
                `Failed to update injury: ${injuryId} at case: ${caseId}`
              );
          }
        })
      );
    } else if (name.toLowerCase() === "description") {
      dispatch(
        uploadDescriptions({
          caseId,
          injuryId,
          texts: [val],
          onFinish: wasSuccessful => {
            setAddingTreatment(!wasSuccessful || isDraft);
            setSubmitting(false);
            if (wasSuccessful) resetForm();
            else
              trackException(
                `Failed to add descriptions to injury: ${injuryId} at case: ${caseId}`
              );
          }
        })
      );
    } else {
      dispatch(
        uploadTreatment({
          caseId,
          injuryId,
          texts: [val],
          onFinish: wasSuccessful => {
            setAddingTreatment(!wasSuccessful || isDraft);
            setSubmitting(false);
            if (wasSuccessful) resetForm();
            else
              trackException(
                `Failed to add treatment to injury: ${injuryId} at case: ${caseId}`
              );
          }
        })
      );
    }
  };

  return (
    <Formik
      initialValues={initialValues(value)}
      onSubmit={handleTreatmentSubmit}
      validationSchema={validationSchema}
    >
      {({ values, submitForm, isSubmitting, errors }) => (
        <Form className={classes.buttonContainer}>
          {addingTraetment ? (
            <>
              <CustomField
                label={title}
                name="value"
                multiline
                error={errors.value}
                value={values.value}
                className={classes.treatmentField}
                disabled={isSubmitting}
              />
              {!isDraft && (
                <Fade in {...{ timeout: 1000 }}>
                  <IconButton
                    className={classes.close}
                    onClick={() => setAddingTreatment(false)}
                  >
                    <Close />
                  </IconButton>
                </Fade>
              )}
              {isUploadingTreatment ? (
                <CircularProgress
                  className={classes.loadingTreatment}
                  size={25}
                  thickness={1}
                />
              ) : (
                <Button
                  className={classes.buttonSend}
                  color="primary"
                  disabled={isSubmitting || values.value === ""}
                  disableElevation
                  onClick={submitForm}
                >
                  {getText("common.prompts.done").toUpperCase()}
                </Button>
              )}
            </>
          ) : (
            <Button
              className={classes.buttonAdd}
              color="primary"
              disableElevation
              onClick={() => setAddingTreatment(true)}
              regular
            >
              {getText(btnText)}
            </Button>
          )}
        </Form>
      )}
    </Formik>
  );
};

InjuryTreatment.propTypes = {
  btnText: PropTypes.string.isRequired,
  caseId: PropTypes.string.isRequired,
  injuryId: PropTypes.string.isRequired,
  isDraft: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string
};

InjuryTreatment.defaultProps = {
  value: ""
};

export default InjuryTreatment;
