/* eslint-disable react/no-array-index-key */
import React, { useCallback, useState } from "react";
import { FieldArray } from "formik";
import PropTypes from "prop-types";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

// Config
import colors from "../../../config/colors";

// Components
import Button from "../../Button";
import Text from "../../Text";
import UserAddition from "../../UserAddition";
import InputListItem from "./InputListItem";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1),
    flex: "1 1",
    display: "flex",
    flexWrap: "wrap"
  },
  title: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1)
  },
  contentEdit: {
    display: "flex",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: colors.whisper,
    color: colors.santasGray
  },
  addButton: {
    display: "block",
    marginLeft: "auto",
    border: "none",
    "&:hover": {
      border: "none"
    }
  },
  input: {
    position: "relative"
  },
  close: {
    position: "absolute",
    right: 0,
    top: -10,
    cursor: "pointer"
  },
  info: {
    position: "absolute",
    right: 0,
    top: 3
  },
  valueLabel: {
    marginBottom: theme.spacing(1.5)
  },
  fields: {
    width: "100%"
  }
}));

const InputList = ({
  addButtonText,
  canAddMore = true,
  className,
  constantValues,
  disableIndex,
  name,
  onDelete,
  subTitle,
  title,
  type,
  values,
  setFieldValue,
  idArray,
  ...rest
}) => {
  const [isRemoving, setRemoving] = useState(null);
  const [localArray, setLocalArray] = useState([]);
  const classes = useStyles();

  const handleRemove = useCallback(
    (index, callback) => () => {
      if (index >= idArray.length) {
        callback();
        setLocalArray([...idArray, ...localArray].splice(index, 1));
        return;
      }

      if (!onDelete) {
        callback();
      } else {
        const itemId = idArray[index];
        // parent component expects the id of the deleted element
        // to use it on an async function
        setRemoving(index);

        onDelete(itemId, success => {
          if (success) {
            // remove deleted input element:
            callback();
            // remove deleted id element:
            setFieldValue(
              `editingId.${name}`,
              idArray.filter(id => id !== itemId)
            );
          }
          setRemoving(null);
        });
      }
    },
    [onDelete, setFieldValue, idArray]
  );

  const handleAdd = cbk => () => {
    localArray.push(new Date() * Math.random());
    cbk();
  };

  const generateKey = i => idArray[i] || [...idArray, ...localArray][i] || i;

  return (
    <div className={className}>
      <Text className={classes.title} type="heading4">
        {title}
      </Text>
      <div className={classes.root}>
        <FieldArray
          name={name}
          render={arrayHelpers => (
            <div className={classes.fields}>
              {constantValues &&
                constantValues.map(({ text, creationDateTime, author }) => (
                  <div
                    className={classes.input}
                    key={`${author.id}${creationDateTime}${text}`}
                  >
                    <UserAddition
                      date={creationDateTime}
                      author={author}
                      text={text}
                    />
                  </div>
                ))}
              {values &&
                values.map(
                  (_, index) =>
                    !disableIndex[index] && (
                      <InputListItem
                        onRemove={handleRemove}
                        removeCallback={arrayHelpers.remove}
                        key={generateKey(index)}
                        index={index}
                        disableDelete={!!isRemoving}
                        isLoading={isRemoving === index}
                        disabled={idArray[index] !== undefined}
                        name={`${name}.${index}`}
                        type={type}
                        {...rest}
                      />
                    )
                )}
              {canAddMore && (
                <Button
                  variant="outlined"
                  color="primary"
                  disableElevation
                  className={classes.addButton}
                  onClick={handleAdd(() => arrayHelpers.push(""))}
                >
                  {addButtonText}
                </Button>
              )}
            </div>
          )}
        />
      </div>
    </div>
  );
};

InputList.propTypes = {
  addButtonText: PropTypes.string.isRequired,
  canAddMore: PropTypes.bool,
  className: PropTypes.string,
  constantValues: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      creationDateTime: PropTypes.string,
      author: PropTypes.shape({
        id: PropTypes.string,
        fullName: PropTypes.string
      })
    })
  ),
  disableIndex: PropTypes.arrayOf(PropTypes.bool),
  idArray: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  subTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string).isRequired
};

InputList.defaultProps = {
  canAddMore: false,
  className: "",
  constantValues: [],
  disableIndex: [],
  idArray: [],
  onDelete: () => {},
  subTitle: undefined,
  type: "text"
};

export default React.memo(InputList);
