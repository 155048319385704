import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

// Config
import { getImgReuploadUrl } from "../../config/apiConfig";
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import {
  MEDIA_LOADING_TIME_OFFSET,
  getTimeDifferenceInSeconds,
  mediaStatus
} from "../../config/values";

// Actions
import { parseFetchOptions } from "../../state/user/actions";
import { retryUploadInjuryImage } from "../../state/injuries/actions";

// Components
import FileUpload from "../FileUpload";
import ImageItem from "./ImageItem";
import ImageItemReupload from "./ImageItemReupload";
import ImageProcessing from "../ImageProcessing";

const ImageItemHandler = ({ image, className, getImage, ...rest }) => {
  const { caseId, injuryId } = useParams();

  const [difference, setDifference] = useState(
    getTimeDifferenceInSeconds(image.creationDateTime)
  );
  const [elementId, setElementId] = useState(null);
  const { trackException } = useAppInsights();
  const { uploadErrors, uploadProgress } = useSelector(s => s.injuries);
  const dispatch = useDispatch();

  const displayProcessing =
    image.status === mediaStatus.PROCESSING ||
    (image.status === mediaStatus.WAITING_FOR_UPLOAD &&
      difference < MEDIA_LOADING_TIME_OFFSET);
  const displayReupload =
    image.status === mediaStatus.WAITING_FOR_UPLOAD ||
    image.status === mediaStatus.ERROR;

  useEffect(() => {
    // display loading locally for MEDIA_LOADING_TIME_OFFSET seconds before displaying actual processing status
    // to prevent server delay from causing waiting upload error
    // TODO remove this after backend optimization
    if (image.status === mediaStatus.WAITING_FOR_UPLOAD) {
      setTimeout(() => {
        setDifference(getTimeDifferenceInSeconds(image.creationDateTime));
        getImage();
      }, 1000);
    }
  }, [image.creationDateTime, image.status, difference]);

  const closeReupload = () => setElementId(null);
  const openReupload = id => setElementId(id);

  const handleSubmit = files => {
    dispatch(
      parseFetchOptions(({ headers: { Authorization, ...headers } }) => {
        const url = getImgReuploadUrl(caseId, injuryId, image.filename);
        const options = {
          method: "PUT",
          headers: { ...headers, Authorization }
        };
        fetch(url, options)
          .then(res => res.json())
          .then(reuploadData => {
            dispatch(
              retryUploadInjuryImage({
                caseId,
                image: files[0].file,
                id: reuploadData.id,
                url: reuploadData.uploadURL,
                onSuccess: getImage,
                onFail: () =>
                  trackException(
                    `Failed to reupload media file ${reuploadData.id} of case ${caseId}`
                  )
              })
            );
          })
          .catch(() => {
            trackException(
              `Failed to reupload injury image ${injuryId} of case ${caseId}`
            );
          });
      })
    );
  };

  return (
    <>
      {displayProcessing && !displayReupload && (
        <div className={className}>
          <ImageProcessing getImage={getImage} />
        </div>
      )}
      {!displayProcessing && displayReupload && (
        <ImageItemReupload className={className} onClick={openReupload} />
      )}
      {!displayProcessing && !displayReupload && (
        <ImageItem className={className} image={image} {...rest} />
      )}
      <FileUpload
        onClose={closeReupload}
        onSubmit={handleSubmit}
        open={Boolean(elementId)}
        noDescriptions
        uploadErrors={uploadErrors}
        uploadProgress={uploadProgress}
      />
    </>
  );
};

ImageItemHandler.propTypes = {
  className: PropTypes.string.isRequired,
  image: PropTypes.shape({
    creationDateTime: PropTypes.string,
    status: PropTypes.string,
    filename: PropTypes.string
  }).isRequired,
  imageItemProps: PropTypes.shape({}).isRequired,
  getImage: PropTypes.func.isRequired
};

export default React.memo(ImageItemHandler);
