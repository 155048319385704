import React from "react";
import { Form, Formik, useField } from "formik";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

// Config
import colors from "../../config/colors";
import styles from "../../config/styles";

// Icons
import sendPlane from "../../assets/icons/sendPlane.svg";

// Hooks
import useTranslate from "../../hooks/useTranslate";

// Components
import Button from "../Button";
import SubmitMenu from "./SubmitMenu";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 2.5),
    width: "100%"
  },
  formWrapper: {
    padding: theme.spacing(1.75, 1.5),
    backgroundColor: colors.whisper,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    borderRadius: theme.shape.borderRadius
  },
  form: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flex: 1
  },
  input: {
    fontFamily: ["Open Sans", "sans-serif"].join(","),
    padding: theme.spacing(1.5, 2.5),
    border: styles.values.border,
    borderRadius: theme.shape.borderRadius,
    flex: 1,
    resize: "none",
    "&:focus": {
      outline: "none"
    }
  },
  button: {
    marginLeft: theme.spacing(1),
    position: "relative",
    padding: theme.spacing(0, 1.5),
    height: 42,
    display: "flex",
    alignItems: "center"
  },
  sendIcon: {
    transform: "rotate(45deg)",
    height: 18,
    width: 18,
    marginRight: theme.spacing(2)
  }
}));

const ChatField = props => {
  const [field] = useField(props);
  return <TextareaAutosize {...props} {...field} />;
};

const ChatInput = ({ onSend, disabled, hideMenu, infoRequestId, onType }) => {
  const classes = useStyles();
  const getText = useTranslate();

  const handleSubmit = ({ text }, { setSubmitting, resetForm }) => {
    setSubmitting(false);
    onSend(text);
    resetForm();
  };

  const enterSubmit = submitFormik => e => {
    onType();
    if (e.ctrlKey && e.keyCode === 13 && e.shiftKey === false) {
      submitFormik();
    }
  };

  return (
    <Formik initialValues={{ text: "" }} onSubmit={handleSubmit}>
      {({ submitForm, values }) => (
        <div className={classes.root}>
          <div className={classes.formWrapper}>
            {!hideMenu && <SubmitMenu infoRequestId={infoRequestId} />}
            <Form className={classes.form}>
              <ChatField
                autoComplete="off"
                className={classes.input}
                name="text"
                onKeyDown={enterSubmit(submitForm)}
                placeholder={getText("furtherInfoPage.inputPlaceholder")}
              />
              <Button
                className={classes.button}
                color="primary"
                disabled={disabled || values.text === ""}
                disableElevation
                onClick={submitForm}
                variant="contained"
              >
                <img src={sendPlane} alt="" className={classes.sendIcon} />
                {getText("common.prompts.send")}
              </Button>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );
};

ChatInput.propTypes = {
  disabled: PropTypes.bool,
  hideMenu: PropTypes.bool,
  infoRequestId: PropTypes.string,
  onSend: PropTypes.func.isRequired,
  onType: PropTypes.func
};

ChatInput.defaultProps = {
  disabled: false,
  hideMenu: false,
  infoRequestId: null,
  onType: () => {}
};

export default ChatInput;
