export const REQUEST_INJURY = "injuries/REQUEST_INJURY";
export const REQUEST_INJURY_SUCCESS = "injuries/REQUEST_INJURY_SUCCESS";
export const REQUEST_INJURY_FAIL = "injuries/REQUEST_INJURY_FAIL";

export const REQUEST_DEL_INJURY = "injuries/REQUEST_DEL_INJURY";
export const REQUEST_DEL_INJURY_SUCCESS = "injuries/REQUEST_DEL_INJURY_SUCCESS";
export const REQUEST_DEL_INJURY_FAIL = "injuries/REQUEST_DEL_INJURY_FAIL";

// UPLOAD IMAGES
export const REQUEST_UPLOAD_IMG = "injuries/REQUEST_UPLOAD_IMG";
export const REQUEST_UPLOAD_IMG_SUCCESS = "injuries/REQUEST_UPLOAD_IMG_SUCCESS";
export const REQUEST_UPLOAD_IMG_FAIL = "injuries/REQUEST_UPLOAD_IMG_FAIL";

export const RESET_IMG_UPLOADS = "injuries/RESET_IMG_UPLOADS";
export const UPDATE_IMG_PROGRESS = "injuries/UPDATE_IMG_PROGRESS";

export const REQUEST_UPLOAD_ANNOTATED = "injuries/REQUEST_UPLOAD_ANNOTATED";
export const REQUEST_UPLOAD_ANNOTATED_SUCCESS =
  "injuries/REQUEST_UPLOAD_ANNOTATED_SUCCESS";
export const REQUEST_UPLOAD_ANNOTATED_FAIL =
  "injuries/REQUEST_UPLOAD_ANNOTATED_FAIL";

export const REQUEST_DELETE_IMG = "injuries/REQUEST_DELETE_IMG";
export const REQUEST_DELETE_IMG_SUCCESS = "injuries/REQUEST_DELETE_IMG_SUCCESS";
export const REQUEST_DELETE_IMG_FAIL = "injuries/REQUEST_DELETE_IMG_FAIL";

export const REQUEST_IMG = "injuries/REQUEST_IMG";
export const REQUEST_IMG_SUCCESS = "injuries/REQUEST_IMG_SUCCESS";
export const REQUEST_IMG_FAIL = "injuries/REQUEST_IMG_FAIL";

export const REQUEST_HEADERS = "injuries/REQUEST_HEADERS";
export const REQUEST_HEADERS_SUCCESS = "injuries/REQUEST_HEADERS_SUCCESS";
export const REQUEST_HEADERS_FAIL = "injuries/REQUEST_HEADERS_FAIL";

// TREATMENTS
export const REQUEST_UPLOAD_TREATMENT = "injuries/REQUEST_UPLOAD_TREATMENT";
export const REQUEST_UPLOAD_TREATMENT_SUCCESS =
  "injuries/REQUEST_UPLOAD_TREATMENT_SUCCESS";
export const REQUEST_UPLOAD_TREATMENT_FAIL =
  "injuries/REQUEST_UPLOAD_TREATMENT_FAIL";

// TREATMENTS DESCRIPTIONS
export const REQUEST_UPLOAD_DESCRIPTION = "injuries/REQUEST_UPLOAD_DESCRIPTION";
export const REQUEST_UPLOAD_DESCRIPTION_SUCCESS =
  "injuries/REQUEST_UPLOAD_DESCRIPTION_SUCCESS";
export const REQUEST_UPLOAD_DESCRIPTION_FAIL =
  "injuries/REQUEST_UPLOAD_DESCRIPTION_FAIL";
