import React from "react";
import PropTypes from "prop-types";

// Formik - Material
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";

// Config
import styles from "../../config/styles";

// Hooks

// Components
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: styles.values.border,
    minHeight: 56
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1.5)
  },
  text: {
    lineHeight: 1.7
  }
}));

const CategoryItem = ({ category, checked, isDraft }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Radio
        checked={checked}
        color="primary"
        disabled={!isDraft}
        value={category}
      />
      <div className={classes.content}>
        <Text
          className={classes.text}
          text={`common.injuryCategory.${category}.name`}
          type="subtitleBold"
        />
        <Text className={classes.text} type="contentLabel4" />
      </div>
    </div>
  );
};

CategoryItem.propTypes = {
  category: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  isDraft: PropTypes.bool.isRequired
};

export default CategoryItem;
