import { combineReducers } from "redux";
import constant from "lodash-es/constant";

import createReducer from "../../utils/createReducer";

import {
  REQUEST_NOTIFICATIONS,
  REQUEST_NOTIFICATIONS_SUCCESS,
  REQUEST_NOTIFICATIONS_FAIL,
  REQUEST_READ,
  REQUEST_UPDATE_NOTIFICATIONS
} from "./actionTypes";

// Cases Data
const data = createReducer(null, {
  [REQUEST_NOTIFICATIONS]: constant(null),
  [REQUEST_NOTIFICATIONS_SUCCESS]: (state, { payload }) => payload,
  [REQUEST_READ]: (state, { payload }) => {
    const newState = JSON.parse(JSON.stringify(state));
    newState[payload].isRead = true;
    return newState;
  },
  [REQUEST_UPDATE_NOTIFICATIONS]: (state, { payload }) =>
    state ? [payload, ...state] : [payload]
});

const error = createReducer(null, {
  [REQUEST_NOTIFICATIONS]: constant(null),
  [REQUEST_NOTIFICATIONS_FAIL]: (state, { payload }) => payload
});

const isLoading = createReducer(false, {
  [REQUEST_NOTIFICATIONS]: constant(true),
  [REQUEST_NOTIFICATIONS_SUCCESS]: constant(false),
  [REQUEST_NOTIFICATIONS_FAIL]: constant(false)
});

export default combineReducers({
  data,
  error,
  isLoading
});
