import React, { useCallback, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import get from "lodash-es/get";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

// Formik
import * as Yup from "yup";

// Config
import { getRoute, urls } from "../../config/routes";
import {
  invitationTypes,
  userTypes,
  userTypesOptions
} from "../../config/values";
import countries from "../../config/countries";
import styles from "../../config/styles";

// Icons
import userNotFound from "../../assets/icons/userNotFound.svg";

// Actions
import {
  sendInvitation,
  forceSendInvitation
} from "../../state/invitations/actions";

// Hooks
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import useHideToWitnesses from "../../hooks/useHideToWitnesses";
import useTranslate from "../../hooks/useTranslate";

// Components
import Button from "../Button";
import ConfirmationDialog from "../ConfirmationDialog";
import ContentCardHeader from "../ContentCardHeader";
import ContributorFormHandler from "./ContributorFormHandler";
import CountryField from "../CountryField";
import CustomField from "../CustomField";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    border: styles.values.border
  },
  button: {
    width: 160
  },
  formContent: {
    padding: theme.spacing(3.5, 5),
    display: "flex",
    flexDirection: "column"
  },
  phoneInputWrapper: {
    display: "flex",
    flexDirection: "row"
  },
  label: {
    marginBottom: theme.spacing(1.5)
  }
}));

const validationSchema = texts =>
  Yup.object().shape({
    number: Yup.string().when("isRequired", {
      is: true,
      then: Yup.string()
        .matches(/^[0-9]*$/, { message: texts.phoneNumber.mustBe })
        .min(4, texts.phoneNumber.tooShort)
        .required(texts.atLeastEmailOrPhone),
      otherwise: Yup.string()
        .matches(/^[0-9]*$/, { message: texts.phoneNumber.mustBe })
        .min(4, texts.phoneNumber.tooShort)
    }),
    capacity: Yup.string(),
    email: Yup.string().when("isRequired", {
      is: true,
      then: Yup.string()
        .email(texts.invalidEmail)
        .required(texts.atLeastEmailOrPhone),
      otherwise: Yup.string().email(texts.invalidEmail)
    }),
    familyName: Yup.string().required(texts.required),
    isRequired: Yup.boolean()
  });

const initialValues = code => ({
  capacity: userTypesOptions[0].value,
  country: code
    ? countries.find(item => item.code === code) || countries[233]
    : countries[233],
  number: "",
  email: "",
  familyName: ""
});

const InviteContributorForm = () => {
  const { trackException } = useAppInsights();
  const { data } = useSelector(s => s.caseDetail);
  const { userDetails } = useSelector(s => s.user);
  const [dialog, setDialog] = useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const getText = useTranslate();
  const history = useHistory();

  useHideToWitnesses(data);

  const goToCaseDetail = useCallback(() => {
    history.push(getRoute(urls.caseDetail, { caseId: data.id }));
  }, [history, data]);

  useEffect(() => {
    if (userDetails) {
      const isWitness = userDetails.userCapacity === userTypes.Witness;
      const isCreator = userDetails.id === data.creatorId;
      if (data.isDraft ? !isCreator : isWitness) goToCaseDetail();
    }
  }, [data, userDetails, goToCaseDetail]);

  const handleSubmit = ({ country, number, ...values }, { setSubmitting }) => {
    dispatch(
      sendInvitation({
        caseId: data.id,
        data: {
          ...values,
          phoneNumber: {
            callingCountryCode: `+${country.phone}`,
            nationalNumber: number
          }
        },
        onFinish: ({ success, type, invitation, ...rest }) => {
          setSubmitting(false);
          if (success) {
            if (type === invitationTypes.match) goToCaseDetail();
            else setDialog({ type, invitation, ...rest });
          } else {
            trackException(`Failed to send invitation to case: ${data.id}`);
            setDialog(null);
          }
        }
      })
    );
  };

  const handleClose = () => setDialog(null);

  const getDialogProps = ({ invitation, retry, type }) =>
    ({
      [invitationTypes.noMatch]: {
        button: getText(
          "inviteContributorPage.userNotFoundDialog.inviteButton"
        ),
        cancel: getText(
          "inviteContributorPage.userNotFoundDialog.recheckButton"
        ),
        confirmLabel: getText("inviteContributorPage.userNotFoundDialog.text2"),
        onConfirm: () => {
          const caseId = data.id;
          const invitationId = invitation.id;

          setDialog({ ...dialog, isLoading: true });

          dispatch(
            forceSendInvitation({
              caseId,
              invitationId,
              onFinish: success => {
                setDialog(null);
                if (success) goToCaseDetail();
                else
                  trackException(
                    `Failed to force send invitation: ${invitationId} for case: ${caseId}`
                  );
              }
            })
          );
        },
        textKeys: ["inviteContributorPage.userNotFoundDialog.text1"],
        title: getText("inviteContributorPage.userNotFoundDialog.title"),
        type: "submit"
      },
      [invitationTypes.expired]: {
        hideCancel: true,
        onConfirm: () => setDialog(null),
        texts: [
          {
            key: "1",
            text: getText("inviteContributorPage.dialog.expiredRetryMessage", {
              1: getText("common.units.minute", {
                value: Math.round(retry / 60)
              })
            })
          }
        ],
        title: getText("inviteContributorPage.dialog.expiredTitle"),
        type: "submit",
        button: getText("common.prompts.ok")
      },
      [invitationTypes.alreadyInvited]: {
        hideConfirm: true,
        title: getText("inviteContributorPage.dialog.alreadyInvitedTitle"),
        type: "submit",
        cancel: getText("inviteContributorPage.dialog.cancelButton")
      },
      [invitationTypes.error]: {
        hideConfirm: true,
        title:
          (invitation && invitation.message) ||
          getText("common.errors.request"),
        type: "submit",
        cancel: getText("inviteContributorPage.dialog.cancelButton")
      }
    }[type]);

  return (
    <>
      <Formik
        initialValues={initialValues(
          get(data, "patient.phoneNumber.isoCountryCode", null)
        )}
        onSubmit={handleSubmit}
        validationSchema={validationSchema(getText("common.validationSchema"))}
      >
        {({ values, submitForm, isSubmitting, errors, setFieldValue }) => (
          <ContributorFormHandler setFieldValue={setFieldValue} values={values}>
            <Paper elevation={0} className={classes.root}>
              <Form>
                <ContentCardHeader>
                  <Text
                    className={classes.title}
                    text="inviteContributorPage.title"
                    type="heading2"
                  />
                  <Button
                    className={classes.button}
                    color="primary"
                    disabled={isSubmitting}
                    disableElevation
                    onClick={submitForm}
                    type="submit"
                    variant="contained"
                  >
                    {getText("common.prompts.send")}
                  </Button>
                </ContentCardHeader>
                <div className={classes.formContent}>
                  <CustomField
                    error={errors.crimeRef}
                    label={getText(
                      "inviteContributorPage.contributorTypeLabel"
                    )}
                    name="capacity"
                    options={userTypesOptions}
                    type="select"
                    value={values.capacity}
                  />
                  <CustomField
                    name="familyName"
                    error={errors.familyName}
                    value={values.familyName}
                    label={getText("inviteContributorPage.contributorLabel")}
                  />
                  <Text
                    className={classes.label}
                    text="common.valueLabels.phoneNumber"
                    type="valueLabel"
                  />
                  <div className={classes.phoneInputWrapper}>
                    <CountryField selectedCountryCode={values.country.code} />
                    <CustomField
                      error={errors.number}
                      name="number"
                      placeholder="(0) 777122435"
                    />
                  </div>
                  <CustomField
                    error={errors.email}
                    label={getText("inviteContributorPage.emailLabel")}
                    name="email"
                    placeholder={getText(
                      "inviteContributorPage.emailPlaceholder"
                    )}
                    value={values.email}
                  />
                </div>
              </Form>
            </Paper>
          </ContributorFormHandler>
        )}
      </Formik>
      {dialog && (
        <ConfirmationDialog
          onClose={handleClose}
          reverseButtons
          icon={userNotFound}
          open={!!dialog}
          {...getDialogProps(dialog)}
        />
      )}
    </>
  );
};

export default InviteContributorForm;
