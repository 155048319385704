import React, { useReducer } from "react";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Hooks
import { useFileUpload } from "./FileUploadProvider";
import useTranslate from "../../hooks/useTranslate";

// Config
import colors from "../../config/colors";

// Components
import Button from "../Button";
import FileInput from "../Input/FileInput";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(3, 5),
    display: "flex",
    flexDirection: "column"
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: theme.spacing(4.5),
    backgroundColor: colors.wildLand
  },
  cancelButton: {
    backgroundColor: colors.white,
    marginRight: theme.spacing(1.5),
    width: 180
  },
  uploadButton: {
    width: 180
  },
  emptyFieldError: {
    marginRight: "auto",
    color: colors.rose
  }
}));

const types = {
  ADD: "ADD",
  DROP: "DROP",
  REMOVE: "REMOVE",
  DESCRIPTION: "DESCRIPTION"
};

const reducer = (files, action) => {
  switch (action.type) {
    case types.ADD:
      return [...files, { file: action.file, description: "" }];
    case types.DROP:
      return action.files.map(file => ({ file, description: "" }));
    case types.DESCRIPTION: {
      const newFiles = [...files];
      newFiles[action.index].description = action.description;
      return newFiles;
    }
    case types.REMOVE:
      return files.filter((_, index) => index !== action.index);
    default:
  }
};

const FileUploadFromFolder = () => {
  const [files, dispatch] = useReducer(reducer, []);
  const classes = useStyles();
  const getText = useTranslate();

  const {
    dropzoneProps,
    enableEdit,
    hasEmptyField,
    isUploading,
    noDescriptions,
    onClose,
    onSubmit,
    uploadErrors,
    uploadProgress
  } = useFileUpload();

  const handleRemoveItem = index => () => {
    dispatch({
      type: types.REMOVE,
      index
    });
  };

  const handleDropFiles = addedFiles => {
    dispatch({
      type: types.DROP,
      files: addedFiles
    });
  };

  const handleChangeDescription = ({ description, index }) => {
    dispatch({
      type: types.DESCRIPTION,
      index,
      description
    });
  };

  const handleSubmit = () => onSubmit(files);

  return (
    <>
      <div className={classes.content}>
        <FileInput
          dropzoneProps={dropzoneProps}
          noDescriptions={noDescriptions}
          uploadErrors={uploadErrors}
          uploadProgress={uploadProgress}
          isUploading={isUploading}
          enableEdit={enableEdit}
          onDrop={handleDropFiles}
          onChangeDescription={handleChangeDescription}
          files={files}
          onRemoveItem={handleRemoveItem}
        />
      </div>

      {enableEdit ? (
        <footer className={classes.footer}>
          {hasEmptyField && (
            <Text
              className={classes.emptyFieldError}
              text="fileUploadPage.completeDescriptionsError"
              type="body"
            />
          )}
          <Button
            className={classes.cancelButton}
            disabled={isUploading}
            disableElevation
            onClick={onClose}
            variant="outlined"
          >
            {getText("common.prompts.cancel")}
          </Button>
          <Button
            className={classes.uploadButton}
            color="primary"
            disabled={isUploading || files.length === 0}
            disableElevation
            onClick={handleSubmit}
            variant="contained"
          >
            {getText("common.prompts.upload")}
          </Button>
        </footer>
      ) : (
        <footer className={classes.footer}>
          <Button
            className={classes.uploadButton}
            color="primary"
            disabled={isUploading}
            disableElevation
            onClick={onClose}
            variant="contained"
          >
            {getText("common.prompts.done")}
          </Button>
        </footer>
      )}
    </>
  );
};

FileUploadFromFolder.propTypes = {};

export default FileUploadFromFolder;
