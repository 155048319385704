import React from "react";
import PropTypes from "prop-types";
import { format } from "date-fns/esm";
import { useSelector } from "react-redux";
import clsx from "clsx";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Config
import colors from "../../config/colors";

// Components
import Avatar from "../Avatar";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    flex: "1 1",
    display: "flex"
  },
  userData: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(4)
  },
  userDataText: {
    fontFamily: ["Gibson Semibold", "sans-serif"].join(","),
    fontSize: 13,
    color: colors.santasGray,
    marginLeft: theme.spacing(1),
    letterSpacing: 0.5
  },
  declaration: {
    whiteSpace: "pre-wrap",
    display: "block",
    lineHeight: 1.5
  },
  avatar: {
    width: "1.2em",
    height: "1.2em",
    fontSize: 14
  },
  box: {
    borderBottom: "1px solid rgba(69, 79, 99, 0.15)",
    paddingBottom: theme.spacing(3),
    minWidth: "100%"
  },
  subTitle: {
    marginBottom: theme.spacing(3)
  }
}));

const UserAddition = ({
  author = {},
  className,
  date,
  hideUserInfo = false,
  subTitle,
  text,
  ...props
}) => {
  const classes = useStyles();
  const { fullName, id } = author;
  const { currentUser } = useSelector(state => state.user);
  const displayName = id === currentUser ? "You" : fullName;

  return (
    <div className={clsx(classes.root, className)} {...props}>
      <div className={classes.box}>
        {subTitle && (
          <Text className={classes.subTitle} type="heading4">
            {subTitle}
          </Text>
        )}
        {!hideUserInfo && (
          <div className={classes.userData}>
            <Avatar className={classes.avatar} src={author.profilePictureURL}>
              {fullName.charAt(0)}
            </Avatar>
            <Text className={classes.userDataText}>
              {` by ${displayName || "Unknown User"} On ${
                date
                  ? format(new Date(date), "dd/MM/yyyy 'at' HH:mm")
                  : "Unknown Date"
              }`}
            </Text>
          </div>
        )}
        <Text className={classes.declaration} key={text} type="contentLabel3">
          {text}
        </Text>
      </div>
    </div>
  );
};

UserAddition.propTypes = {
  author: PropTypes.shape({
    fullName: PropTypes.string,
    id: PropTypes.string,
    profilePictureURL: PropTypes.string
  }).isRequired,
  className: PropTypes.string,
  date: PropTypes.string.isRequired,
  hideUserInfo: PropTypes.bool,
  subTitle: PropTypes.string,
  text: PropTypes.string
};

UserAddition.defaultProps = {
  className: "",
  hideUserInfo: false,
  subTitle: "",
  text: ""
};

export default UserAddition;
