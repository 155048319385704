import React from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Config
import colors from "../../config/colors";
import useTranslate from "../../hooks/useTranslate";

// Components
import Avatar from "../Avatar";
import ContributorStatusChip from "../ContributorStatusChip";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: colors.cloudBurst
  },
  avatar: {
    height: 38,
    width: 38,
    marginRight: theme.spacing(1.5)
  },
  username: {
    marginBottom: theme.spacing(1)
  }
}));

const ContributorItem = ({ boldName, className, hidePending, user }) => {
  const { data } = useSelector(s => s.caseDetail);
  const { userDetails } = useSelector(s => s.user);
  const classes = useStyles();
  const displayName = boldName ? <b key={0}>{user.fullName}</b> : user.fullName;
  const getText = useTranslate();

  const isCreator =
    user.id === "currentUser"
      ? data.creatorId === userDetails.id
      : data.creatorId === user.id;

  return (
    <div className={clsx(classes.root, className)}>
      <Avatar className={classes.avatar} src={user.profilePictureURL}>
        {user.fullName.charAt(0)}
      </Avatar>
      <div className={classes.content}>
        <Text className={classes.username} type="contentLabel2">
          {isCreator ? (
            getText("common.valueLabels.usernameOwner", {
              isComponent: boldName,
              1: displayName
            })
          ) : (
            <>
              {displayName}{" "}
              {!hidePending && data && (
                <ContributorStatusChip joined={user.invitationAccepted} />
              )}
            </>
          )}
        </Text>
        <Text text={`common.userTypes.${user.capacity}`} type="contentLabel3" />
      </div>
    </div>
  );
};

ContributorItem.propTypes = {
  className: PropTypes.string,
  boldName: PropTypes.bool,
  hidePending: PropTypes.bool,
  user: PropTypes.PropTypes.shape({
    capacity: PropTypes.string,
    fullName: PropTypes.string,
    id: PropTypes.string,
    invitationAccepted: PropTypes.bool,
    profilePictureURL: PropTypes.string
  }).isRequired
};

ContributorItem.defaultProps = {
  className: "",
  boldName: false,
  hidePending: false
};

export default ContributorItem;
