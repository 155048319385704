import { combineReducers } from "redux";
import constant from "lodash-es/constant";
import get from "lodash-es/get";

import createReducer from "../../utils/createReducer";

import {
  REQUEST_DEL_INJURY_FAIL,
  REQUEST_DEL_INJURY_SUCCESS,
  REQUEST_DEL_INJURY,
  REQUEST_DELETE_IMG_FAIL,
  REQUEST_DELETE_IMG_SUCCESS,
  REQUEST_DELETE_IMG,
  REQUEST_HEADERS_FAIL,
  REQUEST_HEADERS_SUCCESS,
  REQUEST_HEADERS,
  REQUEST_IMG_FAIL,
  REQUEST_IMG_SUCCESS,
  REQUEST_IMG,
  REQUEST_INJURY_FAIL,
  REQUEST_INJURY_SUCCESS,
  REQUEST_INJURY,
  REQUEST_UPLOAD_ANNOTATED_FAIL,
  REQUEST_UPLOAD_ANNOTATED_SUCCESS,
  REQUEST_UPLOAD_ANNOTATED,
  REQUEST_UPLOAD_DESCRIPTION_FAIL,
  REQUEST_UPLOAD_DESCRIPTION_SUCCESS,
  REQUEST_UPLOAD_DESCRIPTION,
  REQUEST_UPLOAD_IMG_FAIL,
  REQUEST_UPLOAD_IMG_SUCCESS,
  REQUEST_UPLOAD_IMG,
  REQUEST_UPLOAD_TREATMENT_FAIL,
  REQUEST_UPLOAD_TREATMENT_SUCCESS,
  REQUEST_UPLOAD_TREATMENT,
  RESET_IMG_UPLOADS,
  UPDATE_IMG_PROGRESS
} from "./actionTypes";
import { CLEAR_MEDIA_UPLOAD_PROGRESS } from "../caseDetail/actionTypes";

// Injuries data
const data = createReducer(null, {
  [REQUEST_INJURY_SUCCESS]: (state, { payload }) => payload,
  [REQUEST_IMG_SUCCESS]: (state, { payload }) => {
    const newState = JSON.parse(JSON.stringify(state));
    const { index, image } = payload;

    if (index || index === 0) {
      const { isProcessing, resourceURLs } = get(
        newState,
        `images.${index}`,
        {}
      );
      const shouldReplace =
        !resourceURLs || isProcessing !== image.isProcessing;

      if (shouldReplace) newState.images[index] = image;
      else return state;
    } else newState.images.push(payload.image);

    return newState;
  },
  [REQUEST_DELETE_IMG_SUCCESS]: (state, { payload }) => {
    const newState = JSON.parse(JSON.stringify(state));
    const images = newState.images.filter(
      ({ filename }) => filename !== payload
    );
    return { ...newState, images };
  }
});

const error = createReducer(null, {
  [REQUEST_INJURY]: constant(null),
  [REQUEST_INJURY_FAIL]: (state, { payload }) => payload
});

const isLoading = createReducer(false, {
  [REQUEST_INJURY]: constant(true),
  [REQUEST_INJURY_SUCCESS]: constant(false),
  [REQUEST_INJURY_FAIL]: constant(false)
});

const isLoadingImage = createReducer(false, {
  [REQUEST_IMG]: constant(true),
  [REQUEST_IMG_SUCCESS]: constant(false),
  [REQUEST_IMG_FAIL]: constant(false)
});

const requestImageError = createReducer(false, {
  [REQUEST_IMG]: constant(false),
  [REQUEST_IMG_FAIL]: constant(true)
});

const isDeletingInjury = createReducer(false, {
  [REQUEST_DEL_INJURY]: constant(true),
  [REQUEST_DEL_INJURY_SUCCESS]: constant(false),
  [REQUEST_DEL_INJURY_FAIL]: constant(false)
});

// Image Headers
const imageHeaders = createReducer(
  {},
  {
    [REQUEST_HEADERS]: (state, { payload }) => ({
      ...state,
      [payload]: null
    }),
    [REQUEST_HEADERS_FAIL]: (state, { payload }) => ({
      ...state,
      [payload]: null
    }),
    [REQUEST_HEADERS_SUCCESS]: (state, { payload }) => ({
      ...state,
      [payload.filename]: payload.data
    })
  }
);

const loadingHeaders = createReducer(
  {},
  {
    [REQUEST_HEADERS]: (state, { payload }) => {
      const newState = JSON.parse(JSON.stringify(state));
      newState[payload] = true;
      return newState;
    },
    [REQUEST_HEADERS_FAIL]: (state, { payload }) => {
      const newState = JSON.parse(JSON.stringify(state));
      newState[payload.filename] = false;
      return newState;
    },
    [REQUEST_HEADERS_SUCCESS]: (state, { payload }) => {
      const newState = JSON.parse(JSON.stringify(state));
      newState[payload.filename] = false;
      return newState;
    }
  }
);

const headersErrors = createReducer(
  {},
  {
    [REQUEST_HEADERS]: (state, { payload }) => ({
      ...state,
      [payload]: constant(null)
    }),
    [REQUEST_HEADERS_FAIL]: (state, { payload }) => ({
      ...state,
      [payload.filename]: payload.error
    })
  }
);

// Images upload
const uploadProgress = createReducer(null, {
  [UPDATE_IMG_PROGRESS]: (state, { payload }) => ({
    ...state,
    [payload.index]: (payload.progress.loaded / payload.progress.total) * 100
  }),
  [REQUEST_UPLOAD_IMG]: (state, { payload }) => ({
    ...state,
    [payload]: 0
  }),
  [REQUEST_UPLOAD_IMG_SUCCESS]: (state, { payload }) => ({
    ...state,
    [payload]: 100
  }),
  [REQUEST_UPLOAD_IMG_FAIL]: (state, { payload }) => ({
    ...state,
    [payload]: 0
  }),
  [CLEAR_MEDIA_UPLOAD_PROGRESS]: constant(null),
  [RESET_IMG_UPLOADS]: constant(null)
});

const uploadErrors = createReducer(null, {
  [REQUEST_UPLOAD_IMG_FAIL]: (state, { payload }) => ({
    ...state,
    [payload]: true
  }),
  [RESET_IMG_UPLOADS]: constant(null)
});

// Annotated Upload
const isUploadingAnnotated = createReducer(false, {
  [REQUEST_UPLOAD_ANNOTATED]: constant(true),
  [REQUEST_UPLOAD_ANNOTATED_SUCCESS]: constant(false),
  [REQUEST_UPLOAD_ANNOTATED_FAIL]: constant(false)
});

const uploadError = createReducer(null, {
  [REQUEST_UPLOAD_ANNOTATED]: constant(null),
  [REQUEST_UPLOAD_ANNOTATED_FAIL]: (state, { payload }) => payload
});

// Image Delete
const isDeletingImage = createReducer(null, {
  [REQUEST_DELETE_IMG]: (state, { payload }) => payload,
  [REQUEST_DELETE_IMG_SUCCESS]: constant(null),
  [REQUEST_DELETE_IMG_FAIL]: constant(null)
});

const deleteError = createReducer(null, {
  [REQUEST_DELETE_IMG]: constant(null),
  [REQUEST_DELETE_IMG_FAIL]: (state, { payload }) => payload
});

// Treatment Upload
const isUploadingTreatment = createReducer(false, {
  [REQUEST_UPLOAD_TREATMENT]: constant(true),
  [REQUEST_UPLOAD_TREATMENT_SUCCESS]: constant(false),
  [REQUEST_UPLOAD_TREATMENT_FAIL]: constant(false)
});

const treatmentError = createReducer(null, {
  [REQUEST_UPLOAD_TREATMENT]: constant(null),
  [REQUEST_UPLOAD_TREATMENT_FAIL]: (state, { payload }) => payload
});

// Description Upload
const isUploadingDescription = createReducer(false, {
  [REQUEST_UPLOAD_DESCRIPTION]: constant(true),
  [REQUEST_UPLOAD_DESCRIPTION_SUCCESS]: constant(false),
  [REQUEST_UPLOAD_DESCRIPTION_FAIL]: constant(false)
});

const descriptiontError = createReducer(null, {
  [REQUEST_UPLOAD_DESCRIPTION]: constant(null),
  [REQUEST_UPLOAD_DESCRIPTION_FAIL]: (state, { payload }) => payload
});

export default combineReducers({
  data,
  deleteError,
  descriptiontError,
  error,
  headersErrors,
  imageHeaders,
  isDeletingImage,
  isDeletingInjury,
  isLoading,
  isLoadingImage,
  requestImageError,
  isUploadingAnnotated,
  isUploadingDescription,
  isUploadingTreatment,
  loadingHeaders,
  treatmentError,
  uploadError,
  uploadErrors,
  uploadProgress
});
