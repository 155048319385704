import React from "react";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

// Config
import { urls } from "../../config/routes";
import colors from "../../config/colors";
import useTranslate from "../../hooks/useTranslate";

// Components
import Link from "../Link";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2.5, 0, 1.5),
    width: "100%",
    display: "flex"
  },
  link: {
    textTransform: "uppercase"
  },
  chevron: {
    marginRight: theme.spacing(2)
  },
  text: {
    color: colors.cloudBurst
  }
}));

const GoBackLink = ({ text, to, values, onClick }) => {
  const classes = useStyles();
  const getText = useTranslate();
  const string = text || "caseMenu.goBack";
  return (
    <div className={classes.root}>
      <Link onClick={onClick} to={to || urls.cases} className={classes.link}>
        <Text type="valueLabel" className={classes.text}>
          <FontAwesomeIcon className={classes.chevron} icon={faChevronLeft} />
          {getText(string, values)}
        </Text>
      </Link>
    </div>
  );
};

GoBackLink.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string,
  values: PropTypes.shape({}),
  onClick: PropTypes.func
};

GoBackLink.defaultProps = {
  text: null,
  to: null,
  values: null,
  onClick: () => {}
};

export default GoBackLink;
