import { combineReducers } from "redux";
import constant from "lodash-es/constant";

import createReducer from "../../utils/createReducer";

import {
  REQUEST_INFO_REQUESTS,
  REQUEST_INFO_REQUESTS_SUCCESS,
  REQUEST_INFO_REQUESTS_FAIL,
  REQUEST_MESSAGES,
  REQUEST_MESSAGES_SUCCESS,
  REQUEST_MESSAGES_FAIL,
  REQUEST_NEW_INFO,
  REQUEST_NEW_INFO_FAIL,
  REQUEST_NEW_INFO_SUCCESS
} from "./actionTypes";

// Data
const data = createReducer(null, {
  [REQUEST_INFO_REQUESTS_SUCCESS]: (state, { payload }) => payload,
  [REQUEST_NEW_INFO_SUCCESS]: (state, { payload }) => [...state, payload],
  [REQUEST_INFO_REQUESTS]: constant(null)
});

const error = createReducer(null, {
  [REQUEST_INFO_REQUESTS]: constant(null),
  [REQUEST_INFO_REQUESTS_FAIL]: (state, { payload }) => payload
});

const isLoading = createReducer(false, {
  [REQUEST_INFO_REQUESTS]: constant(true),
  [REQUEST_INFO_REQUESTS_SUCCESS]: constant(false),
  [REQUEST_INFO_REQUESTS_FAIL]: constant(false)
});

// Messages
const messages = createReducer(null, {
  [REQUEST_MESSAGES_SUCCESS]: (state, { payload }) => ({
    ...state,
    ...payload
  }),
  [REQUEST_MESSAGES]: constant(null)
});

const messagesError = createReducer(null, {
  [REQUEST_MESSAGES]: constant(null),
  [REQUEST_MESSAGES_FAIL]: (state, { payload }) => ({
    ...state,
    ...payload
  })
});

const loadingMessages = createReducer(false, {
  [REQUEST_MESSAGES]: constant(true),
  [REQUEST_MESSAGES_SUCCESS]: constant(false),
  [REQUEST_MESSAGES_FAIL]: constant(false)
});

// Create Request
const isCreating = createReducer(false, {
  [REQUEST_NEW_INFO]: constant(true),
  [REQUEST_NEW_INFO_SUCCESS]: constant(false),
  [REQUEST_NEW_INFO_FAIL]: constant(false)
});

export default combineReducers({
  data,
  error,
  isLoading,
  messages,
  messagesError,
  loadingMessages,
  isCreating
});
