import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { format } from "date-fns/esm";

// Lodash
import get from "lodash-es/get";
import isEmpty from "lodash-es/isEmpty";
import omit from "lodash-es/omit";

// Formik
import * as Yup from "yup";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

// Icons
import send from "../../assets/icons/send.svg";

// Config
import { contactPreferences } from "../../config/values";
import { getRoute, urls } from "../../config/routes";
import { snackbarTypes } from "../../config/snackbar";
import countries from "../../config/countries";
import customEvents from "../../config/customEvents";
import styles from "../../config/styles";

// Utils
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import incidents, { fromIncidentToForm } from "../../utils/incidents";

// Hooks
import { useFeatureFlags } from "../../context/FeatureFlagsProvider";
import useTranslate from "../../hooks/useTranslate";

// Actions
import { getCaseDetail, submitCase } from "../../state/caseDetail/actions";
import { toggleSnack } from "../../state/snackbar/actions";
import { updateCaseIncident } from "../../state/incident/actions";

// Components
import FormikHandler from "../../components/FormikHandler";
import LoadingSection from "../../components/LoadingSection";

// Handler
import CaseSubmitFormHandler from "./CaseSubmitFormHandler";
import ConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog";
import featureFlagKeys from "../../config/featureFlagKeys";

const useStyles = makeStyles({
  root: {
    border: styles.values.border
  },
  submitButton: {
    marginRight: "auto",
    minWidth: 300
  }
});

// Contact Form Input Names
const startTimeName = contactPreferences.startTime.name;
const endTimeName = contactPreferences.endTime.name;
const channelName = contactPreferences.channel.name;
const notesName = contactPreferences.notes.name;
const emailName = contactPreferences.email.name;
const phoneNumberName = contactPreferences.phoneNumber.name;

const validationSchema = (texts, displayContact) => {
  const validationData = {
    description: Yup.string().required(texts.required),
    location: Yup.string().required(texts.required)
  };

  if (displayContact) {
    validationData.contact = Yup.object().shape({
      [startTimeName]: Yup.string(),
      [endTimeName]: Yup.string(),
      [channelName]: Yup.string(),
      [notesName]: Yup.string(),
      [emailName]: Yup.string().when(channelName, {
        is: contactPreferences.channel.values.EMAIL,
        then: schema =>
          schema.email(texts.invalidEmail).required(texts.required),
        otherwise: schema => schema
      }),
      [phoneNumberName]: Yup.string()
        .matches(/^[0-9]*$/, { message: texts.phoneNumber.mustBe })
        .min(4, texts.phoneNumber.tooShort)
        .when(channelName, {
          is: contactPreferences.channel.values.PHONE,
          then: schema => schema.required(texts.required),
          otherwise: schema => schema
        })
    });
  }

  return Yup.object().shape(validationData);
};

const defaultContactValues = {
  contact: {
    [channelName]: contactPreferences.channel.values.EMAIL,
    [startTimeName]: "10:00",
    [endTimeName]: "11:00",
    [notesName]: "",
    [emailName]: "",
    [phoneNumberName]: ""
  },
  country: countries[233]
};

const CaseIncidentsSubmit = () => {
  const { caseId } = useParams();
  const { featureFlags } = useFeatureFlags();
  const { trackException, trackEvent } = useAppInsights();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  // Selectors
  const { data, isLoading, isSubmitting } = useSelector(s => s.caseDetail);
  const { isLoading: isLoadingIncident } = useSelector(s => s.caseIncident);
  const { userDetails } = useSelector(s => s.user);
  const incident = get(data, "incident") || {};

  // State
  const [coord, setCoord] = useState(null);
  const [dateTimeError, setDateTimeError] = useState("");
  const [formValues, setFormValues] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);

  // Texts
  const getText = useTranslate();
  const schemaTexts = getText("common.validationSchema");
  const maxDateError = getText("common.validationSchema.examinationDateMax", {
    date: format(new Date(data.dateTimeOfExamination), "dd/MM/yy HH:mm")
  });
  const prompts = getText("common.prompts");

  // Flags
  const isCreator = userDetails && data && userDetails.id === data.creatorId;

  useEffect(() => {
    if (!initialized && !isEmpty(incident)) {
      setInitialized(true);
      setCoord(get(incident, "location.coordinate"));
    }
  }, [incident, initialized]);

  if (isLoading) {
    return (
      <Paper elevation={0} className={classes.root}>
        <LoadingSection normalHeight />
      </Paper>
    );
  }

  const initialValues = fromIncidentToForm(incident, true, isCreator);
  const displayContact = featureFlags[featureFlagKeys.SUBMIT_CONTACT];
  const omitIncidents =
    initialValues.descriptions.length > 0 && initialValues.dateTime !== null;

  const onSubmit = (values, { setSubmitting, setFieldError }) => {
    let error = false;

    if (!coord) {
      setFieldError("location", schemaTexts.invalidSelect);
      error = true;
    }

    if (!values.dateTime) {
      setDateTimeError(schemaTexts.required);
      error = true;
    }

    const maxDate = new Date(data.dateTimeOfExamination);
    if (values.dateTime > maxDate) {
      setDateTimeError(maxDateError);
      error = true;
    }

    setSubmitting(false);

    if (error) return;
    setFormValues(values);
    setShowSubmit(true);
  };

  const handleSubmit = () => {
    setShowSubmit(false);

    const showError = () => {
      dispatch(toggleSnack({ type: snackbarTypes.ERROR }));
    };

    const onSuccess = () => {
      history.push(getRoute(urls.caseDetail, { caseId }));
      dispatch(
        getCaseDetail({
          caseId,
          onFail: () => {
            showError();
            trackException(`Fail to get details from case: ${caseId}`);
          }
        })
      );
    };

    const { contact, country, ...incidentValues } = formValues;

    const onFinish = success => {
      if (success) {
        trackEvent(customEvents.SUBMIT_CASE);
        let body = {};

        if (displayContact) {
          if (
            contact[channelName] === contactPreferences.channel.values.PHONE
          ) {
            body = omit(contact, emailName);
            body[
              phoneNumberName
            ] = `+${country.phone}${contact[phoneNumberName]}`;
          } else {
            body = omit(contact, phoneNumberName);
          }
        }

        dispatch(
          submitCase({
            caseId,
            onSuccess,
            body,
            onFail: () => {
              showError();
              trackException(`Failed to submit case: ${caseId}`);
            }
          })
        );
      } else showError();
    };

    const incidentData = {
      caseId,
      incident: {
        ...incidents({
          coord,
          incident,
          isDraft: true,
          values: incidentValues,
          isCreator
        }),
        descriptions: [incidentValues.description]
      },
      isDraft: true,
      author: {
        ...userDetails,
        profilePictureURL: userDetails.profilePhotoURL
      },
      onFinish
    };

    if (omitIncidents) onFinish(true);
    else dispatch(updateCaseIncident(incidentData));
  };

  const handleClose = () => {
    setShowSubmit(false);
    setFormValues(null);
  };

  return (
    <>
      <FormikHandler
        formik={{
          initialValues: {
            ...defaultContactValues,
            ...initialValues,
            description: initialValues.descriptions[0],
            location: get(incident, "location.name", "")
          },
          onSubmit,
          validateOnBlur: false,
          validateOnChange: false,
          validationSchema: validationSchema(schemaTexts, displayContact)
        }}
        render={formikProps => (
          <CaseSubmitFormHandler
            {...formikProps}
            omitIncidents={omitIncidents}
            displayContact={displayContact}
            coord={coord}
            dateTimeError={dateTimeError}
            setCoord={setCoord}
            setDateTimeError={setDateTimeError}
            maxDate={data.dateTimeOfExamination}
          />
        )}
      />
      <ConfirmationDialog
        button={prompts.submit}
        buttonsColumn
        cancel={prompts.cancel}
        icon={send}
        isLoading={Boolean(isLoadingIncident || isSubmitting)}
        onClose={handleClose}
        onConfirm={handleSubmit}
        open={showSubmit}
        texts={[
          { key: 1, text: getText("caseDetailPage.submit.part1") },
          {
            key: 2,
            text: getText("caseDetailPage.submit.part2"),
            spacing: true
          },
          { key: 3, text: getText("caseDetailPage.submit.part3") }
        ]}
        type="submit"
      />
    </>
  );
};

export default CaseIncidentsSubmit;
