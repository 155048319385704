import React, { useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";

// Icons
import editIcon from "../../../assets/icons/edit.svg";

// Config
import { useAppInsights } from "../../../utils/telemetry/AppInsights";
import { userPhotoUrl } from "../../../config/apiConfig";

// Actions
import { parseFetchOptions } from "../../../state/user/actions";

// Components
import FileUpload from "../../../components/FileUpload";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    marginRight: theme.spacing(7),
    height: "100%",
    overflow: "hidden",
    "&:hover": {
      "& button": {
        opacity: "initial"
      }
    }
  },
  iconButton: {
    position: "absolute",
    right: 0,
    bottom: 0,
    padding: theme.spacing(1),
    filter: "brightness(2)",
    opacity: 0
  },
  avatar: {
    height: 252,
    width: 252
  },
  avatarImg: {
    position: "absolute",
    minWidth: "1000%",
    minHeight: "1000%",
    left: "50%",
    top: "50%",
    transform: "translateX(-50%) translateY(-50%) scale(0.1)"
  }
}));

const UploadAvatar = ({ profilePhotoURL }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { trackException } = useAppInsights();

  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(null);
  const [errors, setErrors] = useState(null);
  const [photoUrl, setPhotoUrl] = useState(profilePhotoURL);

  const toggleEditAvatar = value => () => setOpen(value);

  const handleSubmitAvatar = data => {
    const [{ file }] = data;

    dispatch(
      parseFetchOptions(options => {
        axios
          .request({
            method: "PUT",
            headers: {
              ...options.headers,
              "Content-Type": "image/jpeg"
            },
            data: file,
            url: userPhotoUrl,
            onUploadProgress: ({ loaded, total }) => {
              setProgress({ 0: (loaded / total) * 100 });
            }
          })
          .then(res => setPhotoUrl(res.data.url))
          .catch(e => {
            trackException("Failed to update user photo");
            setErrors({ 0: e });
          });
      })
    );
  };

  return (
    <>
      <div className={classes.root}>
        <Avatar
          src={photoUrl}
          variant="rounded"
          className={classes.avatar}
          imgProps={{ className: classes.avatarImg }}
        />
        <IconButton
          onClick={toggleEditAvatar(true)}
          className={classes.iconButton}
        >
          <img alt="" src={editIcon} />
        </IconButton>
      </div>
      <FileUpload
        accept="image/*"
        noDescriptions
        onClose={toggleEditAvatar(false)}
        onSubmit={handleSubmitAvatar}
        open={open}
        uploadErrors={errors}
        uploadProgress={progress}
      />
    </>
  );
};

UploadAvatar.propTypes = {
  profilePhotoURL: PropTypes.string
};

UploadAvatar.defaultProps = {
  profilePhotoURL: null
};

export default UploadAvatar;
