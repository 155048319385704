import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import get from "lodash-es/get";

// Config
import { sensitiveSections } from "../../config/values";
import colors from "../../config/colors";

// Actions
import { getImage } from "../../state/injuries/actions";

// Hooks
import useInjuries from "../../hooks/useInjuries";

// Components
import EditInjuryImageButtons from "./EditInjuryImageButtons";
import ImageItem from "../ImageItem";
import ImageProcessing from "../ImageProcessing";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
    margin: theme.spacing(0, 0.75),
    borderRadius: theme.shape.borderRadius,
    position: "relative",

    "&:last-child": {
      marginRight: "auto"
    }
  },
  imageItem: {
    backgroundColor: colors.whisper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 152,
    height: 125,
    borderRadius: theme.shape.borderRadius
  }
}));

const EditInjuryImage = ({
  handleDeleteImage,
  handleSelect,
  image,
  imageHeaders,
  imageRequest,
  index,
  isDeletingImage,
  loadingHeaders
}) => {
  const { caseId, injuryId } = useParams();
  const { data } = useInjuries(caseId, injuryId);
  const caseDetail = useSelector(s => s.caseDetail);
  const classes = useStyles();
  const dispatch = useDispatch();

  const updateImage = request => () => dispatch(getImage(request));

  if (image.isProcessing) {
    return (
      <div className={classes.root}>
        <div className={classes.imageItem}>
          <ImageProcessing
            image={image}
            getImage={updateImage({
              ...imageRequest,
              filename: image.filename,
              index
            })}
          />
        </div>
      </div>
    );
  }

  const isLoading = isDeletingImage === image.filename;
  const onDelete = handleDeleteImage(image.filename);

  const isSensitive = sensitiveSections.includes(data.bodySection);

  return (
    <div className={classes.root}>
      <ImageItem
        className={classes.imageItem}
        Component={
          get(caseDetail, "data.isDraft") ? EditInjuryImageButtons : null
        }
        componentProps={{ isLoading, onDelete }}
        image={image}
        imageHeaders={imageHeaders}
        imageRequest={imageRequest}
        isSensitive={isSensitive}
        loadingHeaders={loadingHeaders}
        onClick={handleSelect(index)}
      />
    </div>
  );
};

EditInjuryImage.propTypes = {
  handleDeleteImage: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  image: PropTypes.shape({
    filename: PropTypes.string,
    isProcessing: PropTypes.bool
  }).isRequired,
  imageHeaders: PropTypes.shape({}).isRequired,
  imageRequest: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
  isDeletingImage: PropTypes.string,
  loadingHeaders: PropTypes.shape({}).isRequired
};

EditInjuryImage.defaultProps = {
  isDeletingImage: ""
};

export default EditInjuryImage;
