import React, { useEffect, useState } from "react";
import { Route, useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// Material
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";

// Config
import { getRoute, urls } from "../../config/routes";
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import styles from "../../config/styles";

// Actions
import {
  getMedia,
  getMediaItem,
  deleteMedia,
  clearMediaError
} from "../../state/media/actions";

// Components
import AddMedia from "../AddMedia";
import CaseMediaTips from "../CaseMediaTips";
import CaseMediaWhatsNew from "../CaseMediaWhatsNew";
import ContentCardHeader from "../ContentCardHeader";
import GalleryHandler from "../GalleryHandler";
import LoadingSection from "../LoadingSection";
import MediaItemList from "../MediaItemList";
import Text from "../Text";

// Images
import lightBulb from "../../assets/icons/light_bulb.svg";

const useStyles = makeStyles(theme => ({
  root: {
    border: styles.values.border
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(5),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap"
  }
}));

const CaseMediaPanel = () => {
  const { caseId } = useParams();
  const { currentCase, data, isLoading, isDeleting, error } = useSelector(
    state => state.media
  );
  const { data: caseData } = useSelector(s => s.caseDetail);
  const { trackException } = useAppInsights();
  const [showTips, setShowTips] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    return () => {
      dispatch(clearMediaError());
    };
  }, [dispatch]);

  useEffect(() => {
    if ((currentCase !== caseId || !data) && !error && !isLoading) {
      dispatch(
        getMedia({
          caseId,
          onFail: () =>
            trackException(`Failed to get media from case: ${caseId}`)
        })
      );
    }
  }, [caseId, currentCase, data, dispatch, error, isLoading, trackException]);

  if (isLoading) {
    return (
      <Paper elevation={0} className={classes.root}>
        <LoadingSection normalHeight />
      </Paper>
    );
  }

  const handleDelete = record => () => dispatch(deleteMedia(record));
  const handleFetchImage = mediaId => () => {
    dispatch(
      getMediaItem({
        caseId,
        mediaId,
        onFail: () =>
          trackException(
            `Failed to get media item: ${mediaId} at case: ${caseId}`
          )
      })
    );
  };

  const handleSelect = itemId => () => {
    history.push(getRoute(urls.caseMediaDetail, { caseId, itemId }));
  };
  const handleShowTips = () => setShowTips(true);
  const handleCloseTips = () => setShowTips(false);

  return (
    <>
      <Paper elevation={0} className={classes.root}>
        <ContentCardHeader>
          <Text text="mediaPage.title" type="heading2" />
          <div className={classes.buttonWrapper}>
            <IconButton
              onClick={handleShowTips}
              className={classes.openTipsButton}
            >
              <img src={lightBulb} alt="" />
            </IconButton>
            <AddMedia caseId={caseId} />
          </div>
        </ContentCardHeader>
        {data && (
          <div className={classes.content}>
            <MediaItemList
              caseId={caseId}
              data={data}
              mediaItemProps={{ isDeleting, showDelete: caseData.isDraft }}
              onDelete={handleDelete}
              onFetchImage={handleFetchImage}
              onSelect={handleSelect}
              page="mediaPage"
            />
          </div>
        )}
      </Paper>
      <CaseMediaTips
        onClose={handleCloseTips}
        open={showTips}
        page="mediaPage"
      />
      {data && <CaseMediaWhatsNew defaultClosed={data.length > 0} />}
      <Route
        path={urls.caseMediaDetail}
        exact
        render={renderProps =>
          data &&
          data.length > 0 && (
            <GalleryHandler
              {...renderProps}
              data={data}
              defaultRoute={urls.caseMedia}
              detailRoute={urls.caseMediaDetail}
              view="media"
            />
          )
        }
      />
    </>
  );
};

export default CaseMediaPanel;
