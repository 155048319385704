import React from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const CustomTooltip = withStyles(theme => ({
  arrow: {
    color: theme.palette.common.white,
    "&::before": {
      border: "1px solid #F0F0F0"
    }
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 11,
    padding: theme.spacing(1.5),
    border: "1px solid #F0F0F0",
    boxShadow: "0px 1px 9px #00000015"
  }
}))(Tooltip);

const useStyles = makeStyles(theme => ({
  tooltip: {
    padding: ({ padding }) =>
      padding !== null ? padding : theme.spacing(1, 2),
    maxWidth: ({ maxWidth }) => maxWidth
  }
}));

const LightTooltip = ({ padding, maxWidth, ...props }) => (
  <CustomTooltip classes={useStyles({ padding, maxWidth })} {...props} />
);

LightTooltip.propTypes = {
  padding: PropTypes.number,
  maxWidth: PropTypes.number
};

LightTooltip.defaultProps = {
  padding: null,
  maxWidth: 300
};
export default LightTooltip;
