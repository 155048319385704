import { RESET_SNACK, TOGGLE_SNACK } from "./actionTypes";

export const toggleSnack = payload => ({
  type: TOGGLE_SNACK,
  payload
});

export const resetSnack = () => ({
  type: RESET_SNACK
});
