import React from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Components
import Text from "../Text";
import TopTipsDrawer from "../TopTipsDrawer/TopTipsDrawer";

// Hooks
import useTranslate from "../../hooks/useTranslate";

// images
import mediaTipsImg from "../../assets/images/media-tips.png";

const useStyles = makeStyles(theme => ({
  contentTitle: {
    marginBottom: theme.spacing(3)
  },
  tipItem: {
    marginBottom: theme.spacing(2),
    lineHeight: 1.5,
    maxWidth: 600,
    fontSize: 18
  },
  marker: {
    marginRight: theme.spacing(1),
    fontFamily: ["Gibson SemiBold", "sans-serif"].join(",")
  },
  tipsImage: {
    width: 288
  }
}));

const CaseMediaTips = ({ page, ...rest }) => {
  const classes = useStyles();
  const getText = useTranslate();

  const { title, ...texts } = getText(`${page}.guidance`);
  const tips = Object.keys(texts).filter(item => item.includes("item"));

  return (
    <TopTipsDrawer {...rest} pageTitle={`${page}.title`}>
      <img src={mediaTipsImg} alt="" className={classes.tipsImage} />
      <Text type="heading1" className={classes.contentTitle}>
        {title}
      </Text>
      {tips.map((tipItem, index) => (
        <Text className={classes.tipItem} key={tipItem} type="subtitle1Small">
          <span className={classes.marker}>{index + 1}.</span>
          {texts[tipItem]}
        </Text>
      ))}
    </TopTipsDrawer>
  );
};

CaseMediaTips.propTypes = {
  page: PropTypes.string.isRequired
};

export default CaseMediaTips;
