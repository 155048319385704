import React from "react";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Components
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(0, 2)
  },
  disclaimerWrapper: {
    width: 567,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  disclaimerText: {
    opacity: "50%",
    fontSize: 11
  }
}));

const FooterDisclaimer = () => {
  const classes = useStyles();
  return (
    <div className={classes.disclaimerWrapper}>
      <Text
        align="center"
        className={classes.disclaimerText}
        text="footer.disclaimer"
        type="subtitle1Small"
      />
    </div>
  );
};

export default FooterDisclaimer;
