import React from "react";
import PropTypes from "prop-types";

// Font Awesome
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Paper from "@material-ui/core/Paper";

// Hooks
import useTranslate from "../../hooks/useTranslate";

// Config
import colors from "../../config/colors";
import styles from "../../config/styles";

// Components
import Button from "../Button";
import ContentCardFooter from "../ContentCardFooter";
import ContentCardHeader from "../ContentCardHeader";
import FooterDisclaimer from "../FooterDisclaimer";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(6, 2),
    alignItems: "center"
  },
  contentWrapper: {
    flexDirection: "column",
    width: 730,
    marginBottom: theme.spacing(2)
  },
  goBackButton: {
    marginBottom: theme.spacing(1)
  },
  goBackLink: {
    textTransform: "uppercase"
  },
  chevron: {
    marginRight: theme.spacing(2)
  },
  goBackText: {
    color: colors.cloudBurst,
    textTransform: "uppercase"
  },
  paper: {
    border: styles.values.border,
    marginBottom: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(4, 6),
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  footer: {
    marginBottom: theme.spacing(6)
  }
}));

const TopTipsDrawer = ({ onClose, open, children, footer, pageTitle }) => {
  const classes = useStyles();
  const getText = useTranslate();

  return (
    <Drawer anchor="bottom" onClose={onClose} open={open}>
      <div className={classes.root}>
        <div className={classes.contentWrapper}>
          <Button className={classes.goBackButton} onClick={onClose}>
            <Text type="valueLabel" className={classes.goBackText}>
              <FontAwesomeIcon
                className={classes.chevron}
                icon={faChevronLeft}
              />
              {getText(`common.valueLabels.${pageTitle ? "backTo" : "back"}`, {
                1: getText(pageTitle)
              })}
            </Text>
          </Button>
          <Paper className={classes.paper}>
            <ContentCardHeader>
              <Text
                className={classes.title}
                text="notesPage.getTips"
                type="heading2"
              />
            </ContentCardHeader>
            <div className={classes.content}>{children}</div>
            {footer && <ContentCardFooter>{footer}</ContentCardFooter>}
          </Paper>
          <div className={classes.footer}>
            <FooterDisclaimer />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

TopTipsDrawer.defaultProps = {
  footer: null,
  pageTitle: null
};

TopTipsDrawer.propTypes = {
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  pageTitle: PropTypes.string
};

export default TopTipsDrawer;
