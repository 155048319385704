import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles({
  root: {},
  loadingImage: {
    position: "relative"
  },
  loader: {
    position: "absolute",
    top: "0",
    left: "0",
    zIndex: "999",
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(0,0,0,0.2)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
});

const DisplayImage = ({ className, alt, src, ...rest }) => {
  const classes = useStyles();
  const [isLoading, setLoading] = React.useState(true);
  const image = React.useRef(null);
  const [prev, setPrev] = React.useState(src);

  const handleOnLoad = () => setLoading(false);

  React.useEffect(() => {
    if (!isLoading && prev !== src) setLoading(true);
    setPrev(src);
  }, [isLoading, prev, src]);

  React.useEffect(() => {
    if (image.current.complete) handleOnLoad();
  }, [image]);

  return (
    <div
      className={clsx(classes.root, {
        [classes.loading]: isLoading
      })}
    >
      <img
        {...rest}
        alt={alt}
        className={className}
        onLoad={handleOnLoad}
        ref={image}
        src={src}
      />
      {isLoading && (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

DisplayImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string.isRequired
};

DisplayImage.defaultProps = {
  alt: "",
  className: ""
};

export default DisplayImage;
