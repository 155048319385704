import { Redirect } from "react-router-dom";
import { urls } from "./routes";
import CasesRoutes from "../routes/CasesRoutes";

import {
  AddInjuryPage,
  CaseDetailPage,
  CaseIncidents,
  CaseIncidentsSubmit,
  CaseLogPage,
  CaseMediaPage,
  CaseNotesPage,
  CasesPage,
  ContributorDetailPage,
  CreateCasePage,
  DownloadReportPage,
  EditInjuryPage,
  InfoRequestsPage,
  InjuryDetailPage,
  InviteContributorsPage,
  LoginPage,
  MedicalRecordsPage,
  NotFoundPage,
  RedirectToAppStore,
  SendEvidencePage,
  SettingsPage,
  ToolPage
} from "../containers";
import featureFlagKeys from "./featureFlagKeys";

const casesRoutes = [
  {
    isExact: true,
    Component: CreateCasePage,
    title: "createCase",
    id: "CreateCasePage",
    path: urls.createCase
  },
  {
    isExact: true,
    Component: ContributorDetailPage,
    title: "contributorDetails",
    id: "ContributorDetailPage",
    path: urls.contributorDetail
  },
  {
    isExact: true,
    Component: InfoRequestsPage,
    title: "infoRequests",
    id: "InfoRequestDetailPage",
    path: urls.infoRequestDetail
  },
  {
    Component: CaseNotesPage,
    title: "notes",
    id: "caseNotes",
    path: urls.caseNotes,
    featureFlag: featureFlagKeys.STATEMENTS
  },

  // Case Options Subroutes
  {
    isExact: true,
    Component: CaseLogPage,
    title: "caseLog",
    id: "caseLog",
    path: urls.caseLog
  },
  {
    Component: SendEvidencePage,
    title: "caseDownload",
    id: "CaseDownloadPage",
    path: urls.caseDownload
  },
  {
    isExact: true,
    Component: CaseIncidents,
    title: "caseIncidents",
    id: "CaseIncidents",
    path: urls.incident
  },
  {
    isExact: true,
    Component: CaseIncidentsSubmit,
    title: "caseIncidents",
    id: "CaseIncidentsSubmit",
    path: urls.submitIncident
  },
  {
    Component: MedicalRecordsPage,
    title: "medicalRecords",
    id: "MedicalRecordsPage",
    path: urls.medicalRecords
  },
  {
    Component: CaseMediaPage,
    title: "media",
    id: "CaseMediaPage",
    path: urls.caseMedia
  },
  {
    isExact: true,
    Component: InfoRequestsPage,
    title: "infoRequests",
    id: "InfoRequestsPage",
    path: urls.infoRequests
  },
  {
    isExact: true,
    Component: InviteContributorsPage,
    title: "inviteContributors",
    id: "InviteContributorsPage",
    path: urls.inviteContributor
  },

  // Injuries
  {
    Component: EditInjuryPage,
    title: "editInjury",
    id: "EditInjuryPage",
    path: urls.editInjury
  },
  {
    Component: ToolPage,
    title: "annotationTool",
    id: "ToolPage",
    path: urls.annotationTool
  },
  {
    Component: AddInjuryPage,
    title: "addInjury",
    id: "AddInjuryPage",
    path: urls.addInjury
  },
  {
    Component: InjuryDetailPage,
    title: "injuryDetails",
    id: "InjuryDetailPage",
    path: urls.injuryDetail
  },

  // Fallback Routes
  {
    Component: CaseDetailPage,
    title: "caseDetails",
    id: "CaseDetailPage",
    path: urls.caseDetail
  },
  {
    isExact: true,
    Component: CasesPage,
    id: "CasesPage",
    path: urls.cases
  }
];

export const publicRoutes = [
  {
    isExact: true,
    Component: RedirectToAppStore,
    title: "redirect",
    id: "appstores",
    path: urls.downloadApp
  },
  {
    isExact: true,
    Component: DownloadReportPage,
    title: "downloadReport",
    id: "DownloadReport",
    path: urls.downloadReport
  },
  {
    isExact: true,
    Component: NotFoundPage,
    title: "notFound",
    id: "NotFoundPage",
    path: urls.notFound
  },
  {
    isExact: true,
    Component: LoginPage,
    title: "login",
    id: "login",
    path: urls.login
  },
  {
    isExact: true,
    Component: Redirect,
    title: "logout",
    id: "logout",
    path: urls.logout,
    props: {
      to: urls.cases
    }
  }
];

export const privateRoutes = [
  {
    Component: CasesRoutes,
    id: "CasesPage",
    path: urls.cases,
    props: {
      routes: casesRoutes
    }
  },
  {
    isExact: true,
    Component: SettingsPage,
    title: "settings",
    id: "SettingsPage",
    path: urls.settings
  }
];
