import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// Config
import { getCaseLog } from "../config/apiConfig";
import { useAppInsights } from "../utils/telemetry/AppInsights";
import { parseFetchOptions } from "../state/user/actions";

// Snackbar
import { snackbarTypes } from "../config/snackbar";
import { toggleSnack } from "../state/snackbar/actions";

const defaultInitialPageSize = 20;
const defaultPageSize = 5;

const useLogs = (
  initialPageSize = defaultInitialPageSize,
  pageSize = defaultPageSize,
  disableAutoLoad = false
) => {
  const { caseId } = useParams();
  const { trackException } = useAppInsights();
  const dispatch = useDispatch();

  const [logs, setLogs] = useState([]);
  const [next, setNext] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const loadMore = useCallback(
    (mounted, headers) => {
      setLoading(true);
      dispatch(
        parseFetchOptions(options => {
          fetch(getCaseLog(caseId), {
            headers: {
              ...headers,
              ...options.headers
            }
          })
            .then(r => {
              if (mounted) setNext(r.headers.get("Results-Page-Token"));
              return r.json();
            })
            .then(data => {
              if (mounted) {
                setLogs([...logs, ...data]);
                setLoading(false);
              }
            })
            .catch(e => {
              setError(e);
              dispatch(toggleSnack({ type: snackbarTypes.ERROR }));
              trackException(`Failed to get logs at case: ${caseId}`);
            });
        })
      );
    },
    [caseId, dispatch, logs, setError, setLoading, setLogs, trackException]
  );

  // Initialize
  useEffect(() => {
    let mounted = true;

    if (logs.length === 0 && !error) {
      loadMore(mounted, { "Results-Page-Size": initialPageSize });
    }

    return () => {
      mounted = false;
    };
  }, [error, logs, loadMore]);

  // Load onScroll
  useEffect(() => {
    let mounted = true;

    if (disableAutoLoad) {
      return;
    }

    const handleScroll = () => {
      if (
        !loading &&
        next &&
        window.scrollY + window.innerHeight > document.body.scrollHeight - 10
      ) {
        loadMore(mounted, {
          "Results-Page-Size": pageSize,
          "Results-Page-Token": next
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      mounted = false;
    };
  }, [loading, loadMore, next]);

  return [logs, loading];
};

export default useLogs;
