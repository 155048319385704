import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

// Images
import trashIcon from "../../assets/icons/trash.svg";

// Components
import ConfirmationButton from "../ConfirmationButton";

const useStyles = makeStyles({
  iconWrapper: {
    position: "absolute",
    bottom: 0,
    right: 0
  },
  icon: {
    width: 18,
    filter: "brightness(10)"
  }
});

const EditInjuryImageButtons = ({ isLoading, onDelete, isAnnotable }) => {
  const classes = useStyles();

  return (
    <div className={classes.iconWrapper}>
      {!isAnnotable && (
        <ConfirmationButton
          className={classes.iconButton}
          dialog={{ isLoading }}
          onClick={onDelete}
          type="icon"
        >
          <img src={trashIcon} className={classes.icon} alt="" />
        </ConfirmationButton>
      )}
    </div>
  );
};

EditInjuryImageButtons.propTypes = {
  isAnnotable: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default EditInjuryImageButtons;
