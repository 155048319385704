import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, Prompt } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";

// Formik - Material
import { makeStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Paper from "@material-ui/core/Paper";
import RadioGroup from "@material-ui/core/RadioGroup";

// Formik
import { Formik, Form } from "formik";
import * as Yup from "yup";

// Config
import { urls, getRoute } from "../../config/routes";
import colors from "../../config/colors";
import styles from "../../config/styles";

// Hooks
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import useInjuries from "../../hooks/useInjuries";
import useTranslate from "../../hooks/useTranslate";

// Actions
import {
  updateInjury,
  uploadDescriptions,
  uploadTreatment
} from "../../state/injuries/actions";

// Components
import Button from "../Button";
import CategoryItem from "./CategoryItem";
import ContentCardHeader from "../ContentCardHeader";
import EditInjuryGallery from "./EditInjuryGallery";
import InteractiveBodyMap from "./InteractiveBodyMap";
import LoadingSection from "../LoadingSection";
import Text from "../Text";
import InputList from "../Input/InputList";

const useStyles = makeStyles(theme => ({
  root: {
    border: styles.values.border
  },
  title: {
    fontSize: 24
  },
  cancelButton: {
    height: 42,
    width: 160,
    border: styles.values.border,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderColor: colors.riverBed,
    borderRadius: theme.shape.borderRadius
  },
  cancelText: {
    color: colors.riverBed
  },
  formContent: {
    padding: theme.spacing(3.5, 5),
    display: "flex",
    flexDirection: "column",
    borderBottom: styles.values.border
  },
  inputItem: {
    marginBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column"
  },
  label: {
    marginBottom: theme.spacing(1.5)
  },
  addLink: {
    textTransform: "uppercase",
    marginLeft: "auto"
  },
  headingWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2)
  },
  imagesWrapper: {
    display: "flex",
    flexDirection: "row"
  },
  imageBoxLeft: {
    border: styles.values.border,
    padding: theme.spacing(4, 2),
    marginRight: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
  imageBoxRight: {
    border: styles.values.border,
    padding: theme.spacing(2.5, 2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 307
  },
  boxText: {
    marginBottom: theme.spacing(2)
  },
  injuriesIcon: {
    width: 155,
    padding: theme.spacing(2, 0),
    marginBottom: theme.spacing(2)
  },
  sendButton: {
    width: 281,
    position: "relative",
    paddingLeft: theme.spacing(3)
  },
  learnButton: {
    width: 275
  },
  cameraIcon: {
    position: "absolute",
    left: theme.spacing(2),
    fontSize: 16
  },

  buttonContainer: {
    padding: theme.spacing(4, 5)
  },
  mediaButton: {
    marginRight: theme.spacing(2)
  },
  submit: {
    width: "100%"
  }
}));

const SignupSchema = Yup.object().shape({
  description: Yup.string()
});

const categories = [
  "StabWound",
  "IncisedWound",
  "Laceration",
  "Bruising",
  "Swelling",
  "Burn",
  "Other"
];

const loadInitialValues = (
  { bodySection, category = categories[5].value, descriptions, treatments },
  isDraft
) => ({
  bodySection,
  category,
  descriptions: isDraft ? [descriptions[0] ? descriptions[0].text : ""] : [""],
  treatments: isDraft ? [treatments[0] ? treatments[0].text : ""] : [""]
});

const filterEmpty = arrayStrings => arrayStrings.filter(s => s && s !== "");

export default () => {
  const { trackException } = useAppInsights();
  const classes = useStyles();
  const dispatch = useDispatch();
  const getText = useTranslate();
  const history = useHistory();

  const [leaving, setLeaving] = useState(null);
  const { caseId, injuryId } = useParams();
  const { data, imageHeaders, isDeletingImage, isLoading, loadingHeaders } =
    useInjuries(caseId, injuryId);

  const {
    data: { isDraft }
  } = useSelector(state => state.caseDetail);

  useEffect(() => {
    if (leaving) {
      history.push(getRoute(urls.injuryDetail, { caseId, injuryId }));
    }
  }, [caseId, history, injuryId, leaving]);

  if (isLoading || !data) return <LoadingSection />;

  const goBack = () => setLeaving(true);

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    const treatments = filterEmpty(values.treatments);
    const descriptions = filterEmpty(values.descriptions);
    if (isDraft) {
      dispatch(
        updateInjury({
          caseId,
          injuryId,
          form: {
            ...data,
            bodySection: values.bodySection,
            category: values.category,
            description: values.descriptions[0],
            treatment: values.treatments[0]
          },
          onFinish: success => {
            setSubmitting(false);
            if (success) goBack();
            else {
              trackException(
                `Failed to update injury: ${injuryId} at case: ${caseId}`
              );
            }
          }
        })
      );
    } else
      dispatch(
        uploadTreatment({
          caseId,
          injuryId,
          texts: treatments,
          onFinish: successTreatment => {
            if (descriptions.length > 0)
              dispatch(
                uploadDescriptions({
                  caseId,
                  injuryId,
                  texts: descriptions,
                  onFinish: successDescription => {
                    setSubmitting(false);
                    if (successDescription && successTreatment) goBack();
                    else {
                      trackException(
                        `Failed to add description or treatment to injury: ${injuryId} at case: ${caseId}`
                      );
                    }
                  }
                })
              );
            else {
              setSubmitting(false);
              resetForm();
              if (successTreatment) goBack();
              else {
                trackException(
                  `Failed to add treatment to injury: ${injuryId} at case: ${caseId}`
                );
              }
            }
          }
        })
      );
  };

  const getConstantValues = arr => (data && !isDraft ? data[arr] : []);

  return (
    <Paper elevation={0} className={classes.root}>
      <ContentCardHeader>
        <Text
          className={classes.title}
          text={`common.bodySection.${data.bodySection}`}
          type="heading2"
        />
        <Button onClick={goBack} className={classes.cancelButton}>
          <Text
            className={classes.cancelText}
            text="common.prompts.cancel"
            type="contentLabel3"
          />
        </Button>
      </ContentCardHeader>
      <Prompt
        message={() => getText("editInjuryPage.exitPrompt")}
        when={!leaving}
      />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Formik
          initialValues={loadInitialValues(data, isDraft)}
          onSubmit={onSubmit}
          validateOnBlur={false}
          validationSchema={SignupSchema}
        >
          {({ errors, isSubmitting, submitForm, values, setValues }) => (
            <Form>
              <div className={classes.formContent}>
                <InputList
                  addButtonText={getText(
                    "editInjuryPage.descriptionAddButton"
                  ).toUpperCase()}
                  canAddMore={!isDraft}
                  className={classes.inputItem}
                  constantValues={getConstantValues("descriptions")}
                  error={errors.descriptions}
                  label={getText("editInjuryPage.descriptionLabel")}
                  multiline
                  name="descriptions"
                  placeholder={getText("editInjuryPage.descriptionPlaceholder")}
                  title={getText("editInjuryPage.provideDescriptionTitle")}
                  values={values.descriptions}
                />

                <InputList
                  addButtonText={getText(
                    "editInjuryPage.treatmentInputAddButton"
                  ).toUpperCase()}
                  canAddMore={!isDraft}
                  className={classes.inputItem}
                  constantValues={getConstantValues("treatments")}
                  error={errors.treatments}
                  label={getText("editInjuryPage.treatmentInputLabel")}
                  multiline
                  name="treatments"
                  placeholder={getText(
                    "editInjuryPage.treatmentInputPlaceholder"
                  )}
                  title={getText("editInjuryPage.treatmentInputTitle")}
                  values={values.treatments}
                />

                <div className={classes.inputItem}>
                  <div className={classes.headingWrapper}>
                    <Text
                      text="editInjuryPage.categoryTitle"
                      type="subtitleBold"
                    />
                  </div>
                  <RadioGroup
                    aria-label="gender"
                    name="category"
                    onChange={({ target }) =>
                      setValues({ ...values, category: target.value })
                    }
                  >
                    {categories.map(key => (
                      <CategoryItem
                        category={key}
                        checked={
                          values.category.toLowerCase() === key.toLowerCase()
                        }
                        isDraft={isDraft}
                        key={key}
                      />
                    ))}
                  </RadioGroup>
                </div>

                <div className={classes.inputItem}>
                  <div className={classes.headingWrapper}>
                    <Text
                      text="common.valueLabels.bodyMap"
                      type="subtitleBold"
                    />
                  </div>
                  <InteractiveBodyMap
                    onChange={v => setValues({ ...values, bodySection: v })}
                    value={values.bodySection}
                  />
                </div>

                {data.images && (
                  <EditInjuryGallery
                    caseId={caseId}
                    imageHeaders={imageHeaders}
                    images={data.images}
                    injuryId={injuryId}
                    isDeletingImage={isDeletingImage}
                    loadingHeaders={loadingHeaders}
                  />
                )}
              </div>

              <div className={classes.buttonContainer}>
                <Button
                  className={classes.submit}
                  color="primary"
                  disabled={isSubmitting}
                  disableElevation
                  onClick={submitForm}
                  type="submit"
                  variant="contained"
                >
                  {getText("common.prompts.done")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </MuiPickersUtilsProvider>
    </Paper>
  );
};
