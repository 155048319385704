const responseError = response =>
  new Error(
    `response error: ${JSON.stringify({
      response
    })}`
  );

export default {
  responseError
};
