import React from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

// Config
import { getPlaceholder } from "../../config/values";

// Component
import colors from "../../config/colors";
import { useAppInsights } from "../../utils/telemetry/AppInsights";

const useStyles = makeStyles(theme => ({
  image: {
    display: "block",
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover"
  },
  placeholder: {
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    minHeight: 163,
    backgroundColor: colors.concrete,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  placeholderIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0
  },
  imageContainer: {
    width: "100%",
    paddingBottom: "56.25%",
    display: "block",
    position: "relative",
    overflow: "hidden",
    borderRadius: theme.shape.borderRadius,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundImage: ({ image }) => `url(${image})`
  },
  loading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: " translate(-50%, -50%)"
  }
}));

const MediaItemPlaceholder = ({ filename, src }) => {
  const [image, setImage] = React.useState(null);
  const [showPlaceholder, setShowPlaceholder] = React.useState(!src);
  const classes = useStyles({ image });
  const { trackException } = useAppInsights();

  React.useEffect(() => {
    let mounted = true;
    fetch(src)
      .then(res => {
        setShowPlaceholder(!res || !res.url || !res.url.includes("blob"));
        return res.blob();
      })
      .then(img => {
        if (mounted) setImage(window.URL.createObjectURL(img));
      })
      .catch(() => {
        trackException(`Failed to get source: ${src}`);
      });

    return () => {
      mounted = false;
    };
  }, [src, trackException]);

  return showPlaceholder ? (
    <div className={classes.placeholder}>
      <div className={classes.imageContainer}>
        <div className={classes.placeholderIcon}>
          <img src={getPlaceholder(filename)} alt="" />
        </div>
      </div>
    </div>
  ) : (
    <div className={classes.imageContainer}>
      {!image && (
        <span className={classes.loading}>
          <CircularProgress size={50} thickness={3} />
        </span>
      )}
    </div>
  );
};

MediaItemPlaceholder.propTypes = {
  filename: PropTypes.string.isRequired,
  src: PropTypes.string
};

MediaItemPlaceholder.defaultProps = {
  src: null
};

export default MediaItemPlaceholder;
