import React, { useState } from "react";

// Material
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";

// Hooks
import { useFileUpload } from "./FileUploadProvider";
import useTranslate from "../../hooks/useTranslate";

// Images
import errorIcon from "../../assets/icons/alert.svg";

// Config
import colors from "../../config/colors";

// Components
import Button from "../Button";
import FileUploadWebcamInput from "./FileUploadWebcamInput";
import Text from "../Text";

// Sources
// https://developer.mozilla.org/en-US/docs/Web/API/Media_Capture_and_Streams_API/Taking_still_photos#demo
// https://github.com/mozmorris/react-webcam/blob/master/src/react-webcam.tsx

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(3, 5),
    display: "flex",
    flexDirection: "column",
    position: "relative"
  },
  errorWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2),
    maxWidth: 400,
    padding: theme.spacing(4, 0)
  },
  previewImage: {
    display: "block",
    borderRadius: theme.shape.borderRadius,
    opacity: isUploading => (isUploading ? "0.5 !important" : "")
  },
  loadingWrapper: {
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    left: 0
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: theme.spacing(4.5),
    backgroundColor: colors.wildLand
  },
  cancelButton: {
    backgroundColor: colors.white,
    marginRight: theme.spacing(1.5),
    width: 180
  },
  uploadButton: {
    width: 180
  }
}));

const width = 490;

const FileUploadCamera = () => {
  const [photo, setPhoto] = useState(null);
  const { isUploading, onSubmit, uploadErrors, onClose } = useFileUpload();
  const classes = useStyles(isUploading);
  const getText = useTranslate();

  const handleSubmit = () => {
    const metadata = { type: photo.type };
    const file = new File([photo], `webcam-${new Date().getTime()}`, metadata);
    onSubmit([{ file }]);
  };

  const handleTakePicture = picture => {
    setPhoto(picture);
  };

  const handleResetPicture = () => {
    setPhoto(null);
  };

  if (uploadErrors) {
    return (
      <div>
        <div className={classes.content}>
          <div className={classes.errorWrapper}>
            <img src={errorIcon} alt="" height={40} />
            <Text text="errorPage.title" type="subtitleBold" />
            <Text align="center" text="errorPage.subtitle1" type="valueLabel" />
          </div>
        </div>
        <footer className={classes.footer}>
          <Button
            className={classes.uploadButton}
            color="primary"
            disabled={isUploading}
            disableElevation
            onClick={onClose}
            variant="contained"
          >
            {getText("common.prompts.continue")}
          </Button>
        </footer>
      </div>
    );
  }

  return photo ? (
    <div>
      <div className={classes.content}>
        <Fade in {...{ timeout: 500 }}>
          <img
            className={classes.previewImage}
            width={width}
            src={URL.createObjectURL(photo)}
            alt=""
          />
        </Fade>
        {isUploading && (
          <div className={classes.loadingWrapper}>
            <CircularProgress size={70} thickness={3} />
          </div>
        )}
      </div>
      <footer className={classes.footer}>
        <Button
          className={classes.cancelButton}
          disabled={isUploading}
          disableElevation
          onClick={handleResetPicture}
          variant="outlined"
        >
          {getText("common.prompts.discard")}
        </Button>
        <Button
          className={classes.uploadButton}
          color="primary"
          disabled={isUploading}
          disableElevation
          onClick={handleSubmit}
          variant="contained"
        >
          {getText("common.prompts.upload")}
        </Button>
      </footer>
    </div>
  ) : (
    <FileUploadWebcamInput onTakePicture={handleTakePicture} width={width} />
  );
};

export default FileUploadCamera;
