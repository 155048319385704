import React from "react";
import PropTypes from "prop-types";

// Components
import RouteHandler from "./RouteHandler";

const Routes = ({ routes }) =>
  routes.map(route => <RouteHandler key={route.id} {...route} />);

Routes.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default Routes;
