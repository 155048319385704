import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import format from "date-fns/format";

// Actions
import { createCase } from "../../state/cases/actions";

// Config
import { getRoute, urls } from "../../config/routes";
import { useAppInsights } from "../../utils/telemetry/AppInsights";

// Components
import CreateVictimForm from "../../components/CreateVictimForm";
import Disclaimer from "../../components/Disclaimer";
import LoadingSection from "../../components/LoadingSection";
import SectionWrapper from "../../components/SectionWrapper";

const CreateCasePage = () => {
  const { createError, isCreating } = useSelector(s => s.cases);
  const { trackException } = useAppInsights();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = (values, setSubmitting) => {
    const {
      country: { phone },
      coordinate,
      crimeReferenceNumber,
      dateOfBirth,
      dateTimeOfExamination,
      familyName,
      givenName,
      number,
      place,
      userCapacity
    } = values;

    const body = {
      patient: {
        givenName,
        familyName,
        dateOfBirth: format(new Date(dateOfBirth), "yyyy-MM-dd"),
        phoneNumber: {
          callingCountryCode: `+${phone}`,
          nationalNumber: number
        }
      },
      placeOfExamination: {
        name: place,
        coordinate
      },
      dateTimeOfExamination: format(
        dateTimeOfExamination,
        "yyyy-MM-dd'T'HH:mm:ss"
      ),
      userCapacity
    };

    if (crimeReferenceNumber) body.crimeReferenceNumber = crimeReferenceNumber;

    dispatch(
      createCase({
        body,
        state: values,
        onSuccess: caseId =>
          history.push(getRoute(urls.caseDetail, { caseId })),
        onFail: () => {
          window.scrollTo(0, 0);
          setSubmitting(false);
          trackException("Failed to create a new case");
        }
      })
    );
  };

  return (
    <SectionWrapper isNew>
      {createError && (
        <Disclaimer color="secondary" text="common.errors.request" />
      )}
      {isCreating ? (
        <LoadingSection normalHeight />
      ) : (
        <CreateVictimForm onSubmit={handleSubmit} />
      )}
    </SectionWrapper>
  );
};

export default CreateCasePage;
