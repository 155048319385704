import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

// Styles
import colors from "../../config/colors";

const lines = (height, color) => ({
  position: "absolute",
  content: '" "',
  height,
  width: 1,
  backgroundColor: color || colors.doveGray
});

const useStyles = makeStyles(() => ({
  root: ({ size, color }) => ({
    width: size,
    height: size,

    "&:before": {
      ...lines(size, color),
      transform: "rotate(45deg)"
    },

    "&:after": {
      ...lines(size, color),
      transform: "rotate(-45deg)"
    }
  })
}));

const Close = ({ size, color }) => (
  <span className={useStyles({ size, color }).root} />
);

Close.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

Close.defaultProps = {
  color: null,
  size: 16
};

export default Close;
