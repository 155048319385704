import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

// Azure Map
import * as atlas from "azure-maps-control";
import "azure-maps-control/dist/atlas.min.css";
import "mapbox-gl/src/css/mapbox-gl.css";

// Config
import colors from "../../config/colors";

// Info
// https://github.com/Azure/react-azure-maps/blob/master/src/components/AzureMap/AzureMap.tsx

const mapId = "location-preview-map";
const dataSourceId = "showPoints";

const bubbleLayerProps = {
  radius: 5,
  strokeColor: ["get", "color"],
  strokeWidth: 6,
  color: "white"
};
const defaultMapZoom = 16;

const authOptions = atlas.getAuthenticationOptions();
authOptions.authType = atlas.AuthenticationType.subscriptionKey;
authOptions.subscriptionKey = process.env.REACT_APP_AZURE_MAPS_KEY;

const useStyles = makeStyles(theme => ({
  map: {
    width: "100%",
    height: 300,
    overflow: "hidden",
    marginBottom: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    position: "relative"
  }
}));

const getFeatureCollection = (longitude, latitude) => {
  const point = new atlas.data.Point([longitude, latitude]);
  const feature = new atlas.data.Feature(point, { color: colors.rose }, "pin");
  return new atlas.data.FeatureCollection([feature]);
};

const MiniMap = ({ latitude, longitude }) => {
  const [mapRef, setMapRef] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (!mapRef) {
      const map = new atlas.Map(mapId, {
        center: [latitude, longitude],
        authOptions,
        zoom: defaultMapZoom
      });

      map.controls.add(new atlas.control.ZoomControl());

      map.events.add("ready", () => {
        const dataSource = new atlas.source.DataSource(dataSourceId);
        map.sources.add(dataSource);

        dataSource.add(getFeatureCollection(longitude, latitude));

        const bubbleLayer = new atlas.layer.BubbleLayer(
          dataSource,
          null,
          bubbleLayerProps
        );
        map.layers.add(bubbleLayer);

        setInitialized(true);
      });

      setMapRef(map);
    }

    return () => {
      setMapRef(null);
    };
  }, []);

  useEffect(() => {
    if (initialized) {
      const dataSource = mapRef.sources.getById(dataSourceId);

      dataSource.clear();
      dataSource.add(getFeatureCollection(longitude, latitude));

      const bubbleLayer = new atlas.layer.BubbleLayer(
        dataSource,
        null,
        bubbleLayerProps
      );

      mapRef.layers.add(bubbleLayer);

      mapRef.setCamera({
        center: [longitude, latitude],
        zoom: defaultMapZoom
      });
    }
  }, [latitude, longitude, initialized]);

  return <div className={classes.map} id={mapId} />;
};

MiniMap.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired
};

export default React.memo(MiniMap);
