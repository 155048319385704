import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";

// Icons
import addInjuryIcon from "../../assets/icons/add_injury_icon.svg";
import chevron from "../../assets/icons/chevron.svg";
import fileIcon from "../../assets/icons/generic_ico_solid.svg";
import paperclipIcon from "../../assets/icons/attach-outline.svg";
import picsIcon from "../../assets/icons/pics_ico.svg";

// Config
import styles from "../../config/styles";

// Hooks
import usePopper from "../../hooks/usePopper";

// Actions
import { resetMediaUploads } from "../../state/media/actions";
import { resetMedicalUploads } from "../../state/medicalRecords/actions";
import { resetImgUploads } from "../../state/injuries/actions";

// Components
import Button from "../Button";
import InjuryItem from "../InjuryItem";
import NestedMenuItem from "../NestedMenuItem";
import PopperMenu from "../PopperMenu";
import SubmitHandler from "./SubmitHandler";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(1),
    height: 42,
    width: 42,
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  menuTitle: {
    fontSize: 15,
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(2),
    borderBottom: styles.values.border,
    pointerEvents: "none"
  },
  list: {
    padding: 0,
    "&:focus": {
      outline: "none"
    }
  },
  item: {
    padding: theme.spacing(2)
  },
  nestedItem: {
    padding: theme.spacing(1.5, 4),
    display: "flex",
    flexDirection: "column",
    borderBottom: styles.values.border,
    "&:last-of-type": {
      border: "none"
    }
  },
  iconHolster: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 15,
    height: 18,
    marginRight: theme.spacing(2)
  },
  icon: {
    maxWidth: 15,
    maxHeight: 18
  },
  chevron: {
    transform: "rotate(90deg)",
    marginLeft: "auto"
  }
}));

const ariaId = "menu-list-grow";

const SubmitMenu = ({ infoRequestId }) => {
  const { data } = useSelector(s => s.caseDetail);
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selected, setSelected] = useState(null);

  const { anchorRef, handleClose, handleKeyDown, handleToggle, open } =
    usePopper();

  const handleSelect = value => () => {
    if (value !== null) {
      dispatch(resetMediaUploads());
      dispatch(resetMedicalUploads());
      dispatch(resetImgUploads());
    }
    setSelected(value);
  };

  return (
    <SubmitHandler
      infoRequestId={infoRequestId}
      selected={selected}
      onClose={handleSelect(null)}
    >
      <Button
        aria-controls={open ? ariaId : undefined}
        aria-haspopup="true"
        className={classes.button}
        onClick={handleToggle}
        ref={anchorRef}
      >
        <img src={paperclipIcon} alt="" />
      </Button>
      <PopperMenu
        anchorEl={anchorRef.current}
        ariaId={ariaId}
        classes={{ list: classes.list }}
        onClose={handleClose}
        onKeyDown={handleKeyDown}
        open={open}
      >
        <MenuItem
          className={classes.item}
          onClick={handleSelect({ type: "media" })}
        >
          <div className={classes.iconHolster}>
            <img src={picsIcon} alt="" className={classes.icon} />
          </div>
          <Text
            text="furtherInfoPage.submitMenu.addMediaButton"
            type="subtitle2"
          />
        </MenuItem>
        <MenuItem
          className={classes.item}
          onClick={handleSelect({ type: "medical" })}
        >
          <div className={classes.iconHolster}>
            <img src={fileIcon} alt="" className={classes.icon} />
          </div>
          <Text
            text="furtherInfoPage.submitMenu.addRecordButton"
            type="subtitle2"
          />
        </MenuItem>
        <NestedMenuItem
          className={classes.item}
          classes={{ list: classes.list }}
          text={
            <>
              <div className={classes.iconHolster}>
                <img src={addInjuryIcon} alt="" className={classes.icon} />
              </div>
              <Text
                text="furtherInfoPage.submitMenu.addInjuryButton"
                type="subtitle2"
              />
              <img src={chevron} alt="" className={classes.chevron} />
            </>
          }
        >
          <Text
            className={classes.menuTitle}
            text="common.valueLabels.injuriesCaptured"
          />
          {data &&
            data.injuries.map(injury => (
              <MenuItem
                className={classes.nestedItem}
                key={injury.id}
                onClick={handleSelect({
                  type: "injury",
                  id: injury.id
                })}
              >
                <InjuryItem {...injury} />
              </MenuItem>
            ))}
        </NestedMenuItem>
      </PopperMenu>
    </SubmitHandler>
  );
};

SubmitMenu.propTypes = {
  infoRequestId: PropTypes.string.isRequired
};
export default SubmitMenu;
