import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getInjury } from "../state/injuries/actions";
import { useAppInsights } from "../utils/telemetry/AppInsights";

const useInjuries = (caseId, injuryId) => {
  const { trackException } = useAppInsights();
  const [initialized, setInitialized] = useState(false);
  const dispatch = useDispatch();
  const injuries = useSelector(state => state.injuries);

  const loadedInjuryId = injuries.data && injuries.data.id;

  useEffect(() => {
    if (!injuries.error && loadedInjuryId !== injuryId)
      dispatch(
        getInjury({
          caseId,
          injuryId,
          onFinish: success => {
            if (success) {
              trackException(
                `Failed to get injury data from: ${injuryId} at case: ${caseId}`
              );
            }
            setInitialized(true);
          }
        })
      );
  }, [
    caseId,
    dispatch,
    injuries.error,
    injuryId,
    loadedInjuryId,
    trackException
  ]);

  return { ...injuries, initialized };
};

export default useInjuries;
