import React from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";

import colors from "../../config/colors";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: colors.cloudBurst
  },
  avatar: {
    height: 38,
    width: 38,
    marginRight: theme.spacing(1.5)
  },
  username: {
    marginBottom: theme.spacing(1)
  }
}));

const ContributorGroup = ({ boldName, parties }) => {
  const classes = useStyles();
  let displayText = "";

  parties.forEach(({ fullName }, i) => {
    const text = `${displayText}${fullName.split(" ")[0]}`;
    const separator = i !== parties.length - 1 ? ", " : "";
    displayText = `${text}${separator}`;
  });

  return (
    <div className={classes.root}>
      <Avatar className={classes.avatar}>{parties.length}</Avatar>
      <div className={classes.content}>
        <Text className={classes.username} type="contentLabel2">
          {boldName ? <b>{displayText}</b> : displayText}
        </Text>
      </div>
    </div>
  );
};

ContributorGroup.propTypes = {
  parties: PropTypes.arrayOf(
    PropTypes.shape({
      capacity: PropTypes.string,
      profilePictureURL: PropTypes.string,
      fullName: PropTypes.string
    })
  ).isRequired,
  boldName: PropTypes.bool.isRequired
};
export default ContributorGroup;
