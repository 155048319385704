const colors = {
  abbey: "#535355",
  baliHai: "#8F9BB3",
  black: "#000",
  cadetBlue: "#B2B9C7",
  cloudBurst: "#222B45",
  concrete: "#F2F2F2",
  denim: "#1665D8",
  dodgerBlue: "#00B8FF",
  dodgerBlue2: "#2196E3",
  doveGray: "#707070",
  ebonyClay: "#2A2E43",
  frenchRose: "#EC3F9D",
  geyser: "#D8DCE6",
  hotPink: "#FF5AA0",
  jumbo: "#7B7C7F",
  lilyWhite: "#E5F8FF",
  lynch: "#78849E",
  mintGreen: "#98F7A5",
  mystic: "#EAEDF3",
  pastelGreen: "#69D565",
  pictonBlue: "#3ACCE1",
  regentGray: "#818CA4",
  riverBed: "#454F63",
  rose: "#E6007E",
  santasGray: "#9EA0A5",
  shuttleGray: "#6B6C6F",
  silver: "#CCCCCC",
  tuna: "#3E3F42",
  whisper: "#FBFBFD",
  white: "#FFFFFF",
  wildLand: "#F7F7F7",
  emergencyHeader: "#0B4283",
  lockedCardText: "#885067",
  lockedBackground: "#FDB9C4"
};

export default colors;
