import React, { useState } from "react";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

// Icons
import iconFolder from "../../assets/icons/icon-upload-gallery.svg";
import iconCamera from "../../assets/icons/icon-upload-camera.svg";

// Config
import colors from "../../config/colors";
import styles from "../../config/styles";

// Hooks
import { useFileUpload } from "./FileUploadProvider";
import useTranslate from "../../hooks/useTranslate";

// Components
import FileUploadCamera from "./FileUploadCamera";
import FileUploadFromFolder from "./FileUploadFromFolder";
import FileUploadTabPanel from "./FileUploadTabPanel";

const useStyles = makeStyles(theme => ({
  tabWrapper: {
    padding: theme.spacing(3, 5, 0)
  },
  valueLabel: {
    marginBottom: theme.spacing(1),
    color: colors.tuna
  },
  textField: {
    width: "100%"
  },
  inputRoot: styles.mixins.inputRoot,
  input: styles.mixins.input,
  focused: {
    borderColor: theme.palette.primary.main
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: theme.spacing(4.5),
    backgroundColor: colors.wildLand
  },
  cancelButton: {
    backgroundColor: colors.white,
    marginRight: theme.spacing(1.5),
    width: 180
  },
  uploadButton: {
    width: 180
  },
  emptyFieldError: {
    marginRight: "auto",
    color: colors.rose
  },
  tabText: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1)
  }
}));

const a11yProps = index => ({
  id: `fileupload-tab-${index}`,
  "aria-controls": `file-upload-${index}`
});

const FileUploadContent = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { allowWebcam } = useFileUpload();
  const classes = useStyles();
  const getText = useTranslate();

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <div className={classes.tabWrapper}>
        {allowWebcam && (
          <Tabs
            aria-label="files tabs"
            indicatorColor="primary"
            onChange={handleTabChange}
            value={selectedTab}
            classes={{ indicator: classes.tabIndicator }}
          >
            <Tab
              classes={{ wrapper: classes.tabText }}
              label={
                <>
                  <img src={iconFolder} alt="" />
                  {getText("fileUploadPage.fromGallery")}
                </>
              }
              {...a11yProps(0)}
            />
            <Tab
              classes={{ wrapper: classes.tabText }}
              label={
                <>
                  <img src={iconCamera} alt="" />
                  {getText("fileUploadPage.fromCamera")}
                </>
              }
              {...a11yProps(1)}
            />
          </Tabs>
        )}
      </div>

      <FileUploadTabPanel value={selectedTab} index={0}>
        <FileUploadFromFolder />
      </FileUploadTabPanel>
      <FileUploadTabPanel value={selectedTab} index={1}>
        <FileUploadCamera />
      </FileUploadTabPanel>
    </>
  );
};

export default FileUploadContent;
