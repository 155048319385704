import { combineReducers } from "redux";
import constant from "lodash-es/constant";
import createReducer from "../../utils/createReducer";

import { types } from "./actions";

const {
  SUPPORT_LINES_REQUEST,
  SUPPORT_LINES_SUCCESS,
  SUPPORT_LINES_FAIL,
  EMERGENCY_BANNER_CLOSE,
  SET_COORDINATES
} = types;

const supportLines = createReducer(null, {
  [SUPPORT_LINES_SUCCESS]: (state, { payload }) => payload.supportServices
});

const coordinates = createReducer(null, {
  [SET_COORDINATES]: (state, { payload }) => payload
});

const emergencyContact = createReducer(null, {
  [SUPPORT_LINES_SUCCESS]: (state, { payload }) => payload.emergencyContact
});

const loading = createReducer(false, {
  [SUPPORT_LINES_REQUEST]: constant(true),
  [SUPPORT_LINES_SUCCESS]: constant(false),
  [SUPPORT_LINES_FAIL]: constant(false)
});

const error = createReducer(false, {
  [SUPPORT_LINES_REQUEST]: constant(false),
  [SUPPORT_LINES_FAIL]: constant(true)
});

const isEmergencyBannerOpen = createReducer(false, {
  [SUPPORT_LINES_SUCCESS]: constant(true),
  [EMERGENCY_BANNER_CLOSE]: constant(false)
});

export default combineReducers({
  isEmergencyBannerOpen,
  emergencyContact,
  supportLines,
  coordinates,
  loading,
  error
});
