import React from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

// Config
import useTranslate from "../../../hooks/useTranslate";

const useStyles = makeStyles(theme => ({
  tab: {
    height: 76
  },
  tabText: {
    fontFamily: ["Gibson SemiBold", "sans-serif"].join(","),
    fontSize: 24,
    color: theme.palette.common.black,
    textTransform: "capitalize"
  },
  tabIndicator: {
    height: 6
  }
}));

const a11yProps = index => ({
  id: `tab-${index}`,
  "aria-controls": `tabpanel-${index}`
});

const NotesTabs = ({ onChange, value }) => {
  const classes = useStyles();
  const getText = useTranslate();

  return (
    <Tabs
      aria-label="notes tabs"
      indicatorColor="primary"
      onChange={onChange}
      value={value}
      classes={{ indicator: classes.tabIndicator }}
    >
      <Tab
        classes={{ root: classes.tab, wrapper: classes.tabText }}
        label={getText("notesPage.notes.tabTitle")}
        {...a11yProps(0)}
      />
      <Tab
        classes={{ root: classes.tab, wrapper: classes.tabText }}
        label={getText("notesPage.statements.tabTitle")}
        {...a11yProps(1)}
      />
    </Tabs>
  );
};

NotesTabs.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired
};

export default NotesTabs;
