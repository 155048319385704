import React from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

// Icons
import alertIcon from "../../assets/icons/alertBlack.svg";

// Config
import colors from "../../config/colors";

// Components
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 3),
    backgroundColor: "rgba(225, 186, 58, 0.1)",
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: colors.riverBed,
    marginBottom: theme.spacing(2)
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  text: {
    fontSize: 12
  }
}));

const SystemMessage = ({ text }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <img className={classes.icon} src={alertIcon} alt="" />
      <Text className={classes.text} text={text} type="subtitle1" />
    </Paper>
  );
};

SystemMessage.propTypes = {
  text: PropTypes.string.isRequired
};

export default SystemMessage;
