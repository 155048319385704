import React from "react";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Config
import { getResourceRoute } from "../../config/routes";
import colors from "../../config/colors";

// Hooks
import useTranslate from "../../hooks/useTranslate";

// Components
import Avatar from "../Avatar";
import Link from "../Link";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(1.5)
  },
  messageBox: {
    padding: theme.spacing(1, 3),
    backgroundColor: colors.wildLand,
    borderRadius: theme.shape.borderRadius,
    maxWidth: 337,
    display: "flex",
    flexDirection: "column"
  },
  ownMessage: {
    marginLeft: "auto",
    backgroundColor: `${colors.dodgerBlue}19`,
    alignItems: "flex-end"
  },
  avatar: {
    height: 38,
    width: 38,
    marginRight: theme.spacing(1.5)
  },
  capacity: {
    padding: theme.spacing(0.5, 0)
  },
  text: {
    padding: theme.spacing(1, 0),
    whiteSpace: "pre-wrap",
    wordBreak: "break-word"
  },
  textLink: {
    padding: theme.spacing(1, 0),
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    color: theme.palette.secondary.main,
    "&:hover": {
      textDecoration: "underline"
    },
    "&:visited": {
      color: theme.palette.secondary.main
    }
  },
  time: {
    marginLeft: "auto"
  }
}));

const Message = ({
  data: { attachments, creationDateTime, senderID, senderId, text, timestamp },
  userId
}) => {
  const classes = useStyles();
  const getText = useTranslate();

  const { contributors } = useSelector(s => s.caseDetail);

  const sender = senderID || senderId;
  const time = creationDateTime || timestamp;

  const itsMe = sender === userId;
  const user = itsMe
    ? { fullName: getText("furtherInfoPage.you") }
    : contributors.find(({ id }) => sender === id) || {};

  const resourceRoute =
    attachments &&
    attachments[0] &&
    getResourceRoute(attachments[0].resourceId);

  return (
    <div key={time} className={classes.root}>
      {!itsMe && (
        <Avatar className={classes.avatar} src={user.profilePictureURL}>
          {user.fullName && user.fullName.charAt(0)}
        </Avatar>
      )}
      <div
        className={clsx(classes.messageBox, {
          [classes.ownMessage]: itsMe
        })}
      >
        {user && (
          <>
            <Text type="subtitleBold">{user.fullName}</Text>
            {!itsMe && (
              <Text
                className={classes.capacity}
                text={`common.userTypes.${user.capacity}`}
                type="contentLabel3"
              />
            )}
          </>
        )}
        {resourceRoute ? (
          <Link className={classes.textLink} to={resourceRoute}>
            {text}
          </Link>
        ) : (
          <Text className={classes.text}>{text}</Text>
        )}
        {time && (
          <Text className={classes.time} type="contentLabel3">
            {format(
              new Date(time),
              getText("common.dateFormat.dayOfMonthAndTime")
            )}
          </Text>
        )}
      </div>
    </div>
  );
};

Message.propTypes = {
  data: PropTypes.shape({
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        resourceId: PropTypes.string
      })
    ),
    creationDateTime: PropTypes.string,
    senderID: PropTypes.string,
    senderId: PropTypes.string,
    text: PropTypes.string,
    timestamp: PropTypes.string
  }).isRequired,
  userId: PropTypes.string.isRequired
};

export default React.memo(Message);
