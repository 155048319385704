import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash-es/isEmpty";

// State
import { getUserDetails } from "../state/user/actions";

const useAuthorizedContent = () => {
  const [initialized, setInitialized] = useState(false);
  const { currentUser, detailsError, loadingDetails, userDetails } =
    useSelector(s => s.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !initialized &&
      currentUser &&
      !isEmpty(currentUser) &&
      !loadingDetails &&
      !userDetails &&
      !detailsError
    ) {
      dispatch(getUserDetails());
      setInitialized(true);
    }
  }, [
    currentUser,
    detailsError,
    dispatch,
    initialized,
    loadingDetails,
    userDetails
  ]);
};

export default useAuthorizedContent;
