import React from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Assets
import * as bodyImages from "../../assets/charts/png/index";
import colors from "../../config/colors";

// Components
import Side from "./Side";

const useStyles = makeStyles(theme => ({
  mapContainer: {
    width: "100%",
    height: 375,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    position: "relative",
    backgroundColor: colors.wildLand,
    borderRadius: 4
  },
  bodyMap: {
    backgroundImage: ({ bodyImg }) => `url(${bodyImg})`,
    transform: ({ isRight }) => `scaleX(${isRight ? -1 : 1})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "100%"
  }
}));

const getBodyImg = (partId = "") => {
  const pureId = partId.replace(/Left|Right/, "");
  return bodyImages[pureId];
};

const isRightSide = (partId = "") => {
  const containsRight = partId.includes("Right", "");
  return containsRight;
};

const isBackSide = (partId = "") => {
  const containsBack = partId.includes("Back", "");
  return containsBack;
};

const BodyMap = ({ partId, ...rest }) => {
  const bodyImg = getBodyImg(partId);
  const isRight = isRightSide(partId);
  const isBack = isBackSide(partId);
  const classes = useStyles({ bodyImg, isRight });

  return (
    <div className={classes.mapContainer} {...rest}>
      <div className={classes.bodyMap} />
      <Side isRight {...{ isBack }} />
      <Side {...{ isBack }} />
    </div>
  );
};

BodyMap.propTypes = {
  partId: PropTypes.string.isRequired
};

export default BodyMap;
