import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import styled from "styled-components";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

// Config
import styles from "../../../config/styles";
import useTranslate from "../../../hooks/useTranslate";

// Components
import Icon from "../../Icon/Icon";

const useStyles = makeStyles(theme => ({
  root: {
    pointerEvents: "none",
    width: "100%",
    height: "100%"
  },
  group: {
    pointerEvents: "bounding-box"
  },
  path: {
    cursor: "pointer",
    ...styles.values.bodyChart.path,
    "&:hover": {
      fill: `${theme.palette.secondary.main}50`
    }
  },
  selected: {
    fill: theme.palette.secondary.main,
    pointerEvents: "none"
  }
}));

const Svg = styled(Icon)`
  ${styles.values.bodyChart.svg}
`;

const Chart = ({ onChange, value, chart }) => {
  const classes = useStyles();
  const getText = useTranslate();
  const handleChange = id => () => onChange(id);
  return (
    <Svg className={classes.root} viewBox="0 0 749 1407">
      {chart.map(item => (
        <Tooltip
          arrow
          key={item.id}
          placement={
            (item.id.includes("Front") &&
              (item.id.includes("Right") ? "left" : "right")) ||
            (item.id.includes("Right") ? "right" : "left")
          }
          title={getText(`common.bodySection.${item.id}`)}
        >
          <g className={classes.group} onClick={handleChange(item.id)}>
            <path
              className={clsx(classes.path, {
                [classes.selected]: item.id === value
              })}
              {...item}
            />
          </g>
        </Tooltip>
      ))}
    </Svg>
  );
};

Chart.propTypes = {
  chart: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default Chart;
