import React from "react";
import { useSelector } from "react-redux";
import format from "date-fns/format";
import get from "lodash-es/get";
import ReactCountryFlag from "react-country-flag";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Icons
import phonecall from "../../../assets/icons/phonecall.svg";

// Components
import ContentCardHeader from "../../../components/ContentCardHeader";
import JobTitleInput from "./JobTitleInput";
import LoadingSection from "../../../components/LoadingSection";
import Text from "../../../components/Text";
import UploadAvatar from "./UploadAvatar";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(4)
  },
  userContent: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "row"
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    width: 335
  },
  userName: {
    marginBottom: theme.spacing(3.5),
    fontSize: 26
  },
  valuesWrapper: {
    marginBottom: theme.spacing(0.75)
  },
  value: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2.25),
    position: "relative"
  },
  phoneNumber: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  phoneNumberLabel: {
    marginBottom: theme.spacing(3.5)
  },
  flag: {
    fontSize: "18px !important",
    marginRight: theme.spacing(1.5)
  },
  areaCode: {
    marginRight: theme.spacing(3.5)
  },
  number: {
    marginRight: theme.spacing(2)
  },
  callIcon: {
    fontSize: 16
  }
}));

const formateBirthDate = birthDate => {
  const date = new Date(birthDate);
  const isoDate = date.toISOString();
  const dateOnly = `${isoDate.substring(0, 10)} ${isoDate.substring(11, 19)}`;
  return format(new Date(dateOnly), "dd/MM/yy");
};

export default () => {
  const classes = useStyles();
  const { userDetails } = useSelector(state => state.user);

  return (
    <div className={classes.root} id="userinfo">
      <ContentCardHeader>
        <Text text="settingsPage.profile.title" type="heading2" />
      </ContentCardHeader>
      {!userDetails ? (
        <LoadingSection normalHeight noBackground />
      ) : (
        <div className={classes.userContent}>
          <UploadAvatar profilePhotoURL={userDetails.profilePhotoURL} />
          <div className={classes.contentWrapper}>
            <Text type="heading2" className={classes.userName}>
              {userDetails.fullName}
            </Text>
            <div className={classes.valuesWrapper}>
              <div className={classes.value}>
                <Text text="common.valueLabels.birthDate" type="valueLabel2" />
                <Text type="contentLabel2">
                  {formateBirthDate(userDetails.birthDate)}
                </Text>
              </div>
              <div className={classes.value}>
                <Text text="common.valueLabels.email" type="valueLabel2" />
                <Text type="contentLabel2">{userDetails.emailAddress}</Text>
              </div>
              <div className={classes.value}>
                <Text text="common.valueLabels.jobTitle" type="valueLabel2" />
                <JobTitleInput value={userDetails.jobTitle} />
              </div>
              {userDetails.gmcNumber && (
                <div className={classes.value}>
                  <Text text="common.valueLabels.gmc" type="valueLabel2" />
                  <Text type="contentLabel2">{userDetails.gmcNumber}</Text>
                </div>
              )}
            </div>
            <Text
              className={classes.phoneNumberLabel}
              text="common.valueLabels.phoneNumber"
              type="contentLabel3"
            />
            <div className={classes.phoneNumber}>
              <ReactCountryFlag
                className={classes.flag}
                countryCode={get(userDetails, "phoneNumber.isoCountryCode", "")}
              />
              <Text className={classes.areaCode} type="contentBold">
                {get(userDetails, "phoneNumber.callingCountryCode", "")}
              </Text>
              <Text className={classes.number} type="contentBold">
                {get(userDetails, "phoneNumber.nationalNumber", "")}
              </Text>
              <img src={phonecall} alt="Call" className={classes.callIcon} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
