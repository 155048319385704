import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";

const DocumentTitle = ({ title, children }) => (
  <>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    {children}
  </>
);

DocumentTitle.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default DocumentTitle;
