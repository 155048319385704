import React from "react";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Icons
import okTick from "../../assets/icons/ok_tick.svg";

// Config
import colors from "../../config/colors";
import styles from "../../config/styles";

// Components
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  item: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    borderBottom: styles.values.border,
    "&:last-child": {
      border: "none"
    }
  },
  icon: {
    marginRight: theme.spacing(2.5)
  },
  victim: {
    opacity: 0.5,
    color: colors.cloudBurst
  },
  mobile: {
    color: colors.cloudBurst
  }
}));

const CaseSteps = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.item}>
        <img src={okTick} alt="" className={classes.icon} />
        <Text
          className={classes.victim}
          text="caseDetailPage.victimInfoCard.title"
          type="subtitle2"
        />
      </div>
      <div className={classes.item}>
        <Text
          className={classes.mobile}
          text="mobileCapturePage.sidebarTitle"
          type="contentBold"
        />
      </div>
    </>
  );
};

export default CaseSteps;
