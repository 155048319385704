import { combineReducers } from "redux";
import constant from "lodash-es/constant";

import createReducer from "../../utils/createReducer";

import {
  SEND_STORE_MESSAGE,
  SEND_STORE_MESSAGE_SUCCESS,
  SEND_STORE_MESSAGE_FAIL
} from "./actionTypes";

const success = createReducer(false, {
  [SEND_STORE_MESSAGE]: constant(false),
  [SEND_STORE_MESSAGE_SUCCESS]: () => constant(true),
  [SEND_STORE_MESSAGE_FAIL]: constant(false)
});

const error = createReducer(null, {
  [SEND_STORE_MESSAGE]: constant(null),
  [SEND_STORE_MESSAGE_FAIL]: (state, { payload }) => payload
});

const isLoading = createReducer(false, {
  [SEND_STORE_MESSAGE]: constant(true),
  [SEND_STORE_MESSAGE_SUCCESS]: constant(false),
  [SEND_STORE_MESSAGE_FAIL]: constant(false)
});

export default combineReducers({
  error,
  isLoading,
  success
});
