import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import format from "date-fns/format";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import ButtonBase from "@material-ui/core/ButtonBase";

// Config
import { getRoute, urls } from "../../config/routes";
import colors from "../../config/colors";
import styles from "../../config/styles";

// Components
import Link from "../Link";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  request: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(2, 0),
    borderBottom: styles.values.border
  },
  avatar: {
    height: 48,
    width: 48,
    marginRight: theme.spacing(2.5)
  },
  avatarImg: {
    position: "absolute",
    minWidth: "1000%",
    minHeight: "1000%",
    left: "50%",
    top: "50%",
    transform: "translateX(-50%) translateY(-50%) scale(0.1)"
  },
  data: {
    display: "flex",
    flexDirection: "column"
  },
  name: {
    marginBottom: theme.spacing(1)
  },
  message: {
    color: colors.lynch
  },
  details: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginLeft: "auto"
  },
  time: {
    fontSize: 14,
    marginBottom: theme.spacing(1)
  },
  unread: {
    backgroundColor: colors.pictonBlue,
    borderRadius: 9,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 18,
    minWidth: 18
  },
  count: {
    color: colors.white,
    lineHeight: 1,
    fontSize: 14
  }
}));

const ContributorRequestsItem = ({
  caseId,
  contributor,
  id,
  lastMessage,
  numberOfUnreadMessages,
  parties
}) => {
  const classes = useStyles();
  const { userDetails } = useSelector(state => state.user);

  const filteredParties = parties.filter(
    ({ id: partyId }) => partyId !== userDetails.id
  );

  return (
    <ButtonBase
      component={Link}
      to={getRoute(urls.infoRequestDetail, {
        caseId,
        infoRequestId: id
      })}
      className={classes.request}
      key={id}
      disableRipple
    >
      <Avatar
        src={contributor.profilePictureURL}
        variant="circle"
        className={classes.avatar}
        imgProps={{ className: classes.avatarImg }}
      />
      <div className={classes.data}>
        <Text type="heading6" className={classes.name}>
          {filteredParties.map(({ fullName }, index) =>
            index !== filteredParties.length - 1 ? `${fullName}, ` : fullName
          )}
        </Text>
        <Text type="subtitle1Small" className={classes.message}>
          {lastMessage.text}
        </Text>
      </div>
      <div className={classes.details}>
        <Text type="heading6" className={classes.time}>
          {format(new Date(lastMessage.creationDateTime), "HH:mm")}
        </Text>
        {!!numberOfUnreadMessages && (
          <span className={classes.unread}>
            <Text type="heading6" className={classes.count}>
              {numberOfUnreadMessages > 99 ? +99 : numberOfUnreadMessages}
            </Text>
          </span>
        )}
      </div>
    </ButtonBase>
  );
};

ContributorRequestsItem.propTypes = {
  caseId: PropTypes.string.isRequired,
  contributor: PropTypes.shape({
    id: PropTypes.string,
    profilePictureURL: PropTypes.string,
    fullName: PropTypes.string,
    capacity: PropTypes.string
  }).isRequired,
  id: PropTypes.string.isRequired,
  lastMessage: PropTypes.shape({
    creationDateTime: PropTypes.string,
    text: PropTypes.string
  }).isRequired,
  numberOfUnreadMessages: PropTypes.number.isRequired,
  parties: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default ContributorRequestsItem;
