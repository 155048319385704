import React from "react";
import PropTypes from "prop-types";

// Components
import Button from "../Button";

const CycleButton = ({ iconClassName, onClick, steps, toggled, ...props }) => {
  const [currentButton, setCurrentButton] = React.useState(0);
  const { activeIcon, current, icon, next } = steps[currentButton];

  const handleClick = () => {
    if (!toggled && current) {
      onClick(current)();
    } else {
      onClick(next)();
      setCurrentButton(
        currentButton === steps.length - 1 ? 0 : currentButton + 1
      );
    }
  };

  return (
    <Button onClick={handleClick} {...props}>
      <img
        src={(toggled && activeIcon) || icon}
        className={iconClassName}
        alt=""
      />
    </Button>
  );
};

CycleButton.propTypes = {
  iconClassName: PropTypes.string,
  toggled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      current: PropTypes.string,
      next: PropTypes.string,
      icon: PropTypes.node,
      activeIcon: PropTypes.node
    })
  ).isRequired
};

CycleButton.defaultProps = {
  iconClassName: "",
  toggled: false
};

export default CycleButton;
