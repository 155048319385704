import React, { createContext, useContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "lodash-es/isEmpty";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";

// Actions
import { clearAllMediaProgress } from "../../state/caseDetail/actions";

// Config
import { defaultAccept } from "../../config/values";
import styles from "../../config/styles";

// Components
import Close from "../Close";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  heading: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(5, 5, 0)
  },
  paper: {
    maxWidth: "initial",
    ...styles.mixins.scrollBar
  }
}));

const FileUploadContext = createContext();
export const useFileUpload = () => useContext(FileUploadContext);

const FileUploadProvider = ({
  accept,
  allowWebcam,
  children,
  multiple,
  noDescriptions,
  onClose,
  onSubmit,
  open,
  titleText,
  uploadErrors,
  uploadProgress
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isUploading, setIsUploading] = useState(false);
  const [hasEmptyField, setEmptyField] = useState(false);

  useEffect(() => {
    if (uploadProgress) {
      const atLeastOneIsUploading = Object.keys(uploadProgress).some(
        key => uploadProgress[key] < 100
      );
      const allUploadHasError =
        uploadErrors &&
        Object.keys(uploadErrors).every(key => uploadErrors[key]);

      setIsUploading(atLeastOneIsUploading && !allUploadHasError);
    }
  }, [uploadProgress, uploadErrors]);

  const handleClose = () => {
    if (!isUploading) {
      onClose();
      dispatch(clearAllMediaProgress());
    }
  };
  const handleSubmit = files => {
    let isError = false;

    if (!noDescriptions) {
      files.forEach(file => {
        if (!isError && file.description === "") {
          isError = true;
        }
      });

      setEmptyField(isError);
    }

    if (!isError) {
      setIsUploading(true);
      onSubmit(files);
    }
  };

  return (
    <FileUploadContext.Provider
      value={{
        allowWebcam,
        dropzoneProps: { accept, multiple },
        enableEdit: isEmpty(uploadProgress),
        hasEmptyField,
        isUploading,
        noDescriptions,
        onClose: handleClose,
        onSubmit: handleSubmit,
        uploadErrors,
        uploadProgress
      }}
    >
      <Dialog
        classes={{ paper: classes.paper }}
        onClose={handleClose}
        open={open}
      >
        <div className={classes.heading}>
          <Text text={titleText} type="large" />
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
        {children}
      </Dialog>
    </FileUploadContext.Provider>
  );
};

FileUploadProvider.propTypes = {
  accept: PropTypes.string,
  allowWebcam: PropTypes.bool,
  children: PropTypes.node.isRequired,
  multiple: PropTypes.bool,
  noDescriptions: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  titleText: PropTypes.string,
  uploadErrors: PropTypes.shape({}),
  uploadProgress: PropTypes.shape({})
};

FileUploadProvider.defaultProps = {
  accept: defaultAccept,
  allowWebcam: false,
  multiple: false,
  noDescriptions: false,
  titleText: "fileUploadPage.title",
  uploadErrors: {},
  uploadProgress: {}
};

export default FileUploadProvider;
