import React, { useEffect } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash-es/isEqual";

// hooks
import usePrevious from "../../hooks/usePrevious";

const LocationHandler = ({ coord, location, setCoord }) => {
  const prevLocation = usePrevious(location);
  const prevCoord = usePrevious(coord);

  useEffect(() => {
    if (
      coord !== null &&
      isEqual(prevCoord, coord) &&
      prevLocation !== location
    ) {
      setCoord(null);
    }
  }, [coord, location, prevCoord, prevLocation, setCoord]);

  return null;
};

LocationHandler.propTypes = {
  coord: PropTypes.shape({}),
  location: PropTypes.string.isRequired,
  setCoord: PropTypes.func.isRequired
};

LocationHandler.defaultProps = {
  coord: null
};

const areEqual = (prevProps, nextProps) =>
  prevProps.location &&
  nextProps.location &&
  prevProps.location === nextProps.location;

export default React.memo(LocationHandler, areEqual);
