/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import { FieldArray, Form } from "formik";
import PropTypes, { shape } from "prop-types";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";

// Hooks
import useTranslate from "../../../hooks/useTranslate";

// Components
import Button from "../../../components/Button";
import Close from "../../../components/Close";
import CustomField from "../../../components/CustomField";
import FormikHandler from "../../../components/FormikHandler";
import Text from "../../../components/Text";
import UserAddition from "../../../components/UserAddition";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1),
    flex: "1 1",
    display: "flex",
    flexWrap: "wrap"
  },
  title: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1)
  },
  body: {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2)
  },
  contentEdit: {
    display: "flex",
    marginBottom: theme.spacing(2),
    "& .MuiFilledInput-input": {
      padding: theme.spacing(2)
    },
    "& .MuiFilledInput-root.Mui-disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.05)"
    }
  },
  addButton: {
    display: "block",
    marginLeft: "auto",
    border: "none",
    "&:hover": {
      border: "none"
    }
  },
  addButtonQuick: {
    position: "absolute",
    right: 0,
    bottom: -32,
    border: "none"
  },
  input: {
    position: "relative"
  },
  close: {
    position: "absolute",
    right: 0,
    top: -10,
    cursor: "pointer"
  },
  info: {
    position: "absolute",
    right: 0,
    top: 3
  },
  valueLabel: {
    marginBottom: theme.spacing(1.5)
  },
  fields: {
    width: "100%"
  },
  userAddition: {
    minWidth: ({ columns }) => `${100 / columns}%`,
    maxWidth: ({ columns }) => `${100 / columns}%`,
    display: "flex",
    flexDirection: "column",
    position: "relative"
  }
}));

const IncidentInfoList = ({
  addButtonText,
  canAddMore,
  canQuickAdd,
  columns = 1,
  contents,
  hideUserInfo,
  name,
  onSubmit,
  subTitle,
  title,
  type,
  ...rest
}) => {
  const classes = useStyles({ columns });
  const [quickAdding, setQuickAdding] = useState(
    contents.length === 0 && canAddMore
  );
  const getText = useTranslate();

  const closeQuickAdding = arrayHelpers => {
    setQuickAdding(false);
    arrayHelpers.remove(0);
  };

  const openQuickAdding = arrayHelpers => {
    arrayHelpers.push("");
    setQuickAdding(true);
  };

  const submit = (values, { setFieldValue, setSubmitting }) => {
    onSubmit(values, success => {
      if (success) setFieldValue(name, [""]);
      setSubmitting(false);
    });
  };

  return (
    <FormikHandler
      formik={{
        initialValues: {
          [name]: []
        },
        onSubmit: submit
      }}
      render={({ values }) => (
        <Form>
          <Text className={classes.title} type="heading4">
            {title}
          </Text>
          <div className={classes.root}>
            <FieldArray
              name={name}
              render={arrayHelpers => (
                <>
                  {contents &&
                    contents.map((c, i) => (
                      <div
                        className={classes.userAddition}
                        key={`${c.author.id}-${c.creationDateTime}-${c.text}-box`}
                      >
                        <UserAddition
                          text={c.text}
                          subTitle={subTitle && `${subTitle} ${i + 1}`}
                          author={c.author}
                          date={c.creationDateTime}
                          hideUserInfo={hideUserInfo}
                        />
                        {canAddMore &&
                          canQuickAdd &&
                          !quickAdding &&
                          contents.length === i + 1 && (
                            <Button
                              variant="outlined"
                              color="primary"
                              disableElevation
                              className={classes.addButtonQuick}
                              onClick={() => openQuickAdding(arrayHelpers)}
                            >
                              {addButtonText}
                            </Button>
                          )}
                      </div>
                    ))}
                  {quickAdding && canQuickAdd && (
                    <div className={classes.fields}>
                      <div className={classes.input}>
                        <CustomField
                          {...rest}
                          name={`${name}.${0}`}
                          type={type}
                        />
                        {contents.length > 0 && (
                          <Fade in {...{ timeout: 1000 }}>
                            <IconButton
                              className={classes.close}
                              onClick={() => closeQuickAdding(arrayHelpers)}
                            >
                              <Close />
                            </IconButton>
                          </Fade>
                        )}
                      </div>
                      <Button
                        color="primary"
                        type="submit"
                        disableElevation
                        className={classes.addButton}
                        disabled={!values[name] || !values[name][0]}
                      >
                        {getText("common.prompts.done")}
                      </Button>
                    </div>
                  )}
                </>
              )}
            />
          </div>
        </Form>
      )}
    />
  );
};

IncidentInfoList.propTypes = {
  addButtonText: PropTypes.string.isRequired,
  canAddMore: PropTypes.bool,
  canQuickAdd: PropTypes.bool,
  columns: PropTypes.number,
  contents: PropTypes.arrayOf(
    shape({
      text: PropTypes.string,
      creationDateTime: PropTypes.string,
      author: PropTypes.shape({
        fullName: PropTypes.string,
        id: PropTypes.string,
        profilePictureURL: PropTypes.string
      })
    })
  ),
  hideUserInfo: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  subTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string)
};

IncidentInfoList.defaultProps = {
  canAddMore: false,
  canQuickAdd: false,
  columns: 1,
  contents: [],
  hideUserInfo: false,
  onSubmit: () => {},
  subTitle: undefined,
  type: "text",
  values: []
};

export default IncidentInfoList;
