import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Icons
import errorImg from "../../assets/icons/error_illus.svg";

// Config
import colors from "../../config/colors";
import useTranslate from "../../hooks/useTranslate";

// Actions
import { resetErrors } from "../../state/caseDetail/actions";
import { setError } from "../../state/errorPage/actions";

// Components
import Button from "../../components/Button";
import Text from "../../components/Text";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100%"
  },
  errorTitle: {
    fontFamily: "Montserrat",
    fontSize: 60,
    lineHeight: 1,
    color: colors.riverBed,
    textTransform: "uppercase",
    marginBottom: theme.spacing(1)
  },
  errorDescription: {
    lineHeight: 1.5
  },
  content: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    maxWidth: 400
  },
  image: {
    width: "40%",
    height: 350,
    display: "flex",
    backgroundImage: ({ errImg }) => `url(${errImg})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    marginLeft: theme.spacing(1)
  },
  button: {
    marginTop: theme.spacing(2),
    width: 220
  }
}));

const ErrorPage = () => {
  const classes = useStyles({ errImg: errorImg });
  const dispatch = useDispatch();
  const getText = useTranslate();
  const history = useHistory();

  const { pathname } = history.location;
  const [prevPath] = useState(pathname);

  useEffect(() => {
    if (pathname !== prevPath) {
      dispatch(setError());
      dispatch(resetErrors());
    }
  }, [pathname, prevPath, dispatch]);

  return (
    <main className={classes.root}>
      <div className={classes.content}>
        <Text text="errorPage.title" className={classes.errorTitle} />
        <Text
          text="errorPage.subtitle1"
          type="subtitle1Big"
          className={classes.errorDescription}
        />
        <Text text="errorPage.subtitle2" type="subtitle1Big" />
        <Button
          className={classes.button}
          color="primary"
          onClick={() => window.location.reload()}
          variant="contained"
          disableElevation
        >
          {getText("errorPage.button")}
        </Button>
      </div>
      <div className={classes.image} />
    </main>
  );
};

export default ErrorPage;
