import { get } from "lodash-es";
import { useEffect, useRef, useState } from "react";

// polyfill based on https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
(function polyfillGetUserMedia() {
  if (typeof window === "undefined") {
    return;
  }

  // Older browsers might not implement mediaDevices at all, so we set an empty object first
  if (navigator.mediaDevices === undefined) {
    navigator.mediaDevices = {};
  }

  // Some browsers partially implement mediaDevices. We can't just assign an object
  // with getUserMedia as it would overwrite existing properties.
  // Here, we will just add the getUserMedia property if it's missing.
  if (navigator.mediaDevices.getUserMedia === undefined) {
    navigator.mediaDevices.getUserMedia = constraints => {
      // First get ahold of the legacy getUserMedia, if present
      const getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia;

      // Some browsers just don't implement it - return a rejected promise with an error
      // to keep a consistent interface
      if (!getUserMedia) {
        return Promise.reject(
          new Error("getUserMedia is not implemented in this browser")
        );
      }

      // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
      return new Promise((resolve, reject) => {
        getUserMedia.call(navigator, constraints, resolve, reject);
      });
    };
  }
})();

const hasGetUserMedia = () => {
  return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
};

const useWebcam = () => {
  const [hasUserMedia, setHasUserMedia] = useState(false);
  const [videoSrc, setVideoSrc] = useState(null);
  const [error, setError] = useState(false);

  const video = useRef(null);
  let videoStream = useRef(null);

  const stopAndCleanup = () => {
    if (videoSrc) window.URL.revokeObjectURL(videoSrc);

    if (get(video, "current.srcObject.getTracks")) {
      video.current.srcObject.getTracks().forEach(track => {
        video.current.srcObject.removeTrack(track);
        track.stop();
      });
    }

    if (videoStream && videoStream.getVideoTracks) {
      videoStream.getVideoTracks().forEach(track => {
        videoStream.removeTrack(track);
        track.stop();
      });
    }
  };

  useEffect(() => {
    let mounted = true;
    if (!hasGetUserMedia()) {
      setError(new Error("Could not get User Media"));
      return;
    }

    navigator.mediaDevices
      .getUserMedia({ video: true, audio: false })
      .then(stream => {
        videoStream = stream;
        if (mounted) {
          try {
            if (video) video.current.srcObject = stream;
            setHasUserMedia(true);
          } catch (err) {
            setHasUserMedia(true);
            setVideoSrc(window.URL.createObjectURL(stream));
            setError(err);
          }
        } else {
          stopAndCleanup();
        }
      })
      .catch(err => {
        setError(err);
      });

    return () => {
      mounted = false;
      stopAndCleanup();
    };
  }, []);

  const takePicture = callback => {
    if (!hasUserMedia || !video) return null;

    const pictureHeight = video.current.videoHeight;
    const pictureWidth = video.current.videoWidth;

    const canvas = document.createElement("canvas");
    canvas.width = pictureWidth;
    canvas.height = pictureHeight;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(video.current, 0, 0, pictureWidth, pictureHeight);
    canvas.toBlob(callback);
  };

  return {
    error,
    hasUserMedia,
    takePicture,
    video,
    videoSrc
  };
};

export default useWebcam;
