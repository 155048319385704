import React, { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

// Config
import {
  apiVersion,
  featureFlagsRequestInterval,
  getFeatureFlagsUrl
} from "../config/apiConfig";

const FeatureFlagsContext = createContext();

export const useFeatureFlags = () => useContext(FeatureFlagsContext);

const FeatureFlagsProvider = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState(null);
  const [flagsError, setFlagsError] = useState(null);

  useEffect(() => {
    let mounted = true;
    const getFlags = () => {
      fetch(getFeatureFlagsUrl, {
        headers: {
          "api-version": apiVersion
        }
      })
        .then(r => r.json())
        .then(r => {
          if (mounted) {
            setFeatureFlags(r);
            setTimeout(getFlags, featureFlagsRequestInterval);
          }
        })
        .catch(e => {
          if (mounted) setFlagsError(e);
        });
    };

    getFlags();

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <FeatureFlagsContext.Provider
      value={{
        featureFlags,
        flagsError
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};

FeatureFlagsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default FeatureFlagsProvider;
