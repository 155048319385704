import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

// Actions
import { addMedia, updateMediaProgress } from "../../state/media/actions";
import {
  addMedicalRecord,
  updateMedicalProgress
} from "../../state/medicalRecords/actions";
import {
  updateImgProgress,
  uploadInjuryImage
} from "../../state/injuries/actions";

// Hooks
import { useAppInsights } from "../../utils/telemetry/AppInsights";

// Components
import FileUpload from "../FileUpload";

const SubmitHandler = ({ children, infoRequestId, onClose, selected }) => {
  const { caseId } = useParams();
  const { trackException } = useAppInsights();
  const dispatch = useDispatch();

  const injuries = useSelector(s => s.injuries);
  const media = useSelector(s => s.media);
  const medicalRecords = useSelector(s => s.medicalRecords);

  const query = `?context=InfoRequest;${infoRequestId}`;

  const handleAddInjury = files => {
    const onFail = msg =>
      trackException(`${msg} for injury: ${selected.id} at case: ${caseId} `);

    const onUploadProgress = index => progress =>
      dispatch(updateImgProgress({ index, progress }));

    dispatch(
      uploadInjuryImage({
        caseId,
        files,
        injuryId: selected.id,
        onFail,
        onUploadProgress,
        query
      })
    );
  };

  const handleAdd = ({ msg, add, update, valueName }) => files => {
    files.forEach((item, index) => {
      dispatch(
        add({
          caseId,
          description: item.description,
          id: index,
          onUploadProgress: progress => dispatch(update({ index, progress })),
          onFail: () => trackException(msg),
          [valueName]: item.file,
          query
        })
      );
    });
  };

  const uploadProps = {
    media: {
      uploadErrors: media.uploadErrors,
      uploadProgress: media.uploadProgress,
      onSubmit: handleAdd({
        add: addMedia,
        update: updateMediaProgress,
        msg: `Failed to add to case: ${caseId}`,
        valueName: "image"
      })
    },
    medical: {
      uploadErrors: medicalRecords.uploadErrors,
      uploadProgress: medicalRecords.uploadProgress,
      onSubmit: handleAdd({
        add: addMedicalRecord,
        update: updateMedicalProgress,
        msg: `Failed to add medical record to case: ${caseId}`,
        valueName: "file"
      })
    },
    injury: {
      accept: "image/*",
      uploadErrors: injuries.uploadErrors,
      uploadProgress: injuries.uploadProgress,
      onSubmit: handleAddInjury
    }
  };

  return (
    <>
      {children}
      {selected && (
        <FileUpload
          multiple
          onClose={onClose}
          open={Boolean(selected)}
          {...uploadProps[selected.type]}
        />
      )}
    </>
  );
};

SubmitHandler.propTypes = {
  children: PropTypes.node.isRequired,
  infoRequestId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  selected: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string
  })
};

SubmitHandler.defaultProps = {
  selected: null
};

export default SubmitHandler;
