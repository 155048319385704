import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

// Config
import { snackbarTypes } from "../../config/snackbar";
import { triggerNotificationsUrl } from "../../config/apiConfig";
import { urls, getRoute } from "../../config/routes";
import styles from "../../config/styles";

// Actions
import { getCaseDetail } from "../../state/caseDetail/actions";
import { parseFetchOptions } from "../../state/user/actions";
import { toggleSnack } from "../../state/snackbar/actions";

// Hooks
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import useTranslate from "../../hooks/useTranslate";

// Components
import AppStoreButtons from "../AppStoreButtons";
import Button from "../Button";
import ContentCardHeader from "../ContentCardHeader";
import SettingsPopper from "./SettingsPopper";
import Step from "../Step";
import StepsFooter from "../StepsFooter";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    border: styles.values.border,
    paddingBottom: theme.spacing(6.5)
  },
  button: {
    padding: 0,
    height: 42,
    width: 161,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%"
  },
  link: styles.mixins.link,
  howToCaptureButton: {
    width: 388,
    textTransform: "uppercase",
    marginBottom: theme.spacing(2.5)
  },
  text: {
    lineHeight: 1.4
  },
  settingsText: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }
}));

const InjurySteps = () => {
  const [isSending, setSending] = React.useState(false);
  const { data } = useSelector(s => s.caseDetail);
  const { isLoading } = useSelector(s => s.actions);
  const { trackException } = useAppInsights();
  const classes = useStyles();
  const dispatch = useDispatch();
  const getText = useTranslate();
  const history = useHistory();

  const goToDetail = () => {
    history.push(getRoute(urls.caseDetail, { caseId: data.id }));
  };

  const handleDone = () => {
    dispatch(
      getCaseDetail({
        caseId: data.id,
        handleRedirect: () => {
          dispatch(
            toggleSnack({
              type: snackbarTypes.ERROR,
              text: "caseMenu.pleaseAcceptInvitation"
            })
          );
          history.push(urls.cases);
        },
        onFail: () => trackException("Failed to get case details")
      })
    );
  };

  const handleSendLink = () => {
    setSending(true);
    dispatch(
      parseFetchOptions(options => {
        fetch(triggerNotificationsUrl(data.id), {
          method: "POST",
          ...options
        })
          .then(() => {
            dispatch(
              toggleSnack({
                type: snackbarTypes.SUCCESS,
                text: "createInjuryPage.notificationSent"
              })
            );
            setSending(false);
          })
          .catch(() => {
            setSending(false);
            dispatch(toggleSnack({ type: snackbarTypes.ERROR }));
            trackException("Failed to POST trigger notification");
          });
      })
    );
  };

  return (
    <Paper elevation={0} className={classes.root}>
      <ContentCardHeader>
        <Text
          className={classes.title}
          text="createInjuryPage.heading"
          type="heading2"
        />
        <Button
          className={classes.button}
          onClick={goToDetail}
          regular
          variant="outlined"
        >
          {getText("common.prompts.cancel")}
        </Button>
      </ContentCardHeader>
      <div className={classes.content}>
        <Step
          number={1}
          title={getText("mobileCapturePage.heading")}
          subtitle1={getText("createInjuryPage.step1.subtitle1")}
          subtitle={getText("mobileCapturePage.step1.subtitle2")}
          disableButtons={isLoading}
        >
          <AppStoreButtons />
        </Step>
        <Step
          number={2}
          title={getText("mobileCapturePage.step2.title")}
          subtitle={getText("createInjuryPage.step2.subtitle2", {
            1: data.number
          })}
        >
          <Button
            className={classes.howToCaptureButton}
            disabled={isSending}
            disableElevation
            onClick={handleSendLink}
            variant="outlined"
          >
            {getText("createInjuryPage.step2.sendLinkButton")}
          </Button>
          <span className={classes.settingsText}>
            <SettingsPopper />
            <Text
              className={classes.text}
              text="createInjuryPage.step2.subtitle3"
              type="subtitle1Small"
            />
          </span>
        </Step>
        <Step
          number={3}
          title={getText("mobileCapturePage.step3.title")}
          subtitle={getText("mobileCapturePage.step3.subtitle1")}
          isLast
          onClick={handleDone}
        />
      </div>
      <StepsFooter />
    </Paper>
  );
};

export default InjurySteps;
