import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// Actions
import {
  addMedicalRecord,
  resetMedicalUploads,
  updateMedicalProgress
} from "../../state/medicalRecords/actions";

// Hooks
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import useTranslate from "../../hooks/useTranslate";

// Components
import Button from "../Button";
import FileUpload from "../FileUpload";

const AddMedicalRecords = ({ caseId, text }) => {
  const [open, setOpen] = React.useState(false);
  const { trackException } = useAppInsights();
  const { uploadProgress, uploadErrors } = useSelector(s => s.medicalRecords);
  const dispatch = useDispatch();
  const getText = useTranslate();

  const handleOpen = () => {
    setOpen(true);
    dispatch(resetMedicalUploads());
  };

  const handleClose = () => setOpen(false);

  const handleProgress = index => progress => {
    dispatch(updateMedicalProgress({ index, progress }));
  };

  const handleSubmit = files => {
    files.forEach((item, index) => {
      dispatch(
        addMedicalRecord({
          caseId,
          description: item.description,
          file: item.file,
          id: index,
          onUploadProgress: handleProgress(index),
          onFail: () =>
            trackException(`Failed to add medical record to case: ${caseId}`)
        })
      );
    });
  };

  return (
    <>
      <Button
        color="primary"
        disableElevation
        onClick={handleOpen}
        variant="contained"
      >
        {getText(text || "medicalRecordsPage.addFilesButton")}
      </Button>
      <FileUpload
        multiple
        onClose={handleClose}
        onSubmit={handleSubmit}
        open={open}
        uploadErrors={uploadErrors}
        uploadProgress={uploadProgress}
      />
    </>
  );
};

AddMedicalRecords.defaultProps = {
  text: null
};

AddMedicalRecords.propTypes = {
  caseId: PropTypes.string.isRequired,
  text: PropTypes.string
};

export default AddMedicalRecords;
