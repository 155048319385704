export const capitalize = s => s.charAt(0).toUpperCase() + s.slice(1);

export const truncateText = (s, l, d = "...") => {
  const truncated = s.length > l + 2;
  return [truncated ? `${s.substring(0, l)}${d}` : s, truncated];
};

export const switchEnterKey = e => {
  if (e.keyCode === 13 && e.target.nodeName === "INPUT") {
    // make enter key behave as tab key
    const { form } = e.target;
    const index = Array.prototype.indexOf.call(form, e.target);
    form.elements[index + 1].focus();
    e.preventDefault();
  }
};

export default {
  capitalize,
  truncateText
};
