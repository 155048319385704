export const REQUEST_MEDIA = "media/REQUEST_MEDIA";
export const REQUEST_MEDIA_SUCCESS = "media/REQUEST_MEDIA_SUCCESS";
export const REQUEST_MEDIA_FAIL = "media/REQUEST_MEDIA_FAIL";

export const REQUEST_MEDIA_ITEM = "media/REQUEST_MEDIA_ITEM";
export const REQUEST_MEDIA_ITEM_SUCCESS = "media/REQUEST_MEDIA_ITEM_SUCCESS";
export const REQUEST_MEDIA_ITEM_FAIL = "media/REQUEST_MEDIA_ITEM_FAIL";

export const REQUEST_ADD_MEDIA = "media/REQUEST_ADD_MEDIA";
export const REQUEST_ADD_MEDIA_SUCCESS = "media/REQUEST_ADD_MEDIA_SUCCESS";
export const REQUEST_ADD_MEDIA_FAIL = "media/REQUEST_ADD_MEDIA_FAIL";

export const REQUEST_DEL_MEDIA = "media/REQUEST_DEL_MEDIA";
export const REQUEST_DEL_MEDIA_SUCCESS = "media/REQUEST_DEL_MEDIA_SUCCESS";
export const REQUEST_DEL_MEDIA_FAIL = "media/REQUEST_DEL_MEDIA_FAIL";

export const RESET_MEDIA_UPLOADS = "media/RESET_MEDIA_UPLOADS";
export const UPDATE_MEDIA_PROGRESS = "media/UPDATE_MEDIA_PROGRESS";

export const CLEAR_MEDIA_ERROR = "media/CLEAR_MEDIA_ERROR";
