import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";

// FontAwesome
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Config
import colors from "../../config/colors";

// Components
import Button from "../Button";

const useStyles = makeStyles(theme => ({
  button: {
    position: "absolute",
    top: "50%",
    zIndex: 99,
    padding: theme.spacing(3),
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.2)"
    }
  },
  left: {
    left: 0
  },
  right: {
    right: 0
  },
  chevron: {
    color: colors.white
  }
}));

const icon = {
  left: faChevronLeft,
  right: faChevronRight
};

const Arrow = ({ direction, onClick, className }) => {
  const classes = useStyles();

  useEffect(() => {
    const handleKeyDown = ({ key }) => {
      if (key.toLowerCase().includes(direction)) onClick();
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [direction, onClick]);

  return (
    <>
      <Button
        className={clsx(classes[direction], classes.button, className)}
        onClick={onClick}
      >
        <FontAwesomeIcon className={classes.chevron} icon={icon[direction]} />
      </Button>
    </>
  );
};

Arrow.propTypes = {
  direction: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string
};

Arrow.defaultProps = {
  className: ""
};

export const LeftArrow = props => <Arrow {...props} direction="left" />;
export const RightArrow = props => <Arrow {...props} direction="right" />;
