import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";

// FontAwesome
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Config
import styles from "../../config/styles";

// Actions
import {
  getNotifications,
  readNotification
} from "../../state/notifications/actions";

// Hooks
import { useAppInsights } from "../../utils/telemetry/AppInsights";

// Components
import Text from "../Text";
import NotificationItem from "./NotificationItem";

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    width: 496,
    paddingBottom: theme.spacing(7),
    ...styles.mixins.scrollBar
  },
  heading: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: theme.spacing(2, 6),
    justifyContent: "space-between",
    borderBottom: styles.values.border,

    "&:focus": {
      outline: "none"
    }
  },
  read: {
    textTransform: "initial"
  }
}));

const Notifications = ({ classes }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { data, isLoading } = useSelector(state => state.notifications);
  const { trackException } = useAppInsights();
  const customClasses = useStyles();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      getNotifications(() => trackException(`Failed to get notifications`))
    );
  }, [dispatch, trackException]);

  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const unread = data ? data.filter(item => !item.isRead) : [];
  const unreadCount = unread.length > 0 ? unread.length : null;

  const handleMarkAsRead = () => {
    if (data)
      data.forEach((item, index) => {
        if (!item.isRead)
          dispatch(
            readNotification({
              id: item.id,
              index,
              onFail: () =>
                trackException(`Failed to read notification: ${item.id}`)
            })
          );
      });
  };

  const handleRead = (id, index) => dispatch(readNotification({ id, index }));

  return (
    <>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        className={clsx(classes.button, {
          [classes.selectedButton]: anchorEl !== null
        })}
        disabled={isLoading}
        disableElevation
        onClick={handleClick}
      >
        <Badge badgeContent={unreadCount} color="secondary">
          <FontAwesomeIcon className={classes.icon} icon={faBell} />
        </Badge>
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        classes={{ paper: customClasses.paper }}
        elevation={0}
        getContentAnchorEl={null}
        id="customized-menu"
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <div className={customClasses.heading}>
          <Text type="heading6" text="header.notifications.title" />
          <Button
            className={customClasses.read}
            disableElevation
            onClick={handleMarkAsRead}
          >
            <Text
              text="header.notifications.markReadButton"
              type="subtitle1Small"
            />
          </Button>
        </div>
        {data &&
          data.map((item, index) => (
            <NotificationItem
              data={item}
              index={index}
              key={item.id}
              onClose={handleClose}
              onRead={handleRead}
            />
          ))}
      </Menu>
    </>
  );
};

Notifications.propTypes = {
  classes: PropTypes.shape({
    button: PropTypes.string,
    icon: PropTypes.string,
    selectedButton: PropTypes.string
  }).isRequired
};
export default Notifications;
