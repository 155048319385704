import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

// Config
import { getRoute, urls } from "../../config/routes";
import colors from "../../config/colors";
import styles from "../../config/styles";

// Hooks
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import useTranslate from "../../hooks/useTranslate";

// Actions
import { getInfoRequests } from "../../state/infoRequests/actions";

// Components
import Button from "../Button";
import ContentCardHeader from "../ContentCardHeader";
import ContributorRequestsItem from "./ContributorRequestsItem";
import Link from "../Link";
import LoadingSection from "../LoadingSection";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    border: styles.values.border
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3, 5, 5)
  },
  avatar: {
    height: 48,
    width: 48,
    marginRight: theme.spacing(2.5)
  },
  avatarImg: {
    position: "absolute",
    minWidth: "1000%",
    minHeight: "1000%",
    left: "50%",
    top: "50%",
    transform: "translateX(-50%) translateY(-50%) scale(0.1)"
  },
  request: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(2, 0),
    borderBottom: styles.values.border
  },
  data: {
    display: "flex",
    flexDirection: "column"
  },
  name: {
    marginBottom: theme.spacing(1)
  },
  message: {
    color: colors.lynch
  },
  details: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginLeft: "auto"
  },
  time: {
    fontSize: 14,
    marginBottom: theme.spacing(1)
  },
  unread: {
    backgroundColor: colors.pictonBlue,
    borderRadius: 9,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 18,
    minWidth: 18
  },
  count: {
    color: colors.white,
    lineHeight: 1,
    fontSize: 14
  },
  buttonWrapper: {
    margin: "0 auto",
    paddingTop: theme.spacing(5)
  },
  button: {
    width: 344
  }
}));

const filterRequests = (data, user, contributorId) => {
  const result = [];
  if (contributorId === "currentUser") return result;
  data.forEach(item => {
    if (item.parties.find(({ id }) => id === user.id)) {
      result.push(item);
    }
  });
  return result;
};

const ContributorRequests = ({ loading, contributor, caseId }) => {
  const { trackException } = useAppInsights();
  const classes = useStyles();
  const dispatch = useDispatch();
  const getText = useTranslate();

  const { contributorId } = useParams();
  const { data, isLoading } = useSelector(s => s.infoRequests);

  const requests = useMemo(
    () =>
      data && contributor
        ? filterRequests(data, contributor, contributorId)
        : [],
    [data, contributor, contributorId]
  );

  useEffect(() => {
    dispatch(
      getInfoRequests({
        caseId,
        onFail: () =>
          trackException(`Failed to get info request for case: ${caseId}`)
      })
    );
  }, [caseId, dispatch, trackException]);

  return (
    <Paper elevation={0} className={classes.root}>
      <ContentCardHeader>
        <Text
          text="caseDetailPage.contributorDetails.request"
          type="heading2"
        />
      </ContentCardHeader>
      {!contributor || !data || isLoading || loading ? (
        <LoadingSection noBackground normalHeight />
      ) : (
        <div className={classes.content}>
          {requests.map(({ id, ...request }) => (
            <ContributorRequestsItem
              {...request}
              caseId={caseId}
              contributor={contributor}
              id={id}
              key={id}
            />
          ))}
          <div className={classes.buttonWrapper}>
            <Button
              className={classes.button}
              color="primary"
              component={Link}
              disableElevation
              to={getRoute(urls.infoRequests, { caseId })}
              variant="contained"
            >
              {getText("furtherInfoPage.createInfoRequest.title")}
            </Button>
          </div>
        </div>
      )}
    </Paper>
  );
};

ContributorRequests.propTypes = {
  caseId: PropTypes.string.isRequired,
  contributor: PropTypes.shape({}),
  loading: PropTypes.bool.isRequired
};

ContributorRequests.defaultProps = {
  contributor: null
};

export default ContributorRequests;
