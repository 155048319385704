import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

// Config
import { getRoute, urls } from "../../config/routes";

// Components
import ContributorDetailPanel from "../../components/ContributorDetailPanel";
import ContributorRequests from "../../components/ContributorRequests";
import SectionWrapper from "../../components/SectionWrapper";

const ContributorDetailPage = () => {
  const history = useHistory();
  const { contributorId } = useParams();
  const { userDetails } = useSelector(s => s.user);
  const { contributors, data, isDeletingContributor, isLoadingContributors } =
    useSelector(state => state.caseDetail);

  const isCurrentUser = contributorId === "currentUser";

  const currentContributor = React.useMemo(
    () =>
      !isCurrentUser
        ? contributors.find(item => item && item.id === contributorId)
        : userDetails &&
          data && {
            capacity: data.userCapacity,
            fullName: userDetails.fullName,
            id: userDetails.id,
            invitationAccepted: true,
            profilePictureURL: userDetails.profilePhotoURL,
            isCreator: userDetails.id === data.creatorId
          },
    [contributorId, contributors, data, isCurrentUser, userDetails]
  );

  React.useEffect(() => {
    if (
      !isCurrentUser &&
      contributors &&
      contributors.length > 0 &&
      !currentContributor
    )
      history.push(getRoute(urls.caseDetail, { caseId: data.id }));
  }, [contributors, currentContributor, data, history, isCurrentUser]);

  return (
    <SectionWrapper>
      {data && currentContributor && (
        <>
          <ContributorDetailPanel
            caseId={data.id}
            contributor={currentContributor}
            creatorId={data.creatorId}
            deleting={isDeletingContributor}
            loading={isLoadingContributors}
            userCapacity={data.userCapacity}
          />
          <ContributorRequests
            caseId={data.id}
            contributor={currentContributor}
            loading={isLoadingContributors}
          />
        </>
      )}
    </SectionWrapper>
  );
};

export default ContributorDetailPage;
