import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import CardActionArea from "@material-ui/core/CardActionArea";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

// Config
import {
  MEDIA_LOADING_TIME_OFFSET,
  getTimeDifferenceInSeconds,
  mediaStatus
} from "../../config/values";
import colors from "../../config/colors";

// Components
import ImageProcessing from "../ImageProcessing";
import MediaItemPlaceholder from "../MediaItemPlaceholder";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  cardActionArea: {
    marginBottom: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius
  },
  placeholder: {
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    minHeight: 163,
    backgroundColor: colors.concrete,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(1.5)
  },
  errorContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 160,
    backgroundColor: colors.concrete,
    borderRadius: theme.shape.borderRadius
  },
  errorWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1)
  },
  textErrorWrapper: {
    display: "flex",
    flexDirection: "column",
    color: colors.rose
  },
  xmarkIcon: {
    color: colors.rose,
    marginTop: 3
  },
  replacementText: {
    color: colors.rose,
    textTransform: "uppercase",
    textDecoration: "underline"
  }
}));

const MediaItemContent = ({
  creationDateTime,
  filename,
  getImage,
  imgSrc,
  onClick,
  onOpenReupload,
  status
}) => {
  const classes = useStyles();
  const [difference, setDifference] = useState(
    getTimeDifferenceInSeconds(creationDateTime)
  );

  useEffect(() => {
    // display loading locally for MEDIA_LOADING_TIME_OFFSET seconds before displaying actual processing status
    // to prevent server delay from causing waiting upload error
    // TODO remove this after backend optimization
    if (status === mediaStatus.WAITING_FOR_UPLOAD) {
      setTimeout(() => {
        setDifference(getTimeDifferenceInSeconds(creationDateTime));
        getImage();
      }, 1000);
    }
  }, [creationDateTime, status, difference]);

  if (
    status === mediaStatus.PROCESSING ||
    (status === mediaStatus.WAITING_FOR_UPLOAD &&
      difference < MEDIA_LOADING_TIME_OFFSET)
  ) {
    return (
      <div className={classes.placeholder}>
        <span>
          <ImageProcessing
            key={filename}
            getImage={getImage}
            size={50}
            thickness={3}
          />
        </span>
      </div>
    );
  }

  if (
    status === mediaStatus.WAITING_FOR_UPLOAD ||
    status === mediaStatus.ERROR
  ) {
    return (
      <CardActionArea
        onClick={onOpenReupload}
        className={classes.cardActionArea}
      >
        <div className={classes.errorContainer}>
          <div className={classes.errorWrapper}>
            <FontAwesomeIcon
              className={classes.xmarkIcon}
              icon={faCircleXmark}
            />
            <div className={classes.textErrorWrapper}>
              <Text type="body" text="mediaPage.uploadFailed" />
              <Text
                type="body"
                className={classes.replacementText}
                text="common.valueLabels.uploadReplacement"
              />
            </div>
          </div>
        </div>
      </CardActionArea>
    );
  }

  return (
    <CardActionArea onClick={onClick} className={classes.cardActionArea}>
      <MediaItemPlaceholder filename={filename} src={imgSrc} />
    </CardActionArea>
  );
};

MediaItemContent.propTypes = {
  creationDateTime: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  getImage: PropTypes.func.isRequired,
  imgSrc: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onOpenReupload: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired
};

export default React.memo(MediaItemContent);
