import React from "react";

// Components
import EditInjuryForm from "../../components/EditInjuryForm";
import SectionWrapper from "../../components/SectionWrapper/SectionWrapper";

const EditInjuryPage = () => {
  return (
    <SectionWrapper>
      <EditInjuryForm />
    </SectionWrapper>
  );
};

export default EditInjuryPage;
