import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Formik - Material
import { makeStyles } from "@material-ui/core/styles";
import { Select, MenuItem } from "@material-ui/core/";

// Config
import styles from "../../config/styles";

// Components
import Text from "../Text";
import colors from "../../config/colors";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(3),
    width: "100%",
    minWidth: 300
  },
  titles: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.primary.main
  },
  inputs: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(1)
  },
  duration: {
    "& .MuiInputBase-root": {
      border: "none"
    }
  },
  input: styles.mixins.input,
  textIcon: {
    width: 44,
    display: "flex",
    justifyContent: "center"
  },
  focused: {
    borderColor: theme.palette.primary.main
  },
  valueLabel: {
    marginBottom: theme.spacing(1.5),
    color: colors.white
  },
  aproxValue: {
    fontWeight: 600,
    fontSize: 30,
    color: colors.white
  },
  label: {
    color: colors.black,
    marginBottom: theme.spacing(3)
  },
  durInput: {
    margin: theme.spacing(1, 3)
  }
}));

const fill = (max, min = 0) => {
  const list = [];
  for (let i = min; i <= max; i += 1)
    list.push(
      <MenuItem value={i} key={i}>
        {i}
      </MenuItem>
    );
  return list;
};

const DurationInput = ({ className, disabled, label, values, onChange }) => {
  const classes = useStyles();
  const { days, hours, minutes } = values;
  return (
    <div className={clsx(classes.root, className)}>
      {label && (
        <div className={classes.titles}>
          <Text type="contentLabel3" className={classes.valueLabel}>
            {label}
          </Text>
          <Text className={classes.aproxValue}>
            {!!days && `${days} days `}
            {!!hours && `${hours} hours `}
            {!!minutes && `${minutes} minutes`}
            {!minutes && !hours && !days && `less than 1 minute`}
          </Text>
        </div>
      )}
      <div className={classes.inputs}>
        <div className={classes.durInput}>
          <Text type="contentLabel2Bold" className={classes.label}>
            Days
          </Text>
          <Select
            className={classes.duration}
            type="select"
            {...{ disabled, onChange }}
            value={values.days}
            name="days"
          >
            {fill(365)}
          </Select>
        </div>
        <div className={classes.durInput}>
          <Text type="contentLabel2Bold" className={classes.label}>
            Hours
          </Text>
          <Select
            className={classes.duration}
            type="select"
            {...{ disabled, onChange }}
            value={values.hours}
            name="hours"
          >
            {fill(23)}
          </Select>
        </div>
        <div className={classes.durInput}>
          <Text type="contentLabel2Bold" className={classes.label}>
            Minutes
          </Text>
          <Select
            className={classes.duration}
            type="select"
            {...{ disabled, onChange }}
            value={values.minutes}
            name="minutes"
            label="Minutes"
          >
            {fill(59)}
          </Select>
        </div>
      </div>
    </div>
  );
};

DurationInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  values: PropTypes.shape({
    days: PropTypes.number,
    hours: PropTypes.number,
    minutes: PropTypes.number
  }).isRequired,
  onChange: PropTypes.func
};

DurationInput.defaultProps = {
  className: "",
  disabled: false,
  label: null,
  onChange: null
};

export default DurationInput;
