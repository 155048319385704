import React from "react";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Icons
import errorImg from "../../assets/icons/error_illus.svg";

// Config
import { getRoute, urls } from "../../config/routes";
import colors from "../../config/colors";
import useTranslate from "../../hooks/useTranslate";

// Components
import Button from "../../components/Button";
import Link from "../../components/Link";
import Text from "../../components/Text";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100%"
  },
  title: {
    fontFamily: "Montserrat",
    fontSize: 60,
    lineHeight: 1,
    color: colors.riverBed,
    textTransform: "uppercase",
    marginBottom: theme.spacing(1)
  },
  description: {
    lineHeight: 1.5
  },
  content: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    maxWidth: 400
  },
  image: {
    width: "40%",
    height: 350,
    display: "flex",
    backgroundImage: `url(${errorImg})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    marginLeft: theme.spacing(1)
  },
  button: {
    marginTop: theme.spacing(2),
    width: 220
  }
}));

const NotFoundPage = () => {
  const classes = useStyles();
  const getText = useTranslate();

  return (
    <main className={classes.root}>
      <div className={classes.content}>
        <Text text="notFoundPage.title" className={classes.title} />
        <Text
          text="notFoundPage.description"
          type="subtitle1Big"
          className={classes.description}
        />
        <Button
          component={Link}
          className={classes.button}
          color="primary"
          to={getRoute(urls.cases)}
          variant="contained"
          disableElevation
        >
          {getText("notFoundPage.button")}
        </Button>
      </div>
      <div className={classes.image} />
    </main>
  );
};

export default NotFoundPage;
