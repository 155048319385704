import React from "react";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

// Config
import featureFlagKeys from "../../config/featureFlagKeys";
import styles from "../../config/styles";

// Hooks
import { useFeatureFlags } from "../../context/FeatureFlagsProvider";
import useAuthorizedContent from "../../hooks/useAuthorizedContent";

// Components
import ContentCardHeader from "../../components/ContentCardHeader";
import GoBackLink from "../../components/GoBackLink";
import Text from "../../components/Text";

// Inner Components
import {
  DeleteAccount,
  LocalizationSettings,
  NotificationSettings,
  SettingsNav,
  UserInfoCard
} from "./components";

const useStyles = makeStyles(theme => ({
  root: {
    ...styles.mixins.mainSection,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    paddingBottom: theme.spacing(5)
  },
  section: styles.mixins.sectionContainer,
  paper: {
    border: styles.values.border
  },
  leftContent: {
    borderRight: styles.values.border,
    padding: theme.spacing(7, 3.5, 6),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  rightContent: {
    padding: theme.spacing(4)
  },
  localization: {
    marginBottom: theme.spacing(4)
  },
  version: {
    padding: theme.spacing(1),
    marginLeft: "auto"
  }
}));

export default () => {
  const classes = useStyles();
  const { featureFlags } = useFeatureFlags();
  useAuthorizedContent();

  return (
    <main className={classes.root}>
      <Grid container className={classes.section}>
        <GoBackLink />
        <Grid item xs={12}>
          <Paper elevation={0} className={classes.paper}>
            <ContentCardHeader>
              <Text text="settingsPage.title" type="heading2" />
            </ContentCardHeader>
            <Grid container>
              <Grid item xs={3} className={classes.leftContent}>
                <SettingsNav />
                <DeleteAccount />
              </Grid>
              <Grid item xs={9} className={classes.rightContent}>
                <UserInfoCard />
                <NotificationSettings />
                <LocalizationSettings className={classes.localization} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {!featureFlags[featureFlagKeys.INTEGRATION] && (
          <Text type="small" className={classes.version}>
            Version {process.env.REACT_APP_VERSION}
          </Text>
        )}
      </Grid>
    </main>
  );
};
