import React from "react";
// Material
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

// Config
import colors from "../../config/colors";
import styles from "../../config/styles";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 3, 3, 4),
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    borderBottom: styles.values.border,
    backgroundColor: colors.wildLand
  }
}));

const PlaceholderContact = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress size={38} thickness={3} color="primary" />
    </div>
  );
};

export default PlaceholderContact;
