import {
  SEND_STORE_MESSAGE_SUCCESS,
  SEND_STORE_MESSAGE,
  SEND_STORE_MESSAGE_FAIL
} from "./actionTypes";

import { parseFetchOptions } from "../user/actions";
import { sendStoreLinkUrl } from "../../config/apiConfig";
import errors from "../../utils/errors";

// Cases
const requestSendMessage = () => ({
  type: SEND_STORE_MESSAGE
});

const requestSendMessageSuccess = payload => ({
  type: SEND_STORE_MESSAGE_SUCCESS,
  payload
});

const requestSendMessageFail = payload => ({
  type: SEND_STORE_MESSAGE_FAIL,
  payload
});

export const sendStoreMessage =
  ({ platform, onFail }) =>
  async dispatch => {
    dispatch(requestSendMessage());

    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(sendStoreLinkUrl(), {
          method: "POST",
          headers: {
            ...options.headers,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            platform
          })
        }).catch(error => {
          dispatch(requestSendMessageFail(error));
          if (onFail) onFail();
        });

        if (response && response.ok) {
          dispatch(requestSendMessageSuccess());
        } else {
          dispatch(requestSendMessageFail(errors.responseError(response)));
          if (onFail) onFail();
        }
      })
    );
  };

export const otherAction = () => {};
