import React from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Hooks
import useTranslate from "../../../hooks/useTranslate";

// Icons
import statementIcon from "../../../assets/icons/statementIconColored.svg";

// Config
import { getRoute, urls } from "../../../config/routes";
import colors from "../../../config/colors";
import styles from "../../../config/styles";

// Components
import Button from "../../../components/Button";
import StepsFooter from "../../../components/StepsFooter/StepsFooter";
import Text from "../../../components/Text";
import TopTipsDrawer from "../../../components/TopTipsDrawer/TopTipsDrawer";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(6, 2),
    alignItems: "center"
  },
  contentWrapper: {
    flexDirection: "column",
    width: 730,
    marginBottom: theme.spacing(2)
  },
  goBackButton: {
    marginBottom: theme.spacing(1)
  },
  goBackLink: {
    textTransform: "uppercase"
  },
  chevron: {
    marginRight: theme.spacing(2)
  },
  goBackText: {
    color: colors.cloudBurst
  },
  paper: {
    border: styles.values.border,
    marginBottom: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(4, 6),
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  statementIcon: {
    marginBottom: theme.spacing(4)
  },
  contentTitle: {
    marginBottom: theme.spacing(3)
  },
  tipItem: {
    marginBottom: theme.spacing(2),
    lineHeight: 1.5,
    maxWidth: 600,
    fontSize: 18
  },
  marker: {
    marginRight: theme.spacing(1),
    fontFamily: ["Gibson SemiBold", "sans-serif"].join(",")
  },
  buttonsWrapper: {
    padding: theme.spacing(3, 0, 2)
  },
  linkButton: {
    marginRight: theme.spacing(2)
  },
  footer: {
    marginBottom: theme.spacing(6)
  }
}));

const tips = ["tip1", "tip2", "tip3", "tip4", "tip5", "tip6", "tip7"];

const NotesTopTips = ({ onClose, open }) => {
  const { caseId } = useParams();
  const classes = useStyles();
  const getText = useTranslate();

  return (
    <TopTipsDrawer footer={<StepsFooter />} onClose={onClose} open={open}>
      <img
        width={65}
        src={statementIcon}
        alt=""
        className={classes.statementIcon}
      />
      <Text
        text="notesPage.topTips.title"
        type="heading1"
        className={classes.contentTitle}
      />
      {tips.map((tipItem, index) => (
        <Text className={classes.tipItem} key={tipItem} type="subtitle1Small">
          <span className={classes.marker}>{index + 1}.</span>
          {getText(`notesPage.topTips.${tipItem}`)}
        </Text>
      ))}
      <div className={classes.buttonsWrapper}>
        <Button
          component={Link}
          to={getRoute(urls.newNote, { caseId })}
          className={classes.linkButton}
          onClick={onClose}
          color="primary"
          variant="contained"
          disableElevation
        >
          {getText("notesPage.notes.addNewNoteButton")}
        </Button>
        <Button
          component={Link}
          to={getRoute(urls.newStatement, { caseId })}
          className={classes.linkButton}
          onClick={onClose}
          color="primary"
          variant="contained"
          disableElevation
        >
          {getText("notesPage.statements.addNewStatementButton")}
        </Button>
      </div>
    </TopTipsDrawer>
  );
};

NotesTopTips.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default NotesTopTips;
