import React from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash-es/isEmpty";

// Material
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";

// Config
import styles from "../../config/styles";
import useMatches from "../../hooks/useMatches";

// Components
import ActionButtons from "./ActionButtons";
import StatusBar from "../StatusBar";
import TopBar from "./TopBar";
import UserInfo from "./UserInfo";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    boxShadow: styles.values.boxShadow,
    zIndex: 999
  },
  colorInherit: {
    color: theme.palette.common.black
  },
  buttons: {
    display: "flex",
    marginLeft: "auto"
  }
}));

const Header = () => {
  const { buttons, ...classes } = useStyles();
  const {
    annotationTool,
    caseDetail,
    contributorDetail,
    createCase,
    downloadReport,
    editInjury,
    infoRequestDetail
  } = useMatches();
  const { currentUser } = useSelector(s => s.user);
  const isPublic = !currentUser || isEmpty(currentUser);

  const showStatusBar =
    !isPublic &&
    !downloadReport &&
    (!annotationTool || editInjury || contributorDetail || infoRequestDetail);

  return (
    <AppBar classes={classes} position="fixed" color="inherit">
      <TopBar isPublic={isPublic} />
      {showStatusBar && (
        <StatusBar>
          <UserInfo />
          <div className={buttons}>
            <ActionButtons
              isCreating={Boolean(createCase)}
              showCaseOptions={Boolean(caseDetail)}
            />
          </div>
        </StatusBar>
      )}
    </AppBar>
  );
};

export default Header;
