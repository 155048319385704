import React, { useState } from "react";

// Material
import { makeStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";

// FontAwesome
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Icons
import settingsIcon from "../../assets/icons/settings.svg";

// Config
import colors from "../../config/colors";
import styles from "../../config/styles";
import useTranslate from "../../hooks/useTranslate";

// Components
import { NotificationSettings } from "../../containers/SettingsPage/components";
import Button from "../Button";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
    width: 432
  },
  header: {
    borderBottom: styles.values.border,
    width: "100%",
    padding: theme.spacing(0, 2, 3),
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  settingsButton: {
    padding: 0,
    marginRight: theme.spacing(0.5)
  },
  text: {
    ...styles.mixins.link,
    cursor: "pointer"
  },
  chevron: {
    marginRight: theme.spacing(2),
    height: 10
  },
  footerText: {
    color: colors.santasGray
  }
}));

export default () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const getText = useTranslate();

  const handleOpen = event => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <>
      <Popper open={open} anchorEl={anchorEl} transition placement="top-start">
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper className={classes.root}>
                <div className={classes.header}>
                  <FontAwesomeIcon
                    className={classes.chevron}
                    icon={faChevronLeft}
                  />
                  <Text
                    text="createInjuryPage.step2.settingsPopper.title"
                    type="heading2"
                  />
                </div>
                <NotificationSettings hideHeader />
                <Text type="subtitle1Small" className={classes.footerText}>
                  {getText("createInjuryPage.step2.settingsPopper.part1")}
                  <img width={14} height={14} src={settingsIcon} alt="" />{" "}
                  {getText("createInjuryPage.step2.settingsPopper.part2")}
                </Text>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
      <Button
        className={classes.settingsButton}
        disableElevation
        disableRipple
        onClick={handleOpen}
      >
        <Text
          className={classes.text}
          text="createInjuryPage.step2.subtitle3Link"
          type="subtitle1Small"
        />
      </Button>
    </>
  );
};
