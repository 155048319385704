import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Material
import { makeStyles } from "@material-ui/core/styles";
import MuiAvatar from "@material-ui/core/Avatar";

const useStyles = makeStyles({
  avatar: {
    position: "relative"
  },
  avatarImg: {
    height: "100%",
    width: "100%",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "absolute",
    backgroundImage: ({ src }) => `url(${src})`
  }
});

const Avatar = React.forwardRef(
  ({ src, className, children, ...rest }, ref) => {
    const classes = useStyles({ src });

    return (
      <MuiAvatar
        ref={ref}
        className={clsx(classes.avatar, className)}
        {...rest}
      >
        {children}
        <div className={classes.avatarImg} />
      </MuiAvatar>
    );
  }
);

Avatar.propTypes = {
  children: PropTypes.node,
  src: PropTypes.string,
  className: PropTypes.string.isRequired
};

Avatar.defaultProps = {
  children: null,
  src: ""
};

export default Avatar;
