import { combineReducers } from "redux";
import constant from "lodash-es/constant";

import createReducer from "../../utils/createReducer";

import {
  REQUEST_CASES,
  REQUEST_CASES_SUCCESS,
  REQUEST_CASES_FAIL,
  REQUEST_CREATE_CASE_SUCCESS,
  REQUEST_CREATE_CASE,
  REQUEST_CREATE_CASE_FAIL,
  RESET_TOGGLED_CASE,
  TOGGLE_CASE
} from "./actionTypes";
import {
  REQUEST_DELETE_CASE_SUCCESS,
  REQUEST_SUBMIT_CASE_SUCCESS
} from "../caseDetail/actionTypes";

// Cases Data
const data = createReducer(null, {
  [REQUEST_CREATE_CASE_SUCCESS]: constant(null),
  [REQUEST_SUBMIT_CASE_SUCCESS]: constant(null),
  [REQUEST_CASES_SUCCESS]: (state, { payload }) => payload,
  [REQUEST_DELETE_CASE_SUCCESS]: (state, { payload }) => {
    const newData = [];
    const s = state;

    if (s) {
      s.forEach(item => {
        if (item.id !== payload) newData.push(item);
      });
    } else return null;

    return newData;
  },
  [REQUEST_CASES]: constant(null)
});

const error = createReducer(null, {
  [REQUEST_CASES]: constant(null),
  [REQUEST_CASES_FAIL]: (state, { payload }) => payload
});

const isLoading = createReducer(false, {
  [REQUEST_CASES]: constant(true),
  [REQUEST_CASES_SUCCESS]: constant(false),
  [REQUEST_CASES_FAIL]: constant(false)
});

const isCreating = createReducer(false, {
  [REQUEST_CREATE_CASE]: constant(true),
  [REQUEST_CREATE_CASE_SUCCESS]: constant(false),
  [REQUEST_CREATE_CASE_FAIL]: constant(false)
});

const createError = createReducer(null, {
  [REQUEST_CREATE_CASE]: constant(null),
  [REQUEST_CREATE_CASE_FAIL]: (state, { payload }) => payload
});

const toggledCaseId = createReducer(null, {
  [REQUEST_CASES]: constant(null),
  [RESET_TOGGLED_CASE]: constant(null),
  [TOGGLE_CASE]: (state, { payload }) => payload
});

export default combineReducers({
  data,
  error,
  isLoading,
  isCreating,
  createError,
  toggledCaseId
});
