import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

// Config
import colors from "../../config/colors";

// Components
import Text from "../Text";
import UserAddition from "../UserAddition";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  body: {
    marginBottom: theme.spacing(2)
  },
  user: {
    textDecoration: "underline",
    color: colors.dodgerBlue
  }
}));

const DescriptionItem = ({ content, title }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Text
        className={classes.title}
        key="description-title"
        text={title}
        type="heading4"
      />
      {content.map(({ text, creationDateTime: date, author }) => (
        <UserAddition
          key={`${author.id}-${date}-${text}`}
          {...{ text, author, date }}
        />
      ))}
    </div>
  );
};

DescriptionItem.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      creationDateTime: PropTypes.string,
      author: PropTypes.shape({
        fullName: PropTypes.string,
        id: PropTypes.string
      })
    })
  ).isRequired,
  title: PropTypes.string.isRequired
};

export default DescriptionItem;
