import React from "react";
import PropTypes from "prop-types";

// Utils
import { saveAs } from "file-saver";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Config + Utils
import { getFileExtension } from "../../utils/images";
import { getPlaceholder } from "../../config/values";
import useTranslate from "../../hooks/useTranslate";

// Component
import Button from "../Button";
import ContentDelay from "./ContentDelay";
import DisplayImage from "./DisplayImage";
import Text from "../Text/Text";
import VideoPlayer from "./VideoPlayer";

const useStyles = makeStyles(theme => ({
  placeholder: {
    width: 600,
    height: "70vh",
    padding: 40,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  noPreview: {
    margin: theme.spacing(4, 0),
    textAlign: "center"
  },
  image: {
    maxWidth: "100%",
    maxHeight: "70vh",
    display: "block",
    minHeight: 200
  },
  embed: {
    width: "70vw",
    height: "calc(70vh - 55px)",
    border: 0
  },
  galleryItem: {
    minHeight: 200,
    minWidth: 200,
    maxWidth: "70vw",
    display: "flex",
    flexDirection: "column",

    "& .pg-viewer-wrapper": {
      overflow: "initial"
    }
  },
  downloadButton: {
    height: 50,
    width: 200
  },
  floatingButton: {
    width: "100%",
    backgroundColor: "rgba(0,0,0,0.7)",
    padding: theme.spacing(1, 3),
    display: "flex",
    justifyContent: "flex-end",
    position: "sticky",
    top: 0,
    zIndex: 99
  },
  downloadIconButton: {
    filter: "brightness(5)"
  }
}));

const handleDownload = (src, filename) => async () => {
  const response = await fetch(src);
  const data = await response.blob();
  saveAs(data, filename);
};

const MediaItemImage = ({ filename, src, type }) => {
  const classes = useStyles();
  const [showPlaceholder, setShowPlaceholder] = React.useState(false);
  const getText = useTranslate();

  React.useEffect(() => {
    if (!src) setShowPlaceholder(true);
    else setShowPlaceholder(false);
  }, [src]);

  const handleNonImage = () => setShowPlaceholder(true);

  const previews = React.useMemo(
    () => ({
      document: s => <embed src={s} className={classes.embed} />,
      video: s => (
        <ContentDelay
          render={handleRetry => (
            <VideoPlayer
              className={classes.image}
              handleNonImage={handleNonImage}
              hls={s.hls}
              dash={s.dash}
              onError={handleRetry}
            />
          )}
        />
      ),
      image: s => (
        <DisplayImage
          alt=""
          className={classes.image}
          onError={handleNonImage}
          src={s}
        />
      )
    }),
    [classes]
  );

  const extension = getFileExtension(filename);
  const component = previews[type] || previews[extension];

  return !showPlaceholder && component ? (
    <div className={classes.galleryItem}>{component(src)}</div>
  ) : (
    <div className={classes.placeholder}>
      <img src={getPlaceholder(filename)} alt="" />
      <Text
        className={classes.noPreview}
        text="mediaGalleryPage.noPreview"
        type="valueLabel"
      />
      <Button
        className={classes.downloadButton}
        color="primary"
        disableElevation
        onClick={handleDownload(src, filename)}
        variant="contained"
      >
        {getText("mediaGalleryPage.downloadButton")}
      </Button>
    </div>
  );
};

MediaItemImage.propTypes = {
  filename: PropTypes.string.isRequired,
  src: PropTypes.string,
  type: PropTypes.string.isRequired
};

MediaItemImage.defaultProps = {
  src: null
};

export default MediaItemImage;
