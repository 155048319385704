import axios from "axios";

import {
  CLEAR_CASE_DETAIL,
  REQUEST_CASE_DETAIL_SUCCESS,
  REQUEST_CASE_DETAIL,
  REQUEST_CASE_DETAIL_FAIL,
  REQUEST_CASE_CONTRIB,
  REQUEST_CASE_CONTRIB_SUCCESS,
  REQUEST_CASE_CONTRIB_FAIL,
  REQUEST_DELETE_CASE,
  REQUEST_DELETE_CASE_SUCCESS,
  REQUEST_DELETE_CASE_FAIL,
  REQUEST_SUBMIT_CASE,
  REQUEST_SUBMIT_CASE_SUCCESS,
  REQUEST_SUBMIT_CASE_FAIL,
  REQUEST_REPORT,
  REQUEST_REPORT_SUCCESS,
  REQUEST_REPORT_FAIL,
  RESET_ERRORS,
  REQUEST_DEL_CONTRIB,
  REQUEST_DEL_CONTRIB_SUCCESS,
  REQUEST_DEL_CONTRIB_FAIL,
  CLEAR_MEDIA_UPLOAD_PROGRESS
} from "./actionTypes";

import {
  contributorItemUrl,
  getCaseContributorsUrl,
  getCaseDetailUrl,
  requestReportUrl,
  submitCaseUrl
} from "../../config/apiConfig";
import { parseFetchOptions } from "../user/actions";
import errors from "../../utils/errors";

// Case Detail
export const clearCaseDetail = () => ({
  type: CLEAR_CASE_DETAIL
});

const requestCaseDetail = () => ({
  type: REQUEST_CASE_DETAIL
});

const requestCaseDetailSuccess = payload => ({
  type: REQUEST_CASE_DETAIL_SUCCESS,
  payload
});

const requestCaseDetailFail = payload => ({
  type: REQUEST_CASE_DETAIL_FAIL,
  payload
});

export const getCaseDetail =
  ({ caseId, onFail, avoidError, isUpdate = false }) =>
  async dispatch => {
    if (!isUpdate) dispatch(requestCaseDetail());

    dispatch(
      parseFetchOptions(async options => {
        const response = await axios
          .request({
            method: "GET",
            headers: options.headers,
            url: `/cases/${caseId}`
          })
          .catch(error => {
            const callback = () => dispatch(requestCaseDetailFail(error));
            if (!avoidError) callback();
            if (onFail) onFail(callback);
          });

        if (response && response.status === 200) {
          dispatch(requestCaseDetailSuccess(response.data));
        } else {
          dispatch(requestCaseDetailFail(errors.responseError(response)));
          if (onFail) onFail();
        }
      })
    );
  };

// Submit Case
const requestSubmitCase = payload => ({
  type: REQUEST_SUBMIT_CASE,
  payload
});

const requestSubmitCaseSuccess = payload => ({
  type: REQUEST_SUBMIT_CASE_SUCCESS,
  payload
});

const requestSubmitCaseFail = payload => ({
  type: REQUEST_SUBMIT_CASE_FAIL,
  payload
});

export const submitCase =
  ({ caseId, onSuccess, onFail, body }) =>
  async dispatch => {
    dispatch(requestSubmitCase(caseId));

    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(submitCaseUrl(caseId), {
          method: "PUT",
          headers: {
            ...options.headers,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(body)
        }).catch(error => {
          dispatch(requestSubmitCaseFail(error));
          if (onFail) onFail();
        });

        if (response && response.ok) {
          dispatch(requestSubmitCaseSuccess());
          if (onSuccess) onSuccess();
        } else {
          dispatch(requestSubmitCaseFail(errors.responseError(response)));
          if (onFail) onFail();
        }
      })
    );
  };

// Delete Case
const requestDeleteCase = payload => ({
  type: REQUEST_DELETE_CASE,
  payload
});

const requestDeleteCaseSuccess = payload => ({
  type: REQUEST_DELETE_CASE_SUCCESS,
  payload
});

const requestDeleteCaseFail = payload => ({
  type: REQUEST_DELETE_CASE_FAIL,
  payload
});

export const deleteCase =
  ({ caseId, onSuccess, onFail }) =>
  async dispatch => {
    dispatch(requestDeleteCase(caseId));

    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(getCaseDetailUrl(caseId), {
          method: "DELETE",
          headers: options.headers
        }).catch(error => {
          if (onFail) onFail();
          dispatch(requestDeleteCaseFail(error));
        });

        if (response && response.ok) {
          if (onSuccess) onSuccess();
          dispatch(requestDeleteCaseSuccess(caseId));
        } else {
          if (onFail) onFail();
          dispatch(requestDeleteCaseFail(errors.responseError(response)));
        }
      })
    );
  };

// Contributors
const requestCaseContributors = () => ({
  type: REQUEST_CASE_CONTRIB
});

const requestCaseContributorsSuccess = payload => ({
  type: REQUEST_CASE_CONTRIB_SUCCESS,
  payload
});

const requestCaseContributorsFail = payload => ({
  type: REQUEST_CASE_CONTRIB_FAIL,
  payload
});

export const getCaseContributors = (caseId, onFail) => async dispatch => {
  dispatch(requestCaseContributors());

  dispatch(
    parseFetchOptions(async options => {
      const response = await axios
        .request({
          method: "GET",
          headers: options.headers,
          url: getCaseContributorsUrl(caseId)
        })
        .catch(error => {
          if (onFail) onFail();
          dispatch(requestCaseContributorsFail(error));
        });

      if (response && response.status === 200) {
        dispatch(requestCaseContributorsSuccess(response.data));
      } else {
        if (onFail) onFail();
        dispatch(requestCaseContributorsFail(errors.responseError(response)));
      }
    })
  );
};

const requestDeleteContributor = payload => ({
  type: REQUEST_DEL_CONTRIB,
  payload
});

const requestDeleteContributorSuccess = payload => ({
  type: REQUEST_DEL_CONTRIB_SUCCESS,
  payload
});

const requestDeleteContributorFail = payload => ({
  type: REQUEST_DEL_CONTRIB_FAIL,
  payload
});

export const deleteContributor =
  ({ caseId, contributorId, onFail }) =>
  async dispatch => {
    dispatch(requestDeleteContributor(contributorId));

    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(
          contributorItemUrl(caseId, contributorId),
          {
            method: "DELETE",
            headers: options.headers
          }
        ).catch(error => {
          if (onFail) onFail();
          dispatch(requestDeleteContributorFail(error));
        });

        if (response && response.ok) {
          dispatch(requestDeleteContributorSuccess(contributorId));
        } else {
          if (onFail) onFail();
          dispatch(
            requestDeleteContributorFail(errors.responseError(response))
          );
        }
      })
    );
  };

// Request Report
const requestReport = () => ({
  type: REQUEST_REPORT
});

const requestReportSuccess = payload => ({
  type: REQUEST_REPORT_SUCCESS,
  payload
});

const requestReportFail = payload => ({
  type: REQUEST_REPORT_FAIL,
  payload
});

export const downloadCase =
  ({ body, onFail, onFinish }) =>
  async dispatch => {
    dispatch(requestReport());

    dispatch(
      parseFetchOptions(async options => {
        const response = await axios
          .request({
            method: "POST",
            headers: {
              ...options.headers,
              "Content-Type": "application/json"
            },
            url: requestReportUrl,
            data: JSON.stringify(body)
          })
          .catch(error => {
            if (onFail) onFail();
            dispatch(requestReportFail(error));
          });

        if (response && response.status === 202) {
          dispatch(requestReportSuccess());
        } else {
          if (onFail) onFail();
          dispatch(requestReportFail(errors.responseError(response)));
        }

        if (onFinish) onFinish();
      })
    );
  };

// Reset Case Details Error
export const resetErrors = () => ({
  type: RESET_ERRORS
});

// Clear all media upload progress
export const clearAllMediaProgress = () => ({
  type: CLEAR_MEDIA_UPLOAD_PROGRESS
});
