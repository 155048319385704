import { applyMiddleware, combineReducers, createStore, compose } from "redux";
import thunkMiddleware from "redux-thunk";

import actions from "./actions/reducer";
import caseDetail from "./caseDetail/reducer";
import caseIncident from "./incident/reducer";
import cases from "./cases/reducer";
import errorPage from "./errorPage/reducer";
import infoRequests from "./infoRequests/reducer";
import injuries from "./injuries/reducer";
import invitations from "./invitations/reducer";
import media from "./media/reducer";
import medicalRecords from "./medicalRecords/reducer";
import notes from "./notes/reducer";
import notifications from "./notifications/reducer";
import snackbar from "./snackbar/reducer";
import user from "./user/reducer";
import emergencyContact from "./emergencyContact/reducer";
import places from "./places/reducer";

const rootReducer = combineReducers({
  actions,
  caseDetail,
  caseIncident,
  cases,
  errorPage,
  infoRequests,
  injuries,
  invitations,
  media,
  medicalRecords,
  notes,
  notifications,
  snackbar,
  user,
  emergencyContact,
  places
});

const composeEnhancer =
  process.env.NODE_ENV === "production"
    ? compose
    : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(thunkMiddleware))
);
