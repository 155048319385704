import { combineReducers } from "redux";
import constant from "lodash-es/constant";

// ActionTypes
import { RESET_SNACK, TOGGLE_SNACK } from "./actionTypes";
import {
  REQUEST_DELETE_IMG_FAIL,
  REQUEST_INJURY_FAIL,
  REQUEST_UPLOAD_TREATMENT_FAIL,
  REQUEST_DEL_INJURY_FAIL,
  REQUEST_DEL_INJURY_SUCCESS,
  REQUEST_UPLOAD_ANNOTATED_SUCCESS,
  REQUEST_UPLOAD_ANNOTATED
} from "../injuries/actionTypes";
import {
  REQUEST_CASES_FAIL,
  REQUEST_CREATE_CASE_FAIL
} from "../cases/actionTypes";
import {
  REQUEST_CASE_DETAIL_FAIL,
  REQUEST_DELETE_CASE_SUCCESS,
  REQUEST_REPORT_FAIL,
  REQUEST_DEL_CONTRIB_SUCCESS
} from "../caseDetail/actionTypes";
import {
  REQUEST_SEND_INV_SUCCESS,
  REQUEST_SEND_INV_FAIL
} from "../invitations/actionTypes";
import {
  SEND_STORE_MESSAGE_SUCCESS,
  SEND_STORE_MESSAGE,
  SEND_STORE_MESSAGE_FAIL
} from "../actions/actionTypes";
import {
  REQUEST_DEL_MEDIA_SUCCESS,
  REQUEST_DEL_MEDIA_FAIL
} from "../media/actionTypes";
import {
  REQUEST_DEL_MED_SUCCESS,
  REQUEST_DEL_MED_FAIL
} from "../medicalRecords/actionTypes";
import { REQUEST_INFO_REQUESTS_FAIL } from "../infoRequests/actionTypes";

// Config
import { snackbarTypes } from "../../config/snackbar";
import createReducer from "../../utils/createReducer";
import {
  REQUEST_CREATE_NOTE_FAIL,
  REQUEST_DELETE_NOTE_FAIL,
  REQUEST_NOTES_FAIL,
  REQUEST_PUBLISH_NOTE_FAIL,
  REQUEST_UPDATE_NOTE_FAIL
} from "../notes/actionTypes";

const type = createReducer(null, {
  [RESET_SNACK]: constant(null),
  [TOGGLE_SNACK]: (state, { payload }) => payload.type,

  // Annotation Tool
  [REQUEST_DELETE_IMG_FAIL]: constant(snackbarTypes.ERROR),
  [REQUEST_UPLOAD_ANNOTATED_SUCCESS]: constant(snackbarTypes.ANNOTATION_SAVED),
  [REQUEST_UPLOAD_ANNOTATED]: constant(snackbarTypes.UPLOADING),

  // Injuries
  [REQUEST_INJURY_FAIL]: constant(snackbarTypes.ERROR),
  [REQUEST_DEL_INJURY_FAIL]: constant(snackbarTypes.ERROR),
  [REQUEST_DEL_INJURY_SUCCESS]: constant(snackbarTypes.INJURY_DELETED),

  // Treatments
  [REQUEST_UPLOAD_TREATMENT_FAIL]: constant(snackbarTypes.ERROR),

  // Cases
  [REQUEST_CREATE_CASE_FAIL]: constant(snackbarTypes.ERROR),
  [REQUEST_CASES_FAIL]: constant(snackbarTypes.ERROR),

  // Case Detail
  [REQUEST_CASE_DETAIL_FAIL]: constant(snackbarTypes.CASE_NOT_FOUND),
  [REQUEST_DELETE_CASE_SUCCESS]: constant(snackbarTypes.CASE_DELETED),
  [REQUEST_DEL_CONTRIB_SUCCESS]: constant(snackbarTypes.CONTRIBUTOR_DELETED),

  // Invitations
  [REQUEST_SEND_INV_SUCCESS]: (state, { payload }) =>
    payload ? snackbarTypes.INVITATION_SENT : state,
  [REQUEST_SEND_INV_FAIL]: constant(snackbarTypes.ERROR),

  // Send Message
  [SEND_STORE_MESSAGE]: constant(snackbarTypes.SENDING_MESSAGE),
  [SEND_STORE_MESSAGE_SUCCESS]: constant(snackbarTypes.SMS_SENT),
  [SEND_STORE_MESSAGE_FAIL]: constant(snackbarTypes.ERROR),

  // Request Report (Download)
  [REQUEST_REPORT_FAIL]: constant(snackbarTypes.ERROR),

  // Media
  [REQUEST_DEL_MEDIA_SUCCESS]: constant(snackbarTypes.MEDIA_DELETED),
  [REQUEST_DEL_MEDIA_FAIL]: constant(snackbarTypes.ERROR),

  // Medical Records
  [REQUEST_DEL_MED_SUCCESS]: constant(snackbarTypes.MEDIA_DELETED),
  [REQUEST_DEL_MED_FAIL]: constant(snackbarTypes.ERROR),

  // Notes
  [REQUEST_NOTES_FAIL]: constant(snackbarTypes.ERROR),
  [REQUEST_CREATE_NOTE_FAIL]: constant(snackbarTypes.ERROR),
  [REQUEST_DELETE_NOTE_FAIL]: constant(snackbarTypes.ERROR),
  [REQUEST_PUBLISH_NOTE_FAIL]: constant(snackbarTypes.ERROR),
  [REQUEST_UPDATE_NOTE_FAIL]: constant(snackbarTypes.ERROR),

  // Info Requests
  [REQUEST_INFO_REQUESTS_FAIL]: constant(snackbarTypes.ERROR)
});

const text = createReducer(null, {
  [RESET_SNACK]: constant(null),
  [TOGGLE_SNACK]: (state, { payload }) => payload.text || null
});

export default combineReducers({
  type,
  text
});
