import React from "react";
import PropTypes from "prop-types";
import get from "lodash-es/get";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";

// Config
import colors from "../../config/colors";

// Components
import { LeftArrow, RightArrow } from "../Arrows";
import GalleryItem from "./GalleryItem";
import MediaItemImage from "../MediaItemImage/MediaItemImage";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  paper: {
    overflow: "inherit",
    maxWidth: "70vw"
  },
  root: {
    margin: 0
  },
  arrow: {
    position: "fixed",
    zIndex: 9999
  },
  filename: {
    position: "fixed",
    top: theme.spacing(7),
    left: theme.spacing(7),
    color: colors.white
  },
  descriptionContainer: {
    position: "fixed",
    bottom: theme.spacing(5),
    left: theme.spacing(7)
  },
  descriptionTitle: {
    color: colors.white
  },
  description: {
    color: colors.white,
    fontSize: 24
  }
}));

const CaseMediaGallery = ({
  caseId,
  data,
  onClose,
  onNext,
  onPrev,
  open,
  view
}) => {
  const { paper, ...classes } = useStyles();

  const numberFilename = `${data.exhibitNumber} ${data.filename}`;
  return (
    <Dialog classes={{ paper }} open={open} onClose={onClose}>
      <LeftArrow className={classes.arrow} onClick={onPrev} />
      <RightArrow className={classes.arrow} onClick={onNext} />
      <Text type="heading1" className={classes.filename}>
        {numberFilename}
      </Text>
      {!view || !caseId ? (
        <MediaItemImage
          filename={data.filename}
          src={get(data, "downloadURLs.webLargePreviewURL", "")}
          type={data.type}
        />
      ) : (
        <GalleryItem
          caseId={caseId}
          mediaId={data.id}
          onFail={onClose}
          view={view}
        />
      )}
      {data.description && (
        <div className={classes.descriptionContainer}>
          <Text
            className={classes.descriptionTitle}
            text="mediaGalleryPage.description"
            type="heading3"
          />
          <Text className={classes.description} type="large">
            {data.description}
          </Text>
        </div>
      )}
    </Dialog>
  );
};

CaseMediaGallery.propTypes = {
  caseId: PropTypes.string,
  data: PropTypes.shape({
    description: PropTypes.string,
    downloadURL: PropTypes.string,
    exhibitNumber: PropTypes.string,
    filename: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.string
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  view: PropTypes.string
};

CaseMediaGallery.defaultProps = {
  caseId: null,
  view: null
};

export default CaseMediaGallery;
