import { useEffect, useRef, useState } from "react";

const usePopper = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => setOpen(prevOpen => !prevOpen);

  const handleClose = event => {
    if (
      event &&
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleKeyDown = event => {
    if (event.key === "Tab") {
      event.preventDefault();
      handleClose();
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return {
    anchorRef,
    handleClose,
    handleKeyDown,
    handleToggle,
    open
  };
};

export default usePopper;
