import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

// Icons
import statementIcon from "../../../assets/icons/statementIcon.svg";

// Config
import useTranslate from "../../../hooks/useTranslate";
import styles from "../../../config/styles";
import { getRoute, urls } from "../../../config/routes";

// Common Components
import Button from "../../../components/Button/Button";
import Link from "../../../components/Link";
import LoadingSection from "../../../components/LoadingSection";
import Routes from "../../../routes/Routes";

// Components
import EmptyNotesPlaceholder from "./EmptyNotesPlaceholder";
import NoteDetail from "./NoteDetail";
import NoteItem from "./NoteItem";

const useStyles = makeStyles(theme => ({
  root: {
    border: styles.values.border
  },
  drawer: {
    borderRight: styles.values.border,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: 400
  },
  notesList: {
    display: "flex",
    flexDirection: "column"
  },
  footer: {
    width: "100%",
    padding: theme.spacing(3)
  },
  button: {
    width: "100%"
  },
  content: {
    padding: theme.spacing(5),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap"
  }
}));

const StatementsPanel = ({ handleShowTips }) => {
  const { caseId } = useParams();
  const { data, isRequesting } = useSelector(s => s.notes);
  const { data: caseData } = useSelector(s => s.caseDetail);

  const classes = useStyles();
  const getText = useTranslate();

  const notes = useMemo(() => {
    return data
      ? data
          .filter(item => item.noteType === "Statement")
          .sort((a, b) => {
            const i = a.updateDateTime || a.creationDateTime;
            const j = b.updateDateTime || b.creationDateTime;
            if (i > j) return -1;
            if (i < j) return 1;
            return 0;
          })
      : [];
  }, [data]);

  if (isRequesting) return <LoadingSection normalHeight />;
  if (!data || data.length === 0) {
    return (
      <EmptyNotesPlaceholder
        buttonText={getText("notesPage.statements.addNewStatementButton")}
        icon={statementIcon}
        text="notesPage.statements.emptyText"
        to={urls.newStatement}
        onClick={handleShowTips}
      />
    );
  }

  return (
    <Grid container spacing={0}>
      <Grid item xs={4} className={classes.drawer}>
        <div className={classes.notesList}>
          {notes.map(note => (
            <NoteItem key={note.id} data={note} to={urls.statementDetail} />
          ))}
        </div>
        <div className={classes.footer}>
          <Button
            className={classes.button}
            color="primary"
            component={Link}
            disableElevation
            to={getRoute(urls.newStatement, { caseId })}
            variant="contained"
          >
            {getText("notesPage.statements.newStatementButton")}
          </Button>
        </div>
      </Grid>
      <Grid item xs={8}>
        <Routes
          routes={[
            {
              isExact: true,
              Component: NoteDetail,
              title: "statements",
              id: "caseStatements",
              path: urls.statementDetail,
              props: {
                disableDelete: !caseData.isDraft,
                disableEdit: true
              }
            }
          ]}
        />
      </Grid>
    </Grid>
  );
};

StatementsPanel.propTypes = {
  handleShowTips: PropTypes.func.isRequired
};

export default StatementsPanel;
