import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

// Material
import { makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";

// Icons
import editIcon from "../../../assets/icons/edit-3.svg";
import okTick from "../../../assets/icons/ok_tick.svg";

// Config
import { getMeUrl } from "../../../config/apiConfig";
import colors from "../../../config/colors";
import styles from "../../../config/styles";

// Actions
import { parseFetchOptions } from "../../../state/user/actions";

// Components
import Close from "../../../components/Close";
import Text from "../../../components/Text";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative"
  },
  inputBase: {
    display: "flex",
    justifyContent: "flex-end",
    fontSize: 14,
    lineHeight: 1,
    color: colors.tuna,
    fontWeight: theme.typography.fontWeightRegular,
    height: "auto",
    border: styles.values.border,
    padding: theme.spacing(0, 1)
  },
  buttonsWrapper: {
    marginLeft: theme.spacing(1),
    position: "absolute",
    left: "100%",
    top: "50%",
    transform: " translateY(-50%)",
    display: "flex",
    flexDirection: "row"
  },
  button: {
    padding: theme.spacing(1),
    height: 36,
    width: 36
  },
  icon: {
    maxHeight: 20,
    maxWidth: 20
  },
  toggleEdit: {
    position: "absolute",
    left: "100%",
    top: "50%",
    transform: " translateY(-50%)",
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1),
    height: 36,
    width: 36
  },
  editIcon: {
    maxHeight: 20,
    maxWidth: 20
  }
}));

const JobTitleInput = ({ value }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isEditing, setEditing] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [jobTitle, setJobTitle] = useState(value);

  const handleChange = e => setJobTitle(e.target.value);
  const toggleEdit = status => () => setEditing(status);

  const handleSave = e => {
    e.preventDefault();
    setSaving(true);

    dispatch(
      parseFetchOptions(options => {
        fetch(getMeUrl, {
          method: "PATCH",
          headers: {
            ...options.headers,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ jobTitle })
        }).then(() => {
          setSaving(false);
          setEditing(false);
        });
      })
    );
  };

  return isEditing ? (
    <form onSubmit={handleSave} className={classes.root}>
      <InputBase
        className={classes.inputBase}
        disabled={isSaving}
        onChange={handleChange}
        required
        value={jobTitle}
      />
      <div className={classes.buttonsWrapper}>
        {isSaving ? (
          <CircularProgress size={20} thickness={3} />
        ) : (
          <>
            <IconButton onClick={handleSave} className={classes.button}>
              <img src={okTick} alt="Call" className={classes.icon} />
            </IconButton>
            <IconButton
              className={classes.button}
              color="secondary"
              onClick={toggleEdit(false)}
            >
              <Close color={colors.rose} />
            </IconButton>
          </>
        )}
      </div>
    </form>
  ) : (
    <>
      <Text type="contentLabel2">{jobTitle}</Text>
      <IconButton onClick={toggleEdit(true)} className={classes.toggleEdit}>
        <img alt="" className={classes.editIcon} src={editIcon} />
      </IconButton>
    </>
  );
};

JobTitleInput.propTypes = {
  value: PropTypes.string
};

JobTitleInput.defaultProps = {
  value: ""
};

export default JobTitleInput;
