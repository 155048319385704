import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Material
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputBase from "@material-ui/core/InputBase";

// Config
import { panelNames, useCasesPage } from "../../context/CasesPageProvider";
import colors from "../../config/colors";
import styles from "../../config/styles";

// Hooks
import useEmergencyContacts from "../../hooks/useEmergencyContacts";
import useTranslate from "../../hooks/useTranslate";

// Assets
import searchIcon from "../../assets/icons/search.svg";

// Components
import CasePanel from "../../components/CasePanel";
import LoadingSection from "../../components/LoadingSection";
import EmergencyBanner from "../../components/EmergencyBanner/index";

const useStyles = makeStyles(theme => ({
  root: {
    ...styles.mixins.mainSection,
    display: "flex",
    flexDirection: "column",
    transition: "padding-top .3s ease-in-out",
    paddingTop: bannerStatus => styles.getSectionTop(bannerStatus)
  },
  section: styles.mixins.sectionContainer,
  search: {
    padding: theme.spacing(4, 0)
  },
  searchInput: {
    backgroundColor: colors.white,
    padding: theme.spacing(1, 3),
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    border: styles.values.border,
    fontSize: 15
  },
  icon: {
    marginRight: theme.spacing(2)
  },
  progress: {
    padding: theme.spacing(4, 0),
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));

const filterContent = (data, search = "") => {
  if (search === "") return data;
  const filteredData = [];
  const lowerSearch = search.toLowerCase();
  if (data) {
    filteredData.push(
      ...data.filter(
        ({ patientName, userCapacity, placeOfExamination, number }) => {
          return (
            patientName.toLowerCase().includes(lowerSearch) ||
            userCapacity.toLowerCase().includes(lowerSearch) ||
            placeOfExamination.name.toLowerCase().includes(lowerSearch) ||
            number.toLowerCase().includes(lowerSearch)
          );
        }
      )
    );
  }
  return filteredData;
};

let timer = 0;
const delay = (fn, ms) => {
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(fn.bind(this, ...args), ms || 0);
  };
};

const CasesPage = () => {
  const [search, setSearch] = useState("");
  const [scroll, setScroll] = useCasesPage();
  const { data, isLoading } = useSelector(state => state.cases);
  const { isEmergencyBannerOpen } = useEmergencyContacts();
  const classes = useStyles({ isEmergencyBannerOpen });
  const invitations = useSelector(state => state.invitations);
  const getText = useTranslate();

  const content = {
    drafts: [],
    submitted: [],
    collaborations: []
  };

  const filteredInvitations = invitations.data
    ? filterContent(invitations.data, search)
    : [];

  if (data) {
    const filteredData = filterContent(data, search);
    filteredData.forEach(item => {
      if (item.invitation) {
        content.collaborations.push(item);
      } else if (item.isDraft) {
        content.drafts.push(item);
      } else {
        content.submitted.push(item);
      }
    });
  }

  useEffect(() => {
    let position = scroll;
    let listener = null;

    window.scrollTo(0, position);

    if (typeof window !== "undefined") {
      listener = window.addEventListener("scroll", () => {
        position = window.pageYOffset;
      });
    }

    return () => {
      if (listener) window.removeEventListener("scroll", listener);
      setScroll(position);
    };
  }, []);

  return isLoading || invitations.isLoading ? (
    <main className={classes.root}>
      <LoadingSection />
    </main>
  ) : (
    <main className={classes.root}>
      <EmergencyBanner />
      <section className={classes.section}>
        <div className={classes.search}>
          <InputBase
            startAdornment={
              <InputAdornment position="start">
                <img
                  src={searchIcon}
                  className={classes.icon}
                  height={17}
                  alt="search"
                />
              </InputAdornment>
            }
            onChange={e => delay(setSearch, 500)(e.target.value)}
            className={classes.searchInput}
            placeholder={getText("casesPage.searchPlaceholder")}
          />
        </div>
      </section>
      <section className={classes.section}>
        {data && (
          <>
            {(content.collaborations.length > 0 ||
              (invitations.data && invitations.data.length > 0)) && (
              <CasePanel
                name={panelNames.COLLAB}
                data={content.collaborations}
                pending={filteredInvitations}
                title={getText("casesPage.Collaborations")}
                isCollab
              />
            )}
            {content.drafts.length > 0 && (
              <CasePanel
                name={panelNames.DRAFT}
                data={content.drafts}
                title={getText("common.status.draft")}
              />
            )}
            {content.submitted.length > 0 && (
              <CasePanel
                name={panelNames.SUBMITTED}
                data={content.submitted}
                title={getText("common.status.submitted")}
              />
            )}
          </>
        )}
      </section>
    </main>
  );
};

export default CasesPage;
