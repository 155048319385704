import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Config
import { externalRoutes } from "../../config/routes";
import styles from "../../config/styles";

// Hooks
import useTranslate from "../../hooks/useTranslate";

// Components
import Link from "../Link";
import Text from "../Text";

const useStyles = makeStyles({
  link: styles.mixins.link
});

export default () => {
  const classes = useStyles();
  const getText = useTranslate();

  return (
    <Text
      type="subtitle1Small"
      align="center"
      text="createInjuryPage.havingProblems"
      values={{
        isComponent: true,
        1: (
          <Link
            className={classes.link}
            target="_blank"
            to={externalRoutes.contact}
            key={0}
          >
            {getText("createInjuryPage.contact")}
          </Link>
        )
      }}
    />
  );
};
