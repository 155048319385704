import React, { useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Prompt } from "react-router-dom";
import { format } from "date-fns";
import isEmpty from "lodash-es/isEmpty";

// Material
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import FormHelperText from "@material-ui/core/FormHelperText";

// Icons
import edit from "../../assets/icons/edit.svg";

// Config
import { snackbarTypes } from "../../config/snackbar";
import { userTypes } from "../../config/values";
import styles from "../../config/styles";

// Hooks
import useQuery from "../../hooks/useQuery";
import useTranslate from "../../hooks/useTranslate";

// Actions
import { getCaseDetail } from "../../state/caseDetail/actions";
import { toggleSnack } from "../../state/snackbar/actions";
import { updateCaseIncident } from "../../state/incident/actions";

// Utils
import {
  fromMinutesToDuration,
  getDurationDisplay
} from "../../utils/incidents";

// Components
import ContentCardHeader from "../../components/ContentCardHeader";
import LoadingSection from "../../components/LoadingSection";
import SectionWrapper from "../../components/SectionWrapper";
import Text from "../../components/Text";

// Inner Components
import {
  CaseIncidentsEdit,
  IncidentInfo,
  IncidentInfoList
} from "./components";

const useStyles = makeStyles(theme => ({
  root: {
    border: styles.values.border
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2, 3)
  },
  formRow: {
    display: "flex",
    flexDirection: "row"
  },
  incidentInfoWrapper: {
    flex: "1 1"
  }
}));

const CaseIncidents = () => {
  const [dateTimeError, setDateTimeError] = useState("");
  const [isEditing, setEditing] = useState(false);
  const { caseId } = useParams();
  const { data, isLoading } = useSelector(s => s.caseDetail);
  const { userDetails } = useSelector(s => s.user);
  const classes = useStyles();
  const dispatch = useDispatch();
  const getText = useTranslate();
  const query = useQuery();

  const maxDateError =
    data &&
    getText("common.validationSchema.examinationDateMax", {
      date: format(new Date(data.dateTimeOfExamination), "dd/MM/yy HH:mm")
    });

  const { isDraft = true } = data || {};
  const incident = data && data.incident ? data.incident : {};
  const isCreator = userDetails && data && userDetails.id === data.creatorId;

  // User type permissions
  const canMakeChanges =
    (data &&
      userTypes.Relative !== data.userCapacity &&
      userTypes.Witness !== data.userCapacity) ||
    isDraft;

  const editing = query.get("editing");
  const editMode =
    !!editing || isEditing || (!(data && data.incident) && canMakeChanges);

  const onSubmit = (values, callback) => {
    let error = false;
    if (!isEmpty(values)) {
      const onFinish = success => {
        callback(success);
        if (success) {
          dispatch(getCaseDetail({ caseId, isUpdate: true }));
          dispatch(toggleSnack({ type: snackbarTypes.INCIDENT_SAVED }));
        } else {
          dispatch(toggleSnack({ type: snackbarTypes.ERROR }));
        }
      };

      const maxDate = new Date(data.dateTimeOfExamination);
      if (values.dateTime > maxDate) {
        setDateTimeError(maxDateError);
        error = true;
      }
      if (error) return;
      dispatch(
        updateCaseIncident({
          author: {
            ...userDetails,
            profilePictureURL: userDetails.profilePhotoURL
          },
          caseId,
          incident: values,
          onFinish
        })
      );
    }
  };

  const durationDisplay = useMemo(() => {
    const {
      days = 0,
      hours = 0,
      minutes = 0
    } = fromMinutesToDuration(incident.duration);

    return getDurationDisplay({ days, hours, minutes });
  }, [incident.duration]);

  if (isLoading) {
    return (
      <SectionWrapper>
        <Paper elevation={0} className={classes.root}>
          <LoadingSection normalHeight />
        </Paper>
      </SectionWrapper>
    );
  }

  const getInfoListProps = name => ({
    canAddMore: canMakeChanges,
    canQuickAdd: canMakeChanges,
    contents: incident[name],
    multiline: true,
    name,
    onSubmit
  });

  return (
    <SectionWrapper>
      {editMode ? (
        <CaseIncidentsEdit
          editing={editing}
          onSuccess={() => setEditing(false)}
        />
      ) : (
        <Paper elevation={0} className={classes.root}>
          <ContentCardHeader>
            <Text text="pageTitles.caseIncidents" type="heading2" />
            <div>
              {canMakeChanges && (
                <IconButton onClick={() => setEditing(true)}>
                  <img src={edit} alt="" />
                </IconButton>
              )}
            </div>
          </ContentCardHeader>
          <Prompt
            when={isEditing}
            message={() => getText("caseIncidents.exitPrompt")}
          />
          <div className={classes.contentContainer}>
            <IncidentInfo
              contents={[incident.location ? incident.location.name : ""]}
              label={getText("caseIncidentsPage.locationLabel")}
              name="location"
              title={getText("caseIncidentsPage.locationLabel")}
              type="location"
            />
            <div className={classes.formRow}>
              <div className={classes.incidentInfoWrapper}>
                <IncidentInfo
                  contents={[
                    incident.dateTime
                      ? format(
                          new Date(incident.dateTime),
                          "dd/MM/yyyy 'at' HH:mm"
                        )
                      : ""
                  ]}
                  disabled={incident.dateTime && !isDraft}
                  label={getText(
                    "caseDetailPage.requiredIncidentDetails.dateTimeSubtitle"
                  )}
                  name="dateTime"
                  title={getText(
                    "caseDetailPage.requiredIncidentDetails.dateTimeSubtitle"
                  )}
                  type="dateTime"
                  error={
                    dateTimeError && (
                      <FormHelperText className={classes.dateTimeError} error>
                        {dateTimeError}
                      </FormHelperText>
                    )
                  }
                />
              </div>
              <IncidentInfo
                contents={[durationDisplay]}
                disabled={incident.dateTime && !isDraft}
                label={getText("caseIncidentsPage.durationTitle")}
                name="dateTime"
                title={getText("caseIncidentsPage.durationTitle")}
                type="dateTime"
              />
            </div>
            <IncidentInfoList
              {...getInfoListProps("descriptions")}
              addButtonText={getText("caseIncidentsPage.descriptionAddButton")}
              label={getText("caseIncidentsPage.descriptionLabel")}
              title={getText("caseIncidentsPage.descriptionLabel")}
              placeholder={getText("caseIncidentsPage.descriptionPlaceholder")}
              canAddMore={
                canMakeChanges &&
                ((incident.descriptions && incident.descriptions.length > 0) ||
                  isCreator)
              }
            />
            <IncidentInfoList
              {...getInfoListProps("witnesses")}
              addButtonText={getText("caseIncidentsPage.witnessesAddButton")}
              label={getText("caseIncidentsPage.witnessesLabel")}
              title={getText("caseIncidentsPage.witnessesLabel")}
              placeholder={getText("caseIncidentsPage.witnessesPlaceholder")}
              columns={2}
              subTitle={getText("common.userTypes.Witness")}
            />
            <IncidentInfoList
              {...getInfoListProps("perpetrators")}
              addButtonText={getText("caseIncidentsPage.perpetratorsAddButton")}
              label={getText("caseIncidentsPage.perpetratorsLabel")}
              title={getText("caseIncidentsPage.perpetratorsLabel")}
              placeholder={getText("caseIncidentsPage.perpetratorsPlaceholder")}
              columns={2}
              subTitle={getText("caseIncidentsPage.perpetratorLabel")}
            />
          </div>
        </Paper>
      )}
    </SectionWrapper>
  );
};

export default CaseIncidents;
