import React from "react";

// Material
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";

// Config
import colors from "../../config/colors";
import styles from "../../config/styles";

// Assets
import close from "../../assets/icons/close-24px.svg";

// Hooks
import useEmergencyContacts from "../../hooks/useEmergencyContacts";
import useTranslate from "../../hooks/useTranslate";

// Actions
import { emergencyBannerClose } from "../../state/emergencyContact/actions";

// Components
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    left: 0,
    right: 0,
    top: styles.mixins.mainSection.paddingTop,
    zIndex: 10
  },
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    height: 120,
    color: "white",
    background: colors.emergencyHeader,
    [theme.breakpoints.down("xs")]: {
      height: 55,
      justifyContent: "flex-start",
      padding: theme.spacing(0, 2)
    }
  },
  callText: {
    color: "white",
    textDecoration: "underline"
  },
  closeButton: {
    position: "absolute",
    top: 20,
    right: 20,
    fontSize: 18
  },
  closeIcon: {
    filter: "brightness(0) invert(1)"
  }
}));

const EmergencyBanner = () => {
  const classes = useStyles();
  const { emergencyContact, loading, isEmergencyBannerOpen, dispatch } =
    useEmergencyContacts();
  const getText = useTranslate();

  if (!emergencyContact || loading) return null;

  return (
    <div className={classes.root}>
      <Collapse in={isEmergencyBannerOpen}>
        <div className={classes.content}>
          <IconButton
            className={classes.closeButton}
            onClick={() => dispatch(emergencyBannerClose())}
          >
            <img className={classes.closeIcon} src={close} alt="" />
          </IconButton>
          <Text type="emergencyBanner">
            {`${getText("header.emergencyBanner.title")} `}
            <a
              className={classes.callText}
              href={`tel: ${emergencyContact.phoneNumber}`}
            >
              {getText("header.emergencyBanner.link", {
                1: emergencyContact.phoneNumber
              })}
            </a>
          </Text>
        </div>
      </Collapse>
    </div>
  );
};

export default EmergencyBanner;
