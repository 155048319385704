import React from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Config
import colors from "../../config/colors";

// State
import { useAppInsights } from "../../utils/telemetry/AppInsights";

// Icons
import videoIcon from "../../assets/icons/video_ico.svg";

// Components
import AddMedia from "../AddMedia";
import AddMedicalRecords from "../AddMedicalRecords";
import MediaItem from "../MediaItem";
import MediaReuploadProvider from "./MediaReuploadProvider";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  placeholderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(10, 0),
    gap: theme.spacing(2),
    margin: "0 auto"
  },
  placeholderText: {
    textAlign: "center",
    color: colors.riverBed,
    opacity: "0.4",
    marginBottom: theme.spacing(1),
    lineHeight: 1.5
  }
}));

const buttonComponent = {
  medicalRecordsPage: AddMedicalRecords,
  mediaPage: AddMedia
};

const MediaItemList = ({
  data,
  caseId,
  mediaItemProps,
  page,
  onDelete,
  onSelect,
  onFetchImage
}) => {
  const { trackException } = useAppInsights();
  const classes = useStyles();

  const Button = buttonComponent[page];

  if (data.length === 0) {
    return (
      <div className={classes.placeholderContainer}>
        <img width={80} src={videoIcon} alt="" />
        <Text
          className={classes.placeholderText}
          text={`${page}.emptyPlaceholder`}
          type="subtitle1Big"
        />
        <Button caseId={caseId} text={`${page}.addMediaContentButton`} />
      </div>
    );
  }

  return (
    <MediaReuploadProvider page={page} caseId={caseId}>
      {data.map(item => (
        <MediaItem
          data={item}
          handleFetchImage={onFetchImage(item.id)}
          key={item.id}
          onClick={onSelect(item.id)}
          onDelete={onDelete({
            caseId,
            itemId: item.id,
            onFail: () =>
              trackException(
                `Failed to delete ${page} item: ${item.id} at case: ${caseId}`
              )
          })}
          {...mediaItemProps}
        />
      ))}
    </MediaReuploadProvider>
  );
};

MediaItemList.propTypes = {
  caseId: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  mediaItemProps: PropTypes.shape({}).isRequired,
  onDelete: PropTypes.func.isRequired,
  onFetchImage: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired
};

export default React.memo(MediaItemList);
