import React from "react";
import { externalRoutes, landingPageUrl } from "../../config/routes";
import LoadingSection from "../../components/LoadingSection";

export default () => {
  React.useEffect(() => {
    const agent = navigator.userAgent.toLowerCase();
    if (agent.includes("android")) {
      window.location = externalRoutes.googlePlay;
    } else if (agent.includes("iphone") || agent.includes("ipad")) {
      window.location = externalRoutes.appStore;
    } else {
      window.location = landingPageUrl;
    }
  }, []);

  return (
    <div>
      <LoadingSection />
    </div>
  );
};
