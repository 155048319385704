import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Hooks
import { useCaseDownload } from "../../../context/CaseDownloadProvider";
import { useSubmitButton } from "../../../components/SubmitCaseButton/SubmitCaseButton";

// Components
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import FileUpload from "../../../components/FileUpload";
import SendEvidenceForm from "./SendEvidenceForm";
import Text from "../../../components/Text";

const useStyles = makeStyles(theme => ({
  text: {
    lineHeight: 1.5,
    marginBottom: theme.spacing(2),
    "&:last-of-type": {
      marginBottom: theme.spacing(3.5)
    }
  }
}));

const contentType = ["ReportOnly", "ReportAndMedia"];

const CriminalFormatTab = ({ canSubmit, canRequest }) => {
  const [open, setOpen] = useState(false);
  const [sent, setSent] = useState(false);
  const classes = useStyles();

  const { requestCaseDownload, holdSubmitValues } = useCaseDownload();
  const { caseId } = useParams();
  const { dialog, onConfirm, fileUploadProps } = useSubmitButton(caseId);
  const {
    data: { isDraft }
  } = useSelector(s => s.caseDetail);

  const handleCloseDialog = () => setOpen(false);

  const handleSubmit = (
    { email, phoneNumber, country, checked, message },
    { setSubmitting }
  ) => {
    const body = {
      recipient: {
        kind: "Person",
        email,
        phoneNumber: `+${country.phone}${phoneNumber}`
      },
      caseId,
      format: "CriminalCase",
      content: contentType[checked],
      message
    };
    if (isDraft) {
      // User is submitting the case
      // Save data in context because form unmounts in some flows
      holdSubmitValues(body);
      setOpen(true);
      setSubmitting(false);
    } else if (checked !== null) {
      requestCaseDownload(body, () => {
        setSent(true);
        setSubmitting(false);
      });
    }
  };

  const handleResetStatus = () => setSent(false);

  return (
    <>
      <Text
        className={classes.text}
        text="downloadCasePage.description"
        type="contentLabel3"
      />
      <Text
        className={classes.text}
        text="downloadCasePage.description2"
        type="contentLabel3"
      />
      <SendEvidenceForm
        onSubmit={handleSubmit}
        showSubmitCaseButton={isDraft}
        disableSubmit={!canSubmit}
        disabled={!canRequest}
        sent={sent}
        onResetStatus={handleResetStatus}
      />
      <ConfirmationDialog
        onClose={handleCloseDialog}
        onConfirm={onConfirm}
        onCancel={handleCloseDialog}
        open={open}
        {...dialog}
      />
      <FileUpload {...fileUploadProps} />
    </>
  );
};

CriminalFormatTab.propTypes = {
  canRequest: PropTypes.bool.isRequired,
  canSubmit: PropTypes.bool.isRequired
};

export default CriminalFormatTab;
