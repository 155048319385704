export const CLEAR_CASE_DETAIL = "case/CLEAR_CASE_DETAIL";

export const REQUEST_CASE_DETAIL = "case/REQUEST_CASE_DETAIL";
export const REQUEST_CASE_DETAIL_SUCCESS = "case/REQUEST_CASE_DETAIL_SUCCESS";
export const REQUEST_CASE_DETAIL_FAIL = "case/REQUEST_CASE_DETAIL_FAIL";

export const REQUEST_DELETE_CASE = "case/REQUEST_DELETE_CASE";
export const REQUEST_DELETE_CASE_SUCCESS = "case/REQUEST_DELETE_CASE_SUCCESS";
export const REQUEST_DELETE_CASE_FAIL = "case/REQUEST_DELETE_CASE_FAIL";

export const REQUEST_CASE_CONTRIB = "case/REQUEST_CASE_CONTRIB";
export const REQUEST_CASE_CONTRIB_SUCCESS = "case/REQUEST_CASE_CONTRIB_SUCCESS";
export const REQUEST_CASE_CONTRIB_FAIL = "case/REQUEST_CASE_CONTRIB_FAIL";

export const REQUEST_DEL_CONTRIB = "case/REQUEST_DEL_CONTRIB";
export const REQUEST_DEL_CONTRIB_SUCCESS = "case/REQUEST_DEL_CONTRIB_SUCCESS";
export const REQUEST_DEL_CONTRIB_FAIL = "case/REQUEST_DEL_CONTRIB_FAIL";

export const REQUEST_SUBMIT_CASE = "case/REQUEST_SUBMIT_CASE";
export const REQUEST_SUBMIT_CASE_SUCCESS = "case/REQUEST_SUBMIT_CASE_SUCCESS";
export const REQUEST_SUBMIT_CASE_FAIL = "case/REQUEST_SUBMIT_CASE_FAIL";

export const REQUEST_REPORT = "case/REQUEST_REPORT";
export const REQUEST_REPORT_SUCCESS = "case/REQUEST_REPORT_SUCCESS";
export const REQUEST_REPORT_FAIL = "case/REQUEST_REPORT_FAIL";
export const REQUEST_REPORT_CLEAR = "case/REQUEST_REPORT_CLEAR";

export const RESET_ERRORS = "case/RESET_ERRORS";
export const CLEAR_MEDIA_UPLOAD_PROGRESS = "cases/CLEAR_MEDIA_UPLOAD_PROGRESS";
