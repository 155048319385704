import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

// Actions
import { parseFetchOptions } from "../../state/user/actions";

// Config
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import { checkPinUrl } from "../../config/apiConfig";
import styles from "../../config/styles";

// Images
import mainLogo from "../../assets/logos/main-logo.png";

// Components
import GetTheApp from "../GetTheApp";
import LoadingSection from "../LoadingSection";
import MainSection from "../MainSection";

// Pages
import { PinLockScreen, PinCreateScreen } from "../../containers";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    boxShadow: styles.values.boxShadow,
    zIndex: 99
  },
  colorInherit: {
    color: theme.palette.common.black
  },
  toolbar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 70,
    padding: theme.spacing(0, 3),
    boxShadow: styles.values.boxShadow
  },
  branding: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: theme.spacing(3)
  },
  kulpaLogo: {
    height: 28
  }
}));

const Header = () => {
  const { toolbar, branding, kulpaLogo, ...classes } = useStyles();
  return (
    <AppBar classes={classes} position="fixed" color="inherit">
      <Toolbar className={toolbar}>
        <div className={branding}>
          <img className={kulpaLogo} src={mainLogo} alt="Kulpa" />
        </div>
        <GetTheApp />
      </Toolbar>
    </AppBar>
  );
};

const PinValidatorHandler = ({ children, userExists }) => {
  const [data, setData] = useState(null);
  const [authorized, setAuthorized] = useState(false);
  const { trackException } = useAppInsights();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userExists) {
      dispatch(
        parseFetchOptions(options =>
          fetch(checkPinUrl, options)
            .then(res => res.json())
            .then(d => setData(d))
            .catch(() => trackException("Failed to check pin existence"))
        )
      );
    }
  }, [userExists]);

  if (!userExists) return children;

  if (!data) return <LoadingSection />;
  if (!authorized) {
    const handleAuthorize = () => setAuthorized(true);
    const Screen = data.pinExists ? PinLockScreen : PinCreateScreen;
    return (
      <MainSection noStatus>
        <Header />
        <Screen onAuthorize={handleAuthorize} />
      </MainSection>
    );
  }

  return children;
};

PinValidatorHandler.propTypes = {
  children: PropTypes.node.isRequired,
  userExists: PropTypes.bool.isRequired
};

export default PinValidatorHandler;
