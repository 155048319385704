import React from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import { useDispatch } from "react-redux";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

// Actions
import {
  acceptInvitation,
  rejectInvitation
} from "../../state/invitations/actions";

// Config
import colors from "../../config/colors";
import styles from "../../config/styles";
import useTranslate from "../../hooks/useTranslate";

// Components
import Button from "../Button";
import ConfirmationButton from "../ConfirmationButton";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    border: styles.values.border,
    backgroundColor: colors.ebonyClay,
    padding: 0
  },
  content: {
    padding: 0
  },
  col: {
    display: "flex",
    flexDirection: "column"
  },
  headingWrapper: {
    padding: theme.spacing(4, 4, 3),
    borderBottom: styles.values.border,
    display: "flex",
    flexDirection: "column"
  },
  date: {
    color: colors.white,
    opacity: 0.56,
    marginBottom: theme.spacing(2)
  },
  caseInfo: {
    padding: theme.spacing(4, 4, 0)
  },
  inviterWrapper: {
    display: "flex",
    flexDirection: "row",
    borderBottom: styles.values.border,
    paddingBottom: theme.spacing(4)
  },
  avatar: {
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    marginRight: theme.spacing(1.25)
  },
  inviterData: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 190
  },
  invitorName: {
    color: colors.white,
    marginBottom: theme.spacing(2)
  },
  label: {
    color: colors.white,
    marginBottom: theme.spacing(1.5)
  },
  caseId: {
    color: colors.white
  },
  footer: {
    padding: theme.spacing(3, 5, 0)
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    paddingBottom: 3
  },
  button: {
    borderColor: colors.white,
    color: colors.white,
    width: 80,
    height: 35,
    padding: 0,
    marginRight: theme.spacing(1.5)
  }
}));

const InvitationCard = ({ data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const getText = useTranslate();

  const creationDate = new Date(data.creationDateTime);
  const date = format(creationDate, getText("common.dateFormat.datetime"));

  const handleAccept = () => {
    dispatch(acceptInvitation(data.id));
  };

  const handleReject = () => {
    dispatch(rejectInvitation(data.id));
  };

  return (
    <Card elevation={0} className={classes.root}>
      <CardContent className={classes.content}>
        <div className={classes.headingWrapper}>
          <Text type="subtitle1" className={classes.date}>
            {date}
          </Text>
          <Text type="heading1" className={classes.caseId}>
            {getText("common.units.files.one")} {data.number}
          </Text>
        </div>

        <div className={classes.caseInfo}>
          <div className={classes.inviterWrapper}>
            <Avatar
              className={classes.avatar}
              src={data.invitation.inviter.profilePictureURL}
            >
              {data.invitation.inviter.fullName.charAt(0)}
            </Avatar>
            <div className={classes.inviterData}>
              <Text type="heading3" className={classes.invitorName}>
                {data.invitation.inviter.fullName}
              </Text>
              <Text
                className={classes.label}
                text="casesPage.hasInvited"
                type="status"
              />
              <Text type="status" className={classes.label}>
                {getText("common.userTypes.Victim")}: {data.patientName}
              </Text>
            </div>
          </div>
        </div>
      </CardContent>
      <CardContent className={classes.footer}>
        <CardActions className={classes.buttonWrapper}>
          <ConfirmationButton
            className={classes.button}
            disableElevation
            variant="outlined"
            onClick={handleReject}
            dialog={{
              type: "reject",
              texts: [
                { key: 1, text: getText("common.prompts.pleaseConfirm") }
              ],
              title: getText("casesPage.rejectRequest")
            }}
          >
            {getText("common.prompts.reject")}
          </ConfirmationButton>
          <Button
            className={classes.button}
            disableElevation
            variant="contained"
            color="primary"
            onClick={handleAccept}
          >
            {getText("common.prompts.accept")}
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
};

InvitationCard.propTypes = {
  data: PropTypes.shape({
    creationDateTime: PropTypes.string,
    id: PropTypes.string,
    isDraft: PropTypes.bool,
    number: PropTypes.string,
    patientName: PropTypes.string,
    invitation: PropTypes.shape({
      inviter: PropTypes.shape({
        profilePictureURL: PropTypes.string,
        fullName: PropTypes.string
      })
    })
  }).isRequired
};

export default InvitationCard;
