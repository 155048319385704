import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import format from "date-fns/format";

// Material
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";

// Config
import colors from "../../config/colors";
import styles from "../../config/styles";

// Hooks
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import useTranslate from "../../hooks/useTranslate";

// Actions
import {
  getInjury,
  resetImgUploads,
  updateImgProgress,
  uploadInjuryImage
} from "../../state/injuries/actions";

// Components
import Button from "../Button";
import FileUpload from "../FileUpload";

const useStyles = makeStyles(theme => ({
  buttonAdd: {
    color: colors.dodgerBlue
  },
  thumbnailButton: {
    height: 100,
    width: 122,
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: styles.values.borderDashed,
    borderRadius: theme.shape.borderRadius,
    borderWidth: 2,
    fontSize: 32,
    color: colors.mystic
  }
}));

const renameFile = (originalFile, newName) => {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified
  });
};

export const AddInjuryImage = ({ thumbnail, images }) => {
  const [open, setOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { trackException } = useAppInsights();
  const { caseId, injuryId } = useParams();
  const { uploadProgress, uploadErrors } = useSelector(s => s.injuries);
  const classes = useStyles();
  const dispatch = useDispatch();
  const getText = useTranslate();

  const handleOpen = () => {
    setOpen(true);
    dispatch(resetImgUploads());
  };

  const handleClose = () => {
    setOpen(false);
    if (submitted)
      dispatch(
        getInjury({
          caseId,
          injuryId,
          onFail: () =>
            trackException(
              `Failed to get injury data from: ${injuryId} at case: ${caseId}`
            )
        })
      );
  };

  const handleProgress = index => progress => {
    dispatch(updateImgProgress({ index, progress }));
  };

  const handleSubmit = files => {
    setSubmitted(true);
    const body = {
      caseId,
      injuryId,
      files,
      onFail: msg => {
        trackException(`${msg} for injury: ${injuryId} at case: ${caseId} `);
      },
      onUploadProgress: handleProgress
    };

    if (
      images &&
      images.length > 0 &&
      images.map(img => img.filename).includes(files[0].file.name)
    ) {
      const newName = `${format(Date.now(), "ddMMyyyyHHmmss")}_${
        files[0].file.name
      }`;
      body.files[0].file = renameFile(body.files[0].file, newName);
    }

    dispatch(uploadInjuryImage(body));
  };

  return (
    <>
      {thumbnail ? (
        <ButtonBase className={classes.thumbnailButton} onClick={handleOpen}>
          +
        </ButtonBase>
      ) : (
        <Button
          className={classes.buttonAdd}
          color="primary"
          disableElevation
          onClick={handleOpen}
          regular
        >
          {getText("editInjuryPage.addImagesButton").toUpperCase()}
        </Button>
      )}
      <FileUpload
        accept="image/*"
        multiple
        onClose={handleClose}
        onSubmit={handleSubmit}
        open={open}
        uploadErrors={uploadErrors}
        uploadProgress={uploadProgress}
      />
    </>
  );
};

AddInjuryImage.propTypes = {
  thumbnail: PropTypes.bool,
  images: PropTypes.arrayOf(PropTypes.shape({}))
};

AddInjuryImage.defaultProps = {
  thumbnail: false,
  images: []
};

export default AddInjuryImage;
