import React, { createContext, useContext, useRef } from "react";
import PropTypes from "prop-types";

const CasesPageContext = createContext();

export const useCasesPage = name => {
  const {
    [name]: expanded,
    togglePanel,
    scroll,
    setScroll
  } = useContext(CasesPageContext);
  if (name) return [expanded, togglePanel];

  return [scroll.current, setScroll];
};

export const panelNames = {
  DRAFT: "draft",
  SUBMITTED: "submitted",
  COLLAB: "collaborations"
};

const initialState = {
  [panelNames.DRAFT]: true,
  [panelNames.SUBMITTED]: true,
  [panelNames.COLLAB]: true
};

const CasesPageProvider = ({ children }) => {
  const expanded = useRef(initialState);
  const scroll = useRef(null);

  const togglePanel = panelName => {
    expanded.current = {
      ...expanded.current,
      [panelName]: !expanded.current[panelName]
    };
    return expanded.current[panelName];
  };

  const setScroll = scrollTop => {
    scroll.current = scrollTop;
  };

  return (
    <CasesPageContext.Provider
      value={{
        ...expanded.current,
        togglePanel,
        scroll,
        setScroll
      }}
    >
      {children}
    </CasesPageContext.Provider>
  );
};

CasesPageProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default CasesPageProvider;
