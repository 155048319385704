import React, { useState } from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

// Formik
import * as Yup from "yup";

// Config
import countries from "../../config/countries";
import styles from "../../config/styles";
import useTranslate from "../../hooks/useTranslate";

// Components
import ContentCardHeader from "../ContentCardHeader";
import FormikHandler from "../FormikHandler";
import Text from "../Text";
import UserTypeDialog from "./UserTypeDialog";
import VictimFormContent from "./VictimFormContent";
import VictimFormHandler from "./VictimFormHandler";

const today = new Date();

const useStyles = makeStyles(theme => ({
  root: {
    border: styles.values.border
  },
  title: {
    fontSize: 24
  },
  formContent: {
    padding: theme.spacing(3.5, 5),
    display: "flex",
    flexDirection: "column",
    borderBottom: styles.values.border
  },
  label: {
    marginBottom: theme.spacing(1.5)
  },
  phoneInputWrapper: {
    display: "flex",
    flexDirection: "row"
  },
  buttonContainer: {
    padding: theme.spacing(4, 5)
  },
  submit: {
    width: "100%"
  }
}));

const validationSchema = texts =>
  Yup.object().shape({
    givenName: Yup.string().required(texts.required),
    // place: Yup.string().required(texts.required),
    familyName: Yup.string().required(texts.required),
    dateOfBirth: Yup.date().required(texts.required),
    // dateTimeOfExamination: Yup.date().required(texts.required),
    number: Yup.string()
      .matches(/^[0-9]*$/, { message: texts.phoneNumber.mustBe })
      .min(4, texts.phoneNumber.tooShort)
      .required(texts.required)
  });

const initialValues = {
  givenName: "",
  familyName: "",
  dateOfBirth: today,
  dateTimeOfExamination: today,
  // place: "",
  country: countries[233],
  number: "",
  crimeReferenceNumber: ""
};

const CreateVictimForm = ({ onSubmit }) => {
  const classes = useStyles();
  const getText = useTranslate();
  const schemaTexts = getText("common.validationSchema");

  const [isReported, setReported] = useState(false);
  const [userCapacity, setUserCapacity] = useState("none");
  const [coord, setCoord] = useState(null);
  const [isSelecting, setSelecting] = useState(true);

  const handleSubmit = (
    { crimeReferenceNumber, ...values },
    {
      setSubmitting
      // setFieldError
    }
  ) => {
    const currentValues = {
      ...values,
      userCapacity,
      coordinate: coord && {
        latitude: coord.lat(),
        longitude: coord.lng()
      }
    };

    if (isReported) currentValues.crimeReferenceNumber = crimeReferenceNumber;

    const onGetPositionSuccess = location => {
      // Location found: user has granted location access and has geolocation enabled
      currentValues.coordinate = {
        latitude: location.coords.latitude,
        longitude: location.coords.longitude
      };
      currentValues.place = `${location.coords.latitude}, ${location.coords.longitude}`;

      onSubmit(currentValues, setSubmitting);
    };

    const onGetPositionError = () => {
      currentValues.coordinate = {
        latitude: 0,
        longitude: 0
      };
      currentValues.place = "none";
      onSubmit(currentValues, setSubmitting);
    };

    navigator.geolocation.getCurrentPosition(
      onGetPositionSuccess,
      onGetPositionError
    );

    // if (currentValues.coordinate) {
    //   onSubmit(currentValues, setSubmitting);
    // } else {
    //   setFieldError("place", schemaTexts.invalidSelect);
    //   setSubmitting(false);
    // }
  };

  const handleSwitch = () => setReported(!isReported);

  return (
    <>
      <Paper elevation={0} className={classes.root}>
        <ContentCardHeader>
          <Text
            className={classes.title}
            text="caseDetailPage.victimInfoCard.title"
            type="heading2"
          />
        </ContentCardHeader>
        <FormikHandler
          formik={{
            initialValues,
            onSubmit: handleSubmit,
            validationSchema: validationSchema(schemaTexts)
          }}
          render={({ setSubmitting, setFieldValue, ...props }) => (
            <VictimFormHandler
              isSelecting={isSelecting}
              setCoord={setCoord}
              setFieldValue={setFieldValue}
              setSelecting={setSelecting}
              setUserCapacity={setUserCapacity}
              userCapacity={userCapacity}
            >
              <VictimFormContent
                {...props}
                handleSwitch={handleSwitch}
                isReported={isReported}
                setCoord={setCoord}
                setFieldValue={setFieldValue}
                coord={
                  coord && {
                    latitude: coord.lat(),
                    longitude: coord.lng()
                  }
                }
              />
            </VictimFormHandler>
          )}
        />
        <UserTypeDialog
          onSelect={setUserCapacity}
          open={isSelecting}
          setOpen={setSelecting}
          value={userCapacity}
        />
      </Paper>
    </>
  );
};

CreateVictimForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default CreateVictimForm;
