import React from "react";

// Components
import SectionWrapper from "../../components/SectionWrapper";
import CaseMediaPanel from "../../components/CaseMediaPanel";

const CaseMediaPage = () => (
  <SectionWrapper>
    <CaseMediaPanel />
  </SectionWrapper>
);

export default CaseMediaPage;
