import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

// Assets
import lockIcon from "../../assets/icons/lockIcon.svg";

// Config
import { snackbarTypes } from "../../config/snackbar";
import { verifyPinUrl } from "../../config/apiConfig";
import colors from "../../config/colors";

// Hooks
import useTranslate from "../../hooks/useTranslate";

// Actions
import { parseFetchOptions } from "../../state/user/actions";
import { toggleSnack } from "../../state/snackbar/actions";

// Hooks
import { useAppInsights } from "../../utils/telemetry/AppInsights";

// Components
import Button from "../Button";
import PincodeField, { initialPincode } from "../PincodeField";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(5, 10)
  },
  icon: {
    marginBottom: theme.spacing(2)
  },
  title: {
    padding: theme.spacing(1.5, 0, 1),
    color: colors.pictonBlue
  },
  pincodeField: {
    marginBottom: theme.spacing(2)
  },
  submitButton: {
    width: 216
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2)
  }
}));

const PincodeForm = ({ onSubmit, onError }) => {
  const [pincode, setPincode] = useState(initialPincode);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { trackException } = useAppInsights();
  const dispatch = useDispatch();
  const classes = useStyles();
  const getText = useTranslate();
  const submitButton = useRef(null);

  useEffect(() => {
    if (pincode[3] !== "") submitButton.current.focus();
  }, [pincode[3]]);

  const handleChange = value => {
    setError(false);
    setPincode(value);
  };

  const handleSubmit = () => {
    setLoading(true);
    dispatch(
      parseFetchOptions(async ({ headers }) => {
        const response = await fetch(verifyPinUrl, {
          method: "PUT",
          headers: {
            ...headers,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            pin: pincode.join("")
          })
        });

        if (response.ok) {
          const data = await response.json();
          if (data.pinMatches) onSubmit();
          else {
            setPincode(initialPincode);
            setError(true);
            setLoading(false);
          }
        } else {
          trackException("Failed to check pin match");
          setLoading(false);
          dispatch(toggleSnack({ type: snackbarTypes.ERROR }));
          onError();
        }
      })
    );
  };

  return (
    <form className={classes.contentContainer}>
      <img className={classes.icon} src={lockIcon} alt="" />
      <Text
        type="heading1"
        className={classes.title}
        text="enterPasscodePage.description"
      />
      <PincodeField
        className={classes.pincodeField}
        disabled={loading}
        error={error}
        onChange={handleChange}
        value={pincode}
      />
      <div className={classes.buttonWrapper}>
        <Button
          onClick={handleSubmit}
          className={classes.submitButton}
          variant="contained"
          color="primary"
          disableElevation
          disabled={loading || pincode[3] === ""}
          ref={submitButton}
          type="submit"
        >
          {getText("common.prompts.continue")}
        </Button>
        {loading && <CircularProgress size={32} />}
      </div>
    </form>
  );
};

PincodeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func
};

PincodeForm.defaultProps = {
  onError: () => {}
};

export default PincodeForm;
