import { combineReducers } from "redux";
import constant from "lodash-es/constant";

import createReducer from "../../utils/createReducer";

import {
  CLEAR_CASE_DETAIL,
  REQUEST_CASE_DETAIL,
  REQUEST_CASE_DETAIL_SUCCESS,
  REQUEST_CASE_DETAIL_FAIL,
  REQUEST_CASE_CONTRIB,
  REQUEST_CASE_CONTRIB_SUCCESS,
  REQUEST_CASE_CONTRIB_FAIL,
  REQUEST_DELETE_CASE,
  REQUEST_DELETE_CASE_SUCCESS,
  REQUEST_DELETE_CASE_FAIL,
  REQUEST_SUBMIT_CASE,
  REQUEST_SUBMIT_CASE_SUCCESS,
  REQUEST_SUBMIT_CASE_FAIL,
  RESET_ERRORS,
  REQUEST_DEL_CONTRIB,
  REQUEST_DEL_CONTRIB_SUCCESS,
  REQUEST_DEL_CONTRIB_FAIL
} from "./actionTypes";
import {
  REQUEST_CASE_INCIDENT_SUCCESS,
  REQUEST_DEL_ELEMENT_SUCCESS
} from "../incident/actionTypes";

// Cases data
const data = createReducer(null, {
  [REQUEST_CASE_DETAIL_SUCCESS]: (state, { payload }) => payload,
  [CLEAR_CASE_DETAIL]: constant(null),
  [REQUEST_CASE_DETAIL]: constant(null),
  [REQUEST_DELETE_CASE_SUCCESS]: constant(null),
  [REQUEST_CASE_INCIDENT_SUCCESS]: (state, { payload }) => {
    const stateIncident = state.incident || {};
    const descriptions = [
      ...(stateIncident.descriptions || []),
      ...payload.descriptions
    ];
    const perpetrators = [
      ...(stateIncident.perpetrators || []),
      ...payload.perpetrators
    ];
    const witnesses = [
      ...(stateIncident.witnesses || []),
      ...payload.witnesses
    ];

    return {
      ...state,
      incident: {
        ...stateIncident,
        ...payload,
        descriptions,
        perpetrators,
        witnesses
      }
    };
  },
  [REQUEST_DEL_ELEMENT_SUCCESS]: (state, { payload }) => ({
    ...state,
    incident: {
      ...state.incident,
      [payload.type]: state.incident[payload.type].filter(
        item => item.id !== payload.itemId
      )
    }
  })
});

const error = createReducer(null, {
  [REQUEST_CASE_DETAIL]: constant(null),
  [REQUEST_CASE_DETAIL_FAIL]: (state, { payload }) => payload,
  [RESET_ERRORS]: constant(null)
});

const isLoading = createReducer(false, {
  [REQUEST_CASE_DETAIL]: constant(true),
  [REQUEST_CASE_DETAIL_SUCCESS]: constant(false),
  [REQUEST_CASE_DETAIL_FAIL]: constant(false)
});

// Contributors
const contributors = createReducer([], {
  [REQUEST_CASE_CONTRIB_SUCCESS]: (state, { payload }) => payload,
  [REQUEST_DEL_CONTRIB_SUCCESS]: (state, { payload }) =>
    state.filter(({ id }) => id !== payload)
});

const contributorsError = createReducer(null, {
  [REQUEST_CASE_CONTRIB]: constant(null),
  [REQUEST_CASE_CONTRIB_FAIL]: (state, { payload }) => payload
});

const isLoadingContributors = createReducer(false, {
  [REQUEST_CASE_CONTRIB]: constant(true),
  [REQUEST_CASE_CONTRIB_SUCCESS]: constant(false),
  [REQUEST_CASE_CONTRIB_FAIL]: constant(false)
});

const isDeletingContributor = createReducer(false, {
  [REQUEST_DEL_CONTRIB]: constant(true),
  [REQUEST_DEL_CONTRIB_SUCCESS]: constant(false),
  [REQUEST_DEL_CONTRIB_FAIL]: constant(false)
});

// Submit Case
const isSubmitting = createReducer(null, {
  [REQUEST_SUBMIT_CASE]: (state, { payload }) => payload,
  [REQUEST_SUBMIT_CASE_SUCCESS]: constant(null),
  [REQUEST_SUBMIT_CASE_FAIL]: constant(null)
});

// Case Delete
const isDeleting = createReducer(null, {
  [REQUEST_DELETE_CASE]: (state, { payload }) => payload,
  [REQUEST_DELETE_CASE_SUCCESS]: constant(null),
  [REQUEST_DELETE_CASE_FAIL]: constant(null)
});

const deleteError = createReducer(null, {
  [REQUEST_DELETE_CASE]: constant(null),
  [REQUEST_DELETE_CASE_FAIL]: (state, { payload }) => payload
});

export default combineReducers({
  data,
  error,
  isLoading,
  contributors,
  isLoadingContributors,
  isDeletingContributor,
  contributorsError,
  isDeleting,
  deleteError,
  isSubmitting
});
