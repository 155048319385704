import React from "react";

const ContributorFormHandler = ({ children, values, setFieldValue }) => {
  const { number, email } = values;
  React.useEffect(() => {
    setFieldValue("isRequired", email === "" && number === "");
  }, [number, email, setFieldValue]);
  return children;
};

export default ContributorFormHandler;
