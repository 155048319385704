import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Icons
import lockIcon from "../../../assets/icons/lockIcon.svg";

// Config
import { externalRoutes } from "../../../config/routes";
import colors from "../../../config/colors";

// Hooks
import { useCaseDownload } from "../../../context/CaseDownloadProvider";
import useTranslate from "../../../hooks/useTranslate";

// Components
import Button from "../../../components/Button";
import Link from "../../../components/Link";
import SuccessLabel from "../../../components/SuccessLabel";
import Text from "../../../components/Text";

const useStyles = makeStyles(theme => ({
  text: {
    lineHeight: 1.5,
    marginBottom: theme.spacing(2)
  },
  icon: {
    width: 50,
    marginBottom: theme.spacing(1)
  },
  submitButton: {
    minWidth: 327
  },
  contactButton: {
    minWidth: 327,
    color: colors.white,
    backgroundColor: colors.riverBed,
    "&:hover": {
      backgroundColor: colors.riverBed
    }
  }
}));

const CivilFormatTab = ({ canRequest }) => {
  const [status, setStatus] = useState({});
  const { caseId } = useParams();
  const { data } = useSelector(s => s.caseDetail);
  const { requestCaseDownload } = useCaseDownload();
  const classes = useStyles();
  const getText = useTranslate();

  const handleClick = () => {
    const body = {
      caseId,
      recipient: { kind: "LawFirm" },
      format: "CivilCase"
    };
    setStatus({ loading: true });
    requestCaseDownload(body, () => {
      setStatus({
        loading: false,
        sent: true
      });
    });
  };

  const disableButton = status.loading || !canRequest;

  return (
    <div>
      <Text
        className={classes.text}
        text="downloadCasePage.civilFormat.description"
        type="contentLabel3"
      />
      <img src={lockIcon} className={classes.icon} alt="" />
      {data.allowLawFirmCivilReport ? (
        <>
          <Text
            className={classes.text}
            text="downloadCasePage.civilFormat.lawFirmOption.sendDialog.title"
            type="heading3"
          />
          <Text
            className={classes.text}
            text="downloadCasePage.civilFormat.lawFirmOption.description"
            type="subtitle1Big"
          />
          {status.sent ? (
            <SuccessLabel
              buttonText="common.prompts.sentExclamation"
              descriptionText="downloadCasePage.civilFormat.lawFirmOption.sentDialog.title"
            />
          ) : (
            <Button
              className={classes.submitButton}
              color="primary"
              disableElevation
              disabled={disableButton}
              onClick={handleClick}
              type="submit"
              variant="contained"
            >
              {getText("common.prompts.send")}
            </Button>
          )}
        </>
      ) : (
        <>
          <Text
            className={classes.text}
            text="downloadCasePage.evidenceAlreadySent"
            type="heading3"
          />
          <Text
            className={classes.text}
            text="downloadCasePage.evidenceAlreadySentDescription"
            type="subtitle1Big"
          />
          <Button
            component={Link}
            className={classes.contactButton}
            disableElevation
            disabled={disableButton}
            variant="contained"
            to={externalRoutes.contact}
            target="_blank"
          >
            {getText("common.prompts.getInTouch")}
          </Button>
        </>
      )}
    </div>
  );
};

CivilFormatTab.propTypes = {
  canRequest: PropTypes.bool.isRequired
};

export default CivilFormatTab;
