import React from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";

// Components
import Button from "../Button";
import ConfirmationDialog from "../ConfirmationDialog";

const types = {
  button: Button,
  icon: IconButton
};

const ConfirmationButton = ({
  buttonType,
  dialog,
  onCancel,
  onClick,
  type,
  ...props
}) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = status => () => {
    setOpen(status);
  };
  const handleClick = () => {
    if (onClick) onClick();
    setOpen(false);
  };

  const handleCancel = () => {
    if (onCancel) onCancel();
    setOpen(false);
  };

  const Component = types[type];

  return (
    <>
      <Component onClick={handleOpen(true)} {...props} />
      <ConfirmationDialog
        onClose={handleOpen(false)}
        onConfirm={handleClick}
        onCancel={handleCancel}
        open={open}
        {...dialog}
      />
    </>
  );
};

ConfirmationButton.propTypes = {
  buttonType: PropTypes.string,
  dialog: PropTypes.shape({}),
  onCancel: PropTypes.func,
  onClick: PropTypes.func,
  type: PropTypes.string
};

ConfirmationButton.defaultProps = {
  buttonType: null,
  dialog: {},
  onCancel: null,
  onClick: null,
  type: "button"
};

export default ConfirmationButton;
