import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

// Assets
import colors from "../../config/colors";
import disclaimerIcon from "../../assets/icons/disclaimer.svg";

// Components
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: ({ color }) => theme.palette[color].main,
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(2),
    alignItems: "center"
  },
  icon: {
    marginRight: theme.spacing(2)
  },
  text: {
    color: colors.white,
    fontWeight: theme.typography.fontWeightMedium
  }
}));

const Disclaimer = ({
  button,
  className,
  color,
  icon,
  text,
  textClassName
}) => {
  const classes = useStyles({ color });

  return (
    <Paper elevation={0} classes={{ root: classes.root }} className={className}>
      <img className={classes.icon} src={icon || disclaimerIcon} alt="" />
      <Text
        text={text}
        type="contentLabel2"
        className={clsx(classes.text, textClassName)}
      />
      {button}
    </Paper>
  );
};

Disclaimer.propTypes = {
  button: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.node,
  text: PropTypes.node,
  textClassName: PropTypes.string
};

Disclaimer.defaultProps = {
  button: null,
  className: "",
  color: "primary",
  icon: null,
  text: "",
  textClassName: ""
};

export default Disclaimer;
