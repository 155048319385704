import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";

// Formik
import { Formik, Form } from "formik";
import * as Yup from "yup";

// Hooks
import { useAppInsights } from "../../../utils/telemetry/AppInsights";
import useTranslate from "../../../hooks/useTranslate";

// Icons
import statementIcon from "../../../assets/icons/statementIconColored.svg";

// Config
import { getRoute, urls } from "../../../config/routes";
import { switchEnterKey } from "../../../utils/functions";

// Actions
import { createNote } from "../../../state/notes/actions";

// Components
import Close from "../../../components/Close";
import ConfirmationButton from "../../../components/ConfirmationButton";
import ContentCardHeader from "../../../components/ContentCardHeader";
import CustomField from "../../../components/CustomField";
import Link from "../../../components/Link";
import Text from "../../../components/Text";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3, 6, 6)
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  submit: {
    textTransform: "uppercase",
    minWidth: 160,
    marginRight: theme.spacing(2)
  }
}));

const getSchema = text =>
  Yup.object().shape({
    title: Yup.string().required(text),
    content: Yup.string().required(text)
  });

const initialValues = {
  title: "",
  content: "",
  noteType: "Note"
};

const NewStatementPanel = ({ defaultRoute }) => {
  const { caseId } = useParams();
  const { trackException } = useAppInsights();
  const classes = useStyles();
  const dispatch = useDispatch();
  const getText = useTranslate();
  const history = useHistory();

  const texts = getText("notesPage.newStatement");

  const onSubmit = (body, actions) => {
    dispatch(
      createNote({
        caseId,
        body,
        onFinish: (success, data) => {
          if (success) {
            history.push(
              getRoute(urls.noteDetail, { caseId, itemId: data.id })
            );
          } else {
            trackException(
              `Failed to create ${body.noteType} at case: ${caseId}`
            );
            actions.setSubmitting(false);
          }
        }
      })
    );
  };

  return (
    <>
      <ContentCardHeader>
        <Text text="pageTitles.newStatement" type="heading2" />
        <IconButton component={Link} to={defaultRoute}>
          <Close />
        </IconButton>
      </ContentCardHeader>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validationSchema={getSchema(
          getText("common.validationSchema.required")
        )}
      >
        {({ errors, setFieldValue, submitForm, isSubmitting }) => (
          <Form className={classes.root}>
            <CustomField
              autoComplete="off"
              disabled={isSubmitting}
              error={errors.crimeRef}
              label={texts.titleLabel}
              name="title"
              onKeyDown={switchEnterKey}
              placeholder={texts.titlePlaceholder}
            />
            <CustomField
              autoComplete="off"
              disabled={isSubmitting}
              error={errors.crimeRef}
              label={texts.noteLabel}
              multiline
              name="content"
              placeholder={texts.notePlaceholder}
              rows={20}
            />
            <div className={classes.buttonContainer}>
              <ConfirmationButton
                className={classes.submit}
                color="primary"
                disabled={isSubmitting}
                disableElevation
                onClick={e => {
                  setFieldValue("noteType", "Statement", false);
                  submitForm(e);
                }}
                onCancel={submitForm}
                variant="contained"
                dialog={{
                  button: getText(
                    "notesPage.newStatement.saveButtonDialog.primaryButton"
                  ).toUpperCase(),
                  buttonsColumn: true,
                  cancel: getText(
                    "notesPage.newStatement.saveButtonDialog.secondaryButton"
                  ),
                  type: "submit",
                  icon: statementIcon,
                  texts: [
                    {
                      key: 1,
                      text: getText(
                        "notesPage.newStatement.saveButtonDialog.title"
                      )
                    },
                    {
                      key: 2,
                      text: getText(
                        "notesPage.newStatement.saveButtonDialog.description"
                      )
                    }
                  ]
                }}
              >
                {texts.saveButton}
              </ConfirmationButton>
              {isSubmitting && <CircularProgress size={24} />}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

NewStatementPanel.propTypes = {
  defaultRoute: PropTypes.string.isRequired
};

export default NewStatementPanel;
