import React from "react";
import PropTypes from "prop-types";

// Material
import CircularProgress from "@material-ui/core/CircularProgress";

// Config
import { imageRequestInterval } from "../../config/apiConfig";

const ImageProcessing = ({ getImage, ...rest }) => {
  React.useEffect(() => {
    const interval = setInterval(getImage, imageRequestInterval);
    return () => clearInterval(interval);
  }, [getImage]);

  return <CircularProgress {...rest} />;
};

ImageProcessing.propTypes = {
  getImage: PropTypes.func.isRequired
};

export default ImageProcessing;
