import React from "react";
import { Provider, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import isEmpty from "lodash-es/isEmpty";

// Material
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core";

// State
import store from "./state/store";

// Context
import CaseDownloadProvider from "./context/CaseDownloadProvider";
import FeatureFlagsProvider, {
  useFeatureFlags
} from "./context/FeatureFlagsProvider";
import SensitiveImageProvider from "./context/SensitiveImageProvider";

// Config
import { privateRoutes, publicRoutes } from "./config/routeMapping";
import colors from "./config/colors";

// Telemetry
import { AppInsightsProvider } from "./utils/telemetry/AppInsights";

// Components
import { ErrorPage, NotFoundPage } from "./containers/index";
import AppWrapper from "./components/AppWrapper";
import LoadingSection from "./components/LoadingSection/LoadingSection";
import PinValidatorHandler from "./components/PinValidatorHandler/PinValidatorHandler";
import SnackbarHandler from "./components/SnackbarHandler/SnackbarHandler";

// Routes
import RouteHandler from "./routes/RouteHandler";

// Hooks
import useAppInitialize from "./hooks/useAppInitialize";
import CasesPageProvider from "./context/CasesPageProvider";

const theme = createTheme({
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(",")
  },
  palette: {
    common: {
      black: colors.riverBed
    },
    primary: {
      main: colors.pictonBlue,
      contrastText: colors.white
    },
    secondary: {
      main: colors.rose,
      contrastText: colors.white
    },
    text: {
      primary: colors.riverBed
    }
  },
  overrides: {
    MuiInput: {
      underline: {
        "&:before": {
          content: "none"
        }
      }
    },
    MuiAlert: {
      root: {
        opacity: 0.9
      },
      standardInfo: {
        backgroundColor: `${colors.pictonBlue}90`,
        color: colors.white,

        "& .MuiAlert-icon": {
          color: colors.white
        }
      },
      standardSuccess: {
        backgroundColor: `${colors.pictonBlue}90`,
        color: colors.white,

        "& .MuiAlert-icon": {
          color: colors.white
        }
      }
    },
    MuiPickerDTTabs: {
      tabs: {
        "& .MuiSvgIcon-root": {
          color: colors.white
        },
        "& .MuiTabs-indicator": {
          backgroundColor: colors.white,
          height: 3
        }
      }
    }
  }
});

const renderRoutes = routes =>
  routes.map(route => <RouteHandler key={route.id} {...route} />);

const Content = () => {
  const { initialized, skipInit } = useAppInitialize();
  const { currentUser, isLoading } = useSelector(s => s.user);
  const { error } = useSelector(s => s.errorPage);
  const { featureFlags, flagsError } = useFeatureFlags();

  const showLoading = !featureFlags || !initialized || isLoading;

  if (!skipInit && showLoading) return <LoadingSection />;
  if (error || flagsError) return <ErrorPage />;

  const userExists = currentUser && !isEmpty(currentUser);

  return (
    <AppInsightsProvider>
      <SnackbarHandler />
      <PinValidatorHandler userExists={userExists}>
        <AppWrapper>
          <SensitiveImageProvider>
            <CaseDownloadProvider>
              <CasesPageProvider>
                <Switch>
                  {userExists && renderRoutes(privateRoutes)}
                  {renderRoutes(publicRoutes)}
                  <Route path="/" exact>
                    <Redirect to="/cases" />
                  </Route>
                  <Route path="*">
                    <NotFoundPage />
                  </Route>
                </Switch>
              </CasesPageProvider>
            </CaseDownloadProvider>
          </SensitiveImageProvider>
        </AppWrapper>
      </PinValidatorHandler>
    </AppInsightsProvider>
  );
};

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <FeatureFlagsProvider>
        <Router>
          <Content />
        </Router>
      </FeatureFlagsProvider>
    </ThemeProvider>
  </Provider>
);

export default App;
