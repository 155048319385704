import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Config
import { getSettingsUrl } from "../../../config/apiConfig";

// Actions
import { parseFetchOptions } from "../../../state/user/actions";
import { setError } from "../../../state/errorPage/actions";

// Hooks
import { useAppInsights } from "../../../utils/telemetry/AppInsights";

// Components
import ContentCardHeader from "../../../components/ContentCardHeader";
import LoadingSection from "../../../components/LoadingSection";
import Switch from "../../../components/Switch";
import Text from "../../../components/Text";

const useStyles = makeStyles(theme => ({
  items: {
    padding: theme.spacing(2, 3),
    minHeight: 254
  },
  item: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2)
  }
}));

const NotificationSettings = ({ hideHeader, className }) => {
  const [isLoading, setLoading] = useState(true);
  const [settings, setSettings] = useState(null);
  const { trackException } = useAppInsights();
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;
    dispatch(
      parseFetchOptions(options => {
        fetch(getSettingsUrl, options)
          .then(res => res.json())
          .then(data => {
            if (isMounted) {
              setSettings(data);
              setLoading(false);
            }
          })
          .catch(err => {
            dispatch(setError(err));
            trackException("Failed to fetch notification settings");
          });
      })
    );
    return () => {
      isMounted = false;
    };
  }, [dispatch, trackException]);

  const updateSetting = type => () => {
    const notifications = {
      ...settings.notifications,
      [type]: !settings.notifications[type]
    };

    setSettings({ notifications });

    dispatch(
      parseFetchOptions(options => {
        fetch(getSettingsUrl, {
          method: "PUT",
          headers: {
            ...options.headers,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ notifications })
        }).catch(() => {
          trackException("Failed to update settings");
          setSettings({
            notifications: {
              ...notifications,
              [type]: !notifications[type]
            }
          });
        });
      })
    );
  };

  return (
    <div className={className} id="notifications">
      {!hideHeader && (
        <ContentCardHeader>
          <Text text="settingsPage.notifications.title" type="heading2" />
        </ContentCardHeader>
      )}
      {isLoading ? (
        <div className={classes.items}>
          <LoadingSection normalHeight noBackground />
        </div>
      ) : (
        <div className={classes.items}>
          <div className={classes.item}>
            <Text
              text="settingsPage.notifications.allowPushLabel"
              type="subtitle1Big"
            />
            <Switch
              onClick={updateSetting("push")}
              checked={settings.notifications.push}
            />
          </div>
          <div className={classes.item}>
            <Text
              text="settingsPage.notifications.allowEmailLabel"
              type="subtitle1Big"
            />
            <Switch
              onClick={updateSetting("email")}
              checked={settings.notifications.email}
            />
          </div>
          <div className={classes.item}>
            <Text
              text="settingsPage.notifications.allowSMSLabel"
              type="subtitle1Big"
            />
            <Switch
              onClick={updateSetting("smsMessages")}
              checked={settings.notifications.smsMessages}
            />
          </div>
        </div>
      )}
    </div>
  );
};

NotificationSettings.propTypes = {
  className: PropTypes.string,
  hideHeader: PropTypes.bool
};

NotificationSettings.defaultProps = {
  className: null,
  hideHeader: false
};

export default NotificationSettings;
