import React from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

// Config
import colors from "../../config/colors";
import useTranslate from "../../hooks/useTranslate";

const useStyles = makeStyles(theme => ({
  root: {
    height: 20,
    marginLeft: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    fontFamily: ["Gibson", "sans-serif"].join(","),
    fontSize: 8,
    textTransform: "uppercase",
    color: colors.white
  },
  joined: {
    backgroundColor: colors.pastelGreen
  },
  pending: {
    backgroundColor: colors.silver
  }
}));

const ContributorStatusChip = ({ joined }) => {
  const classes = useStyles();
  const getText = useTranslate();

  const status = joined ? "joined" : "pending";

  return (
    <Chip
      component="span"
      className={`${classes.root} ${classes[status]}`}
      label={getText(`common.status.${status}`)}
    />
  );
};

ContributorStatusChip.propTypes = {
  joined: PropTypes.bool.isRequired
};

export default ContributorStatusChip;
