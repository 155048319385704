import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

// Config
import { getRoute, urls } from "../../../config/routes";

// Components
import LogDetailItem from "./LogDetailItem";

const LogDetailHandler = ({ parameters, id, action }) => {
  const { caseId } = useParams();
  const value = parameters[id];

  if (!value) return null;

  if (id === "images") {
    return value.map(data => {
      const { filename, imageType } = JSON.parse(data);
      return (
        <React.Fragment key={filename}>
          <LogDetailItem
            isLink
            name="filename"
            operationCode={action}
            value={filename}
            to={getRoute(urls.annotationTool, {
              caseId,
              index: 0,
              injuryId: parameters.injuryID,
              query: `?filename=${filename}`
            })}
          />
          <LogDetailItem name="imageType" value={imageType} />
        </React.Fragment>
      );
    });
  }

  return (
    <LogDetailItem
      name={id}
      operationCode={action}
      value={Array.isArray(value) ? value.join(", ") : value}
    />
  );
};

LogDetailHandler.propTypes = {
  action: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  parameters: PropTypes.shape({
    injuryID: PropTypes.string
  }).isRequired
};

export default LogDetailHandler;
