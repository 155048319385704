import React from "react";
import { Formik } from "formik";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

// Date
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-GB";
import esLocale from "date-fns/locale/es";

const localeMap = {
  en: enLocale,
  es: esLocale
};

const FormikHandler = ({ render, formik }) => {
  const { locale } = useSelector(s => s.user);
  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={localeMap[locale] || localeMap.en}
    >
      <Formik {...formik}>{props => render(props)}</Formik>
    </MuiPickersUtilsProvider>
  );
};

FormikHandler.propTypes = {
  render: PropTypes.func.isRequired,
  formik: PropTypes.shape({}).isRequired
};

export default FormikHandler;
