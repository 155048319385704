import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import ReactCountryFlag from "react-country-flag";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// Config
import { languageOptions } from "../../../locales/localeConfig";
import styles from "../../../config/styles";

// Actions
import { setLocale } from "../../../state/user/actions";

// Components
import ContentCardHeader from "../../../components/ContentCardHeader";
import Text from "../../../components/Text";

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(2, 3),
    minHeight: 254
  },
  select: {
    padding: theme.spacing(0, 4, 0, 2.5),
    height: 34,
    display: "flex",
    alignItems: "center",
    borderRadius: theme.shape.borderRadius,
    fontSize: 15,
    minHeight: 46
  },
  selectInput: { ...styles.mixins.inputRoot, width: 300 },
  optionFlag: {
    marginRight: theme.spacing(1)
  }
}));

const LocalizationSettings = ({ className }) => {
  const { locale } = useSelector(s => s.user);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSelect = event => dispatch(setLocale(event.target.value));

  return (
    <div className={className} id="localization">
      <ContentCardHeader>
        <Text text="settingsPage.localization.title" type="heading2" />
      </ContentCardHeader>
      <div className={classes.content}>
        <Select
          classes={{ select: classes.select }}
          className={classes.selectInput}
          disableUnderline
          value={locale}
          onChange={handleSelect}
        >
          {languageOptions.map(({ value, code }) => (
            <MenuItem key={value} value={value}>
              <ReactCountryFlag
                className={classes.optionFlag}
                countryCode={code}
              />
              <Text type="body" text={`common.locale.${value}`} />
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};

LocalizationSettings.propTypes = {
  className: PropTypes.string.isRequired
};

export default LocalizationSettings;
