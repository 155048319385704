import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

// Config
import { urls } from "../config/routes";

// State
import { initialize } from "../state/user/actions";

const useAppInitialize = () => {
  const { msalState } = useSelector(s => s.user);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [initialized, setInitialized] = useState(false);
  const matchDownload = useRouteMatch(urls.downloadReport);
  const matchNotFound = useRouteMatch(urls.notFound);

  const skipInit = Boolean(matchDownload || matchNotFound);

  useEffect(() => {
    if (!initialized && !skipInit) {
      // Initialize msalApp singleton only in needed routes
      dispatch(initialize(() => setInitialized(true)));
    }
  }, [dispatch, skipInit]);

  useEffect(() => {
    // Scroll to top on each route change
    // Ignore when Cases Page since it has it's own scroll
    if (pathname !== "/cases") window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    // Handle redirect on msalState change
    if (msalState) history.push(msalState.route);
  }, [msalState, history]);

  return { initialized, skipInit };
};

export default useAppInitialize;
