import React, { useState } from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Zoom from "@material-ui/core/Zoom";

// Config
import { getPlaceholder } from "../../../config/values";
import colors from "../../../config/colors";
import styles from "../../../config/styles";

// Hooks
import useTranslate from "../../../hooks/useTranslate";

// Components
import Close from "../../Close";
import FileUploadProgress from "./FileUploadProgress";
import Text from "../../Text";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(1.25),
    width: "100%"
  },
  imageWrapper: {
    width: 100,
    height: 82,
    position: "relative",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(3)
  },
  image: {
    position: "absolute",
    minWidth: "1000%",
    minHeight: "1000%",
    left: "50%",
    top: "50%",
    transform: "translateX(-50%) translateY(-50%) scale(0.1)"
  },
  imagePlaceholder: {
    width: "100%",
    height: "100%",
    backgroundColor: colors.concrete,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    width: `calc(100% - ${theme.spacing(3)}px)`
  },
  heading: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(0.5)
  },
  textField: {
    width: "100%"
  },
  inputRoot: styles.mixins.inputRoot,
  input: styles.mixins.input,
  focused: {
    borderColor: theme.palette.primary.main
  }
}));

const FileItem = ({
  animationMultiply,
  enableEdit,
  file,
  isUploading,
  noDescriptions,
  onChangeDescription,
  onRemove,
  uploadError,
  uploadProgress
}) => {
  const classes = useStyles();
  const getText = useTranslate();

  const [show, setShow] = useState(true);
  const [timeout, setTimeout] = useState(animationMultiply * 200);
  const [showPlaceholder, setShowPlaceholder] = useState(false);

  const handleRemove = () => {
    setTimeout(200);
    setShow(false);
  };
  const handleNonImage = () => setShowPlaceholder(true);

  return (
    <Zoom in={show} onExited={onRemove} timeout={timeout} unmountOnExit>
      <div className={classes.root}>
        <div className={classes.imageWrapper}>
          {!showPlaceholder ? (
            <img
              alt=""
              className={classes.image}
              onError={handleNonImage}
              src={URL.createObjectURL(file)}
            />
          ) : (
            <div className={classes.imagePlaceholder}>
              <img src={getPlaceholder(file.name)} alt="" />
            </div>
          )}
        </div>
        <div className={classes.contentWrapper}>
          <div className={classes.heading}>
            <Text text="fileUploadPage.uploadFile" type="contentLabel2" />
            {enableEdit && !isUploading && (
              <IconButton onClick={handleRemove}>
                <Close size={10} />
              </IconButton>
            )}
          </div>
          {isUploading || !enableEdit ? (
            <FileUploadProgress
              error={uploadError}
              filename={file.name}
              progress={uploadProgress}
            />
          ) : (
            !noDescriptions && (
              <TextField
                className={classes.textField}
                InputProps={{
                  classes: {
                    root: classes.inputRoot,
                    input: classes.input,
                    focused: classes.focused
                  },
                  disabled: !enableEdit,
                  disableUnderline: true,
                  placeholder: getText("fileUploadPage.addDescription")
                }}
                onChange={onChangeDescription}
              />
            )
          )}
        </div>
      </div>
    </Zoom>
  );
};

FileItem.propTypes = {
  animationMultiply: PropTypes.number,
  enableEdit: PropTypes.bool.isRequired,
  file: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  isUploading: PropTypes.bool.isRequired,
  noDescriptions: PropTypes.bool.isRequired,
  onChangeDescription: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  uploadError: PropTypes.bool,
  uploadProgress: PropTypes.number
};

FileItem.defaultProps = {
  animationMultiply: 0,
  uploadError: false,
  uploadProgress: 0
};

export default FileItem;
