import React, { createContext, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// Config
import {
  mediaItemUploadUrl,
  medicalRecordUploadUrl
} from "../../config/apiConfig";
import { useAppInsights } from "../../utils/telemetry/AppInsights";

// Actions
import { parseFetchOptions } from "../../state/user/actions";
import { retryAddMedia } from "../../state/media/actions";

// Components
import FileUpload from "../FileUpload/FileUpload";

const MediaReuploadContext = createContext();
export const useMediaReupload = () => useContext(MediaReuploadContext);

const uploadUrl = {
  mediaPage: mediaItemUploadUrl,
  medicalRecordsPage: medicalRecordUploadUrl
};

const MediaReuploadProvider = ({ children, page, caseId }) => {
  const [elementId, setElementId] = useState(null);
  const { trackException } = useAppInsights();

  const dispatch = useDispatch();
  const media = useSelector(s => s.media);
  const medicalRecords = useSelector(s => s.medicalRecords);

  const uploadStatus = {
    mediaPage: media,
    medicalRecordsPage: medicalRecords
  };

  const closeReupload = () => setElementId(null);
  const openReupload = id => setElementId(id);

  const handleSubmit = files => {
    dispatch(
      parseFetchOptions(({ headers: { Authorization, ...headers } }) => {
        const url = uploadUrl[page](caseId, elementId);
        const options = {
          method: "PUT",
          headers: { ...headers, Authorization }
        };
        fetch(url, options)
          .then(res => res.json())
          .then(reuploadData => {
            dispatch(
              retryAddMedia({
                caseId,
                image: files[0].file,
                id: reuploadData.id,
                url: reuploadData.uploadURL,
                onFail: () =>
                  trackException(
                    `Failed to reupload media file ${reuploadData.id} at ${page} of case ${caseId}`
                  )
              })
            );
          })
          .catch(() => {
            trackException(
              `Failed to reupload media ${elementId} at ${page} of ${caseId}`
            );
          });
      })
    );
  };

  return (
    <MediaReuploadContext.Provider
      value={{
        openReupload,
        closeReupload
      }}
    >
      <>
        {children}
        <FileUpload
          onClose={closeReupload}
          onSubmit={handleSubmit}
          open={Boolean(elementId)}
          noDescriptions
          uploadErrors={uploadStatus[page].uploadErrors}
          uploadProgress={uploadStatus[page].uploadProgress}
        />
      </>
    </MediaReuploadContext.Provider>
  );
};

MediaReuploadProvider.propTypes = {
  children: PropTypes.node.isRequired,
  page: PropTypes.string.isRequired,
  caseId: PropTypes.string.isRequired
};

export default MediaReuploadProvider;
