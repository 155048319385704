import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// Material
import CircularProgress from "@material-ui/core/CircularProgress";

// Actions
import { getMediaItem } from "../../state/media/actions";
import { getMedicalRecordsItem } from "../../state/medicalRecords/actions";
import { toggleSnack } from "../../state/snackbar/actions";

// Config
import { getMediaType } from "../../config/values";
import { snackbarTypes } from "../../config/snackbar";

// Components
import MediaItemImage from "../MediaItemImage";

const views = {
  media: {
    action: getMediaItem
  },
  medicalRecords: {
    action: ({ mediaId, ...props }) =>
      getMedicalRecordsItem({ recordId: mediaId, ...props })
  }
};

const GalleryItem = ({ mediaId, caseId, view, onFail }) => {
  const dispatch = useDispatch();
  const { galleryItems } = useSelector(s => s[view]);

  const data = galleryItems && galleryItems[mediaId];

  useEffect(() => {
    if (!data) {
      dispatch(
        views[view].action({
          caseId,
          mediaId,
          onFail: () => {
            dispatch(toggleSnack({ type: snackbarTypes.ERROR }));
            onFail();
          }
        })
      );
    }
  }, [caseId, data, dispatch, mediaId, view]);

  if (!data) return <CircularProgress />;

  const type = getMediaType(data.type);
  let src =
    data.resourceURLs && (data.resourceURLs[type] || data.resourceURLs.url);

  if (data.type === "Video") {
    src = {
      hls: data.resourceURLs.hlsStream,
      dash: data.resourceURLs.dashStream
    };
  }

  return <MediaItemImage type={type} filename={data.filename} src={src} />;
};

GalleryItem.propTypes = {
  caseId: PropTypes.string.isRequired,
  mediaId: PropTypes.string.isRequired,
  view: PropTypes.string.isRequired,
  onFail: PropTypes.func.isRequired
};

export default GalleryItem;
