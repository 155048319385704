import React from "react";
import PropTypes from "prop-types";
import format from "date-fns/format";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

// Config
import { getRoute, urls } from "../../config/routes";
import styles from "../../config/styles";
import useTranslate from "../../hooks/useTranslate";

// Components
import CardHeader from "../CardHeader";
import CaseSteps from "./CaseSteps";
import CollapseInjuries from "./CollapseInjuries";
import SelectDevice from "./SelectDevice";

const useStyles = makeStyles(theme => ({
  root: {
    border: styles.values.border,
    overflow: "hidden",
    marginBottom: theme.spacing(1)
  },
  header: {
    padding: theme.spacing(3, 3.5),
    borderBottom: styles.values.border
  }
}));

const CaseDetail = ({ data, isNew, showSteps, isWitness }) => {
  const classes = useStyles();
  const getText = useTranslate();

  const newCaseTitle = getText("createCasePage.newCase");
  const caseTitle = getText("common.units.files.one");

  const creationDate = format(
    isNew ? new Date() : new Date(data.creationDateTime),
    "dd/MM/yyyy"
  );

  if (!data) return null;

  return (
    <Paper elevation={0} className={classes.root}>
      <div className={classes.header}>
        <CardHeader
          isNew={isNew}
          to={getRoute(urls.caseDetail, { caseId: data.id })}
          date={creationDate}
          title={isNew ? newCaseTitle : `${caseTitle} ${data.number}`}
          victimName={data.patientName}
        />
      </div>
      {!isNew &&
        (showSteps ? (
          <CaseSteps />
        ) : (
          !isWitness && (
            <CollapseInjuries injuries={data.injuries} caseId={data.id} />
          )
        ))}
      <SelectDevice />
    </Paper>
  );
};

CaseDetail.propTypes = {
  data: PropTypes.shape({
    creationDateTime: PropTypes.string,
    id: PropTypes.string,
    injuries: PropTypes.arrayOf(PropTypes.shape({})),
    isDraft: PropTypes.bool,
    number: PropTypes.string,
    patientName: PropTypes.string
  }),
  isNew: PropTypes.bool,
  isWitness: PropTypes.bool.isRequired,
  showSteps: PropTypes.bool
};

CaseDetail.defaultProps = {
  data: {},
  isNew: false,
  showSteps: false
};

export default CaseDetail;
