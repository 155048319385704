import { combineReducers } from "redux";
import constant from "lodash-es/constant";

import createReducer from "../../utils/createReducer";

import {
  REQUEST_NOTES,
  REQUEST_NOTES_SUCCESS,
  REQUEST_NOTES_FAIL,
  REQUEST_CREATE_NOTE_SUCCESS,
  REQUEST_CREATE_NOTE,
  REQUEST_CREATE_NOTE_FAIL,
  REQUEST_DELETE_NOTE,
  REQUEST_DELETE_NOTE_SUCCESS,
  REQUEST_DELETE_NOTE_FAIL,
  REQUEST_PUBLISH_NOTE,
  REQUEST_PUBLISH_NOTE_SUCCESS,
  REQUEST_PUBLISH_NOTE_FAIL,
  REQUEST_UPDATE_NOTE_SUCCESS
} from "./actionTypes";

const loadedCaseId = createReducer(null, {
  [REQUEST_NOTES]: (state, { payload }) => payload
});

const data = createReducer(null, {
  [REQUEST_CREATE_NOTE_SUCCESS]: constant(null),
  [REQUEST_NOTES_SUCCESS]: (state, { payload }) => payload,
  [REQUEST_NOTES]: constant(null),
  [REQUEST_DELETE_NOTE_SUCCESS]: (state, { payload }) => {
    const newState = JSON.parse(JSON.stringify(state));
    const filtered = newState.filter(({ id }) => id !== payload);
    return filtered;
  },
  [REQUEST_PUBLISH_NOTE_SUCCESS]: (state, { payload }) => {
    const newState = JSON.parse(JSON.stringify(state));
    const index = newState.findIndex(({ id }) => id === payload);
    newState[index].noteType = "Statement";
    return newState;
  },
  [REQUEST_CREATE_NOTE_SUCCESS]: (state, { payload }) => [...state, payload],
  [REQUEST_UPDATE_NOTE_SUCCESS]: (state, { payload }) =>
    state.map(item => (item.id === payload.id ? payload : item))
});

const error = createReducer(null, {
  [REQUEST_NOTES]: constant(null),
  [REQUEST_NOTES_FAIL]: (state, { payload }) => payload
});

const isRequesting = createReducer(false, {
  [REQUEST_NOTES]: constant(true),
  [REQUEST_NOTES_SUCCESS]: constant(false),
  [REQUEST_NOTES_FAIL]: constant(false)
});

const isCreating = createReducer(false, {
  [REQUEST_CREATE_NOTE]: constant(true),
  [REQUEST_CREATE_NOTE_SUCCESS]: constant(false),
  [REQUEST_CREATE_NOTE_FAIL]: constant(false)
});

const isDeleting = createReducer(false, {
  [REQUEST_DELETE_NOTE]: constant(true),
  [REQUEST_DELETE_NOTE_SUCCESS]: constant(false),
  [REQUEST_DELETE_NOTE_FAIL]: constant(false)
});

const isSubmitting = createReducer(false, {
  [REQUEST_PUBLISH_NOTE]: constant(true),
  [REQUEST_PUBLISH_NOTE_SUCCESS]: constant(false),
  [REQUEST_PUBLISH_NOTE_FAIL]: constant(false)
});

export default combineReducers({
  loadedCaseId,
  data,
  error,
  isRequesting,
  isCreating,
  isDeleting,
  isSubmitting
});
