import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

const SentisiveImageContext = createContext();

export const useSensitiveImage = () => useContext(SentisiveImageContext);

const SensitiveImageProvider = ({ children }) => {
  const [clickedThumbnail, setClickedThumbnail] = useState(null);

  const clickThumbnail = id => setClickedThumbnail(id);
  const resetClickedThumbnail = () => setClickedThumbnail(null);

  return (
    <SentisiveImageContext.Provider
      value={{
        clickedThumbnail,
        clickThumbnail,
        resetClickedThumbnail
      }}
    >
      {children}
    </SentisiveImageContext.Provider>
  );
};

SensitiveImageProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default SensitiveImageProvider;
