import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";

// Components
import DocumentTitle from "./DocumentTitle";
import useTranslate from "../hooks/useTranslate";

const RouteHandler = ({ Component, title, isExact, path, props }) => {
  const getText = useTranslate();
  return (
    <Route
      exact={isExact}
      path={path}
      render={renderProps => (
        <DocumentTitle title={getText(`pageTitles.${title}`, null, "kulpa")}>
          <Component {...renderProps} {...props} />
        </DocumentTitle>
      )}
    />
  );
};

RouteHandler.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  isExact: PropTypes.bool,
  path: PropTypes.string,
  props: PropTypes.shape({}),
  title: PropTypes.string
};

RouteHandler.defaultProps = {
  isExact: false,
  path: undefined,
  props: {},
  title: null
};

export default RouteHandler;
