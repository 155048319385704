import {
  REQUEST_CASES_SUCCESS,
  REQUEST_CASES,
  REQUEST_CASES_FAIL,
  REQUEST_CREATE_CASE_SUCCESS,
  REQUEST_CREATE_CASE,
  REQUEST_CREATE_CASE_FAIL,
  RESET_TOGGLED_CASE,
  TOGGLE_CASE
} from "./actionTypes";

import { getCasesUrl } from "../../config/apiConfig";
import { parseFetchOptions } from "../user/actions";
import errors from "../../utils/errors";

// Cases
const requestCases = () => ({
  type: REQUEST_CASES
});

const requestCasesSuccess = payload => ({
  type: REQUEST_CASES_SUCCESS,
  payload
});

const requestCasesFail = payload => ({
  type: REQUEST_CASES_FAIL,
  payload
});

export const getCases = onFail => dispatch => {
  dispatch(requestCases());

  dispatch(
    parseFetchOptions(async options => {
      const response = await fetch(
        getCasesUrl("?includeContributors=true&includeInjuryCount=true"),
        {
          method: "GET",
          headers: options.headers
        }
      ).catch(error => {
        if (onFail) onFail();
        dispatch(requestCasesFail(error));
      });

      if (response && response.ok) {
        const data = await response.json();
        dispatch(requestCasesSuccess(data));
      } else {
        if (onFail) onFail();
        dispatch(requestCasesFail(errors.responseError(response)));
      }
    })
  );
};

// Toggled Case (Victim ID Steps)
export const resetToggledCase = () => ({ type: RESET_TOGGLED_CASE });

export const toggleCase = payload => ({
  type: TOGGLE_CASE,
  payload
});

// Create Case
const requestCreateCase = () => ({
  type: REQUEST_CREATE_CASE
});

const requestCreateCaseSuccess = payload => ({
  type: REQUEST_CREATE_CASE_SUCCESS,
  payload
});

const requestCreateCaseFail = payload => ({
  type: REQUEST_CREATE_CASE_FAIL,
  payload
});

export const createCase =
  ({ body, onFail, onSuccess, state }) =>
  async dispatch => {
    dispatch(requestCreateCase());

    // msalState is recieved as a string
    const msalState =
      state &&
      JSON.stringify({
        route: "/cases/new",
        data: state
      });

    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(getCasesUrl("?uploadMethod=remote"), {
          method: "POST",
          headers: {
            ...options.headers,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(body)
        }).catch(error => {
          dispatch(requestCreateCaseFail(error));
          onFail();
        });

        if (response && response.ok) {
          const caseData = await response.json();
          dispatch(requestCreateCaseSuccess(caseData));
          toggleCase(caseData.id);
          onSuccess(caseData.id);
        } else {
          dispatch(requestCreateCaseFail(errors.responseError(response)));
          onFail();
        }
      }, msalState)
    );
  };
