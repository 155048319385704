import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

// Formik
import { Form } from "formik";

// Material
import { makeStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import Paper from "@material-ui/core/Paper";

// Config
import { contactPreferences } from "../../config/values";
import styles from "../../config/styles";

// Hooks
import usePrevious from "../../hooks/usePrevious";
import useTranslate from "../../hooks/useTranslate";

// Handlers
import LocationHandler from "./LocationHandler";

// Components
import Button from "../../components/Button";
import ContentCardHeader from "../../components/ContentCardHeader";
import CountryField from "../../components/CountryField";
import CustomField from "../../components/CustomField";
import LocationField from "../../components/Input/LocationField";
import Text from "../../components/Text";

const useStyles = makeStyles(theme => ({
  root: {
    border: styles.values.border
  },
  contentRoot: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0, 3),
    gap: theme.spacing(2)
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2, 3)
  },
  dateTime: {
    marginBottom: ({ dateTimeError }) => (dateTimeError ? 0 : theme.spacing(3))
  },
  dateTimeError: {
    marginBottom: theme.spacing(3)
  },
  contactSubtitle: {
    marginBottom: theme.spacing(1)
  },
  label: {
    marginBottom: theme.spacing(1.5)
  },
  phoneInputWrapper: {
    display: "flex",
    flexDirection: "row"
  },
  timeInputWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(2)
  }
}));

const midnight = { value: "00:00", text: "00:00" };

const hours = [
  { value: "01:00", text: "01:00" },
  { value: "02:00", text: "02:00" },
  { value: "03:00", text: "03:00" },
  { value: "04:00", text: "04:00" },
  { value: "05:00", text: "05:00" },
  { value: "06:00", text: "06:00" },
  { value: "07:00", text: "07:00" },
  { value: "08:00", text: "08:00" },
  { value: "09:00", text: "09:00" },
  { value: "10:00", text: "10:00" },
  { value: "11:00", text: "11:00" },
  { value: "12:00", text: "12:00" },
  { value: "13:00", text: "13:00" },
  { value: "14:00", text: "14:00" },
  { value: "15:00", text: "15:00" },
  { value: "16:00", text: "16:00" },
  { value: "17:00", text: "17:00" },
  { value: "18:00", text: "18:00" },
  { value: "19:00", text: "19:00" },
  { value: "20:00", text: "20:00" },
  { value: "21:00", text: "21:00" },
  { value: "22:00", text: "22:00" },
  { value: "23:00", text: "23:00" }
];

const startTimeOptions = [midnight, ...hours];
const endTimeOptions = [...hours, midnight];

const getContactInputName = (root, key) => `contact.${root[key].name}`;

const CaseSubmitFormHandler = ({
  coord,
  dateTimeError,
  displayContact,
  errors,
  isSubmitting,
  maxDate,
  omitIncidents,
  setCoord,
  setDateTimeError,
  setFieldValue,
  values: { location, dateTime, ...values }
}) => {
  const classes = useStyles({ dateTimeError });
  const prevDate = usePrevious(dateTime);
  const { data } = useSelector(s => s.caseDetail);
  const { dateTimeOfExamination = new Date() } = data || {};

  const getText = useTranslate();

  useEffect(() => {
    if (dateTime !== prevDate) setDateTimeError("");
  }, [dateTime, prevDate, setDateTimeError]);

  const renderEmail =
    values.contact[contactPreferences.channel.name] ===
    contactPreferences.channel.values.EMAIL;

  const disableButton =
    !dateTime ||
    !values.description ||
    !location ||
    (displayContact &&
      (renderEmail
        ? !values.contact.emailAddress
        : !values.contact.phoneNumber));

  return (
    <Form className={classes.contentRoot}>
      {(!displayContact || !omitIncidents) && (
        <Paper elevation={0} className={classes.root}>
          <ContentCardHeader>
            <Text text="pageTitles.caseIncidents" type="heading2" />
          </ContentCardHeader>
          <div className={classes.contentContainer}>
            <LocationHandler
              location={location}
              coord={coord}
              setCoord={setCoord}
            />
            <LocationField
              error={errors.location}
              label={getText("caseIncidentsPage.locationLabel")}
              name="location"
              placeholder={getText("caseIncidentsPage.locationPlaceholder")}
              setCoord={setCoord}
              setFieldValue={setFieldValue}
            />
            <CustomField
              className={classes.dateTime}
              label={getText("caseIncidentsPage.dateTimeLabel")}
              name="dateTime"
              type="dateTime"
              maxDate={maxDate}
              initialFocusedDate={dateTimeOfExamination}
            />
            {dateTimeError && (
              <FormHelperText className={classes.dateTimeError} error>
                {dateTimeError}
              </FormHelperText>
            )}
            <CustomField
              label={getText("caseIncidentsPage.descriptionLabel")}
              multiline
              name="description"
              placeholder={getText("caseIncidentsPage.descriptionPlaceholder")}
              type="text"
            />
          </div>
        </Paper>
      )}
      {displayContact && (
        <Paper elevation={0} className={classes.root}>
          <ContentCardHeader>
            <Text
              text="caseDetailPage.contactPreferences.title"
              type="heading2"
            />
          </ContentCardHeader>
          <div className={classes.contentContainer}>
            <Text
              className={classes.contactSubtitle}
              text="caseDetailPage.contactPreferences.contactMethod"
              type="heading4"
            />
            <CustomField
              label={getText("common.prompts.pleaseSelect")}
              name={getContactInputName(contactPreferences, "channel")}
              type="select"
              options={[
                {
                  value: contactPreferences.channel.values.EMAIL,
                  text: "common.valueLabels.email"
                },
                {
                  value: contactPreferences.channel.values.PHONE,
                  text: "common.valueLabels.phoneCall"
                }
              ]}
            />
            {renderEmail ? (
              <CustomField
                label={getText("common.valueLabels.emailAddress")}
                name={getContactInputName(contactPreferences, "email")}
                placeholder={getText("inviteContributorPage.emailPlaceholder")}
              />
            ) : (
              <>
                <Text
                  className={classes.label}
                  text="common.valueLabels.phoneNumber"
                  type="valueLabel"
                />
                <div className={classes.phoneInputWrapper}>
                  <CountryField selectedCountryCode={values.country.code} />
                  <CustomField
                    name={getContactInputName(
                      contactPreferences,
                      "phoneNumber"
                    )}
                    placeholder="(0) 777122435"
                    error={errors[contactPreferences.phoneNumber.name]}
                  />
                </div>
                <Text
                  className={classes.contactSubtitle}
                  text="caseDetailPage.contactPreferences.contactTime.title"
                  type="heading4"
                />
                <div className={classes.timeInputWrapper}>
                  <CustomField
                    label={getText("common.prompts.from")}
                    name={getContactInputName(contactPreferences, "startTime")}
                    literalOptionText
                    type="select"
                    options={startTimeOptions}
                  />
                  <CustomField
                    label={getText("common.prompts.to")}
                    name={getContactInputName(contactPreferences, "endTime")}
                    literalOptionText
                    type="select"
                    options={endTimeOptions}
                  />
                </div>
              </>
            )}
            <CustomField
              label={getText(
                "caseDetailPage.contactPreferences.contactNotes.title"
              )}
              multiline
              name={getContactInputName(contactPreferences, "notes")}
              placeholder={getText(
                "caseDetailPage.contactPreferences.contactNotes.description"
              )}
              type="text"
            />
          </div>
        </Paper>
      )}
      <Button
        className={classes.submitButton}
        color="primary"
        disabled={isSubmitting || disableButton}
        disableElevation
        type="submit"
        variant="contained"
      >
        {getText("common.prompts.submit")}
      </Button>
    </Form>
  );
};

CaseSubmitFormHandler.propTypes = {
  coord: PropTypes.shape({}),
  dateTimeError: PropTypes.string.isRequired,
  displayContact: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    location: PropTypes.string
  }),
  isSubmitting: PropTypes.bool.isRequired,
  maxDate: PropTypes.string,
  omitIncidents: PropTypes.bool.isRequired,
  setCoord: PropTypes.func.isRequired,
  setDateTimeError: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({
    dateTime: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ]),
    location: PropTypes.string,
    contactPreferences: PropTypes.shape({
      [contactPreferences.channel.name]: PropTypes.string
    })
  }).isRequired
};

CaseSubmitFormHandler.defaultProps = {
  coord: null,
  maxDate: null,
  errors: {}
};

export default CaseSubmitFormHandler;
