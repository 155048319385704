import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Assets
import arrowright from "../../assets/icons/arrowright.svg";
import colors from "../../config/colors";

// Hooks
import useTranslate from "../../hooks/useTranslate";

// Actions
import { resetToggledCase } from "../../state/cases/actions";

// Components
import Link from "../Link";
import SimpleMenu from "../SimpleMenu";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  heading: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    position: "relative"
  },
  details: {
    display: "flex",
    flexDirection: "row"
  },
  icon: {
    marginLeft: "0.5em"
  },
  detailLabel: {
    display: "flex"
  },
  new: {
    color: colors.rose,
    paddingTop: theme.spacing(1.5)
  },
  menuIcon: {
    position: "absolute",
    top: theme.spacing(-1.5),
    right: theme.spacing(-1.5)
  },
  victimName: {
    fontSize: 14,
    marginTop: theme.spacing(2),
    fontWeight: theme.typography.fontWeightRegular
  }
}));

const CardHeader = ({
  date,
  isLocked,
  isNew,
  options,
  showSteps,
  title,
  to,
  victimName
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const getText = useTranslate();

  const reset = () => dispatch(resetToggledCase());

  return (
    <>
      {!isLocked && (
        <div className={classes.heading}>
          <Text type="contentLabel">{date}</Text>
          {options.length > 0 ? (
            <SimpleMenu
              title="casesPage.caseCard.menu.title"
              iconClassName={classes.menuIcon}
              options={options}
            />
          ) : (
            !isNew &&
            !showSteps && (
              <Link to={to} onClick={reset} className={classes.details}>
                <Text type="valueLabel" className={classes.detailLabel}>
                  {getText("casesPage.caseCard.menu.detailsLink")}
                  <img src={arrowright} className={classes.icon} alt="" />
                </Text>
              </Link>
            )
          )}
        </div>
      )}
      <Text type="heading1">{title}</Text>
      {victimName && (
        <Text type="contentLabel" className={classes.victimName}>
          {getText("common.userTypes.Victim")}: {victimName}
        </Text>
      )}
    </>
  );
};

CardHeader.propTypes = {
  date: PropTypes.string.isRequired,
  isLocked: PropTypes.bool,
  isNew: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  showSteps: PropTypes.bool,
  title: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  victimName: PropTypes.string
};

CardHeader.defaultProps = {
  isLocked: false,
  isNew: false,
  options: [],
  showSteps: false,
  victimName: null
};

export default CardHeader;
