import React from "react";
import { useSelector } from "react-redux";
import { HashLink } from "react-router-hash-link";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Config
import { externalRoutes } from "../../../config/routes";
import styles from "../../../config/styles";

// Components
import Button from "../../../components/Button";
import Link from "../../../components/Link";
import Text from "../../../components/Text";

const useStyles = makeStyles(theme => ({
  nav: {
    display: "flex",
    flexDirection: "column"
  },
  sectionTitle: {
    padding: theme.spacing(2, 2),
    textTransform: "uppercase"
  },
  navLink: {
    display: "block",
    color: "initial",
    padding: theme.spacing(2, 2)
  },
  activeLink: {
    fontWeight: theme.typography.fontWeightBold
  }
}));

const onScroll = el => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -styles.values.headerHeight;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

const SettingsNav = () => {
  const classes = useStyles();
  const { userDetails } = useSelector(s => s.user);

  return (
    <nav className={classes.nav}>
      <Text
        className={classes.sectionTitle}
        text="settingsPage.title"
        type="contentLabel"
      />
      <Button
        className={classes.navLink}
        component={HashLink}
        smooth
        scroll={onScroll}
        to="#userinfo"
      >
        <Text text="settingsPage.profile.title" type="subtitle2" />
      </Button>
      <Button
        className={classes.navLink}
        component={HashLink}
        smooth
        scroll={onScroll}
        to="#notifications"
      >
        <Text text="settingsPage.notifications.title" type="subtitle2" />
      </Button>
      <Button
        className={classes.navLink}
        component={HashLink}
        smooth
        scroll={onScroll}
        to="#localization"
      >
        <Text text="settingsPage.localization.title" type="subtitle2" />
      </Button>
      <Text
        className={classes.sectionTitle}
        text="settingsPage.legal"
        type="contentLabel"
      />
      <Button
        className={classes.navLink}
        component={Link}
        disabled={!userDetails}
        target="_blank"
        to={(userDetails && externalRoutes.terms(userDetails.userType)) || ""}
      >
        <Text text="settingsPage.terms" type="subtitle2" />
      </Button>
      <Button
        className={classes.navLink}
        component={Link}
        disabled={!userDetails}
        target="_blank"
        to={(userDetails && externalRoutes.privacy(userDetails.userType)) || ""}
      >
        <Text text="settingsPage.privacy" type="subtitle2" />
      </Button>
    </nav>
  );
};

export default SettingsNav;
