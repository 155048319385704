import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";

// Assets
import lockIcon from "../../assets/icons/lockIcon.svg";

// Config
import {
  apiVersion,
  downloadReportUrl,
  getDownloadReportUrl
} from "../../config/apiConfig";
import { externalRoutes, urls } from "../../config/routes";
import colors from "../../config/colors";
import styles from "../../config/styles";

// Hooks
import useTranslate from "../../hooks/useTranslate";
import { useAppInsights } from "../../utils/telemetry/AppInsights";

// Components
import Button from "../../components/Button";
import ContentCardFooter from "../../components/ContentCardFooter";
import ContentCardHeader from "../../components/ContentCardHeader";
import FooterDisclaimer from "../../components/FooterDisclaimer";
import GoBackLink from "../../components/GoBackLink";
import Link from "../../components/Link";
import LoadingSection from "../../components/LoadingSection";
import MainSection from "../../components/MainSection";
import Text from "../../components/Text";

const useStyles = makeStyles(theme => ({
  root: {
    width: 730,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    paddingBottom: theme.spacing(5)
  },
  paper: {
    border: styles.values.border
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(5, 4, 12)
  },
  icon: {
    marginBottom: theme.spacing(2)
  },
  caseNumber: {
    padding: theme.spacing(1.5, 0),
    color: colors.pictonBlue
  },
  instructions: {
    padding: theme.spacing(1.5, 0),
    width: 500,
    lineHeight: 1
  },
  bold: {
    fontFamily: ["Gibson SemiBold", "sans-serif"].join(",")
  },
  form: {
    display: "flex",
    flexDirection: "column"
  },
  label: {
    marginBottom: theme.spacing(1)
  },
  contactLink: {
    color: colors.riverBed,
    "&:hover": {
      textDecoration: "underline"
    }
  },
  textField: {
    width: 430,
    marginBottom: theme.spacing(1.75)
  },
  inputRoot: styles.mixins.inputRoot,
  input: styles.mixins.input,
  focused: {
    borderColor: theme.palette.primary.main
  },
  error: {
    borderColor: theme.palette.secondary.main
  },
  helperText: {
    color: `${theme.palette.secondary.main} !important`
  },
  submitButton: {
    width: 227
  },
  link: {
    ...styles.mixins.link,
    textTransform: "lowercase"
  }
}));

const headers = {
  "api-version": apiVersion
};

const DownloadReportPage = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [psw, setPsw] = useState("");
  const [pswError, setPswError] = useState(false);
  const [expiredError, setExpiredError] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const { reportId } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const getText = useTranslate();
  const { trackException } = useAppInsights();

  useEffect(() => {
    const fetchurl = getDownloadReportUrl(reportId);
    fetch(fetchurl, { headers })
      .then(r => r.json())
      .then(res => {
        setData(res);
        setLoading(false);
      })
      .catch(() => {
        trackException(`Failed to get source: ${fetchurl}`);
      });
  }, [reportId, setData, trackException]);

  useEffect(() => {
    if (data ? data.status === 404 : !loading) history.push(urls.notFound);
  }, [loading, data]);

  const handleChange = e => {
    setPswError(false);
    setPsw(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setRequesting(true);
    const fetchurl = downloadReportUrl(reportId);
    fetch(fetchurl, {
      headers: { ...headers, "report-password": psw },
      redirect: "manual"
    })
      .then(r => {
        if (r.status === 401 || r.status === 400) setPswError(true);
        return r.json();
      })
      .then(resData => {
        if (resData.Expired) setExpiredError(true);
        if (resData.url) window.open(resData.url, "_self");
        setRequesting(false);
      })
      .catch(() => {
        setRequesting(false);
        trackException(`Failed to get source: ${fetchurl}`);
      });
  };

  if (loading || !data) return <LoadingSection />;

  return (
    <MainSection noStatus>
      <div className={classes.root}>
        <GoBackLink text="downloadReportPage.backLink" />
        <Paper elevation={0} className={classes.paper}>
          <ContentCardHeader>
            <Text
              className={classes.title}
              text="downloadReportPage.title"
              type="heading2"
            />
          </ContentCardHeader>
          <div className={classes.contentContainer}>
            <img className={classes.icon} src={lockIcon} alt="" />
            <Text type="subtitle1Small">
              {getText("downloadReportPage.requestNumber", {
                1: data.requestNumber
              })}
            </Text>
            <Text type="heading1" className={classes.caseNumber}>
              {getText("downloadReportPage.caseNumber", {
                1: data.caseNumber
              })}
            </Text>
            <Text
              type="subtitle1Small"
              text="downloadReportPage.sharedBy"
              values={{
                1: (
                  <span className={classes.bold}>
                    {data.senderName} (
                    {getText(`common.userTypes.${data.senderCapacity}`)})
                  </span>
                ),
                isComponent: true
              }}
            />
            <Text
              className={classes.instructions}
              text="downloadReportPage.instructions"
              type="heading2"
            />
            <form className={classes.form} onSubmit={handleSubmit}>
              <Text
                className={classes.label}
                text="downloadReportPage.passwordLabel"
                type="body2"
                values={{
                  1: (
                    <Link
                      className={classes.contactLink}
                      target="_blank"
                      to={externalRoutes.contact}
                    >
                      {getText("downloadReportPage.contactSupportLink")}
                    </Link>
                  ),
                  isComponent: true
                }}
              />
              <TextField
                error={pswError}
                className={classes.textField}
                FormHelperTextProps={{ classes: { root: classes.helperText } }}
                InputProps={{
                  classes: {
                    root: classes.inputRoot,
                    input: classes.input,
                    focused: classes.focused,
                    error: classes.error
                  },
                  disabled: requesting,
                  disableUnderline: true,
                  placeholder: "******",
                  required: true,
                  type: "password",
                  value: psw
                }}
                helperText={
                  (expiredError &&
                    getText("downloadReportPage.reportExpiredError")) ||
                  (pswError && getText("downloadReportPage.passwordError"))
                }
                onChange={handleChange}
              />
              <Button
                color="primary"
                disabled={requesting}
                disableElevation
                type="submit"
                variant="contained"
                className={classes.submitButton}
              >
                {getText("downloadReportPage.downloadFileButton")}
              </Button>
            </form>
          </div>
          <ContentCardFooter>
            <Text
              type="subtitle1Small"
              align="center"
              text="downloadReportPage.havingIssues"
              values={{
                1: (
                  <Link
                    className={classes.link}
                    target="_blank"
                    to={externalRoutes.contact}
                  >
                    {getText("downloadReportPage.contactSupportLink")}
                  </Link>
                ),
                isComponent: true
              }}
            />
          </ContentCardFooter>
        </Paper>
      </div>
      <FooterDisclaimer />
    </MainSection>
  );
};

export default DownloadReportPage;
