import {
  REQUEST_NOTES_SUCCESS,
  REQUEST_NOTES,
  REQUEST_NOTES_FAIL,
  REQUEST_CREATE_NOTE_SUCCESS,
  REQUEST_CREATE_NOTE,
  REQUEST_CREATE_NOTE_FAIL,
  REQUEST_DELETE_NOTE_SUCCESS,
  REQUEST_DELETE_NOTE,
  REQUEST_DELETE_NOTE_FAIL,
  REQUEST_PUBLISH_NOTE_SUCCESS,
  REQUEST_PUBLISH_NOTE,
  REQUEST_PUBLISH_NOTE_FAIL,
  REQUEST_UPDATE_NOTE,
  REQUEST_UPDATE_NOTE_SUCCESS,
  REQUEST_UPDATE_NOTE_FAIL
} from "./actionTypes";

import {
  getNotesDetailUrl,
  getNotesUrl,
  submitNoteUrl
} from "../../config/apiConfig";
import { parseFetchOptions } from "../user/actions";
import errors from "../../utils/errors";

// Data
const requestNotes = payload => ({
  type: REQUEST_NOTES,
  payload
});

const requestNotesSuccess = payload => ({
  type: REQUEST_NOTES_SUCCESS,
  payload
});

const requestNotesFail = payload => ({
  type: REQUEST_NOTES_FAIL,
  payload
});

export const getNotes =
  ({ caseId, onFinish }) =>
  dispatch => {
    dispatch(requestNotes(caseId));
    const handleFinish = status => {
      if (onFinish) onFinish(status);
    };

    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(getNotesUrl(caseId), {
          method: "GET",
          headers: options.headers
        }).catch(error => {
          handleFinish(false);
          dispatch(requestNotesFail(error));
        });

        if (response && response.ok) {
          const data = await response.json();
          dispatch(requestNotesSuccess(data));
        } else {
          handleFinish(false);
          dispatch(requestNotesFail(errors.responseError(response)));
        }
      })
    );
  };

// Create Note
const requestCreateNote = () => ({
  type: REQUEST_CREATE_NOTE
});

const requestCreateNoteSuccess = payload => ({
  type: REQUEST_CREATE_NOTE_SUCCESS,
  payload
});

const requestCreateNoteFail = payload => ({
  type: REQUEST_CREATE_NOTE_FAIL,
  payload
});

export const createNote =
  ({ caseId, body, onFinish }) =>
  async dispatch => {
    dispatch(requestCreateNote());
    const handleFinish = (status, data) => {
      if (onFinish) onFinish(status, data);
    };

    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(getNotesUrl(caseId), {
          method: "POST",
          headers: {
            ...options.headers,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            noteType: "Note",
            ...body
          })
        }).catch(error => {
          dispatch(requestCreateNoteFail(error));
          handleFinish(false);
        });

        if (response && response.ok) {
          const resData = await response.json();
          dispatch(requestCreateNoteSuccess(resData));
          handleFinish(true, resData);
        } else {
          dispatch(requestCreateNoteFail(errors.responseError(response)));
          handleFinish(false);
        }
      })
    );
  };

// Delete Note
const requestDeleteNote = payload => ({
  type: REQUEST_DELETE_NOTE,
  payload
});

const requestDeleteNoteSuccess = payload => ({
  type: REQUEST_DELETE_NOTE_SUCCESS,
  payload
});

const requestDeleteNoteFail = payload => ({
  type: REQUEST_DELETE_NOTE_FAIL,
  payload
});

export const deleteNote =
  ({ caseId, itemId, onFail }) =>
  async dispatch => {
    dispatch(requestDeleteNote());

    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(getNotesDetailUrl(caseId, itemId), {
          method: "DELETE",
          headers: options.headers
        }).catch(error => {
          dispatch(requestDeleteNoteFail(error));
          onFail();
        });

        if (response && response.ok) {
          dispatch(requestDeleteNoteSuccess(itemId));
        } else {
          dispatch(requestDeleteNoteFail(errors.responseError(response)));
          onFail();
        }
      })
    );
  };

// Publish Note
const requestPublishNote = payload => ({
  type: REQUEST_PUBLISH_NOTE,
  payload
});

const requestPublishNoteSuccess = payload => ({
  type: REQUEST_PUBLISH_NOTE_SUCCESS,
  payload
});

const requestPublishNoteFail = payload => ({
  type: REQUEST_PUBLISH_NOTE_FAIL,
  payload
});

export const publishNote =
  ({ caseId, itemId, onFail, onSuccess }) =>
  async dispatch => {
    dispatch(requestPublishNote());

    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(submitNoteUrl(caseId, itemId), {
          method: "PUT",
          headers: options.headers
        }).catch(error => {
          dispatch(requestPublishNoteFail(error));
          onFail();
        });

        if (response && response.ok) {
          dispatch(requestPublishNoteSuccess(itemId));
          if (onSuccess) onSuccess();
        } else {
          dispatch(requestPublishNoteFail(errors.responseError(response)));
          onFail();
        }
      })
    );
  };

// Update Note
const requestUpdateNote = payload => ({
  type: REQUEST_UPDATE_NOTE,
  payload
});

const requestUpdateNoteSuccess = payload => ({
  type: REQUEST_UPDATE_NOTE_SUCCESS,
  payload
});

const requestUpdateNoteFail = payload => ({
  type: REQUEST_UPDATE_NOTE_FAIL,
  payload
});

export const updateNote =
  ({ caseId, itemId, body, onFinish }) =>
  async dispatch => {
    dispatch(requestUpdateNote());

    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(getNotesDetailUrl(caseId, itemId), {
          method: "PUT",
          headers: {
            ...options.headers,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(body)
        }).catch(error => {
          dispatch(requestUpdateNoteFail(error));
          onFinish(false);
        });

        if (response && response.ok) {
          const resData = await response.json();
          dispatch(requestUpdateNoteSuccess(resData));
          onFinish(true, resData);
        } else {
          dispatch(requestUpdateNoteFail(errors.responseError(response)));
          onFinish(false);
        }
      })
    );
  };
