import React from "react";

// Components
import FileUploadContent from "./FileUploadContent";
import FileUploadProvider from "./FileUploadProvider";

const FileUpload = props => (
  <FileUploadProvider {...props}>
    <FileUploadContent />
  </FileUploadProvider>
);

export default FileUpload;
