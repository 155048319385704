import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";

// Icons
import editIcon from "../../assets/icons/edit.svg";
import trashIcon from "../../assets/icons/trash.svg";

// Actions
import { deleteInjury } from "../../state/injuries/actions";

// Config
import { getRoute, urls } from "../../config/routes";
import { sensitiveSections } from "../../config/values";
import styles from "../../config/styles";

// Hooks
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import useHideToWitnesses from "../../hooks/useHideToWitnesses";
import useInjuries from "../../hooks/useInjuries";
import useTranslate from "../../hooks/useTranslate";

// Components
import BodyMap from "../BodyMap";
import ConfirmationButton from "../ConfirmationButton";
import ContentCardHeader from "../ContentCardHeader";
import DescriptionItem from "./DescriptionItem";
import ImagesBox from "./ImagesBox";
import InjuryTreatment from "./InjuryTreatment";
import LoadingSection from "../LoadingSection";
import Text from "../Text";

const columnStyle = {
  display: "flex",
  flexDirection: "column"
};

const useStyles = makeStyles(theme => ({
  data: {
    border: styles.values.border
  },
  title: {
    fontSize: 24
  },
  iconButton: {
    padding: 0,
    height: 40,
    width: 40,
    marginLeft: theme.spacing(1.5)
  },
  content: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(4, 3.5)
  },
  leftColumn: {
    ...columnStyle,
    marginRight: theme.spacing(4),
    width: "100%"
  },
  rightColumn: {
    ...columnStyle,
    width: 256,
    minWidth: 256
  },
  rightTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  }
}));

const InjuryDetailPanel = ({ caseId, injuryId }) => {
  const { data: caseDetail } = useSelector(s => s.caseDetail);
  const { trackException } = useAppInsights();
  const classes = useStyles();
  const dispatch = useDispatch();
  const getText = useTranslate();
  const history = useHistory();

  const {
    data,
    imageHeaders,
    isDeletingInjury,
    isLoading,
    loadingHeaders,
    error
  } = useInjuries(caseId, injuryId);

  const { descriptions, treatments } = data || {};

  useHideToWitnesses(caseDetail);

  if (isLoading || !data) {
    if (error) history.push(getRoute(urls.caseDetail, { caseId }));
    return (
      <Paper elevation={0} className={classes.data}>
        <LoadingSection />
      </Paper>
    );
  }

  const getImageRoute = index =>
    getRoute(urls.annotationTool, {
      caseId,
      injuryId,
      index
    });

  const handleDeleteInjury = () => {
    dispatch(
      deleteInjury({
        caseId,
        injuryId,
        onFinish: success => {
          if (success) history.push(getRoute(urls.caseDetail, { caseId }));
          else
            trackException(
              `Failed to delete injury: ${injuryId} at case: ${caseId}`
            );
        }
      })
    );
  };

  const isSensitive = sensitiveSections.includes(data.bodySection);

  return (
    <Paper elevation={0} className={classes.data}>
      <ContentCardHeader>
        <Text type="heading2" className={classes.title}>
          {getText(`common.bodySection.${data.bodySection}`)}{" "}
          {getText(`common.injuryCategory.${data.category}.shortName`)}
        </Text>
        <div>
          <IconButton
            className={classes.iconButton}
            onClick={() =>
              history.push(getRoute(urls.editInjury, { caseId, injuryId }))
            }
          >
            <img src={editIcon} alt="" />
          </IconButton>
          {caseDetail.isDraft && (
            <ConfirmationButton
              className={classes.iconButton}
              type="icon"
              onClick={handleDeleteInjury}
              dialog={{
                isLoading: isDeletingInjury,
                texts: [
                  { key: 1, text: "injuryDetailPage.deleteButtonDialog.title" },
                  {
                    key: 2,
                    text: "injuryDetailPage.deleteButtonDialog.description"
                  }
                ]
              }}
            >
              <img src={trashIcon} alt="" />
            </ConfirmationButton>
          )}
        </div>
      </ContentCardHeader>
      <div className={classes.content}>
        <div className={classes.leftColumn}>
          {data.descriptions.length > 0 && !caseDetail.isDraft && (
            <DescriptionItem
              content={data.descriptions}
              title="common.valueLabels.description"
            />
          )}
          <InjuryTreatment
            caseId={caseId}
            injuryId={injuryId}
            value={
              caseDetail.isDraft ? descriptions[0] && descriptions[0].text : ""
            }
            isDraft={caseDetail.isDraft}
            name="description"
            btnText="caseIncidentsPage.descriptionAddButton"
            title={`${getText("common.valueLabels.description")} ${
              caseDetail.isDraft ? 1 : descriptions.length + 1
            }`}
          />
          {data.treatments.length > 0 && !caseDetail.isDraft && (
            <DescriptionItem
              content={data.treatments}
              title="common.valueLabels.givenTreatment"
            />
          )}
          <InjuryTreatment
            caseId={caseId}
            injuryId={injuryId}
            value={
              caseDetail.isDraft ? treatments[0] && treatments[0].text : ""
            }
            isDraft={caseDetail.isDraft}
            name="treatment"
            btnText="editInjuryPage.treatmentInputAddButton"
            title={`${getText("common.valueLabels.treatment")} ${
              caseDetail.isDraft ? 1 : treatments.length + 1
            }`}
          />
        </div>
        <div className={classes.rightColumn}>
          {data.images && (
            <ImagesBox
              imageHeaders={imageHeaders}
              imageRequest={{ caseId, injuryId }}
              images={data.images}
              isSensitive={isSensitive}
              loadingHeaders={loadingHeaders}
              to={getImageRoute}
            />
          )}
          {data.bodySection && (
            <>
              <Text
                className={classes.rightTitle}
                text="common.valueLabels.bodyMap"
                type="body2"
              />
              <BodyMap partId={data.bodySection} />
            </>
          )}
        </div>
      </div>
    </Paper>
  );
};

InjuryDetailPanel.propTypes = {
  caseId: PropTypes.string.isRequired,
  injuryId: PropTypes.string.isRequired
};

export default InjuryDetailPanel;
