import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

// Hooks
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import useConnection from "../../hooks/useConnection";

// Actions
import { getMessages } from "../../state/infoRequests/actions";

// Components
import ChatInput from "./ChatInput";
import InfoMessages from "./InfoMessages";
import LoadingSection from "../LoadingSection";
import UserTyping from "./UserTyping";
import { signalRChatUrl } from "../../config/apiConfig";

const getTimeStamp = (arr1, arr2) => {
  if (arr1.length > 0) return arr1[arr1.length - 1].creationDateTime;
  if (arr2.length > 0) return arr2[arr2.length - 1].creationDateTime;
  return null;
};

const Chat = ({ isLoading, data, infoRequestId, parties }) => {
  const [currentMessages, setCurrentMessages] = useState([]);
  const [typing, setTyping] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const { caseId } = useParams();
  const { trackException } = useAppInsights();
  const { userDetails } = useSelector(s => s.user);

  const dispatch = useDispatch();

  const [connection, initialized] = useConnection({
    updateFunctions: true,
    url: signalRChatUrl,
    infoRequestId,
    functions: {
      receiveMessage: rest => setCurrentMessages([...currentMessages, rest]),
      typing: r => {
        if (timeoutId) clearTimeout(timeoutId);
        setTyping(r);
        setTimeoutId(setTimeout(() => setTyping(null), 2000));
      }
    }
  });

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      dispatch(
        getMessages({
          caseId,
          infoRequestId,
          onFail: () =>
            trackException(
              `Failed to get message from case: ${caseId} at info request: ${infoRequestId}`
            )
        })
      );
    }

    return () => {
      setCurrentMessages([]);
      mounted = false;
    };
  }, [caseId, dispatch, infoRequestId, trackException]);

  useEffect(() => {
    let mounted = true;
    const messageTimestamp = getTimeStamp(currentMessages, data);
    if (mounted && connection && messageTimestamp) {
      connection.invoke("messageReceived", {
        messageTimestamp,
        infoRequestId
      });
    }
    return () => {
      mounted = false;
    };
  }, [connection, currentMessages, data, infoRequestId]);

  const handleTyping = () => {
    connection.invoke("typing", { infoRequestId });
  };

  const handleSendMessage = text => {
    connection.invoke("sendMessage", {
      infoRequestId,
      text
    });
  };

  const policeInChat = useMemo(() => {
    let res = false;
    if (parties) {
      parties.forEach(({ id, capacity }) => {
        if (
          userDetails &&
          id !== userDetails.id &&
          capacity === "PoliceOfficer"
        )
          res = true;
      });
    }
    return res;
  }, [parties, userDetails]);

  return isLoading || !userDetails ? (
    <LoadingSection normalHeight noBackground />
  ) : (
    <>
      <InfoMessages
        data={[...data, ...currentMessages]}
        policeInChat={policeInChat}
        userDetails={userDetails}
      />
      {connection && initialized && (
        <div>
          {typing && <UserTyping partyId={typing.partyId} />}
          <ChatInput
            onType={handleTyping}
            infoRequestId={infoRequestId}
            onSend={handleSendMessage}
          />
        </div>
      )}
    </>
  );
};

Chat.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  infoRequestId: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  parties: PropTypes.arrayOf(
    PropTypes.shape({
      capacity: PropTypes.string
    })
  ).isRequired
};

Chat.defaultProps = {
  infoRequestId: false
};

export default Chat;
