import React from "react";
import { useDispatch, useSelector } from "react-redux";

// Material
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

// Actions
import { sendStoreMessage } from "../../state/actions/actions";

// Config
import { devices } from "../../config/values";
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import styles from "../../config/styles";
import useTranslate from "../../hooks/useTranslate";

// Components
import Button from "../Button";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  deviceSelection: {
    padding: theme.spacing(2.5, 2.5, 3.5),
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between"
  },
  selectContainer: {
    width: "70%",
    padding: theme.spacing(0, 0.5, 0, 0)
  },
  inputLabel: {
    marginBottom: theme.spacing(1)
  },
  select: {
    padding: theme.spacing(0, 4, 0, 2.5),
    height: 34,
    display: "flex",
    alignItems: "center",
    border: styles.values.border,
    borderRadius: theme.shape.borderRadius,
    fontSize: 12
  },
  button: {
    width: "30%",
    height: 36,
    padding: 0
  }
}));

const SelectDevice = () => {
  const [device, setDevice] = React.useState(devices.ANDROID);
  const { isLoading, success } = useSelector(state => state.actions);
  const { trackException } = useAppInsights();
  const classes = useStyles();
  const dispatch = useDispatch();
  const getText = useTranslate();

  const handleGetLink = () =>
    dispatch(
      sendStoreMessage({
        platform: device,
        onFail: () => trackException(`Fail to get appstore link for ${device}`)
      })
    );

  const handleSelectDevice = event => setDevice(event.target.value);

  return success ? null : (
    <div className={classes.deviceSelection}>
      <FormControl className={classes.selectContainer}>
        <Text
          className={classes.inputLabel}
          text="caseMenu.getLinkLabel"
          type="valueLabel"
        />
        <Select
          classes={{ select: classes.select }}
          onChange={handleSelectDevice}
          value={device}
          disableUnderline
        >
          <MenuItem value={devices.ANDROID}>
            {getText("common.os.android")}
          </MenuItem>
          <MenuItem value={devices.IOS}>{getText("common.os.ios")}</MenuItem>
        </Select>
      </FormControl>
      <Button
        className={classes.button}
        color="primary"
        disableElevation
        regular
        variant="contained"
        onClick={handleGetLink}
        disabled={isLoading}
      >
        {getText("caseMenu.getLinkButton")}
      </Button>
    </div>
  );
};

export default SelectDevice;
