import React from "react";
import { useDispatch } from "react-redux";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Config
import { getMeUrl } from "../../../config/apiConfig";
import useTranslate from "../../../hooks/useTranslate";

// Actions
import { parseFetchOptions } from "../../../state/user/actions";

// Components
import ConfirmationButton from "../../../components/ConfirmationButton";

const useStyles = makeStyles({
  root: {
    width: "100%",
    borderRadius: 12,
    textTransform: "uppercase",
    fontSize: 15
  }
});

const DeleteAccount = () => {
  const classes = useStyles();
  const getText = useTranslate();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = React.useState(false);

  const handleDeleteAccount = () => {
    setLoading(true);

    dispatch(
      parseFetchOptions(options => {
        fetch(getMeUrl, {
          method: "DELETE",
          ...options
        }).then(() => {
          setLoading(false);
        });
      })
    );
  };

  return (
    <ConfirmationButton
      className={classes.root}
      onClick={handleDeleteAccount}
      variant="outlined"
      dialog={{
        isLoading,
        title: getText("settingsPage.deleteAccount.title"),
        textKeys: ["settingsPage.deleteAccount.description"]
      }}
    >
      {getText("settingsPage.deleteAccount.button")}
    </ConfirmationButton>
  );
};

export default DeleteAccount;
