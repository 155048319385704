import {
  REQUEST_CASE_INCIDENT,
  REQUEST_CASE_INCIDENT_SUCCESS,
  REQUEST_DEL_ELEMENT,
  REQUEST_DEL_ELEMENT_SUCCESS,
  REQUEST_DEL_ELEMENT_FAIL,
  REQUEST_UPDATE_CASE_INCIDENT_FAIL
} from "./actionTypes";
import {
  getCaseIncidentUrl,
  getIncidentElementUrl
} from "../../config/apiConfig";
import { parseFetchOptions } from "../user/actions";
import errors from "../../utils/errors";

const requestCaseIncident = () => ({
  type: REQUEST_CASE_INCIDENT
});

const requestCaseIncidentSucess = payload => ({
  type: REQUEST_CASE_INCIDENT_SUCCESS,
  payload
});

const requestUpdateCaseIncidentFail = payload => ({
  type: REQUEST_UPDATE_CASE_INCIDENT_FAIL,
  payload
});

export const fromStringToAddition = (text = "", author) => ({
  text,
  author,
  creationDateTime: new Date().toString()
});

const fromFormToIncident = ({ incident, author }) => {
  const getItemList = i =>
    i ? i.map(d => fromStringToAddition(d, author)) : [];

  return {
    ...incident,
    descriptions: getItemList(incident.descriptions),
    perpetrators: getItemList(incident.perpetrators),
    witnesses: getItemList(incident.witnesses)
  };
};

export const updateCaseIncident =
  ({ caseId, incident, author, onFinish }) =>
  async dispatch => {
    dispatch(requestCaseIncident());

    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(getCaseIncidentUrl(caseId), {
          method: "PATCH",
          headers: {
            ...options.headers,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(incident)
        }).catch(error => {
          dispatch(requestUpdateCaseIncidentFail(error));
          if (onFinish) onFinish(false);
        });

        if (response && response.ok) {
          dispatch(
            requestCaseIncidentSucess(fromFormToIncident({ incident, author }))
          );
          if (onFinish) onFinish(true);
        } else {
          dispatch(
            requestUpdateCaseIncidentFail(errors.responseError(response))
          );
          if (onFinish) onFinish(false);
        }
      })
    );
  };

const requestDeleteElement = () => ({
  type: REQUEST_DEL_ELEMENT
});

const requestDeleteElementSucess = payload => ({
  type: REQUEST_DEL_ELEMENT_SUCCESS,
  payload
});

const requestDeleteElementFail = () => ({
  type: REQUEST_DEL_ELEMENT_FAIL
});

export const deleteIncidentItem =
  ({ caseId, itemId, type, onFinish }) =>
  async dispatch => {
    dispatch(requestDeleteElement());

    const handleFinish = v => onFinish && onFinish(v);

    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(
          getIncidentElementUrl({ caseId, type, itemId }),
          { method: "DELETE", headers: options.headers }
        ).catch(error => {
          dispatch(requestDeleteElementFail(error));
          handleFinish(false);
        });

        if (response && response.ok) {
          dispatch(requestDeleteElementSucess({ type, itemId }));
          handleFinish(true);
        } else {
          dispatch(requestDeleteElementFail(errors.responseError(response)));
          handleFinish(false);
        }
      })
    );
  };
