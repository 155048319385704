import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

// Actions
import { setMsalState } from "../../state/user/actions";

// Config
import { userTypes } from "../../config/values";
import countries from "../../config/countries";

// Components
import LoadingSection from "../LoadingSection/LoadingSection";

const VictimFormHandler = ({
  children,
  isSelecting,
  setCoord,
  setFieldValue,
  setSelecting,
  setUserCapacity,
  userCapacity
}) => {
  const dispatch = useDispatch();
  const { userDetails, msalState, loadingDetails } = useSelector(
    state => state.user
  );

  React.useEffect(() => {
    if (!isSelecting && userCapacity === userTypes.Victim && userDetails) {
      const { birthDate, givenName, phoneNumber, surname } = userDetails;

      const country = countries.find(
        ({ code }) => code === phoneNumber.isoCountryCode
      );

      setFieldValue("givenName", givenName || "");
      setFieldValue("familyName", surname || "");
      setFieldValue("dateOfBirth", birthDate || "");
      setFieldValue("country", country || "");
      setFieldValue("number", phoneNumber.nationalNumber || "");
    }
  }, [userDetails, isSelecting, setFieldValue, userCapacity]);

  React.useEffect(() => {
    if (msalState && msalState.data) {
      const {
        coordinate,
        country,
        dateOfBirth,
        familyName,
        givenName,
        number,
        place,
        userCapacity: capacity
      } = msalState.data;

      const finalCountry = countries.find(({ code }) => code === country.code);

      setFieldValue("givenName", givenName);
      setFieldValue("familyName", familyName);
      setFieldValue("dateOfBirth", dateOfBirth);
      setFieldValue("place", place);
      setFieldValue("country", finalCountry);
      setFieldValue("number", number);
      setCoord({
        lat: () => coordinate.latitude,
        lng: () => coordinate.longitude
      });
      setUserCapacity(capacity);

      setSelecting(false);
      dispatch(setMsalState(null));
    }
  }, [
    dispatch,
    msalState,
    setCoord,
    setFieldValue,
    setSelecting,
    setUserCapacity
  ]);

  return userCapacity === userTypes.Victim && loadingDetails ? (
    <LoadingSection normalHeight />
  ) : (
    children
  );
};

VictimFormHandler.propTypes = {
  children: PropTypes.node.isRequired,
  isSelecting: PropTypes.bool.isRequired,
  setCoord: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setSelecting: PropTypes.func.isRequired,
  setUserCapacity: PropTypes.func.isRequired,
  userCapacity: PropTypes.string.isRequired
};

export default VictimFormHandler;
