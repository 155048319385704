import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";

// Config
import colors from "../../config/colors";

// Components
import Button from "../Button";
import CycleButton from "../CycleButton";

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(1),
    height: 40,
    width: 40,
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  selected: {
    backgroundColor: colors.regentGray,
    "&:hover": {
      backgroundColor: colors.regentGray,
      opacity: 0.5
    }
  },
  whiteIcon: {
    filter: "brightness(2)"
  },
  disabledButton: {
    backgroundColor: colors.mystic,

    "& img": {
      opacity: 0.5
    }
  },
  disabledIcon: {
    opacity: 0.5
  },
  divider: {
    marginLeft: theme.spacing(1),
    width: 1,
    height: 38,
    backgroundColor: colors.mystic
  }
}));

const getClassNames = (status, classes) => ({
  className: clsx(classes.button, {
    [classes.selected]: status
  }),
  iconClassName: clsx({
    [classes.whiteIcon]: status
  })
});

const isGridToggled = (steps, selectedMode) => {
  return Boolean(steps.find(({ current }) => selectedMode === current));
};

const ToolButton = ({
  item: { icon, isDisabled, key, steps, type },
  disabled,
  selectedMode,
  onClick
}) => {
  const classes = useStyles();
  if (type === "divider") return <div className={classes.divider} />;

  const disableButton = disabled || isDisabled;
  let Component;
  let props;
  let toggled;

  if (steps && steps.length > 0) {
    toggled = isGridToggled(steps, selectedMode);

    Component = CycleButton;
    props = ({ className }) => ({
      className,
      onClick,
      steps,
      toggled
    });
  } else {
    toggled = key === selectedMode;

    Component = Button;

    props = ({ iconClassName, className }) => {
      const children = <img src={icon} className={iconClassName} alt="" />;
      return {
        children,
        className,
        onClick: onClick(key)
      };
    };
  }

  return (
    <Component
      {...props(getClassNames(toggled, classes))}
      disabledClassName={classes.disabledButton}
      disabled={disableButton}
    />
  );
};

ToolButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    icon: PropTypes.node,
    isDisabled: PropTypes.bool,
    isMulti: PropTypes.bool,
    key: PropTypes.string,
    steps: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.string
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  selectedMode: PropTypes.string.isRequired
};

export default ToolButton;
