import {
  REQUEST_SEARCH_PLACES,
  REQUEST_SEARCH_PLACES_SUCCESS,
  REQUEST_SEARCH_PLACES_FAIL,
  REQUEST_SEARCH_PLACES_RESET
} from "./actionTypes";

import { getPlacesFuzzySearchUrl } from "../../config/apiConfig";
import { parseFetchOptions } from "../user/actions";
import errors from "../../utils/errors";

// Cases
const requestPlaces = () => ({
  type: REQUEST_SEARCH_PLACES
});

const requestPlacesSuccess = payload => ({
  type: REQUEST_SEARCH_PLACES_SUCCESS,
  payload
});

const requestPlacesFail = payload => ({
  type: REQUEST_SEARCH_PLACES_FAIL,
  payload
});

export const requestPlacesReset = () => ({
  type: REQUEST_SEARCH_PLACES_RESET
});

// eslint-disable-next-line import/prefer-default-export
export const searchPlaces =
  ({ query, latitude, longitude }) =>
  dispatch => {
    dispatch(requestPlaces());

    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(
          getPlacesFuzzySearchUrl(query, latitude, longitude),
          {
            method: "GET",
            headers: options.headers
          }
        ).catch(error => {
          dispatch(requestPlacesFail(error));
        });

        if (response && response.ok) {
          const data = await response.json();
          dispatch(requestPlacesSuccess(data));
        } else {
          dispatch(requestPlacesFail(errors.responseError(response)));
        }
      })
    );
  };
