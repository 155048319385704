import React from "react";
import PropTypes from "prop-types";
import format from "date-fns/format";

// Material
import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Tooltip from "@material-ui/core/Tooltip";

// Config
import { submissionStatus } from "../../config/values";
import styles from "../../config/styles";
import useTranslate from "../../hooks/useTranslate";

// Components
import Avatar from "../Avatar";
import StatusChip, { chipType } from "../StatusChip";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 5, 0)
  },
  summaryWrapper: {
    borderBottom: styles.values.border,
    paddingBottom: theme.spacing(1.5)
  },
  summaryTitle: {
    textTransform: "uppercase",
    marginBottom: theme.spacing(1.25)
  },
  summaryRow: {
    display: "flex",
    flexDirection: "row",
    margin: theme.spacing(1.25, 0),

    "&:last-child": {
      margin: 0
    }
  },
  value: {
    marginLeft: "auto"
  },
  chipWrapper: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "row",
    gap: 3
  },
  chip: {
    borderRadius: theme.shape.borderRadius,
    marginLeft: "auto",
    height: 20
  },
  avatar: {
    height: 26,
    width: 26,
    marginRight: theme.spacing(1),
    fontSize: 12
  }
}));

const CardSummary = ({
  patient,
  mediaItems,
  civilCase,
  criminalCase,
  users,
  incidentDate
}) => {
  const classes = useStyles();
  const getText = useTranslate();

  return (
    <CardContent className={classes.root}>
      <div className={classes.summaryWrapper}>
        <Text
          className={classes.summaryTitle}
          text="caseDetailPage.caseInfoCard.summaryTitle"
          type="contentLabel"
        />
        <div className={classes.summaryRow}>
          <Text text="common.userTypes.Victim" type="valueLabel" />
          <span className={classes.value}>{patient}</span>
        </div>
        <div className={classes.summaryRow}>
          <Text text="common.valueLabels.evidenceCaptured" type="valueLabel" />
          <span className={classes.value}>{mediaItems}</span>
        </div>
        <div className={classes.summaryRow}>
          <Text text="common.valueLabels.incidentDate" type="valueLabel" />
          <span className={classes.value}>
            {incidentDate
              ? format(
                  new Date(incidentDate),
                  getText("common.dateFormat.shortDateAndTime")
                )
              : getText("common.prompts.incomplete")}
          </span>
        </div>
        <div className={classes.summaryRow}>
          <Text text="common.valueLabels.submissionStatus" type="valueLabel" />
          {civilCase.status === submissionStatus.NONE &&
          criminalCase.status === submissionStatus.NONE ? (
            <span className={classes.value}>
              {getText("common.prompts.none")}
            </span>
          ) : (
            <div className={classes.chipWrapper}>
              <StatusChip data={criminalCase} type={chipType.POLICE} />
              <StatusChip data={civilCase} type={chipType.CIVIL} />
            </div>
          )}
        </div>
        <div className={classes.summaryRow}>
          {users.map(({ fullName, profilePictureURL, id }) => (
            <Tooltip title={fullName} key={id}>
              <Avatar className={classes.avatar} src={profilePictureURL}>
                {fullName.charAt(0)}
              </Avatar>
            </Tooltip>
          ))}
        </div>
      </div>
    </CardContent>
  );
};

CardSummary.propTypes = {
  civilCase: PropTypes.shape({
    status: PropTypes.string
  }).isRequired,
  criminalCase: PropTypes.shape({
    status: PropTypes.string
  }).isRequired,
  mediaItems: PropTypes.number.isRequired,
  patient: PropTypes.string,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string,
      id: PropTypes.string,
      profilePictureURL: PropTypes.string
    })
  ).isRequired,
  incidentDate: PropTypes.string
};

CardSummary.defaultProps = {
  patient: "",
  incidentDate: ""
};

export default CardSummary;
