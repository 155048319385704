import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Material
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ButtonBase from "@material-ui/core/ButtonBase";

// Icons
import backIcon from "../../assets/icons/icons-back.svg";
import helpMenuIcon from "../../assets/icons/help_menu_icn.svg";
import phonecallIcon from "../../assets/icons/phonecall.svg";
import witnessHelpIcon from "../../assets/icons/witness-help-icon.svg";
import policeIcon from "../../assets/icons/user-police-cross.svg";

// Config
import { externalRoutes } from "../../config/routes";
import { userTypes } from "../../config/values";
import colors from "../../config/colors";

// Hooks
import useEmergencyContacts from "../../hooks/useEmergencyContacts";
import useTranslate from "../../hooks/useTranslate";

// Components
import Button from "../Button";
import Link from "../Link";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4, 3),
    display: "flex",
    flexDirection: "column",
    maxWidth: 400
  },
  iconButton: {
    marginRight: "auto"
  },
  defaultIcon: {
    margin: "0 auto",
    width: 120
  },
  witnessIcon: {
    margin: "0 auto",
    width: 120,
    padding: theme.spacing(2)
  },
  title: {
    padding: theme.spacing(2, 0)
  },
  paragraph: {
    color: colors.lynch,
    marginBottom: theme.spacing(3),
    padding: theme.spacing(0, 2),
    opacity: 0.7,
    lineHeight: 1.5,
    "&:last-of-type": {
      marginBottom: theme.spacing(6)
    }
  },
  buttons: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  witnessButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(0, 3),
    fontFamily: ["Gibson SemiBold", "sans-serif"].join(","),
    borderColor: colors.shuttleGray,
    borderRadius: theme.shape.borderRadius,
    fontSize: 14,
    padding: theme.spacing(2),
    lineHeight: 1.2,
    textTransform: "inherit",
    minWidth: 50,
    color: colors.white,
    cursor: "initial",
    "&:first-child": {
      marginBottom: theme.spacing(2)
    }
  },
  phoneIcon: {
    filter: "brightness(0) invert(1)",
    marginRight: "1rem"
  },
  button: {
    margin: theme.spacing(0, 3),
    textTransform: "uppercase",
    cursor: "pointer"
  }
}));

const defaultParagraphs = ["createCasePage.safeSpace.p1"];
const witnessParagraphs = ["createCasePage.safeSpaceWitness.bodyText"];
const policeParagraphs = ["createCasePage.safeSpacePolice.bodyText"];

const SafeSpace = ({ userType, onCancel, onClick }) => {
  const { emergencyContact } = useEmergencyContacts();
  const classes = useStyles();
  const getText = useTranslate();

  const contents = {
    [userTypes.Witness]: {
      icon: witnessHelpIcon,
      iconClass: classes.witnessIcon,
      title: "createCasePage.safeSpaceWitness.title",
      paragraphs: witnessParagraphs,
      buttons: (
        <div className={classes.buttons}>
          <ButtonBase disableRipple className={classes.witnessButton}>
            <img className={classes.phoneIcon} src={phonecallIcon} alt="" />
            {emergencyContact &&
              getText("header.emergencyBanner.link", {
                1: emergencyContact.phoneNumber
              })}
          </ButtonBase>
        </div>
      )
    },
    PoliceOfficer: {
      icon: policeIcon,
      iconClass: classes.witnessIcon,
      title: "createCasePage.safeSpacePolice.title",
      paragraphs: policeParagraphs,
      buttons: (
        <div className={classes.buttons}>
          <ButtonBase
            component={Link}
            disableRipple
            className={clsx(classes.button, classes.witnessButton)}
            to={externalRoutes.kpcc}
          >
            {getText("createCasePage.safeSpacePolice.button")}
          </ButtonBase>
        </div>
      )
    },
    default: {
      icon: helpMenuIcon,
      iconClass: classes.defaultIcon,
      title: "createCasePage.safeSpace.title",
      paragraphs: defaultParagraphs,
      buttons: (
        <Button
          className={classes.button}
          color="primary"
          disableElevation
          onClick={onClick}
          variant="contained"
        >
          {getText("common.prompts.ok")}
        </Button>
      )
    }
  };

  const content = contents[userType] || contents.default;

  return (
    <div className={classes.root}>
      <IconButton className={classes.iconButton} onClick={onCancel}>
        <img src={backIcon} alt="" />
      </IconButton>
      <img alt="" className={content.iconClass} src={content.icon} />
      <Text
        align="center"
        className={classes.title}
        text={content.title}
        type="heading1"
      />
      {content.paragraphs.map(text => (
        <Text
          align="center"
          className={classes.paragraph}
          key={text}
          text={text}
          type="subtitle1Big"
        />
      ))}
      {content.buttons}
    </div>
  );
};

SafeSpace.propTypes = {
  userType: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
};

export default SafeSpace;
