import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// Actions
import {
  addMedia,
  resetMediaUploads,
  updateMediaProgress
} from "../../state/media/actions";

// Hooks
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import useTranslate from "../../hooks/useTranslate";

// Components
import Button from "../Button";
import FileUpload from "../FileUpload";

const AddMedia = ({ caseId, text }) => {
  const { trackException } = useAppInsights();
  const dispatch = useDispatch();
  const getText = useTranslate();
  const { uploadProgress, uploadErrors } = useSelector(state => state.media);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
    dispatch(resetMediaUploads());
  };

  const handleClose = () => setOpen(false);

  const handleProgress = index => progress => {
    dispatch(updateMediaProgress({ index, progress }));
  };

  const handleSubmit = files => {
    files.forEach((item, index) => {
      dispatch(
        addMedia({
          caseId,
          description: item.description,
          image: item.file,
          id: index,
          onUploadProgress: handleProgress(index),
          onFail: () => trackException(`Failed to add media to case: ${caseId}`)
        })
      );
    });
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="contained"
        color="primary"
        disableElevation
      >
        {getText(text || "mediaPage.addMediaButton")}
      </Button>
      <FileUpload
        onClose={handleClose}
        onSubmit={handleSubmit}
        open={open}
        uploadErrors={uploadErrors}
        uploadProgress={uploadProgress}
        multiple
      />
    </>
  );
};

AddMedia.defaultProps = {
  text: null
};

AddMedia.propTypes = {
  caseId: PropTypes.string.isRequired,
  text: PropTypes.string
};

export default AddMedia;
