import React from "react";
import { format } from "date-fns";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Config
import colors from "../../config/colors";
import styles from "../../config/styles";
import useTranslate from "../../hooks/useTranslate";

// Components
import Text from "../Text";
import LoadingSection from "../LoadingSection/LoadingSection";
import Avatar from "../Avatar/Avatar";

const useStyles = makeStyles(theme => ({
  tooltipContainer: {
    display: "flex",
    flexDirection: "column",
    minWidth: 350
  },
  tooltipTitleWrapper: {
    padding: theme.spacing(3),
    borderBottom: styles.values.border
  },
  tooltipContent: {
    padding: theme.spacing(3)
  },
  tooltipRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1.5),
    gap: theme.spacing(1)
  },
  tooltipStatus: {
    color: colors.riverBed,
    fontWeight: 700
  },
  tooltipValue: {
    color: colors.riverBed,
    marginLeft: "auto"
  },
  avatar: {
    height: 17,
    width: 17,
    backgroundColor: colors.pictonBlue
  }
}));

const CaseLogPopper = ({ loading, logs }) => {
  const classes = useStyles();
  const getText = useTranslate();

  return (
    <div className={classes.tooltipContainer}>
      {loading ? (
        <LoadingSection normalHeight noBackground />
      ) : (
        <>
          <div className={classes.tooltipTitleWrapper}>
            <Text
              type="subtitle1"
              text="caseMenu.caseActivity.auditLogSection.activitySectionTitle"
            />
          </div>
          <div className={classes.tooltipContent}>
            {logs.map(logItem => (
              <div className={classes.tooltipRow} key={logItem.id}>
                <Avatar
                  src={logItem.actorProfilePhotoURL}
                  className={classes.avatar}
                >
                  {logItem.actorDisplayName.charAt(0)}
                </Avatar>
                <Text type="contentLabel4" className={classes.tooltipStatus}>
                  {format(
                    new Date(logItem.dateTime),
                    getText("common.dateFormat.shortDateTime")
                  )}
                </Text>
                <Text type="valueLabel" className={classes.tooltipValue}>
                  {logItem.operationName}
                </Text>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

CaseLogPopper.propTypes = {
  logs: PropTypes.arrayOf(
    PropTypes.shape({
      actorDisplayName: PropTypes.string,
      actorProfilePhotoURL: PropTypes.string,
      dateTime: PropTypes.string,
      id: PropTypes.string,
      operationName: PropTypes.string
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired
};

export default CaseLogPopper;
