import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  coordinatesSet,
  getSupportLines
} from "../state/emergencyContact/actions";

const useEmergencyContacts = () => {
  const dispatch = useDispatch();
  // Reducer Data
  const { emergencyContact, coordinates, loading, error, ...rest } =
    useSelector(state => state.emergencyContact);

  useEffect(() => {
    let isMounted = true;
    if (!coordinates && !emergencyContact) {
      navigator.geolocation.getCurrentPosition(
        userLocation => {
          if (isMounted) dispatch(coordinatesSet(userLocation.coords));
        },
        () => {
          if (isMounted) dispatch(coordinatesSet({}));
        }
      );
    } else if (!emergencyContact && !loading && !error)
      dispatch(getSupportLines({ coordinates }));

    return () => {
      isMounted = false;
    };
  }, [dispatch, coordinates, emergencyContact, error, loading]);

  return { emergencyContact, coordinates, loading, error, dispatch, ...rest };
};

export default useEmergencyContacts;
