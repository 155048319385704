import React from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// Actions
import { toggleSnack } from "../../state/snackbar/actions";

// Config
import { snackbarTypes } from "../../config/snackbar";
import { submissionStatus } from "../../config/values";
import colors from "../../config/colors";
import useTranslate from "../../hooks/useTranslate";

// Icons
import clock from "../../assets/icons/clock.svg";
import copy from "../../assets/icons/copy.svg";
import okTick from "../../assets/icons/ok_tick.svg";

// Components
import LightTooltip from "../LightTooltip/LightTooltip";

export const chipType = {
  POLICE: "police",
  CIVIL: "civil"
};

const chipDefaultStyle = {
  borderRadius: "17px",
  height: 24,
  color: "white"
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "row",
    gap: 3
  },
  chip: {
    ...chipDefaultStyle
  },
  [chipType.POLICE]: {
    backgroundColor: theme.palette.primary.main
  },
  [chipType.CIVIL]: {
    backgroundColor: colors.pastelGreen
  },
  okTick: {
    filter: "brightness(100)",
    width: 14
  },
  tooltipWrapper: {
    padding: theme.spacing(1, 2)
  },
  tooltipContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1)
  },
  iconButton: {
    padding: 8
  },
  buttonIcon: {
    height: 14,
    width: 14
  }
}));

const renderIcon = {
  [submissionStatus.IN_PROGRESS]: clock,
  [submissionStatus.ACCEPTED]: okTick
};

const renderProps = {
  [chipType.POLICE]: {
    label: "casesPage.caseCard.labels.submissionStatus.policeCapsule",
    tooltipTitle: {
      [submissionStatus.IN_PROGRESS]:
        "caseMenu.caseSummary.referenceNumbersBanner.policeReference.pendingReview",
      [submissionStatus.ACCEPTED]:
        "caseMenu.caseSummary.referenceNumbersBanner.policeReference.numberAssigned"
    }
  },
  [chipType.CIVIL]: {
    label: "casesPage.caseCard.labels.submissionStatus.civilCapsule",
    tooltipTitle: {
      [submissionStatus.IN_PROGRESS]:
        "caseMenu.caseSummary.referenceNumbersBanner.civilCaseReference.pendingReview",
      [submissionStatus.ACCEPTED]:
        "caseMenu.caseSummary.referenceNumbersBanner.civilCaseReference.numberAssigned"
    }
  }
};

const getRenderProps = ({ status, referenceNumber }, type) => {
  const { label, tooltipTitle } = renderProps[type];
  return {
    label,
    tooltip: tooltipTitle[status],
    tooltipArgs: status === submissionStatus.ACCEPTED && {
      1: referenceNumber
    },
    icon: renderIcon[status]
  };
};

const StatusChip = ({ data, type }) => {
  const classes = useStyles();
  const getText = useTranslate();
  const dispatch = useDispatch();

  if (data.status === submissionStatus.NONE) return null;

  const { label, tooltip, tooltipArgs, icon } = getRenderProps(data, type);

  const handleClickCopy = () => {
    navigator.clipboard.writeText(data.referenceNumber);
    dispatch(
      toggleSnack({
        type: snackbarTypes.SUCCESS,
        text: "common.prompts.copiedToClipboard"
      })
    );
  };

  return (
    <LightTooltip
      arrow
      classes={{ tooltip: classes.tooltipWrapper }}
      interactive
      title={
        <div className={classes.tooltipContent}>
          <span>{getText(tooltip, tooltipArgs)}</span>
          {data.status === submissionStatus.ACCEPTED && (
            <Tooltip
              title={getText("common.prompts.copyReference")}
              placement="right"
            >
              <IconButton
                className={classes.iconButton}
                onClick={handleClickCopy}
              >
                <img className={classes.buttonIcon} src={copy} alt="" />
              </IconButton>
            </Tooltip>
          )}
        </div>
      }
    >
      <Chip
        className={clsx(classes.chip, {
          [classes[type]]: data.status === submissionStatus.ACCEPTED
        })}
        icon={<img className={classes.okTick} src={icon} alt="" />}
        label={getText(label)}
      />
    </LightTooltip>
  );
};

StatusChip.propTypes = {
  data: PropTypes.shape({
    status: PropTypes.string,
    referenceNumber: PropTypes.string
  }).isRequired,
  type: PropTypes.oneOf([chipType.POLICE, chipType.CIVIL]).isRequired
};

export default StatusChip;
