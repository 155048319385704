import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

// Config
import { getRoute, urls } from "../../config/routes";
import { useSensitiveImage } from "../../context/SensitiveImageProvider";

// Components
import { LeftArrow, RightArrow } from "../../components/Arrows";

const NavigationController = ({ children }) => {
  const [isNavigating, setNavigating] = useState(false);
  const { caseId, injuryId, imgIndex } = useParams();
  const { data } = useSelector(s => s.injuries);
  const { resetClickedThumbnail } = useSensitiveImage();
  const history = useHistory();

  useEffect(() => {
    if (isNavigating) setNavigating(false);
  }, [isNavigating]);

  const handleClick = i => () => {
    setNavigating(true);
    resetClickedThumbnail();
    history.push(
      getRoute(urls.annotationTool, {
        caseId,
        injuryId,
        index: i.toString()
      })
    );
  };

  return (
    data &&
    data.images && (
      <>
        {imgIndex > 0 && (
          <LeftArrow onClick={handleClick(parseInt(imgIndex, 10) - 1)} />
        )}
        {imgIndex < data.images.length - 1 && (
          <RightArrow onClick={handleClick(parseInt(imgIndex, 10) + 1)} />
        )}
        {!isNavigating && children}
      </>
    )
  );
};

NavigationController.propTypes = {
  children: PropTypes.node.isRequired
};

export default NavigationController;
