import React from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";

// Material
import { makeStyles } from "@material-ui/core/styles";
import CardActionArea from "@material-ui/core/CardActionArea";
import Collapse from "@material-ui/core/Collapse";
import AccordionSummary from "@material-ui/core/AccordionSummary";

// Config
import { getRoute, urls } from "../../config/routes";
import colors from "../../config/colors";
import styles from "../../config/styles";
import useTranslate from "../../hooks/useTranslate";

// Images
import chevron from "../../assets/icons/chevron.svg";

// Components
import Button from "../Button";
import InjuriesPlaceholder from "./InjuriesPlaceholder";
import InjuryItem from "../InjuryItem";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: styles.values.border
  },
  title: {
    color: colors.cloudBurst,
    textTransform: "capitalize"
  },
  injuries: {
    display: "flex",
    flexDirection: "column"
  },
  injuryItem: {
    padding: theme.spacing(2, 6.5, 2.5),
    position: "relative"
  },
  injuryActive: {
    "&:after": {
      content: `""`,
      backgroundColor: colors.dodgerBlue,
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      opacity: 0.1
    }
  },
  injuryType: {
    marginBottom: theme.spacing(1)
  },
  iconButton: {
    fontSize: 14,
    transform: ({ expanded }) => !expanded && "rotate(180deg)"
  },
  buttonAdd: {
    display: "flex",
    alignSelf: "flex-end",
    width: "auto",
    textTransform: "uppercase",
    fontWeight: "normal",
    padding: theme.spacing(1.5),
    color: colors.dodgerBlue,
    margin: theme.spacing(0.5, 1.5, 3)
  }
}));

const CollapseInjuries = ({ injuries, caseId }) => {
  const [expanded, setExpanded] = React.useState(true);
  const classes = useStyles({ expanded });
  const getText = useTranslate();

  const handleExpandClick = () => setExpanded(!expanded);

  return (
    <div className={classes.root}>
      <AccordionSummary
        classes={{ expandIcon: classes.iconButton }}
        expandIcon={<img src={chevron} alt="" className={classes.chevron} />}
        onClick={handleExpandClick}
        size="small"
      >
        <Text
          className={classes.title}
          text="common.units.injuriesCaptured"
          type="valueLabel"
          values={{ value: injuries ? injuries.length : 0 }}
        />
      </AccordionSummary>
      <Collapse in={expanded} timeout="auto">
        {injuries && injuries.length > 0 ? (
          <div className={classes.injuries}>
            {injuries.map(injury => (
              <CardActionArea
                component={NavLink}
                key={injury.id}
                to={getRoute(urls.injuryDetail, {
                  caseId,
                  injuryId: injury.id
                })}
                activeClassName={classes.injuryActive}
                className={classes.injuryItem}
              >
                <InjuryItem {...injury} />
              </CardActionArea>
            ))}
            <Button
              className={classes.buttonAdd}
              color="primary"
              component={Link}
              disableElevation
              regular
              to={getRoute(urls.addInjury, { caseId })}
            >
              {getText("caseMenu.injuries.addInjuryButton")}
            </Button>
          </div>
        ) : (
          <InjuriesPlaceholder
            text={getText("caseMenu.injuries.addInjuriesButton")}
          />
        )}
      </Collapse>
    </div>
  );
};

CollapseInjuries.propTypes = {
  injuries: PropTypes.arrayOf(PropTypes.shape({})),
  caseId: PropTypes.string
};

CollapseInjuries.defaultProps = {
  injuries: [],
  caseId: ""
};

export default CollapseInjuries;
