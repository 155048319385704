import React from "react";
import PropTypes from "prop-types";

// Components
import LogEntryRow from "./LogEntryRow";

const LogItemsHandler = ({ toggleDrawer, itemClasses, logs }) =>
  logs &&
  logs.map(item => (
    <LogEntryRow
      classes={itemClasses}
      item={item}
      key={item.id}
      onClick={toggleDrawer(item.id)}
    />
  ));

LogItemsHandler.propTypes = {
  itemClasses: PropTypes.shape({}).isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  logs: PropTypes.arrayOf(PropTypes.shape({}))
};

LogItemsHandler.defaultProps = {
  logs: []
};

const areEqual = (prevProps, nextProps) =>
  prevProps.logs &&
  nextProps.logs &&
  prevProps.logs.length === nextProps.logs.length;

export default React.memo(LogItemsHandler, areEqual);
