import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

// Material
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

// Config
import colors from "../../config/colors";
import styles from "../../config/styles";

// Actions
import { getImage } from "../../state/injuries/actions";

// Components
import AddInjuryImage from "../AddInjuryImage";
import Text from "../Text";
import ImageItemHandler from "../ImageItem/ImageItemHandler";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  heading: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1)
  },
  imagesBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between"
  },
  imageItem: {
    height: 100,
    width: 122,
    backgroundColor: colors.whisper,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: styles.values.border,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1)
  }
}));

const ImagesBox = ({
  imageHeaders,
  imageRequest,
  images,
  loadingHeaders,
  to,
  isSensitive
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = index => () => history.push(to(index));
  const updateImage = request => () =>
    dispatch(getImage({ ...request, ...imageRequest }));

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <Text text="editInjuryPage.imagesTitle" type="body2" />
        <AddInjuryImage images={images} />
      </div>
      <div className={classes.imagesBox}>
        {images.map((image, index) => (
          <ImageItemHandler
            className={classes.imageItem}
            image={image}
            key={image.filename}
            getImage={updateImage({
              filename: image.filename,
              index
            })}
            imageHeaders={imageHeaders}
            loadingHeaders={loadingHeaders}
            isSensitive={isSensitive}
            onClick={handleClick(index.toString())}
          />
        ))}
        <AddInjuryImage thumbnail images={images} />
      </div>
    </div>
  );
};

ImagesBox.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      filename: PropTypes.string
    })
  ).isRequired,
  imageHeaders: PropTypes.shape({}).isRequired,
  imageRequest: PropTypes.shape({}).isRequired,
  isSensitive: PropTypes.bool,
  loadingHeaders: PropTypes.shape({}).isRequired,
  to: PropTypes.func.isRequired
};

ImagesBox.defaultProps = {
  isSensitive: false
};

export default ImagesBox;
