export const getImageHeaders = async ({ url, onFail }) => {
  const res = await fetch(url, {
    method: "HEAD"
  }).catch(onFail);

  return res.headers;
};

export const generateFilename = (filename, status) => {
  const temp = new Date();
  return `${filename.split(".")[0]}-${status}-${temp.getTime()}.jpg`;
};

export const getPpcm = imageMeta =>
  imageMeta && imageMeta.score !== 0 ? imageMeta.ppcm_median : null;

export function ImageDataToBlob(imageData) {
  const w = imageData.width;
  const h = imageData.height;
  const canvas = document.createElement("canvas");
  canvas.width = w;
  canvas.height = h;
  const ctx = canvas.getContext("2d");
  ctx.putImageData(imageData, 0, 0);
  return new Promise(resolve => {
    canvas.toBlob(resolve, "image/jpeg", 0.95);
  });
}

export const imageTypes = {
  annotated: "Annotated",
  noGrid: "NoGrid",
  normalGrid: "NormalGrid",
  largeGrid: "LargeGrid"
};

export const isAnnotated = imageType => imageType === imageTypes.annotated;

export const isAnnotable = imageType =>
  [imageTypes.normalGrid, imageTypes.largeGrid].includes(imageType);

export const getFileExtension = filename => {
  const splitFilename = filename.toLowerCase().split(".");
  return splitFilename[splitFilename.length - 1];
};
