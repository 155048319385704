import React from "react";

// Components
import InviteContributorForm from "../../components/InviteContributorForm";
import SectionWrapper from "../../components/SectionWrapper";

const InviteContributorsPage = () => {
  return (
    <SectionWrapper>
      <InviteContributorForm />
    </SectionWrapper>
  );
};

export default InviteContributorsPage;
