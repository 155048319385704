import React from "react";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import debounce from "lodash-es/debounce";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Icons
import dropIcon from "../../../assets/icons/dropImage.svg";

// Config
import styles from "../../../config/styles";

// Components
import FileItem from "./FileItem";
import Text from "../../Text";

const useStyles = makeStyles(theme => ({
  dropImage: {
    width: 600,
    display: "flex",
    flexDirection: "column",
    border: styles.values.borderDashed,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(5),
    padding: theme.spacing(3),
    "&:focus": {
      outline: "none"
    }
  },
  imageDropIcon: {
    marginBottom: theme.spacing(3),
    width: 80
  },
  placeholder: {
    height: 450,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  }
}));

const FileInput = ({
  dropzoneProps,
  enableEdit,
  files,
  isUploading,
  noDescriptions,
  onRemoveItem,
  uploadErrors,
  onDrop,
  onChangeDescription,
  uploadProgress
}) => {
  const classes = useStyles();

  const handleDrop = React.useCallback(
    addedFiles => onDrop(addedFiles),
    [onDrop]
  );

  const handleChangeDescription = index =>
    debounce(e => {
      onChangeDescription({
        index,
        description: e.target.value
      });
    }, 300);

  const { getRootProps, getInputProps } = useDropzone({
    ...dropzoneProps,
    onDrop: handleDrop
  });

  if (files && files.length > 0) {
    return (
      <div className={classes.dropImage}>
        {files.map(({ file }, index) => (
          <FileItem
            animationMultiply={index}
            enableEdit={enableEdit}
            file={file}
            isUploading={isUploading}
            key={file.name}
            noDescriptions={noDescriptions}
            onChangeDescription={handleChangeDescription(index)}
            onRemove={onRemoveItem(index)}
            uploadError={uploadErrors && uploadErrors[index]}
            uploadProgress={uploadProgress ? uploadProgress[index] : 0}
          />
        ))}
      </div>
    );
  }

  return (
    <div className={classes.dropImage} {...getRootProps()}>
      <input {...getInputProps()} />
      <div className={classes.placeholder}>
        <img src={dropIcon} alt="" className={classes.imageDropIcon} />
        <Text text="fileUploadPage.dragDrop" type="valueLabel" />
      </div>
    </div>
  );
};

FileInput.propTypes = {
  dropzoneProps: PropTypes.shape({}).isRequired,
  enableEdit: PropTypes.bool.isRequired,
  files: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isUploading: PropTypes.bool.isRequired,
  noDescriptions: PropTypes.bool.isRequired,
  onChangeDescription: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  uploadErrors: PropTypes.shape({}),
  uploadProgress: PropTypes.shape({})
};

FileInput.defaultProps = {
  uploadErrors: {},
  uploadProgress: {}
};

export default FileInput;
