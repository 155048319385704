import React from "react";
import PropTypes from "prop-types";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";

// Configs
import styles from "../../../config/styles";

// Components
import CustomField from "../../../components/CustomField";
import LocationField from "../../../components/Input/LocationField";
import Text from "../../../components/Text";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    flex: "1 1"
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  body: {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2)
  },
  duration: {
    padding: theme.spacing(0, 4, 0, 2.5),
    height: 34,
    display: "flex",
    alignItems: "center",
    border: styles.values.border,
    borderRadius: theme.shape.borderRadius,
    fontSize: 15,
    minHeight: 46
  },
  valueLabel: {
    marginBottom: theme.spacing(1.5)
  }
}));

const IncidentInfo = ({
  contents,
  type,
  title,
  editing = true,
  error,
  ...rest
}) => {
  const classes = useStyles(Boolean(error));

  const field = editing
    ? {
        location: <LocationField {...rest} type={type} />,
        default: <CustomField {...rest} />
      }[type] || <CustomField {...rest} type={type} />
    : null;

  return (
    <div className={classes.root}>
      {editing ? (
        field
      ) : (
        <>
          <Text className={classes.title} type="heading4">
            {title}
          </Text>
          {contents &&
            contents.map(c => {
              const text = c || "";
              return (
                <Text
                  key={c}
                  className={classes.body}
                  type="contentLabel3"
                  {...rest}
                >
                  {text}
                </Text>
              );
            })}
        </>
      )}
      {error}
    </div>
  );
};

IncidentInfo.propTypes = {
  title: PropTypes.string,
  contents: PropTypes.arrayOf(PropTypes.string),
  editing: PropTypes.bool,
  type: PropTypes.string,
  error: PropTypes.node
};

IncidentInfo.defaultProps = {
  type: "default",
  contents: [],
  editing: false,
  title: "",
  error: null
};

export default React.memo(IncidentInfo);
