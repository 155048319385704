export const snackbarTypes = {
  // Common
  DELETING: "deleting",
  ERROR: "error",
  LOADING: "loading",
  SUCCESS: "success",
  UPLOADING: "uploading",

  // Case
  ANNOTATION_SAVED: "annotationSaved",
  INCIDENT_SAVED: "incidentSaved",
  CASE_CREATED: "caseCreated",
  CASE_DELETED: "caseDeleted",
  CASE_NOT_FOUND: "caseNotFound",
  CONTRIBUTOR_DELETED: "contributorDeleted",

  // Injuries
  INJURY_DELETED: "injuryDeleted",

  // Requests
  INVITATION_SENT: "invitationSent",
  SENDING_MESSAGE: "sendSms",
  SMS_SENT: "smsSent",
  REQUEST_SENT: "requestReportSent",

  // Media
  MEDIA_DELETED: "mediaDeleted"
};

export const dummyExport = null;
