import React from "react";
import PropTypes from "prop-types";
import get from "lodash-es/get";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Config
import colors from "../../../config/colors";
import useTranslate from "../../../hooks/useTranslate";

// Components
import Link from "../../../components/Link";
import Text from "../../../components/Text";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(4)
  },
  detailKey: {
    marginRight: theme.spacing(1)
  },
  detailValue: {
    color: colors.riverBed
  },
  link: {
    cursor: "pointer",
    "& p": {
      color: colors.pictonBlue,
      "&:hover": {
        textDecoration: "underline"
      }
    }
  }
}));

const backendTexts = {
  caseNumber: "caseNumberLabel",
  content: "contentLabel",
  emailAddress: "emailAddressLabel",
  locationName: "locationNameLabel",
  dateTime: "dateTimeLabel",
  duration: "durationLabel",
  descriptions: "descriptionsLabel",
  witnesses: "witnessesLabel",
  offenders: "offendersLabel",
  perpetrators: "perpetratorsLabel",
  injuryID: "injuryIDLabel",
  injuryName: "injuryNameLabel",
  filename: "filenameLabel",
  imageType: "imageTypeLabel",
  treatments: "treatmentsLabel",
  categoryFrom: "categoryFromLabel",
  categoryTo: "categoryToLabel",
  medicalRecordID: "medicalRecordIDLabel",
  name: "nameLabel",
  capacity: "capacityLabel",
  method: "methodLabel",
  downloadType: "downloadTypeLabel",
  emailSentTo: "emailSentToLabel",
  details: "detailsLabel",
  reportOnly: "downloadTypeEnum.reportOnlyValue",
  reportAndMedia: "downloadTypeEnum.reportAndMediaValue",
  "InjuryCapture/Case/Report/Request": {
    content: "caseLogPage.detailsColumn.downloadType",
    emailAddress: "caseLogPage.detailsColumn.emailSentTo"
  },
  "InjuryCapture/Case/Incident/Update": {
    descriptions: "caseLogPage.detailsColumn.details"
  }
};

const LogDetailItem = ({ isLink, name, value, to, operationCode }) => {
  const classes = useStyles(isLink);
  const getText = useTranslate();

  const keyText =
    getText(
      get(
        backendTexts,
        `${operationCode}.${name}`,
        `caseLogPage.detailsColumn.${name}`
      )
    ) || name;

  const content = (
    <Text
      className={classes.detailValue}
      text={`caseLogPage.detailsColumn.${value}`}
      type="contentLabel3"
    >
      {value}
    </Text>
  );

  return (
    <div className={classes.root}>
      <Text className={classes.detailKey} suffix=":" type="contentLabel3">
        {keyText}
      </Text>
      {isLink ? (
        <Link className={classes.link} to={to}>
          {content}
        </Link>
      ) : (
        content
      )}
    </div>
  );
};

LogDetailItem.propTypes = {
  isLink: PropTypes.bool,
  name: PropTypes.string.isRequired,
  operationCode: PropTypes.string,
  to: PropTypes.string,
  value: PropTypes.string.isRequired
};

LogDetailItem.defaultProps = {
  isLink: false,
  operationCode: "",
  to: ""
};

export default LogDetailItem;
