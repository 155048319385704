import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import get from "lodash-es/get";
import PropTypes from "prop-types";

// Actions
import {
  deleteImage,
  getHeaders,
  uploadAnnotatedImage,
  getImage
} from "../../state/injuries/actions";

// Config
import { sensitiveSections } from "../../config/values";
import { snackbarTypes } from "../../config/snackbar";
import { useSensitiveImage } from "../../context/SensitiveImageProvider";
import styles from "../../config/styles";

// Utils
import { generateFilename, imageTypes } from "../../utils/images";
import { toggleSnack } from "../../state/snackbar/actions";
import { useAppInsights } from "../../utils/telemetry/AppInsights";

// Components
import AnnotationTool from "../../components/AnnotationTool";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import LoadingSection from "../../components/LoadingSection";
import NavigationController from "./NavigationController";

const useStyles = makeStyles(() => ({
  main: {
    ...styles.mixins.mainSection,
    marginBottom: styles.values.annotationFooterHeight,
    position: "relative"
  },
  canvas: {
    height: `calc(100vh - ${styles.values.headerHeight}px - ${styles.values.annotationFooterHeight}px)`,
    width: "auto",
    maxWidth: "100vw",
    borderLeft: styles.values.border,
    borderRight: styles.values.border,
    filter: ({ applyFilter }) => (applyFilter ? "blur(20px)" : "none")
  },
  hidden: {
    display: "none"
  }
}));

const texts = {
  delete: ["toolPage.deleteDialog.title", "toolPage.deleteDialog.description"],
  discard: [
    "toolPage.discardDialog.title",
    "toolPage.discardDialog.description"
  ]
};

const initialState = { status: false, type: null };

const annotable = [imageTypes.normalGrid, imageTypes.largeGrid];

const ToolPageWrapper = ({ currentImage, goBack }) => {
  const [open, setOpen] = useState(initialState);
  const { caseId, injuryId, imgIndex } = useParams();
  const { clickedThumbnail } = useSensitiveImage();
  const { trackException } = useAppInsights();
  const dispatch = useDispatch();
  const injury = useSelector(s => s.injuries);

  const isSensitive = sensitiveSections.includes(
    get(injury, "data.bodySection", "")
  );

  const classes = useStyles({
    applyFilter: isSensitive && imgIndex !== clickedThumbnail
  });

  const imageHeaders = injury.imageHeaders[currentImage.filename];
  const loadingHeaders = injury.loadingHeaders[currentImage.filename];
  const headersErrors = injury.headersErrors[currentImage.filename];

  useEffect(() => {
    if (!injury.isLoadingImage) {
      if (
        (currentImage.isProcessing || !currentImage.resourceURLs) &&
        !injury.requestImageError
      ) {
        dispatch(
          getImage({
            caseId,
            filename: currentImage.filename,
            index: imgIndex,
            injuryId
          })
        );
      } else if (!currentImage.resourceURLs.image) {
        dispatch(toggleSnack({ type: snackbarTypes.ERROR }));
      } else if (
        annotable.includes(currentImage.imageType) &&
        !imageHeaders &&
        !loadingHeaders &&
        !headersErrors
      ) {
        dispatch(
          getHeaders({
            ...currentImage,
            onFail: () =>
              trackException(
                `Failed to get image headers for image: ${currentImage.filename} at case: ${caseId}`
              )
          })
        );
      }
    }
  }, [
    caseId,
    currentImage,
    dispatch,
    headersErrors,
    imageHeaders,
    imgIndex,
    injury.isLoadingImage,
    injury.requestImageError,
    injuryId,
    loadingHeaders,
    trackException
  ]);

  const handleClickOpen = type => () => setOpen({ status: true, type });
  const handleClose = () => setOpen(initialState);

  const handleSave = image => {
    const filename = generateFilename(currentImage.filename, "annotated");
    dispatch(
      uploadAnnotatedImage({
        caseId,
        description: currentImage.description,
        filename,
        image,
        imageMeta: imageHeaders,
        injuryId,
        onSuccess: goBack,
        onFail: msg =>
          trackException(
            `${msg} at file: ${filename} from injury: ${injuryId} in case: ${caseId}`
          )
      })
    );
  };

  const handleDelete = () => {
    dispatch(
      deleteImage({
        caseId,
        injuryId,
        filename: currentImage.filename,
        onSuccess: goBack,
        onFail: () =>
          trackException(
            `Failed to delete file: ${currentImage.filename} from injury: ${injuryId} in case: ${caseId}`
          )
      })
    );
  };

  return (
    <main>
      {get(currentImage, "resourceURLs.image") &&
      (!annotable.includes(currentImage.imageType) ||
        (!loadingHeaders && imageHeaders)) ? (
        <>
          <NavigationController>
            <AnnotationTool
              classes={classes}
              image={currentImage}
              imageMeta={imageHeaders}
              index={imgIndex}
              isSensitive={isSensitive}
              onDelete={handleClickOpen("delete")}
              onDiscard={handleClickOpen("discard")}
              onSave={handleSave}
            />
          </NavigationController>
          <ConfirmationDialog
            onClose={handleClose}
            open={open.status}
            onConfirm={open.type === "delete" ? handleDelete : goBack}
            textKeys={texts[open.type]}
            isLoading={!!injury.isDeletingImage}
            type={open.type}
          />
        </>
      ) : (
        <LoadingSection />
      )}
    </main>
  );
};

ToolPageWrapper.propTypes = {
  currentImage: PropTypes.shape({
    description: PropTypes.string,
    filename: PropTypes.string,
    imageType: PropTypes.string,
    isProcessing: PropTypes.bool.isRequired,
    resourceURLs: PropTypes.shape({
      image: PropTypes.string
    })
  }).isRequired,
  goBack: PropTypes.func.isRequired
};

export default ToolPageWrapper;
