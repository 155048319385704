import axios from "axios";

import {
  REQUEST_DEL_INJURY_FAIL,
  REQUEST_DEL_INJURY_SUCCESS,
  REQUEST_DEL_INJURY,
  REQUEST_DELETE_IMG_FAIL,
  REQUEST_DELETE_IMG_SUCCESS,
  REQUEST_DELETE_IMG,
  REQUEST_HEADERS_FAIL,
  REQUEST_HEADERS_SUCCESS,
  REQUEST_HEADERS,
  REQUEST_IMG_SUCCESS,
  REQUEST_INJURY_FAIL,
  REQUEST_INJURY_SUCCESS,
  REQUEST_INJURY,
  REQUEST_UPLOAD_ANNOTATED_FAIL,
  REQUEST_UPLOAD_ANNOTATED_SUCCESS,
  REQUEST_UPLOAD_ANNOTATED,
  REQUEST_UPLOAD_IMG_FAIL,
  REQUEST_UPLOAD_IMG_SUCCESS,
  REQUEST_UPLOAD_IMG,
  REQUEST_UPLOAD_TREATMENT_FAIL,
  REQUEST_UPLOAD_TREATMENT_SUCCESS,
  REQUEST_UPLOAD_TREATMENT,
  RESET_IMG_UPLOADS,
  UPDATE_IMG_PROGRESS,
  REQUEST_UPLOAD_DESCRIPTION,
  REQUEST_UPLOAD_DESCRIPTION_SUCCESS,
  REQUEST_UPLOAD_DESCRIPTION_FAIL
} from "./actionTypes";

// Config
import {
  getImgUrl,
  getImgUploadUrl,
  getInjuryUrl,
  getTreatmentsUrl,
  getDescriptionsUrl
} from "../../config/apiConfig";

// Utils
import { parseFetchOptions } from "../user/actions";
import { imageTypes, getImageHeaders } from "../../utils/images";
import appendToPath from "../../utils/appendToPath";
import errors from "../../utils/errors";

// Injuries
const requestInjury = () => ({ type: REQUEST_INJURY });

const requestInjurySuccess = payload => ({
  type: REQUEST_INJURY_SUCCESS,
  payload
});

const requestInjuryFail = payload => ({
  type: REQUEST_INJURY_FAIL,
  payload
});

export const getInjury =
  ({ caseId, injuryId, onFinish }) =>
  async dispatch => {
    dispatch(requestInjury());

    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(getInjuryUrl(caseId, injuryId), {
          method: "GET",
          headers: options.headers
        }).catch(error => {
          if (onFinish) onFinish(false);
          dispatch(requestInjuryFail(error));
        });

        if (response && response.ok) {
          const data = await response.json();
          dispatch(requestInjurySuccess(data));
          if (onFinish) onFinish(true);
        } else {
          if (onFinish) onFinish(false);
          dispatch(requestInjuryFail(errors.responseError(response)));
        }
      })
    );
  };

// Injuries
const requestInjuryDelete = () => ({ type: REQUEST_DEL_INJURY });

const requestInjuryDeleteSuccess = payload => ({
  type: REQUEST_DEL_INJURY_SUCCESS,
  payload
});

const requestInjuryDeleteFail = payload => ({
  type: REQUEST_DEL_INJURY_FAIL,
  payload
});

export const deleteInjury =
  ({ caseId, injuryId, onFinish }) =>
  async dispatch => {
    dispatch(requestInjuryDelete());

    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(getInjuryUrl(caseId, injuryId), {
          method: "DELETE",
          headers: options.headers
        }).catch(error => {
          dispatch(requestInjuryDeleteFail(error));
          if (onFinish) onFinish(false);
        });

        if (response && response.ok) {
          dispatch(requestInjuryDeleteSuccess());
          if (onFinish) onFinish(true);
        } else {
          dispatch(requestInjuryDeleteFail(errors.responseError(response)));
          if (onFinish) onFinish(false);
        }
      })
    );
  };

// Image Get
const requestImageSuccess = payload => ({
  type: REQUEST_IMG_SUCCESS,
  payload
});

export const getImage =
  ({ caseId, filename, index, injuryId }) =>
  async dispatch => {
    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(getImgUrl(caseId, injuryId, filename), {
          method: "GET",
          headers: options.headers
        });

        if (response && response.ok) {
          const image = await response.json();
          dispatch(requestImageSuccess({ index, image }));
        }
      })
    );
  };

// Image Headers
const requestHeaders = payload => ({
  type: REQUEST_HEADERS,
  payload
});

const requestHeadersSuccess = payload => ({
  type: REQUEST_HEADERS_SUCCESS,
  payload
});

const requestHeadersFail = payload => ({
  type: REQUEST_HEADERS_FAIL,
  payload
});

const headerId = "x-ms-meta-iccv";

export const getHeaders =
  ({ resourceURLs, filename, onFail }) =>
  async dispatch => {
    dispatch(requestHeaders(filename));

    const headers = await getImageHeaders({
      url: resourceURLs.image,
      onFail: error => {
        if (onFail) onFail();
        dispatch(requestHeadersFail({ filename, error }));
      }
    });

    dispatch(
      requestHeadersSuccess({
        filename,
        data: JSON.parse(headers.get(headerId))
      })
    );
  };

// Image Upload
const requestUploadImg = payload => ({
  type: REQUEST_UPLOAD_IMG,
  payload
});

const requestUploadImgSuccess = payload => ({
  type: REQUEST_UPLOAD_IMG_SUCCESS,
  payload
});

const requestUploadImgFail = payload => ({
  type: REQUEST_UPLOAD_IMG_FAIL,
  payload
});

export const updateImgProgress = payload => ({
  type: UPDATE_IMG_PROGRESS,
  payload
});

export const resetImgUploads = () => ({ type: RESET_IMG_UPLOADS });

export const uploadInjuryImage =
  ({ caseId, files, id, injuryId, onFail, onUploadProgress, query = "" }) =>
  async dispatch => {
    dispatch(
      parseFetchOptions(async options => {
        const requestResponse = await fetch(
          `${getImgUploadUrl(caseId, injuryId)}${query}`,
          {
            method: "POST",
            headers: {
              ...options.headers,
              "Content-Type": "application/json"
            },
            body: JSON.stringify(
              files.map(({ file, description }) => ({
                filename: file.name,
                description,
                imageType: imageTypes.noGrid
              }))
            )
          }
        );

        if (requestResponse && requestResponse.ok) {
          const { uploadURL, closeSessionURL } = await requestResponse.json();

          files.forEach(({ file }, i) => {
            dispatch(requestUploadImg(i));

            axios
              .request({
                method: "PUT",
                headers: {
                  "x-ms-blob-type": "BlockBlob",
                  "Content-Type": file.type
                },
                data: file,
                url: appendToPath(uploadURL, file.name),
                onUploadProgress: onUploadProgress(i)
              })
              .then(async () => {
                await fetch(closeSessionURL).catch(() =>
                  onFail("Failed to close img upload session")
                );
                dispatch(requestUploadImgSuccess(id));
              })
              .catch(() => {
                if (onFail) onFail("Failed to upload image");
                dispatch(requestUploadImgFail(id));
              });
          });
        } else onFail("Failed to get img upload url");
      })
    );
  };

export const retryUploadInjuryImage =
  ({ id, image, onFail, onSuccess, url }) =>
  async dispatch => {
    dispatch(requestUploadImg(id));
    axios
      .request({
        method: "PUT",
        headers: {
          "x-ms-blob-type": "BlockBlob",
          "Content-Type": image.type
        },
        data: image,
        url,
        onUploadProgress: progress =>
          dispatch(updateImgProgress({ index: 0, progress }))
      })
      .then(async () => {
        dispatch(requestUploadImgSuccess(id));
        if (onSuccess) onSuccess();
      })
      .catch(() => {
        if (onFail) onFail();
        dispatch(requestUploadImgFail(id));
      });
  };

// Annotated Image Upload
const requestUploadAnnotated = () => ({ type: REQUEST_UPLOAD_ANNOTATED });

const requestUploadAnnotatedSuccess = payload => ({
  type: REQUEST_UPLOAD_ANNOTATED_SUCCESS,
  payload
});

const requestUploadAnnotatedFail = payload => ({
  type: REQUEST_UPLOAD_ANNOTATED_FAIL,
  payload
});

export const uploadAnnotatedImage =
  ({
    caseId,
    description,
    filename,
    image,
    imageMeta,
    injuryId,
    onFail,
    onSuccess
  }) =>
  async dispatch => {
    dispatch(requestUploadAnnotated());

    dispatch(
      parseFetchOptions(async options => {
        const requestResponse = await fetch(getImgUploadUrl(caseId, injuryId), {
          method: "POST",
          headers: {
            ...options.headers,
            "Content-Type": "application/json"
          },
          body: JSON.stringify([
            {
              filename,
              description,
              imageType: imageTypes.annotated
            }
          ])
        }).catch(error => {
          if (onFail) onFail(`Failed to get annotated img upload url`);
          dispatch(requestUploadAnnotatedFail(error));
        });

        if (requestResponse && requestResponse.status === 200) {
          const requestBody = await requestResponse.json();

          const uploadResponse = await fetch(
            appendToPath(requestBody.uploadURL, filename),
            {
              method: "PUT",
              headers: {
                "x-ms-blob-type": "BlockBlob",
                "Content-Type": "image/jpeg",
                "x-ms-meta-iccv": JSON.stringify(imageMeta)
              },
              body: image
            }
          ).catch(error => {
            if (onFail) onFail(`Failed to upload annotated img`);
            dispatch(requestUploadAnnotatedFail(error));
          });

          await fetch(requestBody.closeSessionURL);

          if (uploadResponse && uploadResponse.status === 201) {
            dispatch(requestUploadAnnotatedSuccess());
            dispatch(getImage({ caseId, filename, injuryId }));
            if (onSuccess) onSuccess();
          } else {
            if (onFail) onFail(`Failed to close annotated img upload session`);
            dispatch(
              requestUploadAnnotatedFail(
                new Error(`uploadResponse: ${JSON.stringify(uploadResponse)}`)
              )
            );
          }
        } else {
          if (onFail) onFail(`Failed to get annotated img upload url`);
          dispatch(
            requestUploadAnnotatedFail(
              new Error(`requestResponse: ${JSON.stringify(requestResponse)}`)
            )
          );
        }
      })
    );
  };

// Image Delete
const requestDeleteImg = payload => ({ type: REQUEST_DELETE_IMG, payload });

const requestDeleteImgSuccess = payload => ({
  type: REQUEST_DELETE_IMG_SUCCESS,
  payload
});

const requestDeleteImgFail = payload => ({
  type: REQUEST_DELETE_IMG_FAIL,
  payload
});

export const deleteImage =
  ({ caseId, injuryId, filename, onSuccess, onFail }) =>
  async dispatch => {
    dispatch(requestDeleteImg(filename));

    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(getImgUrl(caseId, injuryId, filename), {
          method: "DELETE",
          headers: options.headers
        }).catch(error => {
          if (onFail) onFail();
          dispatch(requestDeleteImgFail(error));
        });

        if (response && response.ok) {
          dispatch(requestDeleteImgSuccess(filename));
          if (onSuccess) onSuccess();
        } else {
          if (onFail) onFail();
          dispatch(requestDeleteImgFail(errors.responseError(response)));
        }
      })
    );
  };

// Treatments
const uploadTreatmentRequest = () => ({
  type: REQUEST_UPLOAD_TREATMENT
});
const uploadTreatmentSuccess = () => ({
  type: REQUEST_UPLOAD_TREATMENT_SUCCESS
});

const uploadTreatmentFail = error => ({
  type: REQUEST_UPLOAD_TREATMENT_FAIL,
  payload: error
});

export const uploadTreatment =
  ({ caseId, injuryId, texts, onFinish }) =>
  async dispatch => {
    dispatch(uploadTreatmentRequest());

    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(getTreatmentsUrl(caseId, injuryId), {
          method: "POST",
          headers: {
            ...options.headers,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(texts)
        }).catch(error => {
          dispatch(uploadTreatmentFail(errors.responseError(error)));
          if (onFinish) onFinish(false);
        });

        if (response && response.ok) {
          const data = await response.json();
          dispatch(uploadTreatmentSuccess());
          dispatch(requestInjurySuccess(data));
          if (onFinish) onFinish(true);
        } else {
          dispatch(uploadTreatmentFail(errors.responseError(response)));
          if (onFinish) onFinish(false);
        }
      })
    );
  };

// Descriptions
const uploadDescriptionsRequest = () => ({
  type: REQUEST_UPLOAD_DESCRIPTION
});
const uploadDescriptionsSuccess = () => ({
  type: REQUEST_UPLOAD_DESCRIPTION_SUCCESS
});

const uploadDescriptionsFail = error => ({
  type: REQUEST_UPLOAD_DESCRIPTION_FAIL,
  payload: error
});

export const uploadDescriptions =
  ({ caseId, injuryId, texts, onFinish }) =>
  async dispatch => {
    dispatch(uploadDescriptionsRequest());
    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(getDescriptionsUrl(caseId, injuryId), {
          method: "POST",
          headers: {
            ...options.headers,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(texts)
        }).catch(error => {
          dispatch(uploadDescriptionsFail(errors.responseError(error)));
          if (onFinish) onFinish(false);
        });

        if (response && response.ok) {
          const data = await response.json();
          dispatch(uploadDescriptionsSuccess());
          dispatch(requestInjurySuccess(data));
          if (onFinish) onFinish(true);
        } else {
          dispatch(uploadDescriptionsFail(errors.responseError(response)));
          if (onFinish) onFinish(false);
        }
      })
    );
  };

// Update Injury
export const updateInjury =
  ({ caseId, injuryId, form, onFinish }) =>
  async dispatch => {
    dispatch(uploadTreatmentRequest());
    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(getInjuryUrl(caseId, injuryId), {
          method: "PUT",
          headers: {
            ...options.headers,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            ...form,
            id: injuryId,
            bodySection: form.bodySection || "FrontLeftHead"
          })
        }).catch(error => {
          dispatch(uploadTreatmentFail(errors.responseError(error)));
          if (onFinish) onFinish(false);
        });

        if (response && response.ok) {
          const data = await response.json();
          dispatch(uploadTreatmentSuccess());
          dispatch(requestInjurySuccess(data));
          if (onFinish) onFinish(true);
        } else {
          dispatch(uploadTreatmentFail(errors.responseError(response)));
          if (onFinish) onFinish(false);
        }
      })
    );
  };
