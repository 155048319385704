import React from "react";
import PropTypes from "prop-types";

// Material
import MenuItem from "@material-ui/core/MenuItem";

// Components
import PopperMenu from "../PopperMenu";

// Hooks
import usePopper from "../../hooks/usePopper";

const ariaId = "nested-menu-list-grow";

const NestedMenuItem = ({ children, classes, className, text }) => {
  const {
    anchorRef,
    handleClose,
    handleKeyDown,
    handleToggle,
    open
  } = usePopper();

  return (
    <>
      <MenuItem
        aria-controls={open ? ariaId : undefined}
        aria-haspopup="true"
        className={className}
        onClick={handleToggle}
        ref={anchorRef}
      >
        {text}
      </MenuItem>
      <PopperMenu
        anchorEl={anchorRef.current}
        ariaId={ariaId}
        classes={classes}
        onClose={handleClose}
        onKeyDown={handleKeyDown}
        open={open}
        placement="right-end"
      >
        {children}
      </PopperMenu>
    </>
  );
};

NestedMenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({}),
  className: PropTypes.string,
  text: PropTypes.node.isRequired
};

NestedMenuItem.defaultProps = {
  classes: {},
  className: null
};

export default NestedMenuItem;
