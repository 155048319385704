import colors from "./colors";

const values = {
  annotationFooterHeight: 126,
  border: `1px solid ${colors.mystic}`,
  borderDashed: `1px dashed ${colors.mystic}`,
  boxShadow: `0px 1px 0px ${colors.mystic}`,
  boxShadowMedium: `0px 3px 6px #00000029;`,
  statusBarHeight: 102,
  topBarHeight: 70,
  emergencyBannerHeight: 120,
  headerHeight: 172,
  bodyChart: {
    svg: `
    fill-rule: evenodd;
    clip-rule: evenodd;
    stroke-linejoin: round;
    stroke-miterlimit: 2;
  `,
    path: {
      fill: "white",
      fillRule: "nonzero",
      stroke: "black"
    }
  }
};

const getSectionTop = ({ isEmergencyBannerOpen } = {}) =>
  values.topBarHeight +
  values.statusBarHeight +
  (isEmergencyBannerOpen ? values.emergencyBannerHeight : 0);

const mixins = {
  mainSection: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    width: "100%",
    backgroundColor: colors.whisper,
    paddingTop: getSectionTop()
  },
  topPadding: {
    paddingTop: values.topBarHeight
  },
  sectionContainer: {
    maxWidth: 1216,
    width: "100%",
    margin: "0 auto",
    padding: "0 24px"
  },
  link: {
    color: colors.pictonBlue,

    "&:hover": {
      textDecoration: "underline"
    }
  },
  inputRoot: {
    border: values.border,
    transition: "border .2s ease-in-out",
    borderRadius: 4,
    width: "100%"
  },
  input: {
    fontWeight: "bold",
    minHeight: 46,
    padding: "0 16px",
    display: "flex",
    justifyContent: "center",
    fontSize: 15
  },
  scrollBar: {
    "&::-webkit-scrollbar": {
      width: 5,
      borderRadius: "4px"
    },

    "&::-webkit-scrollbar-track": {
      backgroundColor: `${colors.pictonBlue}19`
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: `${colors.pictonBlue}70`,
      borderRadius: "4px"
    },

    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: colors.pictonBlue
    }
  },
  horizontalScrollBar: {
    "&::-webkit-scrollbar": {
      height: 3
    },

    "&::-webkit-scrollbar-track": {
      backgroundColor: `${colors.pictonBlue}19`
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: `${colors.pictonBlue}70`
    },

    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: colors.pictonBlue
    }
  }
};

export default {
  values,
  mixins,
  getSectionTop
};
