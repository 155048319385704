import React from "react";
import { Link, useParams } from "react-router-dom";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Config
import { getRoute, urls } from "../../config/routes";

// Images
import injuriesCaptures from "../../assets/icons/injuriesCaptures.svg";

// Components
import Button from "../Button";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 3, 2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  injuriesIcon: {
    width: 155,
    padding: theme.spacing(2, 0),
    marginBottom: theme.spacing(3.5)
  },
  button: {
    marginBottom: theme.spacing(3),
    width: "100%"
  }
}));

const InjuriesPlaceholder = ({ text }) => {
  const classes = useStyles();
  const { caseId } = useParams();

  return (
    <div className={classes.root}>
      <img src={injuriesCaptures} className={classes.injuriesIcon} alt="" />
      <Button
        className={classes.button}
        color="primary"
        component={Link}
        disableElevation
        to={getRoute(urls.addInjury, { caseId })}
        variant="contained"
      >
        {text}
      </Button>
    </div>
  );
};

InjuriesPlaceholder.propTypes = {
  text: PropTypes.string.isRequired
};

export default InjuriesPlaceholder;
