import React from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Icons
import picsIcon from "../../assets/icons/pics_ico.svg";

// Config
import useTranslate from "../../hooks/useTranslate";

// Components
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  injuryType: {
    marginBottom: theme.spacing(1)
  },
  images: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row"
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}));

const InjuryItem = ({ bodySection, category, numberOfMediaItems }) => {
  const classes = useStyles();
  const getText = useTranslate();

  return (
    <>
      <Text type="contentLabel2Bold" className={classes.injuryType}>
        {getText(`common.bodySection.${bodySection}`)}{" "}
        {getText(`common.injuryCategory.${category}.shortName`)}
      </Text>
      <div className={classes.images}>
        <img src={picsIcon} alt="" className={classes.icon} />
        <Text type="body2">
          {getText("caseMenu.injuries.imagesAdded", {
            1: numberOfMediaItems,
            unit: "image",
            countKey: 1,
            unitKey: 2
          })}
        </Text>
      </div>
    </>
  );
};

InjuryItem.propTypes = {
  bodySection: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  numberOfMediaItems: PropTypes.number.isRequired
};

export default InjuryItem;
