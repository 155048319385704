import React from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Config
import { getRoute } from "../../../config/routes";
import useTranslate from "../../../hooks/useTranslate";

// Components
import Button from "../../../components/Button";
import Link from "../../../components/Link";
import Text from "../../../components/Text";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(20, 0)
  },
  icon: {
    height: 86
  },
  text: {
    padding: theme.spacing(5, 0),
    opacity: 0.4,
    fontSize: 20,
    width: 386
  },
  button: {
    width: 327,
    marginBottom: theme.spacing(1)
  }
}));

const EmptyNotesPlaceholder = ({ buttonText, icon, text, to, onClick }) => {
  const { caseId } = useParams();
  const classes = useStyles();
  const getText = useTranslate();

  return (
    <div className={classes.root}>
      <img src={icon} alt="" className={classes.icon} />
      <Text
        align="center"
        text={text}
        type="subtitle1"
        className={classes.text}
      />
      <Button
        className={classes.button}
        color="primary"
        component={Link}
        disableElevation
        to={getRoute(to, { caseId })}
        variant="contained"
      >
        {buttonText.toUpperCase()}
      </Button>
      <Button className={classes.button} onClick={onClick}>
        {getText("notesPage.getTips")}
      </Button>
    </div>
  );
};

EmptyNotesPlaceholder.propTypes = {
  buttonText: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

export default EmptyNotesPlaceholder;
