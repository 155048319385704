export const REQUEST_INFO_REQUESTS = "infoRequest/REQUEST_INFO_REQUESTS";
export const REQUEST_INFO_REQUESTS_SUCCESS =
  "infoRequest/REQUEST_INFO_REQUESTS_SUCCESS";
export const REQUEST_INFO_REQUESTS_FAIL =
  "infoRequest/REQUEST_INFO_REQUESTS_FAIL";

export const REQUEST_MESSAGES = "infoRequest/REQUEST_MESSAGES";
export const REQUEST_MESSAGES_SUCCESS = "infoRequest/REQUEST_MESSAGES_SUCCESS";
export const REQUEST_MESSAGES_FAIL = "infoRequest/REQUEST_MESSAGES_FAIL";

export const REQUEST_NEW_INFO = "infoRequest/REQUEST_NEW_INFO";
export const REQUEST_NEW_INFO_SUCCESS = "infoRequest/REQUEST_NEW_INFO_SUCCESS";
export const REQUEST_NEW_INFO_FAIL = "infoRequest/REQUEST_NEW_INFO_FAIL";
