import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

// Material
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";

// Icons
import chevron from "../../../assets/icons/chevron.svg";

// Hooks
import { useCaseDownload } from "../../../context/CaseDownloadProvider";

// Components
import SendEvidenceForm from "./SendEvidenceForm";
import Text from "../../../components/Text";

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    // "&:not(:last-child)": {
    //   borderBottom: 0
    // },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {}
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    padding: "0 34px",
    minHeight: 74,
    "&$expanded": {
      minHeight: 74
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(3, 5),
    borderTop: "1px solid rgba(0, 0, 0, .125)"
  }
}))(MuiAccordionDetails);

const useStyles = makeStyles({
  chevron: {
    transform: "rotate(180deg)"
  }
});

const contentType = ["ReportOnly", "ReportAndMedia"];

const SendEvidenceDropdown = ({ canRequest }) => {
  const [expanded, setExpanded] = useState(false);
  const [sent, setSent] = useState(false);
  const classes = useStyles();

  const { caseId } = useParams();
  const { requestCaseDownload } = useCaseDownload();

  const handleToggle = () => setExpanded(!expanded);

  const handleSubmit = (
    { email, phoneNumber, country, checked, message },
    { setSubmitting }
  ) => {
    const body = {
      recipient: {
        kind: "Person",
        email,
        phoneNumber: `+${country.phone}${phoneNumber}`
      },
      caseId,
      format: "CivilCase",
      content: contentType[checked],
      message
    };

    requestCaseDownload(body, () => {
      setSent(true);
      setSubmitting(false);
    });
  };

  const handleResetStatus = () => setSent(false);

  return (
    <div>
      <Accordion square expanded={expanded} onChange={handleToggle}>
        <AccordionSummary
          expandIcon={<img src={chevron} alt="" className={classes.chevron} />}
        >
          <Text
            type="heading6"
            text="downloadCasePage.civilFormat.otherOption.description"
          />
        </AccordionSummary>
        <AccordionDetails>
          <SendEvidenceForm
            disabled={!canRequest}
            onSubmit={handleSubmit}
            sent={sent}
            onResetStatus={handleResetStatus}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

SendEvidenceDropdown.propTypes = {
  canRequest: PropTypes.bool.isRequired
};

export default SendEvidenceDropdown;
