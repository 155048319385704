import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Config
import styles from "../../config/styles";

// Components
import LoadingSection from "../LoadingSection";
import Message from "./Message";
import SystemMessage from "./SystemMessage";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 4, 0),
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    maxHeight: 310,
    ...styles.mixins.scrollBar
  }
}));

const InfoMessages = ({ data, policeInChat, userDetails }) => {
  const { isLoadingContributors } = useSelector(s => s.caseDetail);
  const classes = useStyles();
  const ref = React.useRef();

  useEffect(() => {
    if (ref.current) ref.current.scrollTo(0, ref.current.scrollHeight);
  }, [data]);

  const displaySystemMessage = useMemo(() => {
    const lastMsg = data.length > 0 && data[data.length - 1];
    const senderId = lastMsg.senderID || lastMsg.senderId;
    const itsMe = senderId && senderId === userDetails.id;
    return itsMe && policeInChat;
  }, [data, userDetails, policeInChat]);

  if (isLoadingContributors) {
    return <LoadingSection normalHeight noBackground />;
  }

  return (
    <div ref={ref} className={classes.root}>
      {data.map(item => (
        <Message
          data={item}
          key={item.creationDateTime || item.timestamp}
          userId={userDetails.id}
        />
      ))}
      {displaySystemMessage && (
        <SystemMessage text="furtherInfoPage.systemMessage" />
      )}
    </div>
  );
};

InfoMessages.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      creationDateTime: PropTypes.string,
      timestamp: PropTypes.string
    })
  ).isRequired,
  policeInChat: PropTypes.bool,
  userDetails: PropTypes.shape({
    id: PropTypes.string
  }).isRequired
};

InfoMessages.defaultProps = {
  policeInChat: false
};

export default InfoMessages;
