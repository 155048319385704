import React from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import AccordionSummary from "@material-ui/core/AccordionSummary";

// Icons
import arrowcyan from "../../assets/icons/arrowcyan.svg";
import arrowred from "../../assets/icons/arrowred.svg";
import chevron from "../../assets/icons/chevron.svg";

// Config
import { getRoute, urls } from "../../config/routes";
import colors from "../../config/colors";

// Components
import Link from "../Link";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 5, 0)
  },
  actions: {
    padding: 0,
    paddingBottom: 0
  },
  heading: {
    padding: 0,
    width: "100%"
  },
  headingContent: {
    margin: 0
  },
  title: {
    textTransform: "uppercase",
    margin: 0
  },
  iconButton: {
    marginLeft: "auto"
  },
  icon: {
    transform: ({ expanded }) => !expanded && "rotate(180deg)",
    transition: "transform .2s ease-in-out"
  },
  option: {
    textTransform: "capitalize",
    color: colors.pictonBlue,
    marginBottom: theme.spacing(1.7),
    display: "flex",

    "&:hover": {
      color: colors.rose,
      fontWeight: theme.typography.fontWeightBold
    }
  },
  linkText: {
    color: "inherit"
  },
  optionArrow: {
    marginLeft: "0.5em"
  }
}));

const options = ["incident", "medicalRecords", "caseMedia", "infoRequests"];

const CardFooter = ({ showMedicalRecords, caseId, disabled }) => {
  const [expanded, setExpanded] = React.useState(false);
  const [hover, setHover] = React.useState(null);
  const classes = useStyles({ expanded });

  const handleExpandClick = () => setExpanded(!expanded);

  return (
    <CardContent className={classes.root}>
      <CardActions className={classes.actions}>
        <AccordionSummary
          size="small"
          disabled={disabled}
          onClick={handleExpandClick}
          classes={{
            root: classes.heading,
            content: classes.headingContent,
            expandIcon: classes.iconButton
          }}
          expandIcon={<img src={chevron} className={classes.icon} alt="" />}
        >
          <Text
            className={classes.title}
            text="casesPage.caseCard.quickActions.title"
            type="contentLabel"
          />
        </AccordionSummary>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {options.map(
          option =>
            (showMedicalRecords || option !== "medicalRecords") && (
              <Link
                className={classes.option}
                key={option}
                onMouseEnter={() => setHover(option)}
                onMouseLeave={() => setHover(null)}
                to={getRoute(urls[option], { caseId })}
              >
                <Text
                  className={classes.linkText}
                  text={`casesPage.caseCard.quickActions.${option}`}
                  type="valueLabel"
                />
                <img
                  src={(hover === option && arrowred) || arrowcyan}
                  className={classes.optionArrow}
                  alt=""
                />
              </Link>
            )
        )}
      </Collapse>
    </CardContent>
  );
};

CardFooter.propTypes = {
  caseId: PropTypes.string.isRequired,
  showMedicalRecords: PropTypes.bool.isRequired,
  disabled: PropTypes.bool
};

CardFooter.defaultProps = {
  disabled: false
};

export default CardFooter;
