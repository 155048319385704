/* eslint-disable react/no-danger */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import JSONPretty from "react-json-pretty";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Drawer from "@material-ui/core/Drawer";

// Icons
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Api
import { getCaseLogEntry } from "../../../config/apiConfig";
import { parseFetchOptions } from "../../../state/user/actions";

// Config
import { useAppInsights } from "../../../utils/telemetry/AppInsights";
import colors from "../../../config/colors";
import useTranslate from "../../../hooks/useTranslate";

// SnackBar
import { snackbarTypes } from "../../../config/snackbar";
import { toggleSnack } from "../../../state/snackbar/actions";

// Components
import LoadingSection from "../../../components/LoadingSection";
import Text from "../../../components/Text";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2.5, 3),
    backgroundColor: colors.doveGray,
    width: "45vw",
    maxWidth: "100%"
  },
  loading: {
    height: "100%"
  },
  titleRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(5)
  },
  goBack: {
    padding: theme.spacing(1)
  },
  chevron: {
    marginRight: theme.spacing(2)
  },
  goBackText: {
    textTransform: "uppercase",
    color: colors.white
  },
  content: {
    padding: theme.spacing(1),
    fontFamily: ["Lato", "sans-serif"].join(","),
    color: colors.lilyWhite,
    "& .__json-key__": {
      color: colors.mintGreen
    }
  }
}));

const LogEntryDetails = ({ entryId, onClose }) => {
  const { caseId } = useParams();
  const { trackException } = useAppInsights();
  const classes = useStyles();
  const dispatch = useDispatch();
  const getText = useTranslate();

  const [entries, setEntries] = useState({});
  const [loading, setLoading] = useState(null);
  const [entriesErrors, setEntriesErrors] = useState({});

  useEffect(() => {
    let mounted = true;

    if (entryId && !entries[entryId] && !entriesErrors[entryId]) {
      setLoading(true);
      dispatch(
        parseFetchOptions(options =>
          fetch(getCaseLogEntry({ caseId, entryId }), options)
            .then(r => r.json())
            .then(data => {
              if (mounted) {
                setEntries({ ...entries, [entryId]: data });
                setLoading(false);
              }
            })
            .catch(e => {
              setEntriesErrors({ ...entriesErrors, [entryId]: e });
              dispatch(toggleSnack({ type: snackbarTypes.ERROR }));
              trackException(
                `Failed to get entry ${entryId} at case: ${caseId}`
              );
            })
        )
      );
    }

    return () => {
      mounted = false;
    };
  }, [caseId, dispatch, entries, entriesErrors, entryId, trackException]);

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.root }}
      elevation={0}
      onClose={onClose}
      open={!!entryId}
    >
      {entryId &&
        (loading ? (
          <LoadingSection
            className={classes.loading}
            noBackground
            normalHeight
          />
        ) : (
          <div className={classes.root}>
            <div className={classes.titleRow}>
              <ButtonBase onClick={onClose} className={classes.goBack}>
                <Text className={classes.goBackText} type="valueLabel">
                  <FontAwesomeIcon
                    className={classes.chevron}
                    icon={faChevronLeft}
                  />
                  {getText("caseLogPage.goBack")}
                </Text>
              </ButtonBase>
            </div>
            <JSONPretty className={classes.content} data={entries[entryId]} />
          </div>
        ))}
    </Drawer>
  );
};

LogEntryDetails.propTypes = {
  onClose: PropTypes.func.isRequired,
  entryId: PropTypes.string
};

LogEntryDetails.defaultProps = {
  entryId: null
};

export default LogEntryDetails;
