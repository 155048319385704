import React from "react";

// Components
import InjurySteps from "../../components/InjurySteps";
import SectionWrapper from "../../components/SectionWrapper";

const AddInjuryPage = () => {
  return (
    <SectionWrapper>
      <InjurySteps />
    </SectionWrapper>
  );
};

export default AddInjuryPage;
