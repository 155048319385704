import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Config
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import styles from "../../config/styles";

// Actions
import { deleteImage } from "../../state/injuries/actions";

// Components
import AddInjuryImage from "../AddInjuryImage";
import CaseMediaGallery from "../CaseMediaGallery";
import EditInjuryImage from "./EditInjuryImage";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  heading: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1)
  },
  imagesBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "100%",
    padding: theme.spacing(2, 0, 2),
    border: styles.values.border,
    borderRadius: theme.shape.borderRadius
  }
}));

const EditInjuryGallery = ({
  caseId,
  imageHeaders,
  images,
  injuryId,
  isDeletingImage,
  loadingHeaders
}) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(0);
  const { trackException } = useAppInsights();
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleDeleteImage = filename => () => {
    dispatch(
      deleteImage({
        caseId,
        injuryId,
        filename,
        onFail: () =>
          trackException(
            `Failed to delete file: ${filename} from injury: ${injuryId} at case: ${caseId}`
          )
      })
    );
  };

  const handleNavigate = forward => () => {
    let next = forward ? selected + 1 : selected - 1;
    if (next < 0) next = images.length - 1;
    if (next === images.length) next = 0;
    setSelected(next);
  };

  const handleCloseGallery = () => setOpen(false);
  const handleSelect = index => () => {
    setSelected(index);
    setOpen(true);
  };

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <Text type="subtitleBold" text="editInjuryPage.imagesTitle" />
        <AddInjuryImage />
      </div>
      {images && (
        <div className={classes.imagesBox}>
          {images.map((image, index) => (
            <EditInjuryImage
              handleDeleteImage={handleDeleteImage}
              handleSelect={handleSelect}
              image={image}
              imageHeaders={imageHeaders}
              imageRequest={{ caseId, injuryId }}
              index={index}
              isDeletingImage={isDeletingImage}
              key={image.filename}
              loadingHeaders={loadingHeaders}
            />
          ))}
        </div>
      )}
      {images && images.length > 0 && (
        <CaseMediaGallery
          data={images[selected]}
          onClose={handleCloseGallery}
          onNext={handleNavigate(true)}
          onPrev={handleNavigate(false)}
          open={open}
        />
      )}
    </div>
  );
};

EditInjuryGallery.propTypes = {
  caseId: PropTypes.string.isRequired,
  imageHeaders: PropTypes.shape({}).isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      filename: PropTypes.string,
      isProcessing: PropTypes.bool
    })
  ).isRequired,
  isDeletingImage: PropTypes.string,
  loadingHeaders: PropTypes.shape({}).isRequired,
  injuryId: PropTypes.string.isRequired
};

EditInjuryGallery.defaultProps = {
  isDeletingImage: ""
};

export default EditInjuryGallery;
