import React, { useState } from "react";
import { format } from "date-fns";
import get from "lodash-es/get";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";

// Config
import colors from "../../config/colors";
import styles from "../../config/styles";

// Hooks
import { useMediaReupload } from "../MediaItemList/MediaReuploadProvider";
import useTranslate from "../../hooks/useTranslate";

// Icons
import trashIcon from "../../assets/icons/trash.svg";

// Components
import ConfirmationDialog from "../ConfirmationDialog";
import Text from "../Text";
import MediaItemContent from "./MediaItemContent";

const useStyles = makeStyles(theme => ({
  root: {
    border: styles.values.border,
    borderColor: colors.wildSand,
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    width: `calc(50% - ${theme.spacing(1)}px)`,
    marginBottom: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(5),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  heading: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(2.5)
  },
  avatar: {
    height: 36,
    width: 36,
    marginRight: theme.spacing(1)
  },
  headingInfo: {
    display: "flex",
    flexDirection: "column"
  },
  description: {
    color: colors.lynch,
    marginBottom: theme.spacing(1.75)
  },
  cardActionArea: {
    marginBottom: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius
  },
  imageContainer: {
    width: "100%",
    paddingBottom: "56.25%",
    display: "block",
    position: "relative",
    overflow: "hidden",
    borderRadius: theme.shape.borderRadius
  },
  image: {
    display: "block",
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover"
  },
  placeholder: {
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    minHeight: 163,
    backgroundColor: colors.concrete,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(1.5)
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  filename: {
    color: colors.lynch,
    marginRight: theme.spacing(1)
  },
  deleteIcon: {
    height: 15
  }
}));

const MediaItem = ({
  data,
  handleFetchImage,
  isDeleting,
  onClick,
  onDelete,
  showDelete
}) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { openReupload } = useMediaReupload();

  const classes = useStyles();
  const getText = useTranslate();

  const date = format(
    new Date(data.creationDateTime),
    getText("common.dateFormat.timeOnDate")
  );

  const handleDelete = () => {
    setDeleteOpen(false);
    onDelete();
  };

  const toggleDelete = status => () => setDeleteOpen(status);
  const handleOpenReupload = () => openReupload(data.id);

  const numberFilename = `${data.exhibitNumber} ${data.filename}`;

  return (
    <>
      <Card component="article" variant="outlined" className={classes.root}>
        <div className={classes.heading}>
          <Avatar
            className={classes.avatar}
            src={data.creator.profilePictureURL}
          />
          <div className={classes.headingInfo}>
            <Text type="heading6">{data.creator.fullName}</Text>
            <Text type="contentLabel">{date}</Text>
          </div>
        </div>
        <Text type="subtitle1Small" className={classes.description}>
          {data.description}
        </Text>
        <MediaItemContent
          creationDateTime={data.creationDateTime}
          filename={numberFilename}
          getImage={handleFetchImage}
          imgSrc={get(data, "downloadURLs.thumbnailURL", "")}
          isProcessing={data.isProcessing}
          onClick={onClick}
          onOpenReupload={handleOpenReupload}
          status={data.status}
        />
        <div className={classes.footer}>
          <Text type="subtitle1Small" className={classes.filename}>
            {numberFilename}
          </Text>
          {showDelete && (
            <IconButton onClick={toggleDelete(true)}>
              <img className={classes.deleteIcon} src={trashIcon} alt="" />
            </IconButton>
          )}
        </div>
      </Card>
      <ConfirmationDialog
        onClose={toggleDelete(false)}
        onConfirm={handleDelete}
        open={deleteOpen}
        textKeys={[
          "medicalRecordsPage.deleteButtonDialog.title",
          "medicalRecordsPage.deleteButtonDialog.description"
        ]}
        type="delete"
        button={getText("common.prompts.delete").toUpperCase()}
        cancel={getText("common.prompts.cancel").toUpperCase()}
        isLoading={isDeleting === data.id}
      />
    </>
  );
};

MediaItem.propTypes = {
  data: PropTypes.shape({
    creationDateTime: PropTypes.string,
    creator: PropTypes.shape({
      fullName: PropTypes.string,
      profilePictureURL: PropTypes.string
    }),
    description: PropTypes.string,
    downloadURLs: PropTypes.shape({
      thumbnailURL: PropTypes.string
    }),
    filename: PropTypes.string,
    exhibitNumber: PropTypes.string,
    id: PropTypes.string,
    isProcessing: PropTypes.bool,
    status: PropTypes.string
  }).isRequired,
  handleFetchImage: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  showDelete: PropTypes.bool.isRequired
};

MediaItem.defaultProps = {
  isDeleting: null
};

export default MediaItem;
