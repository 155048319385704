const isString = v => typeof v === "string";

const flatten = array => {
  let newArray = [];

  array.forEach(item => {
    if (Array.isArray(item)) newArray = newArray.concat(item);
    else newArray.push(item);
  });

  return newArray;
};

const replaceString = (str, match, fn) => {
  if (str === "") return str;

  const result = str.split(match);

  // Apply fn to all odd elements result from the split
  for (let i = 1; i < result.length; i += 2) {
    result[i] = fn(result[i]);
  }

  return result;
};

// Also works with arrays of strings
// Non string values are left untouched
const reactStringReplace = (src, match, fn) => {
  let source = src;

  if (!Array.isArray(source)) source = [source];

  return flatten(
    source.map(x => (isString(x) ? replaceString(x, match, fn) : x))
  );
};

export default reactStringReplace;
