import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Material
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";

// Assets
import colors from "../../config/colors";
import chevron from "../../assets/icons/chevron.svg";
import arrowup from "../../assets/icons/arrowup.svg";
import folder from "../../assets/icons/folder.svg";

// Hooks
import useTranslate from "../../hooks/useTranslate";

// Components
import CaseCard from "../CaseCard";
import InvitationCard from "../InvitationCard";
import Text from "../Text";
import { useCasesPage } from "../../context/CasesPageProvider";

// Custom Components
const Accordion = withStyles({
  root: {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",

    "&:not(:last-child)": {
      borderBottom: 0
    },

    "&:before": {
      display: "none"
    },

    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {}
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    padding: 0,
    minHeight: "auto",
    display: "flex",
    flexDirection: "row",
    margin: "12px 0",

    "&$expanded": {
      minHeight: "auto"
    }
  },
  content: {
    alignItems: "center",

    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: 0
  }
}))(MuiAccordionDetails);

const useStyles = makeStyles(theme => ({
  root: {},
  chevron: {
    transform: ({ expanded }) => !expanded && "rotate(180deg)",
    transition: "transform .2s ease-in-out",
    marginRight: theme.spacing(1.5),
    marginLeft: theme.spacing(1)
  },
  folder: {
    fontSize: 16,
    color: colors.riverBed,
    marginRight: theme.spacing(0.5)
  },
  label: {
    color: colors.jumbo,
    fontSize: 12,
    textTransform: "capitalize"
  },
  line: {
    display: "flex",
    height: 1,
    backgroundColor: colors.doveGray,
    flexGrow: 1,
    opacity: 0.3,
    margin: theme.spacing(0, 1)
  },
  sortOptions: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    marginRight: theme.spacing(1)
  },
  filterLabel: {
    lineHeight: 1,
    marginLeft: theme.spacing(2)
  },
  icon: {
    marginLeft: "0.5em",
    fontSize: 12,
    transition: "transform 0.25s"
  },
  reverse: {
    transform: "rotate(180deg)"
  }
}));

const sortTypes = {
  number: "Number",
  date: "Date"
};

const compareDates = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) return 1;
  return 0;
};

const CasePanel = ({ data, title, pending, isCollab, name }) => {
  const [expandedInitial, togglePanel] = useCasesPage(name);
  const [expanded, setExpanded] = useState(expandedInitial);
  const [sortType, setSortType] = useState(sortTypes.date);
  const [orderAscending, setOrderAscending] = useState(false);
  const classes = useStyles({ expanded });
  const getText = useTranslate();

  const handleExpandClick = () => {
    setExpanded(togglePanel(name));
  };

  const onSelectFilter = selectedFilter => e => {
    e.stopPropagation();
    if (sortType !== selectedFilter) {
      setSortType(selectedFilter);
      setOrderAscending(true);
    } else {
      setOrderAscending(!orderAscending);
    }
  };

  const sortData = () => {
    if (sortType === sortTypes.number)
      return data.sort((a, b) => {
        let value = 0;
        if (a.number < b.number) value = -1;
        if (a.number > b.number) value = 1;
        return orderAscending ? value : value * -1;
      });
    const sorted = data.sort((a, b) => {
      const dateA = new Date(a.creationDateTime);
      const dateB = new Date(b.creationDateTime);
      return orderAscending
        ? compareDates(dateA, dateB)
        : compareDates(dateB, dateA);
    });
    return sorted;
  };

  const renderSortArrow = (show, isUp) => {
    return (
      show && (
        <img
          src={arrowup}
          className={clsx(classes.icon, {
            [classes.reverse]: isUp
          })}
          alt=""
        />
      )
    );
  };

  return (
    <Accordion expanded={expanded} square>
      <AccordionSummary size="small" onClick={handleExpandClick}>
        <img src={chevron} alt="" className={classes.chevron} />
        <img src={folder} alt="" className={classes.folder} />
        <Text type="valueLabel" className={classes.label}>
          {title} ({pending.length + data.length})
        </Text>
        <div className={classes.line} />
        <div className={classes.sortOptions}>
          <Text
            className={classes.label}
            text="casesPage.sortBy"
            type="valueLabel"
          />
          <Text
            className={classes.filterLabel}
            onClick={onSelectFilter(sortTypes.date)}
            type="valueLabel"
          >
            {getText("casesPage.creationDate")}
            {renderSortArrow(sortType === sortTypes.date, orderAscending)}
          </Text>
          <Text
            onClick={onSelectFilter(sortTypes.number)}
            type="valueLabel"
            className={classes.filterLabel}
          >
            {getText("casesPage.caseNumber")}
            {renderSortArrow(sortType === sortTypes.number, orderAscending)}
          </Text>
        </div>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container spacing={3}>
          {pending &&
            pending.map(item => (
              <Grid key={item.id} item xs={4}>
                <InvitationCard data={item} />
              </Grid>
            ))}
          {sortData(data).map(item => (
            <Grid key={item.id} item xs={4}>
              <CaseCard isCollab={isCollab} data={item} />
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

CasePanel.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isCollab: PropTypes.bool,
  name: PropTypes.string.isRequired,
  pending: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string.isRequired
};

CasePanel.defaultProps = {
  isCollab: false,
  pending: []
};

export default CasePanel;
