import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

// Icons
import contributorsImg from "../../assets/icons/contributors.svg";

// Config
import { getRoute, urls } from "../../config/routes";
import colors from "../../config/colors";
import styles from "../../config/styles";

// Components
import Button from "../Button";
import ContributorItem from "../ContributorItem/ContributorItem";

const useStyles = makeStyles(theme => ({
  root: {
    border: styles.values.border
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  contributor: {
    padding: theme.spacing(1, 3.5)
  },
  button: {
    padding: "0",
    display: "flex",
    justifyContent: "flex-start",
    textAlign: "left",

    "&:last-of-type": {
      margin: theme.spacing(0, 0, 1)
    }
  },
  selected: {
    backgroundColor: colors.lilyWhite,
    pointerEvents: "none"
  }
}));

const ContributorsList = ({ contributors, caseId }) => {
  const classes = useStyles();

  return contributors.length > 0 ? (
    contributors.map(item => (
      <Button
        component={NavLink}
        to={getRoute(urls.contributorDetail, {
          caseId,
          contributorId: item.id
        })}
        activeClassName={classes.selected}
        className={classes.button}
        key={item.id}
      >
        <ContributorItem user={item} className={classes.contributor} />
      </Button>
    ))
  ) : (
    <div className={classes.imageContainer}>
      <img src={contributorsImg} alt="" className={classes.img} />
    </div>
  );
};

ContributorsList.propTypes = {
  caseId: PropTypes.string.isRequired,
  contributors: PropTypes.arrayOf(PropTypes.shape({}))
};

ContributorsList.defaultProps = {
  contributors: []
};

export default ContributorsList;
