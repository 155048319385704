import React from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import MuiButton from "@material-ui/core/Button";
import colors from "../../config/colors";

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: ({ regular }) =>
      [!regular ? "Gibson SemiBold" : "Gibson", "sans-serif"].join(","),
    borderColor: colors.shuttleGray,
    fontSize: 14,
    padding: theme.spacing(2),
    lineHeight: 1.2,
    textTransform: "inherit",
    minWidth: 50
  }
}));

const Button = React.forwardRef(
  ({ disabledClassName, regular, ...props }, ref) => {
    const classes = useStyles({ regular });

    return (
      <MuiButton
        ref={ref}
        classes={{
          root: classes.root,
          disabled: disabledClassName
        }}
        {...props}
      />
    );
  }
);

Button.propTypes = {
  disabledClassName: PropTypes.string,
  regular: PropTypes.bool
};

Button.defaultProps = {
  disabledClassName: null,
  regular: false
};

export default Button;
