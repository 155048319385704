import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import debounce from "lodash-es/debounce";

// Components
import CustomField from "../../CustomField";

// Actions
import {
  searchPlaces,
  requestPlacesReset
} from "../../../state/places/actions";

const LocationField = ({ setFieldValue, setCoord, name, ...rest }) => {
  const [isDirty, setDirty] = useState(false);
  const [coordinates, setCoordinates] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const { isLoading, searchData } = useSelector(state => state.places);
  const dispatch = useDispatch();

  const options =
    !isLoading && searchData
      ? searchData.map(place => ({
          value: place.properties.name,
          text: `${place.properties.name}, ${place.properties.freeformAddress}`,
          ...place
        }))
      : [];

  const dispatchFetchPlaces = useMemo(
    () => debounce(request => dispatch(searchPlaces(request)), 500),
    [dispatch]
  );

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(location =>
      setCoordinates({
        latitude: location.coords.latitude,
        longitude: location.coords.longitude
      })
    );

    return () => {
      dispatch(requestPlacesReset());
    };
  }, [dispatch]);

  useEffect(() => {
    if (inputValue.length) {
      dispatchFetchPlaces({
        query: inputValue,
        latitude: coordinates && coordinates.latitude,
        longitude: coordinates && coordinates.longitude
      });
    } else if (setFieldValue && isDirty) {
      setFieldValue(name, "");
    }
  }, [
    coordinates,
    dispatchFetchPlaces,
    inputValue,
    isDirty,
    name,
    setFieldValue
  ]);

  const handleKeyUp = e => {
    setDirty(true);
    setInputValue(e.target.value);
  };

  return (
    <>
      <CustomField
        {...rest}
        type="location"
        name={name}
        options={options}
        loading={isLoading}
        filterOptions={x => x}
        getOptionSelected={(option, value) => {
          if (typeof value === "string") {
            return value.length
              ? option.text === value
              : option.text === options[0].text;
          }
          return option.text === value.text;
        }}
        getOptionLabel={option =>
          typeof option === "string" ? option : option.text
        }
        onKeyUp={handleKeyUp}
        onChange={(_, newValue) => {
          if (newValue) {
            if (setFieldValue) {
              setFieldValue(name, newValue.text);
            }
            if (setCoord) {
              setCoord({
                lng: () => newValue.geometry.coordinates[0],
                lat: () => newValue.geometry.coordinates[1]
              });
            }
          }
        }}
      />
    </>
  );
};

LocationField.propTypes = {
  name: PropTypes.string.isRequired,
  setCoord: PropTypes.func,
  setFieldValue: PropTypes.func
};

LocationField.defaultProps = {
  setCoord: null,
  setFieldValue: null
};

export default LocationField;
