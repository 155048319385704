import React, { useMemo } from "react";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import clsx from "clsx";
import get from "lodash-es/get";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";

// Config
import { getRoute, urls } from "../../../config/routes";
import colors from "../../../config/colors";

// Icons
import editIcon from "../../../assets/icons/edit.svg";
import trashIcon from "../../../assets/icons/trash.svg";
import statementIcon from "../../../assets/icons/statementIconColored.svg";

// Utils
import { useAppInsights } from "../../../utils/telemetry/AppInsights";
import useTranslate from "../../../hooks/useTranslate";

// Actions
import { deleteNote, publishNote } from "../../../state/notes/actions";

// Components
import Avatar from "../../../components/Avatar";
import ConfirmationButton from "../../../components/ConfirmationButton";
import LoadingSection from "../../../components/LoadingSection";
import Text from "../../../components/Text";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3, 6)
  },
  headingWrapper: {
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(3)
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "column"
  },
  title: {
    lineHeight: 1,
    marginBottom: theme.spacing(2)
  },
  dateWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  avatar: {
    height: 24,
    width: 24,
    marginRight: theme.spacing(1),
    fontSize: 14
  },
  draftDate: {
    color: colors.pictonBlue
  },
  buttonWrapper: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "row"
  },
  actionButton: {
    padding: 0,
    height: 40,
    width: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: theme.spacing(1.5)
  },
  toggleEdit: {
    padding: theme.spacing(1),
    height: 36,
    width: 36
  },
  content: {
    whiteSpace: "pre-wrap"
  },
  contentFooter: {
    padding: theme.spacing(3, 0)
  },
  publishButton: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    width: 280,
    borderRadius: 12
  }
}));

const noteTypes = {
  NOTE: "Note"
};

const NoteDetail = ({ disableDelete, disableEdit }) => {
  const { data, isRequesting, isDeleting, isSubmitting } = useSelector(
    s => s.notes
  );
  const { itemId, caseId } = useParams();
  const { trackException } = useAppInsights();
  const classes = useStyles();
  const dispatch = useDispatch();
  const getText = useTranslate();
  const history = useHistory();

  const note = useMemo(
    () => (data && data.find(({ id }) => id === itemId)) || null,
    [data, itemId]
  );

  if (isRequesting) return <LoadingSection normalHeight />;
  if (!note) return null;

  const isNote = note.noteType === noteTypes.NOTE;

  const texts = getText("notesPage.noteItem");
  const date = format(
    new Date(note.updateDateTime || note.creationDateTime),
    "dd/MM/yy"
  );
  const name = get(note, "creator.fullName", "");

  const handleEdit = () => {
    history.push(getRoute(urls.editNote, { caseId, itemId }));
  };

  const handlePublish = () => {
    dispatch(
      publishNote({
        caseId,
        itemId,
        onSuccess: () =>
          history.push(getRoute(urls.statementDetail, { caseId, itemId })),
        onFail: () =>
          trackException(`Failed to publish note: ${itemId} at case ${caseId}`)
      })
    );
  };

  const handleDelete = () => {
    dispatch(
      deleteNote({
        caseId,
        itemId,
        onFail: () =>
          trackException(`Failed to delete note: ${itemId} at case ${caseId}`)
      })
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.headingWrapper}>
        <div className={classes.titleWrapper}>
          <Text type="heading2" className={classes.title}>
            {note.title}
          </Text>
          <div className={classes.dateWrapper}>
            {!isNote && (
              <Avatar
                src={note.creator.profilePictureURL}
                className={classes.avatar}
              >
                {name.charAt(0)}
              </Avatar>
            )}
            <Text
              className={clsx({
                [classes.draftDate]: isNote
              })}
              type="subtitleLight"
              text="notesPage.noteItem.lastEditedBy"
              values={{
                1: isNote ? texts.you : name,
                2: date
              }}
            />
          </div>
        </div>
        <div className={classes.buttonWrapper}>
          {!disableEdit && (
            <IconButton onClick={handleEdit} className={classes.actionButton}>
              <img alt="" className={classes.buttonIcon} src={editIcon} />
            </IconButton>
          )}
          {!disableDelete && (
            <ConfirmationButton
              onClick={handleDelete}
              className={classes.actionButton}
              type="icon"
              dialog={{
                type: "delete",
                texts: [
                  {
                    key: 1,
                    text: getText("notesPage.noteItem.deleteButtonDialog.title")
                  },
                  {
                    key: 2,
                    text: getText(
                      "notesPage.noteItem.deleteButtonDialog.description"
                    )
                  }
                ],
                isLoading: !!isDeleting
              }}
            >
              <img alt="" className={classes.buttonIcon} src={trashIcon} />
            </ConfirmationButton>
          )}
        </div>
      </div>
      <Text className={classes.content}>{note.content}</Text>
      {isNote && (
        <div className={classes.contentFooter}>
          <ConfirmationButton
            className={classes.publishButton}
            disableElevation
            onClick={handlePublish}
            variant="contained"
            type="button"
            dialog={{
              type: "submit",
              button: getText(
                "notesPage.noteItem.publishButtonDialog.primaryButton"
              ),
              texts: [
                {
                  key: 1,
                  text: getText("notesPage.noteItem.publishButtonDialog.title")
                },
                {
                  key: 2,
                  text: getText(
                    "notesPage.noteItem.publishButtonDialog.description"
                  )
                }
              ],
              icon: statementIcon,
              isLoading: !!isSubmitting
            }}
          >
            {texts.publishButton}
          </ConfirmationButton>
        </div>
      )}
    </div>
  );
};

NoteDetail.propTypes = {
  disableDelete: PropTypes.bool,
  disableEdit: PropTypes.bool
};

NoteDetail.defaultProps = {
  disableDelete: false,
  disableEdit: false
};

export default NoteDetail;
