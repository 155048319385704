import React, { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import get from "lodash-es/get";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";

// Actions
import { deleteCase } from "../../state/caseDetail/actions";

// Icons
import download from "../../assets/icons/download.svg";

// Config
import { getRoute, urls } from "../../config/routes";
import customEvents from "../../config/customEvents";

// Hooks
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import useShowSteps from "../../hooks/useShowSteps";
import useTranslate from "../../hooks/useTranslate";

// Components
import Button from "../Button";
import ConfirmationButton from "../ConfirmationButton";
import PinPasscodeDialog from "../PinPasscodeDialog";
import SubmitCaseButton from "../SubmitCaseButton";

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(2),
    minWidth: 190
  }
}));

const ActionButtons = ({ isCreating, showCaseOptions }) => {
  const [showPasscode, setShowPasscode] = useState(false);
  const { data, isLoading, isDeleting } = useSelector(s => s.caseDetail);
  const { trackException, trackEvent } = useAppInsights();
  const { userDetails } = useSelector(s => s.user);
  const classes = useStyles();
  const disableSubmit = useRouteMatch(urls.submitIncident);
  const dispatch = useDispatch();
  const getText = useTranslate();
  const history = useHistory();
  const match = useRouteMatch(urls.caseDetail);

  const caseId = get(match, "params.caseId");
  const showSteps = useShowSteps(caseId);

  const goTo = url => () => history.push(url);

  const handleDeleteCase = () => {
    setShowPasscode(false);
    trackEvent(customEvents.DELETE_CASE);
    dispatch(
      deleteCase({
        caseId,
        onSuccess: () => history.push(urls.cases),
        onFail: () => trackException(`Failed to delete case: ${caseId}`)
      })
    );
  };

  const handleCreateCase = () => {
    trackEvent(customEvents.ENTER_CREATE_CASE);
    history.push(urls.createCase);
  };

  const handleConfirmCreation = () => {
    trackEvent(customEvents.CREATE_CASE);
    history.push(urls.cases);
  };

  if (isCreating) {
    return (
      <>
        <ConfirmationButton
          className={classes.button}
          disableElevation
          onClick={handleConfirmCreation}
          variant="outlined"
        >
          {getText("header.actionButtons.cancelButton")}
        </ConfirmationButton>
      </>
    );
  }

  const startButton = (
    <Button
      className={classes.button}
      color="primary"
      disableElevation
      onClick={handleCreateCase}
      variant="contained"
    >
      {getText("header.actionButtons.createButton")}
    </Button>
  );

  if (!showCaseOptions) return startButton;

  if (isLoading || !data || !userDetails) {
    return (
      <>
        <Skeleton variant="rect" width={190} height={48} />
        {startButton}
      </>
    );
  }

  const { isDraft } = data;
  const isCreator = userDetails.id === data.creatorId;
  const showCaseButtons = !showSteps;
  const showDeleteSubmit = isDraft && isCreator;

  const togglePasscode = value => () => setShowPasscode(value);

  return (
    <>
      {showCaseButtons && (
        <>
          <Button
            disableElevation
            onClick={goTo(getRoute(urls.caseDownload, { caseId: data.id }))}
            variant="outlined"
          >
            <img src={download} alt="" />
          </Button>
          {showDeleteSubmit && (
            <SubmitCaseButton
              caseId={caseId}
              className={classes.button}
              disabled={!!disableSubmit}
            >
              {getText("header.actionButtons.submitButton")}
            </SubmitCaseButton>
          )}
        </>
      )}
      {showDeleteSubmit && (
        <ConfirmationButton
          className={classes.button}
          color="secondary"
          disableElevation
          onClick={togglePasscode(true)}
          variant="outlined"
          dialog={{
            texts: [
              { key: 1, text: getText("deleteCaseDialog.description") },
              { key: 2, text: getText("deleteCaseDialog.title") }
            ],
            isLoading: !!isDeleting
          }}
        >
          {getText("header.actionButtons.deleteButton")}
        </ConfirmationButton>
      )}
      {startButton}
      <PinPasscodeDialog
        onClose={togglePasscode(false)}
        onSubmit={handleDeleteCase}
        open={showPasscode}
      />
    </>
  );
};

ActionButtons.propTypes = {
  isCreating: PropTypes.bool.isRequired,
  showCaseOptions: PropTypes.bool.isRequired
};

export default ActionButtons;
