import React from "react";
import PropTypes from "prop-types";

// Material
import Dialog from "@material-ui/core/Dialog";

// Components
import PincodeForm from "../PincodeForm";

const PinPasscodeDialog = ({ onSubmit, onClose, open }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <PincodeForm onError={onClose} onSubmit={onSubmit} />
    </Dialog>
  );
};

PinPasscodeDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default PinPasscodeDialog;
