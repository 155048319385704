import React from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

// Components
import Text from "../../../components/Text";

const useStyles = makeStyles(theme => ({
  checkboxContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(1)
  },
  checboxWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  checkbox: {
    marginRight: theme.spacing(2)
  }
}));

const DownloadTypeSelector = ({ checked, onChange, types, disabled }) => {
  const classes = useStyles();

  return (
    <div className={classes.checkboxContainer}>
      {types.map(({ value, text }) => (
        <div key={value} className={classes.checboxWrapper}>
          <Checkbox
            disabled={disabled}
            checked={checked === value}
            className={classes.checkbox}
            color="primary"
            onChange={onChange(value)}
          />
          <Text text={text} type="contentLabel3" />
        </div>
      ))}
    </div>
  );
};

DownloadTypeSelector.propTypes = {
  checked: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      text: PropTypes.string
    })
  ).isRequired
};

DownloadTypeSelector.defaultProps = {
  checked: null,
  disabled: false
};

export default DownloadTypeSelector;
