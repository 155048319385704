import React from "react";
import { isEmpty } from "lodash-es";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { onSignIn } from "../../state/user/actions";
import { getRoute, urls } from "../../config/routes";

export default () => {
  const dispatch = useDispatch();
  const { currentUser, isLoading } = useSelector(state => state.user);
  const history = useHistory();

  React.useEffect(() => {
    if ((!currentUser || isEmpty(currentUser)) && !isLoading) {
      dispatch(onSignIn());
    } else {
      history.push(getRoute(urls.cases));
    }
  }, [currentUser, dispatch, isLoading]);

  return null;
};
