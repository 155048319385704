import React, { useState } from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";

// Config
import useTranslate from "../../hooks/useTranslate";

// Images
import mediaTipsModalBg from "../../assets/images/media-tips-modal.png";

// Components
import Button from "../Button";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  modalContent: {
    width: 444
  },
  modalImage: {
    width: "100%"
  },
  modalInfo: {
    padding: theme.spacing(5)
  },
  modalTitle: {
    fontSize: 22,
    marginBottom: theme.spacing(1)
  },
  modalDescription: {
    fontSize: 18,
    marginBottom: theme.spacing(3)
  },
  button: {
    minWidth: 160
  }
}));

const CaseMediaWhatsNew = ({ defaultClosed }) => {
  const classes = useStyles();
  const [closed, setClosed] = useState(defaultClosed);
  const getText = useTranslate();

  const handleCloseDialog = () => setClosed(true);

  return (
    <Dialog onClose={handleCloseDialog} open={!closed}>
      <div className={classes.modalContent}>
        <img src={mediaTipsModalBg} alt="" className={classes.modalImage} />
        <div className={classes.modalInfo}>
          <Text
            className={classes.modalTitle}
            text="tooltips.whatsNew.title"
            type="heading2"
          />
          <Text
            className={classes.modalDescription}
            text="tooltips.whatsNew.description"
            type="subtitle1Big"
          />
          <Button
            className={classes.button}
            color="primary"
            disableElevation
            onClick={handleCloseDialog}
            variant="contained"
          >
            {getText("common.prompts.gotIt")}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

CaseMediaWhatsNew.propTypes = {
  defaultClosed: PropTypes.bool.isRequired
};

export default CaseMediaWhatsNew;
