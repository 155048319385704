import React from "react";
import SectionWrapper from "../../components/SectionWrapper/SectionWrapper";
import CaseIncidentsSubmit from "./CaseIncidentsSubmit";

const CaseIncidentSubmitPage = () => {
  return (
    <SectionWrapper>
      <CaseIncidentsSubmit />
    </SectionWrapper>
  );
};

export default CaseIncidentSubmitPage;
