import React, { memo } from "react";
import { format } from "date-fns";
import clsx from "clsx";
import isEqual from "lodash-es/isEqual";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";

// Icons
import codeIcon from "../../../assets/icons/code.svg";

// Config
import colors from "../../../config/colors";

// Utils
import useTranslate from "../../../hooks/useTranslate";

// Components
import Button from "../../../components/Button";
import Text from "../../../components/Text";
import LogDetailHandler from "./LogDetailHandler";

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 38,
    width: 38,
    marginRight: theme.spacing(1.5)
  },
  username: {
    color: colors.riverBed,
    lineHeight: 1.5
  },
  operationValue: {
    color: colors.riverBed,
    lineHeight: 1.5,
    textTransform: "capitalize"
  },
  dateTime: {
    lineHeight: 1.5
  },
  cellRow: {
    flexDirection: "row"
  },
  cellCol: {
    flexDirection: "column"
  },
  iconButton: {
    position: "absolute",
    top: theme.spacing(2.5),
    right: theme.spacing(2),
    borderColor: theme.palette.common.black,
    padding: 0,
    height: 28,
    width: 28,
    minWidth: "auto"
  }
}));

const LogEntryRow = ({
  classes: { row, cell },
  item: {
    actorCapacity,
    actorDisplayName,
    actorProfilePhotoURL,
    action,
    dateTime,
    id,
    operationName,
    parameters
  },
  onClick
}) => {
  const classes = useStyles();
  const getText = useTranslate();

  return (
    <Grid key={id} className={row} container>
      <Button
        className={classes.iconButton}
        onClick={onClick}
        variant="outlined"
      >
        <img width={12} src={codeIcon} alt="" />
      </Button>
      <Grid className={clsx(cell, classes.cellRow)} item xs={4}>
        <Avatar src={actorProfilePhotoURL} className={classes.avatar}>
          {actorDisplayName.charAt(0)}
        </Avatar>
        <div>
          <Text type="contentLabel2Bold" className={classes.username}>
            {actorDisplayName}
          </Text>
          <Text type="body2">{actorCapacity}</Text>
        </div>
      </Grid>
      <Grid className={clsx(cell, classes.cellCol)} item xs={3}>
        <Text type="contentLabel2Bold" className={classes.operationValue}>
          {operationName}
        </Text>
        <Text type="body2" className={classes.dateTime}>
          {format(
            new Date(dateTime),
            getText("common.dateFormat.onDateAtTime")
          )}
        </Text>
      </Grid>
      <Grid className={clsx(cell, classes.cellCol)} item xs={5}>
        {parameters &&
          Object.keys(parameters).map(key => (
            <LogDetailHandler
              action={action}
              id={key}
              key={key}
              parameters={parameters}
            />
          ))}
      </Grid>
    </Grid>
  );
};

LogEntryRow.propTypes = {
  classes: PropTypes.shape({
    cell: PropTypes.string,
    row: PropTypes.string
  }).isRequired,
  item: PropTypes.shape({
    actorCapacity: PropTypes.string,
    actorDisplayName: PropTypes.string,
    actorProfilePhotoURL: PropTypes.string,
    dateTime: PropTypes.string,
    id: PropTypes.string,
    action: PropTypes.string,
    operationName: PropTypes.string,
    parameters: PropTypes.shape({
      injuryID: PropTypes.string
    })
  }).isRequired,
  onClick: PropTypes.func.isRequired
};

const areEqual = (prevProps, nextProps) => {
  return isEqual(prevProps.item, nextProps.item);
};

export default memo(LogEntryRow, areEqual);
