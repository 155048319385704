import React from "react";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Icons
import arrow from "../../../assets/icons/poligon.svg";

// Config
import bodyCharts from "./bodyCharts.json";

// Components
import Chart from "./Chart";
import Text from "../../Text/Text";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  map: {
    width: "45%"
  },
  label: {
    marginBottom: theme.spacing(5)
  },
  imageWrapper: {
    position: "relative"
  },
  leftIndicator: {
    position: "absolute",
    bottom: 0,
    left: 0
  },
  rightIndicator: {
    position: "absolute",
    bottom: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  arrow: {
    transform: "rotate(180deg) translateY(-25%)"
  }
}));

const InteractiveBodyMap = chart => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.map}>
        <Text
          className={classes.label}
          text="common.valueLabels.front"
          type="contentLabel"
        />
        <div className={classes.imageWrapper}>
          <Chart {...chart} chart={bodyCharts.front} />
          <div className={classes.leftIndicator}>
            <img src={arrow} className={classes.arrow} alt="" />
            <Text text="common.userTypes.Victim" type="body2" />
            <Text text="common.valueLabels.rightSide" type="body2" />
          </div>
          <div className={classes.rightIndicator}>
            <img src={arrow} alt="" />
            <Text text="common.userTypes.Victim" type="body2" />
            <Text text="common.valueLabels.leftSide" type="body2" />
          </div>
        </div>
      </div>
      <div className={classes.map}>
        <Text
          className={classes.label}
          text="common.valueLabels.back"
          type="contentLabel"
        />
        <div className={classes.imageWrapper}>
          <Chart {...chart} chart={bodyCharts.back} />
          <div className={classes.leftIndicator}>
            <img src={arrow} className={classes.arrow} alt="" />
            <Text text="common.userTypes.Victim" type="body2" />
            <Text text="common.valueLabels.leftSide" type="body2" />
          </div>
          <div className={classes.rightIndicator}>
            <img src={arrow} alt="" />
            <Text text="common.userTypes.Victim" type="body2" />
            <Text text="common.valueLabels.rightSide" type="body2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InteractiveBodyMap;
