import { combineReducers } from "redux";
import constant from "lodash-es/constant";

import createReducer from "../../utils/createReducer";

import {
  REQUEST_CASE_INCIDENT,
  REQUEST_CASE_INCIDENT_SUCCESS,
  REQUEST_UPDATE_CASE_INCIDENT_FAIL
} from "./actionTypes";

const isLoading = createReducer(false, {
  [REQUEST_CASE_INCIDENT]: constant(true),
  [REQUEST_CASE_INCIDENT_SUCCESS]: constant(false),
  [REQUEST_UPDATE_CASE_INCIDENT_FAIL]: constant(false)
});

const error = createReducer(null, {
  [REQUEST_CASE_INCIDENT]: constant(null)
});

export default combineReducers({
  error,
  isLoading
});
