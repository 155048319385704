import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(() => ({
  loadingWrapper: {
    height: 240,
    width: 240,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));

// Delay rendering content while the server loads the media source
const delay = process.env.REACT_APP_CONTENT_DELAY || 10000;

const ContentDelay = ({ render }) => {
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    let timer;
    if (isLoading) {
      timer = setTimeout(() => {
        setIsLoading(false);
      }, delay);
    }

    return () => clearTimeout(timer);
  }, [isLoading]);

  if (isLoading) {
    return (
      <div className={classes.loadingWrapper}>
        <CircularProgress />
      </div>
    );
  }

  const handleRetry = () => setIsLoading(true);

  return render(handleRetry);
};

ContentDelay.propTypes = {
  render: PropTypes.func.isRequired
};

export default ContentDelay;
