import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

// Config
import { getRoute, urls } from "../../config/routes";
import customEvents from "../../config/customEvents";

// Actions
import { getCaseDetail, submitCase } from "../../state/caseDetail/actions";

// Hooks
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import { useCaptureVictimButton } from "../CaptureVictimButton/CaptureVictimButton";
import useDialog from "../../hooks/useDialog";

// Components
import ConfirmationButton from "../ConfirmationButton";
import FileUpload from "../FileUpload";

export const useSubmitButton = caseId => {
  const { openVictimUpload, fileUploadProps } = useCaptureVictimButton(caseId);
  const { trackException, trackEvent } = useAppInsights();
  const { data } = useSelector(s => s.caseDetail);
  const dispatch = useDispatch();
  const getDialogProps = useDialog();
  const history = useHistory();

  const onSuccess = () => {
    dispatch(
      getCaseDetail({
        caseId,
        onFail: () => trackException(`Fail to get details from case: ${caseId}`)
      })
    );
  };

  const functions = {
    captureVictimId: openVictimUpload,
    selectLocation: () =>
      history.push(
        getRoute(urls.incident, { caseId, query: "?editing=location" })
      ),
    addInjury: () => history.push(getRoute(urls.addInjury, { caseId })),
    provideDetails: () =>
      history.push(getRoute(urls.submitIncident, { caseId })),
    submit: () => {
      trackEvent(customEvents.SUBMIT_CASE);
      dispatch(
        submitCase({
          caseId,
          onSuccess,
          onFail: () => trackException(`Failed to submit case: ${caseId}`)
        })
      );
    }
  };

  const modalProps = data && getDialogProps(data, functions);

  return { ...modalProps, fileUploadProps };
};

const SubmitCaseButton = ({
  buttonProps,
  caseId,
  children,
  className,
  disabled
}) => {
  const { dialog, onConfirm, fileUploadProps } = useSubmitButton(caseId);
  const { isSubmitting } = useSelector(s => s.caseDetail);

  return (
    <>
      <ConfirmationButton
        className={className}
        dialog={{ ...dialog, isLoading: !!isSubmitting }}
        disabled={disabled}
        disableElevation
        onClick={onConfirm}
        variant="outlined"
        {...buttonProps}
      >
        {children}
      </ConfirmationButton>
      <FileUpload {...fileUploadProps} />
    </>
  );
};

SubmitCaseButton.propTypes = {
  buttonProps: PropTypes.shape({}),
  caseId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool
};

SubmitCaseButton.defaultProps = {
  buttonProps: null,
  className: null,
  disabled: false
};

export default SubmitCaseButton;
