import { combineReducers } from "redux";
import { constant } from "lodash-es";

import createReducer from "../../utils/createReducer";

import SET_FETCH_ERROR from "./actionTypes";
import { REQUEST_CASES_FAIL } from "../cases/actionTypes";
import { REQUEST_CASE_CONTRIB_FAIL } from "../caseDetail/actionTypes";
import {
  ACQUIRE_TOKEN_FAIL,
  REQUEST_USER_DETAILS_FAIL
} from "../user/actionTypes";

const error = createReducer(null, {
  [SET_FETCH_ERROR]: (state, { value }) => value,
  [REQUEST_CASES_FAIL]: constant(true),
  [REQUEST_CASE_CONTRIB_FAIL]: constant(true),
  [REQUEST_CASES_FAIL]: constant(true),
  [ACQUIRE_TOKEN_FAIL]: constant(true),
  [REQUEST_USER_DETAILS_FAIL]: (state, { payload }) => payload
});

export default combineReducers({
  error
});
