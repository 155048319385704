import { combineReducers } from "redux";
import constant from "lodash-es/constant";

import createReducer from "../../utils/createReducer";

import {
  REQUEST_INV,
  REQUEST_INV_SUCCESS,
  REQUEST_INV_FAIL,
  REQUEST_ACCEPT_INV,
  REQUEST_ACCEPT_INV_SUCCESS,
  REQUEST_ACCEPT_INV_FAIL,
  REQUEST_REJECT_INV,
  REQUEST_REJECT_INV_SUCCESS,
  REQUEST_REJECT_INV_FAIL,
  REQUEST_SEND_INV,
  REQUEST_SEND_INV_SUCCESS,
  REQUEST_SEND_INV_FAIL
} from "./actionTypes";

const data = createReducer(null, {
  [REQUEST_INV_SUCCESS]: (state, { payload }) => payload,
  [REQUEST_INV]: constant(null)
});

const error = createReducer(null, {
  [REQUEST_INV]: constant(null),
  [REQUEST_INV_FAIL]: (state, { payload }) => payload
});

const isLoading = createReducer(false, {
  [REQUEST_INV]: constant(true),
  [REQUEST_INV_SUCCESS]: constant(false),
  [REQUEST_INV_FAIL]: constant(false)
});

const isRequesting = createReducer(false, {
  [REQUEST_ACCEPT_INV]: constant(true),
  [REQUEST_ACCEPT_INV_SUCCESS]: constant(false),
  [REQUEST_ACCEPT_INV_FAIL]: constant(false),
  [REQUEST_REJECT_INV]: constant(true),
  [REQUEST_REJECT_INV_SUCCESS]: constant(false),
  [REQUEST_REJECT_INV_FAIL]: constant(false)
});

const requestError = createReducer(null, {
  [REQUEST_ACCEPT_INV]: constant(null),
  [REQUEST_ACCEPT_INV_FAIL]: (state, { payload }) => payload,
  [REQUEST_REJECT_INV]: constant(null),
  [REQUEST_REJECT_INV_FAIL]: (state, { payload }) => payload
});

const sending = createReducer(false, {
  [REQUEST_SEND_INV]: constant(true),
  [REQUEST_SEND_INV_SUCCESS]: constant(false),
  [REQUEST_SEND_INV_FAIL]: constant(false)
});

export default combineReducers({
  data,
  error,
  isLoading,
  isRequesting,
  requestError,
  sending
});
