import { useRouteMatch } from "react-router-dom";
import { urls } from "../config/routes";

const useMatches = () => ({
  login: useRouteMatch(urls.login),
  cases: useRouteMatch(urls.cases),
  createCase: useRouteMatch(urls.createCase),
  caseDetail: useRouteMatch(urls.caseDetail),
  createInjury: useRouteMatch(urls.createInjury),
  injuryDetail: useRouteMatch(urls.injuryDetail),
  annotationTool: useRouteMatch(urls.annotationTool),
  editInjury: useRouteMatch(urls.editInjury),
  contributorDetail: useRouteMatch(urls.contributorDetail),
  infoRequestDetail: useRouteMatch(urls.infoRequestDetail),
  downloadReport: useRouteMatch(urls.downloadReport)
});

export default useMatches;
