import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Material
import { makeStyles } from "@material-ui/core/styles";
import CardActionArea from "@material-ui/core/CardActionArea";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";

// Config
import colors from "../../config/colors";
import useTranslate from "../../hooks/useTranslate";

// Components
import Button from "../Button";
import ChatInput from "./ChatInput";
import ContributorItem from "../ContributorItem";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    padding: theme.spacing(3, 0),
    alignSelf: "flex-end"
  },
  newRequest: {
    margin: "0 auto",
    padding: theme.spacing(1.5),
    width: ({ width }) => width
  },
  dialogPaper: {
    maxWidth: "initial",
    paddingBottom: theme.spacing(1)
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "70vw"
  },
  heading: {
    padding: theme.spacing(2, 4)
  },
  contactItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: theme.spacing(2, 3)
  },
  disabledContact: {
    backgroundColor: colors.wildLand
  },
  checkbox: {
    marginRight: theme.spacing(1)
  }
}));

const NewRequest = ({ contributors, onSubmit, width }) => {
  const classes = useStyles({ width });
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState({});
  const getText = useTranslate();

  const handleOpen = status => () => {
    if (!status) setSelected({});
    setOpen(status);
  };

  const handleSelect = id => () => {
    setSelected({
      ...selected,
      [id]: !selected[id]
    });
  };

  const handleSubmit = message => {
    onSubmit({
      parties: Object.keys(selected).filter(key => selected[key]),
      message
    });
    setOpen(false);
    setSelected({});
  };

  const contacts = contributors.sort((a, b) => {
    let value = 0;
    if (a.fullName < b.fullName) value = -1;
    if (a.fullName > b.fullName) value = 1;
    return value;
  });

  const atLeastOneSelected = Object.keys(selected).some(key => selected[key]);

  return (
    <div className={classes.buttonWrapper}>
      <Button
        className={classes.newRequest}
        color="primary"
        disabled={!contacts || contacts.length === 0}
        disableElevation
        onClick={handleOpen(true)}
        variant="contained"
      >
        {getText("furtherInfoPage.createInfoRequest.title")}
      </Button>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        onClose={handleOpen(false)}
        open={open}
      >
        <div className={classes.content}>
          <Text
            className={classes.heading}
            text="furtherInfoPage.requestFromTitle"
            type="body2"
          />
          {contacts.map(contact => (
            <CardActionArea
              className={clsx(classes.contactItem, {
                [classes.disabledContact]: !contact.invitationAccepted
              })}
              key={contact.id}
              onClick={
                contact.invitationAccepted ? handleSelect(contact.id) : null
              }
              disabled={!contact.invitationAccepted}
            >
              <Checkbox
                checked={Boolean(selected[contact.id])}
                className={classes.checkbox}
                color="primary"
                disabled={!contact.invitationAccepted}
                disableRipple
                value=""
              />
              <ContributorItem user={contact} />
            </CardActionArea>
          ))}
        </div>
        <ChatInput
          disabled={!atLeastOneSelected}
          hideMenu
          onSend={handleSubmit}
        />
      </Dialog>
    </div>
  );
};

NewRequest.propTypes = {
  contributors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSubmit: PropTypes.func.isRequired,
  width: PropTypes.number
};

NewRequest.defaultProps = {
  width: 200
};

export default NewRequest;
