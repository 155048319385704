import {
  REQUEST_ACCEPT_INV_FAIL,
  REQUEST_ACCEPT_INV_SUCCESS,
  REQUEST_ACCEPT_INV,
  REQUEST_INV_FAIL,
  REQUEST_INV_SUCCESS,
  REQUEST_INV,
  REQUEST_REJECT_INV_FAIL,
  REQUEST_REJECT_INV_SUCCESS,
  REQUEST_REJECT_INV,
  REQUEST_SEND_INV_FAIL,
  REQUEST_SEND_INV_SUCCESS,
  REQUEST_SEND_INV
} from "./actionTypes";

// Actions
import { getCases } from "../cases/actions";

// Utils
import {
  acceptInvitationUrl,
  caseForceInvitationUrl,
  caseInvitationUrl,
  getInvitationsUrl,
  rejectInvitationUrl
} from "../../config/apiConfig";
import { getInvitationValues, invitationTypes } from "../../config/values";
import { parseFetchOptions } from "../user/actions";
import errors from "../../utils/errors";

const requestInvitations = () => ({
  type: REQUEST_INV
});

const requestInvitationsSuccess = payload => ({
  type: REQUEST_INV_SUCCESS,
  payload
});

const requestInvitationsFail = payload => ({
  type: REQUEST_INV_FAIL,
  payload
});

export const getInvitations = onFail => async dispatch => {
  dispatch(requestInvitations());

  dispatch(
    parseFetchOptions(async options => {
      const response = await fetch(getInvitationsUrl(), {
        method: "GET",
        headers: options.headers
      }).catch(error => {
        if (onFail) onFail();
        dispatch(requestInvitationsFail(error));
      });

      if (response && response.ok) {
        const data = await response.json();
        dispatch(requestInvitationsSuccess(data));
      } else {
        if (onFail) onFail();
        dispatch(requestInvitationsFail(errors.responseError(response)));
      }
    })
  );
};

// ACCEPT INVITATION
const requestAcceptInvitation = () => ({
  type: REQUEST_ACCEPT_INV
});

const requestAcceptInvitationSuccess = payload => ({
  type: REQUEST_ACCEPT_INV_SUCCESS,
  payload
});

const requestAcceptInvitationFail = payload => ({
  type: REQUEST_ACCEPT_INV_FAIL,
  payload
});

export const acceptInvitation = caseId => async dispatch => {
  dispatch(requestAcceptInvitation());

  dispatch(
    parseFetchOptions(async options => {
      const response = await fetch(acceptInvitationUrl(caseId), {
        method: "PUT",
        headers: options.headers
      }).catch(error => {
        dispatch(requestAcceptInvitationFail(error));
      });

      if (response && response.ok) {
        dispatch(requestAcceptInvitationSuccess());
        dispatch(getInvitations());
        dispatch(getCases());
      } else {
        dispatch(requestAcceptInvitationFail(errors.responseError(response)));
      }
    })
  );
};

// REJECT INVITATION
const requestRejectInvitation = () => ({
  type: REQUEST_REJECT_INV
});

const requestRejectInvitationSuccess = payload => ({
  type: REQUEST_REJECT_INV_SUCCESS,
  payload
});

const requestRejectInvitationFail = payload => ({
  type: REQUEST_REJECT_INV_FAIL,
  payload
});

export const rejectInvitation = caseId => async dispatch => {
  dispatch(requestRejectInvitation());

  dispatch(
    parseFetchOptions(async options => {
      const response = await fetch(rejectInvitationUrl(caseId), {
        method: "PUT",
        headers: options.headers
      }).catch(error => {
        dispatch(requestRejectInvitationFail(error));
      });

      if (response && response.ok) {
        dispatch(requestRejectInvitationSuccess());
        dispatch(getInvitations());
      } else {
        dispatch(requestRejectInvitationFail(errors.responseError(response)));
      }
    })
  );
};

// SEND INVITATION
const requestSendInvitation = () => ({
  type: REQUEST_SEND_INV
});

const requestSendInvitationSuccess = payload => ({
  type: REQUEST_SEND_INV_SUCCESS,
  payload
});

const requestSendInvitationFail = payload => ({
  type: REQUEST_SEND_INV_FAIL,
  payload
});

export const sendInvitation =
  ({ caseId, data, onFinish }) =>
  async dispatch => {
    dispatch(requestSendInvitation());

    const body = JSON.stringify({
      ...data,
      email: data.email === "" ? null : data.email,
      phoneNumber:
        data.phoneNumber.nationalNumber === "" ? null : data.phoneNumber
    });

    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(caseInvitationUrl(caseId), {
          method: "POST",
          headers: {
            ...options.headers,
            "Content-Type": "application/json"
          },
          body
        }).catch(e => dispatch(requestSendInvitationFail(e)));

        const { type, ...values } = await getInvitationValues(response);

        if (type) {
          dispatch(
            requestSendInvitationSuccess(type === invitationTypes.match)
          );

          onFinish({
            success: true,
            type,
            ...values
          });
        } else {
          dispatch(requestSendInvitationFail(errors.responseError(response)));
          onFinish({ success: false });
        }
      })
    );
  };

export const forceSendInvitation =
  ({ caseId, invitationId, onFinish }) =>
  async dispatch => {
    dispatch(requestSendInvitation());

    const handleFinish = v => {
      if (onFinish) onFinish(v);
    };

    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(
          caseForceInvitationUrl(caseId, invitationId),
          {
            method: "PUT",
            headers: options.headers
          }
        ).catch(e => {
          dispatch(requestSendInvitationFail(e));
          handleFinish(false);
        });

        if (response && response.ok) {
          dispatch(requestSendInvitationSuccess(true));
          handleFinish(true);
        } else {
          dispatch(requestSendInvitationFail(errors.responseError(response)));
          handleFinish(false);
        }
      })
    );
  };
