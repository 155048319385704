import React from "react";
import { useParams, useHistory } from "react-router-dom";

// Config
import { getRoute, urls } from "../../config/routes";

// Components
import Disclaimer from "../../components/Disclaimer";
import InjuryDetailPanel from "../../components/InjuryDetailPanel";
import SectionWrapper from "../../components/SectionWrapper";

const InjuryDetailPage = () => {
  const { caseId, injuryId } = useParams();
  const history = useHistory();

  React.useEffect(() => {
    if (!injuryId || injuryId === "undefined")
      history.push(getRoute(urls.caseDetail, { caseId }));
  }, [history, injuryId]);

  return (
    <SectionWrapper>
      <Disclaimer text="injuryDetailPage.disclaimer" />
      <InjuryDetailPanel caseId={caseId} injuryId={injuryId} />
    </SectionWrapper>
  );
};

export default InjuryDetailPage;
