import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// lodash
import get from "lodash-es/get";
import isEmpty from "lodash-es/isEmpty";

// Config
import {
  getCaseContributorsUrl,
  getMessageUrl,
  signalRNotificationsUrl
} from "../../config/apiConfig";
import { getRoute, urls } from "../../config/routes";

// Hooks
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import useConnection from "../../hooks/useConnection";

// Actions
import { parseFetchOptions } from "../../state/user/actions";
import { updateNotifications } from "../../state/notifications/actions";

// Components
import NewMessageSnackbar from "./NewMessageSnackbar";

const notificationTypes = {
  infoRequest: "InfoRequestMessageReceived",
  caseUpdated: "CaseUpdated"
};

const NotificationsHandler = () => {
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [last, setLast] = useState(null);
  const dispatch = useDispatch();
  const { trackException } = useAppInsights();

  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (!open && !isEmpty(data)) setOpen(true);
  }, [data]);

  useConnection({
    url: signalRNotificationsUrl,
    functions: {
      receiveNotification: res => {
        const notification = JSON.parse(res);
        const {
          caseId,
          contributorId,
          infoRequestId,
          infoRequestMessageId,
          message,
          notificationType
        } = notification;

        if (
          notificationType === notificationTypes.infoRequest &&
          infoRequestMessageId !== last
        ) {
          setLast(infoRequestMessageId);
          updateNotifications(notification);
          if (infoRequestId) {
            dispatch(
              parseFetchOptions(options => {
                fetch(
                  getMessageUrl(caseId, infoRequestId, infoRequestMessageId),
                  options
                )
                  .then(r => r.json())
                  .then(content =>
                    setData({
                      to: getRoute(urls.infoRequestDetail, {
                        caseId,
                        infoRequestId
                      }),
                      message: content.text,
                      username: get(content, "sender.fullName")
                    })
                  )
                  .catch(() => {
                    trackException("Failed to get notification message");
                  });
              })
            );
          }
        }

        if (notificationType === notificationTypes.caseUpdated) {
          dispatch(
            parseFetchOptions(options => {
              fetch(getCaseContributorsUrl(caseId), options)
                .then(r => r.json())
                .then(content => {
                  const sender = content.find(
                    contributor => contributor.id === contributorId
                  );
                  setData({
                    to: getRoute(urls.caseDetail, { caseId }),
                    message,
                    username: sender.fullName
                  });
                })
                .catch(() => {
                  trackException("Failed to get notification message");
                });
            })
          );
        }
      }
    }
  });

  return <NewMessageSnackbar open={open} onClose={handleClose} data={data} />;
};

export default React.memo(NotificationsHandler);
