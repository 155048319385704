import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

// Icons
import icon from "../../assets/icons/illustration_3.svg";

// Actions
import { getCaseContributors } from "../../state/caseDetail/actions";

// Config
import { getRoute, urls } from "../../config/routes";
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import styles from "../../config/styles";
import useTranslate from "../../hooks/useTranslate";

// Components
import Button from "../Button";
import ConfirmationDialog from "../ConfirmationDialog";
import ContributorsList from "../ContributorsList";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    border: styles.values.border
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3, 0)
  },
  wrapper: {
    display: "flex",
    padding: theme.spacing(0, 3.5)
  },
  title: {
    textTransform: "uppercase",
    marginBottom: theme.spacing(3)
  },
  inviteButton: {
    width: "auto",
    marginLeft: "auto"
  },
  text: {
    textTransform: "uppercase"
  },
  loadingWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(2)
  }
}));

const CaseContributors = ({
  capacity,
  caseId,
  creatorId,
  isDraft,
  isWitness
}) => {
  const { trackException } = useAppInsights();
  const classes = useStyles();
  const dispatch = useDispatch();
  const getText = useTranslate();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const { userDetails } = useSelector(s => s.user);
  const { contributors, data, isLoadingContributors } = useSelector(
    s => s.caseDetail
  );

  useEffect(() => {
    if (caseId && caseId !== "")
      dispatch(
        getCaseContributors(caseId, () =>
          trackException(`Failed to get contributors for case: ${caseId}`)
        )
      );
  }, [caseId, dispatch, trackException]);

  const contributorList = [
    {
      ...userDetails,
      capacity,
      id: "currentUser",
      invitationAccepted: true,
      profilePictureURL: userDetails && userDetails.profilePhotoURL,
      isCreator: userDetails && creatorId === userDetails.id
    },
    ...contributors
  ];

  const handleInvite = () => {
    if (isDraft && userDetails.id !== data.creatorId) setOpen(true);
    else history.push(getRoute(urls.inviteContributor, { caseId }));
  };

  const handleClose = () => setOpen(false);

  return (
    <Paper elevation={0} className={classes.root}>
      <div className={classes.content}>
        <div className={classes.wrapper}>
          <Text
            className={classes.title}
            text="caseMenu.caseContributors.title"
            type="contentLabel"
          />
        </div>
        {isLoadingContributors || !userDetails ? (
          <div className={classes.loadingWrapper}>
            <CircularProgress />
          </div>
        ) : (
          <ContributorsList caseId={data.id} contributors={contributorList} />
        )}
        <div className={classes.wrapper}>
          {!isWitness && (
            <Button className={classes.inviteButton} onClick={handleInvite}>
              <Text
                className={classes.text}
                text="caseMenu.caseContributors.inviteButton"
                type="status"
              />
            </Button>
          )}
        </div>
      </div>
      <ConfirmationDialog
        button={getText("common.prompts.okGotIt")}
        hideCancel
        icon={icon}
        onClose={handleClose}
        onConfirm={handleClose}
        open={open}
        textKeys={["caseMenu.caseContributors.noPermissionsDialog.title"]}
        title={getText("caseMenu.caseContributors.noPermissionsDialog.title")}
        type="submit"
      />
    </Paper>
  );
};

CaseContributors.propTypes = {
  capacity: PropTypes.string.isRequired,
  caseId: PropTypes.string.isRequired,
  creatorId: PropTypes.string.isRequired,
  isDraft: PropTypes.bool.isRequired,
  isWitness: PropTypes.bool.isRequired
};

export default CaseContributors;
