import React, { useState } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";

// Formik - Material
import { makeStyles } from "@material-ui/core/styles";

// Config

// Components
import Button from "../Button/Button";
import DurationInput from "./DurationInput";

const useStyles = makeStyles(() => ({
  durationChoices: {
    display: "flex",
    justifyContent: "flex-end"
  }
}));

const DurationInputModal = ({
  className,
  disabled,
  label,
  values,
  setFieldValue,
  open,
  onClose
}) => {
  const classes = useStyles();
  const { days, hours, minutes } = values;
  const [duration, setDuration] = useState({ days, hours, minutes });

  const handleSubmit = () => {
    setFieldValue("days", duration.days);
    setFieldValue("hours", duration.hours);
    setFieldValue("minutes", duration.minutes);
    onClose();
  };

  const handleRemove = () => {
    setFieldValue("days", null);
    setFieldValue("hours", null);
    setFieldValue("minutes", null);
    onClose();
  };

  const handleCancel = () => {
    setDuration({ days, hours, minutes });
    onClose();
  };

  const handleChange = ({ target }) => {
    setDuration({
      ...duration,
      [target.name]: target.value
    });
  };

  return (
    <Dialog open={open && !disabled} classes={{ paper: className }}>
      <DurationInput
        onChange={handleChange}
        {...{ disabled, values: duration, label }}
      />
      <div className={classes.durationChoices}>
        <Button color="primary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button color="danger" onClick={handleRemove}>
          Delete
        </Button>
        <Button color="primary" onClick={handleSubmit}>
          Ok
        </Button>
      </div>
    </Dialog>
  );
};

DurationInputModal.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  values: PropTypes.shape({
    days: PropTypes.number,
    hours: PropTypes.number,
    minutes: PropTypes.number
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

DurationInputModal.defaultProps = {
  className: "",
  disabled: false,
  label: null
};

export default DurationInputModal;
