import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";

// Config
import { userTypes } from "../../config/values";
import styles from "../../config/styles";

// Icons
import removeUser from "../../assets/icons/removeUser.svg";

// Hooks
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import useTranslate from "../../hooks/useTranslate";

// Actions
import { deleteContributor } from "../../state/caseDetail/actions";

// Components
import ConfirmationButton from "../ConfirmationButton";
import ContentCardHeader from "../ContentCardHeader";
import ContributorStatusChip from "../ContributorStatusChip";
import LoadingSection from "../LoadingSection";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    border: styles.values.border,
    marginBottom: theme.spacing(1)
  },
  content: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(3, 5, 5)
  },
  avatar: {
    height: 130,
    width: 130,
    marginRight: theme.spacing(3)
  },
  avatarImg: {
    position: "absolute",
    minWidth: "1000%",
    minHeight: "1000%",
    left: "50%",
    top: "50%",
    transform: "translateX(-50%) translateY(-50%) scale(0.1)"
  },
  details: {
    display: "flex",
    flexDirection: "column"
  },
  name: {
    marginBottom: theme.spacing(1.5)
  },
  capacity: {
    marginBottom: theme.spacing(2.25)
  },
  iconButton: {
    padding: 0,
    height: 40,
    width: 40
  }
}));

const ContributorDetailPanel = ({
  caseId,
  contributor,
  creatorId,
  deleting,
  loading,
  userCapacity
}) => {
  const { trackException } = useAppInsights();
  const classes = useStyles();
  const dispatch = useDispatch();
  const getText = useTranslate();

  const handleDelete = () => {
    dispatch(
      deleteContributor({
        caseId,
        contributorId: contributor.id,
        onFail: () =>
          trackException(
            `Failed to delete contributor: ${contributor.id}, from case: ${caseId}`
          )
      })
    );
  };

  const isCreator = contributor.id === creatorId;

  return (
    <Paper elevation={0} className={classes.root}>
      <ContentCardHeader>
        <Text text="common.valueLabels.contributor" type="heading2" />
        {!isCreator && userCapacity !== userTypes.Witness && (
          <ConfirmationButton
            className={classes.iconButton}
            dialog={{
              isLoading: deleting,
              button: getText("common.prompts.remove"),
              title: getText(
                "caseDetailPage.contributorDetails.removeContributorDialog.title"
              )
            }}
            onClick={handleDelete}
            variant="outlined"
            type="icon"
          >
            <img src={removeUser} alt="" />
          </ConfirmationButton>
        )}
      </ContentCardHeader>
      {!contributor || loading ? (
        <LoadingSection noBackground normalHeight />
      ) : (
        <div className={classes.content}>
          <Avatar
            src={contributor.profilePictureURL}
            variant="rounded"
            className={classes.avatar}
            imgProps={{ className: classes.avatarImg }}
          />
          <div className={classes.details}>
            <Text type="heading1" className={classes.name}>
              {isCreator ? (
                getText("common.valueLabels.usernameOwner", {
                  1: contributor.fullName
                })
              ) : (
                <>
                  {contributor.fullName}
                  <ContributorStatusChip
                    isCreator={isCreator}
                    joined={contributor.invitationAccepted}
                  />
                </>
              )}
            </Text>
            <Text
              text={`common.userTypes.${contributor.capacity}`}
              type="contentLabel3"
              className={classes.capacity}
            />
          </div>
        </div>
      )}
    </Paper>
  );
};

ContributorDetailPanel.propTypes = {
  caseId: PropTypes.string.isRequired,
  contributor: PropTypes.shape({
    capacity: PropTypes.string,
    fullName: PropTypes.string,
    id: PropTypes.string,
    invitationAccepted: PropTypes.bool,
    isCreator: PropTypes.bool,
    profilePictureURL: PropTypes.string
  }),
  creatorId: PropTypes.string.isRequired,
  deleting: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  userCapacity: PropTypes.string.isRequired
};

ContributorDetailPanel.defaultProps = {
  contributor: null
};

export default ContributorDetailPanel;
