import { format } from "date-fns";

export const fromDurationToMinutes = ({ days = 0, hours = 0, minutes }) => {
  let total = null;
  if (days != null) total += Number(days) * 60 * 24;
  if (hours != null) total += Number(hours) * 60;
  if (minutes != null) total += Number(minutes);
  return total;
};

export const fromMinutesToDuration = minDuration => {
  const days =
    minDuration === null ? minDuration : Math.floor(minDuration / 60 / 24);
  const hours =
    minDuration === null ? minDuration : Math.floor((minDuration / 60) % 24);
  const minutes = minDuration === null ? minDuration : minDuration % 60;
  return {
    days,
    hours,
    minutes
  };
};

export const getDurationDisplay = ({ days = 0, hours = 0, minutes = 0 }) => {
  const dayString = days !== null && days !== 0 ? `${days} Days ` : "";
  const hoursString = hours !== null && hours !== 0 ? `${hours} Hours ` : "";
  let minutesString = "";
  if (minutes !== null) {
    if (minutes !== 0) minutesString = `${minutes} Minutes`;
    // If days and hours are equal to null/undef/0
    else if (!days && !hours) minutesString = "Less than a minute.";
  }

  return dayString + hoursString + minutesString;
};

export const fromIncidentToForm = (
  {
    location = {},
    dateTime = null,
    duration = null,
    descriptions = [],
    witnesses = [],
    perpetrators = []
  },
  isDraft,
  isCreator,
  userId
) => {
  // Create default initial values
  // Asumes case is submitted
  // Hide content that can only be edited and removed in draft
  const initialValues = {
    descriptions: [""],
    witnesses: [""],
    perpetrators: [""]
  };

  if (isCreator) {
    // Add editable values only if needed
    Object.assign(initialValues, {
      dateTime,
      location: (location && location.name) || "",
      ...fromMinutesToDuration(duration)
    });
  }

  if (isDraft) {
    const currentValues = { descriptions, witnesses, perpetrators };
    initialValues.editingId = {};

    const loadEditableValues = key => {
      const valueList = []; // Create array of texts
      const idList = []; // Create array of IDs

      // Populate arrays with editable data only
      currentValues[key].forEach(({ text, id, author }) => {
        if (isCreator || author.id === userId) {
          valueList.push(text);
          idList.push(id);
        }
      });

      initialValues[key] = valueList;
      initialValues.editingId[key] = idList;
    };

    // Show hidden content in draft
    loadEditableValues("witnesses");
    loadEditableValues("perpetrators");
    loadEditableValues("descriptions");
  }

  return initialValues;
};

const removeExisting = (values, existing) =>
  values.filter((_, index) => existing[index] === undefined);

const elementsArray = ["descriptions", "witnesses", "perpetrators"];

export default ({ coord, incident, isCreator, isDraft, values }) => {
  const sentValues = {};

  // filter already existing values to prevent sending duplicates
  elementsArray.forEach(key => {
    const filteredArray = isDraft
      ? removeExisting(values[key], values.editingId[key])
      : values[key];
    if (filteredArray.length > 0) sentValues[key] = filteredArray;
  });

  if (isCreator) {
    // assign duration value only if changed
    const newDuration = fromDurationToMinutes(values);
    if (newDuration) sentValues.duration = newDuration;

    if (values.dateTime && values.dateTime !== incident.dateTime) {
      // assign dateTime value only if changed
      sentValues.dateTime = format(
        new Date(values.dateTime),
        "yyyy-MM-dd'T'hh:mm:ss"
      );
    }

    if (
      coord &&
      (!incident.location || values.location !== incident.location.name)
    ) {
      // assign location value only if changed
      sentValues.location = {
        name: values.location,
        coordinate: {
          // Checks if coordinates are comming from server values or input values
          latitude: coord.lat ? coord.lat() : coord.latitude,
          longitude: coord.lng ? coord.lng() : coord.longitude
        }
      };
    }
  }

  if (!isDraft) {
    Object.keys(incident).forEach(key => {
      if (elementsArray.includes(key)) {
        const filteredArray = sentValues[key].filter(i => i !== "");
        if (filteredArray.length > 0) sentValues[key] = filteredArray;
      }
      // First, checks if incident has a key and also if the value of that key is not null/undefined/0
      // Secondly, checks if the sent value is invalid (null/undefined)
      // If any is true, it deletes the atribute.
      else if (
        (key in incident && incident[key]) ||
        (!sentValues[key] && sentValues[key] !== 0)
      ) {
        delete sentValues[key];
      }
    });
  }
  // Stops users from sending locations that don't exist.
  else if (
    sentValues.location &&
    (!sentValues.location.coordinate || !sentValues.location.name)
  )
    sentValues.location = null;
  return sentValues;
};
