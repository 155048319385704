import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Formik - Material
import { DateTimePicker, DatePicker } from "formik-material-ui-pickers";
import { Field, FastField } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Select } from "formik-material-ui";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import FormikAutocomplete from "../FormikAutocomplete";

// Config
import styles from "../../config/styles";

// Icons
import calendar from "../../assets/icons/calendar.svg";
import gps from "../../assets/icons/gps.svg";

// Components
import Text from "../Text";
import useTranslate from "../../hooks/useTranslate";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(3),
    width: "100%"
  },
  textField: {
    width: "100%"
  },
  select: {
    padding: theme.spacing(0, 4, 0, 2.5),
    height: 34,
    display: "flex",
    alignItems: "center",
    borderRadius: theme.shape.borderRadius,
    fontSize: 15,
    minHeight: 46
  },
  inputRoot: styles.mixins.inputRoot,
  input: styles.mixins.input,
  multiline: {
    padding: theme.spacing(1, 2)
  },
  dateInput: {
    paddingRight: 0,
    "& .MuiInputAdornment-positionEnd": {
      marginRight: theme.spacing(1.5)
    }
  },
  locationInput: {
    paddingRight: 0,
    "& .MuiInputAdornment-positionEnd": {
      marginRight: theme.spacing(1.5)
    }
  },
  focused: {
    borderColor: theme.palette.primary.main
  },
  valueLabel: {
    marginBottom: theme.spacing(1.5)
  }
}));

const getFieldProps = ({
  autoComplete,
  autoFocus,
  classes,
  multiline,
  type,
  maxDate,
  options,
  onChange,
  error
}) =>
  ({
    date: {
      maxDate,
      className: clsx(classes.inputRoot, classes.input, classes.dateInput),
      component: DatePicker,
      format: "dd/MM/yyyy",
      disableFuture: true,
      InputProps: {
        endAdornment: (
          <InputAdornment position="end">
            <img src={calendar} alt="" />
          </InputAdornment>
        ),
        disableUnderline: true
      }
    },
    dateTime: {
      maxDate,
      className: clsx(classes.inputRoot, classes.input, classes.dateInput),
      component: DateTimePicker,
      format: "dd/MM/yyyy HH:mm",
      disableFuture: true,
      InputProps: {
        endAdornment: (
          <InputAdornment position="end">
            <img src={calendar} alt="" />
          </InputAdornment>
        ),
        disableUnderline: true
      }
    },
    text: {
      multiline,
      component: TextField,
      className: classes.textField,
      helperText: error,
      InputProps: {
        error: !!error,
        classes: {
          root: classes.inputRoot,
          input: clsx(classes.input, { [classes.multiline]: multiline }),
          focused: classes.focused
        },
        disableUnderline: true,
        inputProps: {
          autoComplete
        },
        autoComplete
      }
    },
    location: {
      options,
      component: FormikAutocomplete,
      onChange,
      helperText: error,
      error: !!error,
      textFieldProps: {
        InputProps: {
          className: clsx(
            classes.inputRoot,
            classes.input,
            classes.locationInput
          ),
          classes: {
            focused: classes.focused
          },
          autoFocus,
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <img src={gps} alt="" />
            </InputAdornment>
          )
        }
      }
    },
    select: {
      component: Select,
      className: classes.inputRoot,
      disableUnderline: true,
      classes: {
        select: classes.select
      }
    }
  })[type];

const CustomField = ({
  autoComplete,
  autoFocus,
  className,
  label,
  literalOptionText,
  maxDate,
  multiline,
  name,
  onChange,
  options,
  type,
  error,
  useFastField,
  ...rest
}) => {
  const classes = useStyles();
  const getText = useTranslate();

  const fieldProps = getFieldProps({
    autoComplete,
    autoFocus,
    classes,
    maxDate,
    multiline,
    onChange,
    type,
    options,
    error
  });

  const displayMenuItems = type === "select" && Boolean(options);
  const FieldComponent = useFastField ? FastField : Field;

  return (
    <div className={clsx(classes.root, className)}>
      {label && (
        <Text type="valueLabel" className={classes.valueLabel}>
          {label}
        </Text>
      )}
      <FieldComponent id={name} name={name} {...fieldProps} {...rest}>
        {displayMenuItems &&
          options.map(({ value, text, props }) => (
            <MenuItem value={value} key={value} {...props}>
              {literalOptionText ? text : getText(text)}
            </MenuItem>
          ))}
      </FieldComponent>
    </div>
  );
};

CustomField.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  literalOptionText: PropTypes.bool,
  maxDate: PropTypes.string,
  multiline: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  type: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.node,
      key: PropTypes.string,
      value: PropTypes.string,
      props: PropTypes.shape({})
    })
  ),
  useFastField: PropTypes.bool
};

CustomField.defaultProps = {
  autoComplete: "on",
  autoFocus: false,
  className: "",
  error: "",
  label: null,
  literalOptionText: false,
  maxDate: undefined,
  multiline: false,
  onChange: undefined,
  options: null,
  type: "text",
  useFastField: false
};

export default React.memo(CustomField);
