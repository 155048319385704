import React from "react";

// Material
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Menu from "@material-ui/core/Menu";

// Config
import { externalRoutes } from "../../config/routes";
import colors from "../../config/colors";
import styles from "../../config/styles";
import useTranslate from "../../hooks/useTranslate";

// Icons
import appstore from "../../assets/logos/appstore.svg";
import arrowup from "../../assets/icons/chevron.svg";
import playstore from "../../assets/logos/playstore.svg";
import qrStore from "../../assets/qrcodes/qr-stores.png";
import ukasTrust from "../../assets/logos/ukas-trust.png";

// Components
import Link from "../Link";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  appContainer: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
    cursor: "pointer"
  },
  button: {
    padding: theme.spacing(2),
    height: "100%",
    backgroundColor: ({ anchorEl }) => anchorEl && colors.pictonBlue
  },
  text: {
    fontFamily: "Gibson SemiBold",
    fontSize: 14,
    margin: "auto",
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    height: "100%",
    color: ({ anchorEl }) =>
      anchorEl ? theme.palette.primary.contrastText : "inherit"
  },
  storeContainer: {
    display: "flex",
    flexDirection: "column",
    borderRight: styles.values.border,
    padding: theme.spacing(3, 7),
    width: 380,

    "&:focus": {
      outline: "none"
    }
  },
  description: {
    margin: theme.spacing(1, 0),
    lineHeight: 1.2
  },
  storeLink: {
    margin: theme.spacing(1, 0),
    "&:hover": {
      opacity: 0.7
    }
  },
  qrLink: {
    display: "flex",
    width: "50%",
    margin: theme.spacing(-2)
  },
  storeImg: {
    display: "block",
    width: "100%"
  },
  appView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: theme.spacing(2, 0)
  },
  stores: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    height: "100%",
    marginLeft: theme.spacing(1)
  },
  arrow: {
    transform: "rotateX(180deg)",
    marginLeft: theme.spacing(1),
    filter: ({ anchorEl }) => `brightness(${anchorEl ? 2 : 0.5})`,
    width: "1.5em",
    height: "0.6em"
  },
  paper: {
    boxShadow: styles.values.boxShadowMedium
  },
  patent: {
    borderBottom: styles.values.border,
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(3.5)
  },
  cert: {
    fontSize: 12,
    marginBottom: theme.spacing(2)
  },
  certWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  bsiLogo: {
    marginRight: theme.spacing(1)
  }
}));

const GetTheApp = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles({ anchorEl });
  const getText = useTranslate();

  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <div className={classes.appContainer}>
      <ButtonBase onClick={handleClick} className={classes.button}>
        <Text type="valueLabel" className={classes.text}>
          {getText("header.getTheApp.button")}{" "}
          <img className={classes.arrow} src={arrowup} alt="" />
        </Text>
      </ButtonBase>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        classes={{ paper: classes.paper }}
        elevation={1}
        getContentAnchorEl={null}
        id="customized-menu"
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <div className={classes.storeContainer}>
          <Text text="header.getTheApp.title" type="heading3" />
          <Text
            className={classes.description}
            text="header.getTheApp.description"
            type="subtitle1Small"
          />
          <div className={classes.appView}>
            <img className={classes.qrLink} src={qrStore} alt="AppStore" />
            <div className={classes.stores}>
              <Link
                className={classes.storeLink}
                to={externalRoutes.appStore}
                target="_blank"
              >
                <img
                  className={classes.storeImg}
                  src={appstore}
                  alt="AppStore"
                />
              </Link>
              <Link
                className={classes.storeLink}
                to={externalRoutes.googlePlay}
                target="_blank"
              >
                <img
                  className={classes.storeImg}
                  src={playstore}
                  alt="PlayStore"
                />
              </Link>
            </div>
          </div>
          <Text
            className={classes.patent}
            text="header.getTheApp.patent"
            type="subtitleLight"
          />
          <div className={classes.certWrapper}>
            <img height={100} src={ukasTrust} alt="" />
          </div>
        </div>
      </Menu>
    </div>
  );
};

export default GetTheApp;
