import React from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// Config
import colors from "../../config/colors";
import useTranslate from "../../hooks/useTranslate";

const useStyles = makeStyles(theme => ({
  heading1: {
    fontFamily: ["Gibson SemiBold", "sans-serif"].join(","),
    letterSpacing: 0.5,
    fontSize: 30,
    lineHeight: 1
  },
  heading2: {
    fontFamily: ["Gibson SemiBold", "sans-serif"].join(","),
    letterSpacing: 0.5,
    fontSize: 24,
    lineHeight: 1.5
  },
  heading3: {
    fontFamily: ["Gibson SemiBold", "sans-serif"].join(","),
    letterSpacing: 0.5,
    fontSize: 20,
    lineHeight: 1,
    color: colors.riverBed
  },
  heading4: {
    fontFamily: ["Gibson SemiBold", "sans-serif"].join(","),
    letterSpacing: 0.5,
    fontSize: 18
  },
  heading5: {
    fontFamily: ["Gibson SemiBold", "sans-serif"].join(","),
    letterSpacing: 0.5,
    fontSize: 18
  },
  heading6: {
    fontFamily: ["Gibson SemiBold", "sans-serif"].join(","),
    letterSpacing: 0.5,
    fontSize: 16
  },
  subtitle1Big: {
    fontFamily: ["Gibson Regular", "sans-serif"].join(","),
    fontSize: 16,
    lineHeight: 1
  },
  subtitle1: {
    fontFamily: ["Gibson Regular", "sans-serif"].join(","),
    fontSize: 15,
    lineHeight: 1
  },
  subtitle1Small: {
    fontFamily: ["Gibson Regular", "sans-serif"].join(","),
    fontSize: 14,
    lineHeight: 1
  },
  subtitle2: {
    fontSize: 15,
    lineHeight: 1
  },
  subtitle3: {
    fontSize: 16,
    lineHeight: 1,
    color: colors.riverBed
  },
  subtitleBold: {
    fontSize: 16,
    lineHeight: 1,
    color: colors.riverBed,
    fontWeight: theme.typography.fontWeightBold
  },
  subtitleLight: {
    fontFamily: ["Gibson Light", "sans-serif"].join(","),
    fontSize: 12,
    fontWeight: theme.typography.fontWeightLight
  },
  status: {
    fontFamily: ["Gibson Regular", "sans-serif"].join(","),
    fontSize: 14,
    lineHeight: 1,
    color: colors.dodgerBlue
  },
  contentLabel: {
    fontSize: 12,
    lineHeight: 1.5,
    color: colors.santasGray,
    fontWeight: theme.typography.fontWeightBold
  },
  contentLabel2: {
    fontSize: 14,
    lineHeight: 1.5,
    color: colors.tuna,
    fontWeight: theme.typography.fontWeightRegular
  },
  contentLabel2Bold: {
    fontSize: 14,
    lineHeight: 1.5,
    color: colors.tuna,
    fontWeight: theme.typography.fontWeightBold
  },
  contentLabel3: {
    fontSize: 14,
    lineHeight: 1,
    color: colors.santasGray,
    fontWeight: theme.typography.fontWeightRegular
  },
  contentLabel4: {
    fontSize: 12,
    lineHeight: 1.5,
    color: colors.baliHai,
    fontWeight: theme.typography.fontWeightRegular
  },
  contentBold: {
    fontSize: 15,
    lineHeight: 1,
    color: colors.riverBed,
    fontWeight: theme.typography.fontWeightBold
  },
  contentSmall: {
    fontSize: 12,
    lineHeight: 1,
    color: colors.riverBed,
    fontWeight: theme.typography.fontWeightBold
  },
  valueLabel: {
    fontSize: 14,
    lineHeight: 1.5,
    color: colors.santasGray,
    fontWeight: theme.typography.fontWeightRegular
  },
  valueLabel2: {
    fontSize: 14,
    lineHeight: 1.5,
    color: colors.shuttleGray,
    fontWeight: theme.typography.fontWeightRegular
  },
  body: {
    fontSize: 12
  },
  body2: {
    fontSize: 12,
    color: colors.santasGray
  },
  large: {
    fontSize: 26,
    color: colors.tuna,
    lineHeight: 1
  },
  small: {
    fontSize: 10,
    lineHeight: 1,
    color: colors.santasGray
  },
  link: {
    fontSize: 14,
    lineHeight: 1,
    color: colors.denim
  },
  emergencyBanner: {
    fontSize: 20,
    color: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14
    }
  }
}));

const variants = {
  heading1: "h2",
  heading2: "h3",
  heading3: "h3",
  heading4: "h3",
  subtitle1: "subtitle1",
  subtitle2: "subtitle1",
  contentLabel: "body1",
  contentLabel2: "body1",
  contentLabel3: "body1",
  contentBold: "body1",
  valueLabel: "body1",
  valueLabel2: "body1",
  body: "body1",
  large: "body1"
};

const Text = React.forwardRef(
  ({ type, text, values, children, suffix, className, ...props }, ref) => {
    const classes = useStyles();
    const getText = useTranslate();

    return (
      <Typography
        ref={ref}
        variant={variants[type]}
        classes={{ root: className }}
        className={classes[type]}
        {...props}
      >
        {(text && getText(text, values)) || children}
        {suffix}
      </Typography>
    );
  }
);

Text.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  suffix: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  values: PropTypes.shape({})
};

Text.defaultProps = {
  children: null,
  className: "",
  suffix: "",
  text: null,
  type: "",
  values: null
};

export default Text;
