/* eslint-disable no-param-reassign */
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import get from "lodash-es/get";

// Config
import { instrumentationKey } from "../../config/apiConfig";

const reactPlugin = new ReactPlugin();
let appInsights = null;

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {
  /**
   * Initialize the Application Insights class
   * @param {Object} history - client's browser history, supplied by the withRouter HOC
   * @return {void}
   */
  const initialize = (history, currentUser) => {
    if (!history) {
      throw new Error("Could not initialize Telemetry Service");
    }

    // reactPlugin = new ReactPlugin();

    appInsights = new ApplicationInsights({
      config: {
        correlationHeaderExcludedDomains: "*.media.azure.net",
        disableFetchTracking: false,
        enableCorsCorrelation: true,
        extensionConfig: { [reactPlugin.identifier]: { history } },
        extensions: [reactPlugin],
        instrumentationKey,
        maxBatchInterval: 0
      }
    });

    appInsights.loadAppInsights();
    const telemetryInitializer = envelope => {
      envelope.tags["ai.cloud.role"] = "WebApp";

      if (currentUser) {
        envelope.ext.user.id = currentUser.accountIdentifier;
        envelope.data.userType = get(currentUser, "idToken.userType", "");
      }
    };

    appInsights.addTelemetryInitializer(telemetryInitializer);
  };

  return { reactPlugin, appInsights, initialize };
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;
