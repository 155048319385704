import React, { useCallback, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import get from "lodash-es/get";

// Config
import { getRoute, urls } from "../../config/routes";

// Utils
import useInjuries from "../../hooks/useInjuries";
import useQuery from "../../hooks/useQuery";

// Components
import ToolWrapper from "./ToolWrapper";
import LoadingSection from "../../components/LoadingSection/LoadingSection";

const ToolPage = () => {
  const { caseId, injuryId, imgIndex } = useParams();
  const history = useHistory();
  const query = useQuery();

  const injury = useInjuries(caseId, injuryId);
  const images = get(injury, `data.images`, []);
  const currentImage = images[imgIndex];

  const goBack = useCallback(() => {
    history.push(getRoute(urls.injuryDetail, { caseId, injuryId }));
  }, [caseId, history, injuryId]);

  useEffect(() => {
    if (query) {
      const filename = query.get("filename");
      images.forEach((item, i) => {
        if (item.filename === filename && i !== Number(imgIndex)) {
          history.push(
            getRoute(urls.annotationTool, {
              caseId,
              injuryId,
              index: i
            })
          );
        }
      });
    }
  }, [caseId, history, images, imgIndex, injuryId, query]);

  useEffect(() => {
    if (!currentImage && injury.initialized) goBack();
  }, [currentImage, goBack, injury.initialized]);

  return currentImage ? (
    <ToolWrapper goBack={goBack} currentImage={currentImage} />
  ) : (
    <LoadingSection />
  );
};

export default ToolPage;
