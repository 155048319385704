import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

// Material
import { makeStyles } from "@material-ui/core/styles";
import CardActionArea from "@material-ui/core/CardActionArea";

// Icons
import chevron from "../../assets/icons/chevron.svg";

// Config
import colors from "../../config/colors";
import styles from "../../config/styles";

// Components
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: ({ isLast }) => !isLast && styles.values.border,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: colors.cloudBurst
  },
  selected: {
    backgroundColor: colors.lilyWhite,
    pointerEvents: "none"
  },
  chevron: {
    transform: "rotate(90deg)",
    marginLeft: "auto"
  }
}));

const LinkItem = ({ to, text, isLast }) => {
  const classes = useStyles({ isLast });

  return (
    <CardActionArea
      component={NavLink}
      to={to}
      activeClassName={classes.selected}
      className={classes.root}
    >
      <Text type="subtitle2">{text}</Text>
      <img src={chevron} alt="" className={classes.chevron} />
    </CardActionArea>
  );
};

LinkItem.propTypes = {
  isLast: PropTypes.bool,
  text: PropTypes.string,
  to: PropTypes.string.isRequired
};

LinkItem.defaultProps = {
  isLast: false,
  text: ""
};

export default LinkItem;
