/* eslint-disable */
import React from "react";
import paper from "paper";

// Styles
import colors from "../../config/colors";

// Components
import AnnotationHeader from "./AnnotationHeader";
import AnnotationFooter from "./AnnotationFooter";
import CanvasWrapper from "./CanvasWrapper";

// Utils
import { getPpcm, ImageDataToBlob } from "../../utils/images";

var activeAnnotationTool = null;

const fgColor = colors.dodgerBlue2;
const lineStyles = {
  path: {
    // shadowColor: "white",
    // shadowBlur: 4,
    strokeColor: fgColor,
    strokeWidth: 2,
    opacity: 0.8,
    dashArray: [5, 5]
  },
  bar: {
    // shadowColor: "white",
    // shadowBlur: 4,
    strokeColor: fgColor,
    strokeWidth: 1,
    opacity: 0.8
  },
  text: {
    // shadowColor: "white",
    // shadowBlur: 4,
    opacity: 1,
    fillColor: fgColor,
    fontFamily: "'Open Sans', 'Arial', sans-serif",
    fontWeight: "bold",
    fontSize: 20
  },
  scale: {
    strokeColor: "white",
    strokeWidth: 1
  },
  scaleText: {
    fillColor: "white",
    fontFamily: "Open Sans",
    fontWeight: "bold",
    fontSize: 10
  }
};
const colorPalette = ["#2196E3", "#E03CFA", "#FA2A6A", "#84FA00", "#FFCD00"];

function registerEventListeners(paper, mode) {
  paper.view.onMouseDown = mode.onMouseDown;
  paper.view.onMouseMove = mode.onMouseMove;
  paper.view.onMouseDrag = mode.onMouseDrag;
  paper.view.onMouseUp = mode.onMouseUp;
}

function unregisterEventListeners(paper) {
  paper.view.onMouseDown = null;
  paper.view.onMouseMove = null;
  paper.view.onMouseDrag = null;
  paper.view.onMouseUp = null;
}

function MoveMode(tool) {
  let self = this;

  this.onMouseDrag = function (event) {
    let delta = event.delta;
    let matrix = new tool.paper.Matrix();
    matrix.translate(delta.x, delta.y);
    tool.transform(matrix);
  };

  this.activate = function () {
    registerEventListeners(tool.paper, self);
    tool.activeMode = self;
  };

  this.deactivate = function () {
    unregisterEventListeners(tool.paper);
  };
}

function LineMode(tool) {
  let self = this;

  function curLine() {
    if (tool.lines.length === 0) {
      return null;
    } else {
      return tool.lines.slice(-1)[0];
    }
  }

  this.deleteAll = function () {
    while (curLine() != null) {
      curLine().remove();
    }
  };

  this.toggleAnnotations = function () {
    for (let i = 0; i < tool.lines.length; i++) {
      let line = tool.lines[i];
      line.getGroup().visible = !line.getGroup().visible;
    }
  };

  this.onMouseDown = function (event) {
    if (curLine() == null || curLine().isDrawing === false) {
      tool.saveUndoState();
      new Line(tool);
      curLine().setStart(event.point);
    } else {
      curLine().setEnd(event.point);
    }
  };

  this.onMouseMove = function (event) {
    if (curLine() != null && curLine().isDrawing === true) {
      curLine().setDrawing(event.point);
    }
  };

  this.onMouseDrag = function (event) {};

  this.activate = function () {
    registerEventListeners(tool.paper, self);
    tool.activeMode = self;
  };

  this.deactivate = function () {
    unregisterEventListeners(tool.paper);
  };
}

function GridMode(tool, spacing) {
  let self = this;

  if (tool.ppcm) this.grid = new Grid(tool, spacing);

  this.onMouseDown = function (event) {};

  this.onMouseMove = function (event) {};

  this.onMouseDrag = function (event) {
    tool.saveUndoState(`gridRotate-${spacing}`);
    let delta = event.delta;

    self.grid.getGrid().rotate(delta.x / 2);
    self.grid.getGrid().rotate(delta.y / 2);
  };

  this.activate = function () {
    registerEventListeners(tool.paper, self);
    for (let i = 0; i < tool.grids.length; i++) {
      tool.grids[i].getGroup().visible = false;
    }
    self.grid.getGroup().visible = true;
    tool.activeMode = self;
  };

  this.deactivate = function (toggle) {
    unregisterEventListeners(tool.paper);
    if (toggle) {
      for (let i = 0; i < tool.grids.length; i++) {
        tool.grids[i].getGroup().visible = false;
      }
    }
  };
}

function CropMode(tool) {
  let self = this;
  this.start = null;
  this.rectangle = null;

  this.onMouseDown = function (event) {
    self.start = event.point;
  };

  this.onMouseUp = function (event) {
    if (self.rectangle === null) return;
    if (self.rectangle.area < 100) {
      self.rectangle.remove();
      return;
    }

    tool.saveUndoState();
    let rectangle = new tool.paper.Path.Rectangle(self.start, event.point);
    let raster = tool.raster.clone();
    let rasterRotation = raster.rotation;
    let rasterScaling = raster.scaling;
    rectangle.scale(1 / rasterScaling.x);
    raster.rotate(-rasterRotation, rectangle.position);
    raster.scale(1 / rasterScaling.x, rectangle.position);
    let rb = rectangle.bounds.clone();
    rb.x -= raster.bounds.x;
    rb.y -= raster.bounds.y;
    rectangle = new tool.paper.Path.Rectangle(rb);
    rectangle.rotate(rasterRotation);
    rb = rectangle.bounds.clone();
    let subRaster = raster.getSubRaster(rb);
    subRaster.rotate(rasterRotation);
    subRaster.scale(rasterScaling.x);
    raster.remove();
    tool.raster.remove();
    self.rectangle.remove();
    self.start = null;
    tool.raster = subRaster;
    tool.zoomToFit();
  };

  this.onMouseDrag = function (event) {
    self.current = event.point;
    if (self.rectangle != null) {
      self.rectangle.remove();
    }
    self.rectangle = new tool.paper.Path.Rectangle(self.start, self.current);
    self.rectangle.style = lineStyles.path;
  };

  this.activate = function () {
    registerEventListeners(tool.paper, self);
    tool.activeMode = self;
  };

  this.deactivate = function () {
    unregisterEventListeners(tool.paper);
  };
}

function Line(tool) {
  let self = this;
  this.paper = tool.paper;

  this.letter = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".charAt(tool.lines.length);
  let fgColor = colorPalette[tool.lines.length % colorPalette.length];

  let lineStylePath = Object.assign(Object.assign({}, lineStyles.path), {
    strokeColor: fgColor
  });
  let lineStyleBar = Object.assign(Object.assign({}, lineStyles.bar), {
    strokeColor: fgColor
  });
  let lineStyleText = Object.assign(Object.assign({}, lineStyles.text), {
    fillColor: fgColor
  });

  let group = new this.paper.Group();
  this.index = tool.root.children.indexOf(group.addTo(tool.root));
  new this.paper.Path(lineStylePath).addTo(group);
  new this.paper.Path(lineStyleBar).addTo(group);
  new this.paper.Path(lineStyleBar).addTo(group);
  new this.paper.PointText(lineStyleText).addTo(group);
  new this.paper.PointText(lineStyleText).addTo(group);
  new this.paper.Path.Rectangle({
    point: [20, 20],
    size: [60, 60],
    radius: [5, 5],
    fillColor: "black",
    opacity: 0
  }).addTo(group);
  new this.paper.PointText(lineStyleText).addTo(group);

  this.isDrawing = false;
  tool.lines.push(this);

  this.getGroup = function () {
    return tool.root.children[this.index];
  };

  this.getItems = function () {
    let group = this.getGroup();
    return {
      path: group.children[0],
      bar1: group.children[1],
      bar2: group.children[2],
      textLetter1: group.children[3],
      textLetter2: group.children[4],
      bgDistance: group.children[5],
      textDistance: group.children[6]
    };
  };

  this.getItem = function (name) {
    return this.getItems()[name];
  };

  this.remove = function () {
    this.getGroup().remove();
    tool.lines.splice(tool.lines.indexOf(this), 1);
  };

  this.setStart = function (point) {
    let path = this.getItem("path");
    path.add(point);
    this.isDrawing = true;
  };

  this.setEnd = function (point) {
    let path = this.getItem("path");
    while (path.segments.length > 1) {
      path.removeSegment(1);
    }
    path.add(point);
    this.isDrawing = false;
  };

  this.setDrawing = function (point) {
    let path = this.getItem("path");
    let bar1 = this.getItem("bar1");
    let bar2 = this.getItem("bar2");
    let textLetter1 = self.getItem("textLetter1");
    let textLetter2 = self.getItem("textLetter2");
    let bgDistance = self.getItem("bgDistance");
    let textDistance = self.getItem("textDistance");

    if (point === null) {
      // Sending in point = null signals that we should just redraw
      // using the existing two points:
      if (path.segments.length > 1) {
        point = path.segments[1];
        path.removeSegment(1);
      } else {
        return;
      }
    }

    while (path.segments.length > 1) {
      path.removeSegment(1);
    }
    path.add(point);

    let p1 = path.segments[0].point;
    let p2 = path.segments[1].point;

    let dist = Math.hypot(p2.x - p1.x, p2.y - p1.y);
    let dist_cm = dist / tool.ppcm;

    let vec = p2.subtract(p1);
    vec = vec.divide(dist).multiply(10).rotate(90);

    bar1.removeSegments();
    bar1.add(p1.subtract(vec));
    bar1.add(p1.add(vec));

    bar2.removeSegments();
    bar2.add(p2.subtract(vec));
    bar2.add(p2.add(vec));

    // x, y
    textLetter1.point = p2.add([0, 25]);
    textLetter1.content = `${this.letter}`;
    textLetter2.point = p1.add([-15, -15]);
    textLetter2.content = `${this.letter}`;
    textDistance.point = p2.add([25, 25]);
    textDistance.content = `${dist_cm.toFixed(1)}\u1d9c\u1d50`;
    bgDistance.bounds = textDistance.bounds.clone();
    bgDistance.bounds.width += 8;
    bgDistance.bounds.height += 6;
    bgDistance.bounds.topLeft = bgDistance.bounds.topLeft.subtract([4, 4]);
    bgDistance.opacity = 0.7;
  };

  tool.addTransformListener(function (matrix) {
    let textLetter1 = self.getItem("textLetter1");
    let textLetter2 = self.getItem("textLetter2");
    let textDistance = self.getItem("textDistance");

    for (const text of [textLetter1, textLetter2, textDistance]) {
      // We never want to rotate or scale our text labels:
      text.rotation = 0;
      text.scaling = 1;
    }

    // On scaling, we redraw the measurement line; this will make sure
    // that all widths, positions etc. are relative to the scale:
    self.setDrawing(null);
  });
}

function Grid(tool, spacing) {
  let self = this;
  let paper = tool.paper;
  let vsz = tool.paper.view.size;
  let ex = 400;
  let lines = [];
  let spacingPx = tool.ppcm * spacing;

  for (let x = -ex; x < vsz.width + ex; x += spacingPx) {
    // verticals
    lines.push(paper.Path.Line([x, -ex], [x, vsz.height + ex]));
  }

  for (let y = -ex; y < vsz.height + ex; y += spacingPx) {
    // horizontals
    lines.push(paper.Path.Line([-ex, y], [vsz.width + ex, y]));
  }

  lines = new paper.Group({
    children: lines,
    strokeColor: "black",
    strokeWidth: 1,
    opacity: 0.2
  });

  let scaleGroup = new paper.Group({
    children: [
      new tool.paper.Path.Rectangle({
        point: [20, 20],
        size: [60, 60],
        fillColor: "black",
        opacity: 0.7
      }),
      new tool.paper.Path(lineStyles.scale),
      new tool.paper.Path(lineStyles.scale),
      new tool.paper.Path(lineStyles.scale),
      new tool.paper.PointText(lineStyles.scaleText)
    ]
  });

  let group = new paper.Group({
    children: [lines, scaleGroup],
    visible: false
  });

  this.index = tool.root.children.indexOf(group.addTo(tool.root));
  tool.grids.push(this);

  this.getGroup = function () {
    return tool.root.children[this.index];
  };

  this.getGrid = function () {
    return this.getGroup().children[0];
  };

  this.drawScale = function () {
    let spacingPx = tool.ppcm * spacing;
    let rasterBounds = tool.raster.bounds.clone();
    let scaleGroup = this.getGroup().children[1];
    let bg = scaleGroup.children[0];
    let line = scaleGroup.children[1];
    let bar1 = scaleGroup.children[2];
    let bar2 = scaleGroup.children[3];
    let text = scaleGroup.children[4];

    line.removeSegments();
    line.add(rasterBounds.bottomLeft.add([20, -30]));
    line.add(rasterBounds.bottomLeft.add([20 + spacingPx, -30]));
    let p1 = line.segments[0].point;
    let p2 = line.segments[1].point;
    let vec = p2.subtract(p1);
    vec = vec.divide(spacingPx).multiply(5).rotate(90);

    bar1.removeSegments();
    bar1.add(p1.subtract(vec));
    bar1.add(p1.add(vec));

    bar2.removeSegments();
    bar2.add(p2.subtract(vec));
    bar2.add(p2.add(vec));

    text.content = `Grid Scale: ${spacing}\u1d9c\u1d50`;
    text.point = rasterBounds.bottomLeft.add([20 + spacingPx + 5, -26]);

    bg.bounds = text.bounds;
    bg.bounds = scaleGroup.bounds.clone();
    bg.bounds.width += 6;
    bg.bounds.height += 4;
    bg.bounds.topLeft = bg.bounds.topLeft.subtract([3, 2]);
    bg.opacity = 0.7;
  };

  this.drawScale();

  tool.addTransformListener(function (matrix) {
    let scaleGroup = self.getGroup().children[1];
    let text = scaleGroup.children[4];
    text.rotation = 0;
    text.scaling = 1;

    self.drawScale();
  });
}

const toggeableModes = ["move", "line", "crop", "grid05", "grid10", "grid20"];
const isToggleable = mode => toggeableModes.indexOf(mode) !== -1;

class AnnotationTool extends React.Component {
  constructor(props) {
    super();
    this.index = props.index;
    this.image = props.image;
    this.paper = new paper.PaperScope();

    this.imageId = `annotationtool-image-${this.index}`;
    this.canvasId = `annotationtool-canvas-${this.index}`;

    this.deleteKeys = ["escape", "backspace", "delete"];

    this.canvas = null;
    this.raster = null;
    this.lines = [];
    this.grids = [];
    this.modes = null;
    this.activeMode = null;
    this.disableAnnotations = true;

    this.state = {
      isLoading: true,
      selectedMode: "none",
      undoStates: [],
      redoStates: []
    };
  }

  scaleToFit = (canvas, image) => {
    let container = canvas.parentElement;
    let containerHeight = container.offsetHeight;
    let containerWidth = container.offsetWidth;

    if (image.height / image.width > containerHeight / containerWidth) {
      return containerHeight / image.height;
    } else {
      return containerWidth / image.width;
    }
  };

  init = () => {
    let self = this;
    const { imageMeta } = this.props;

    this.canvas = document.getElementById(this.canvasId);
    this.paper.setup(this.canvas);
    this.transformListeners = [];
    this.root = new this.paper.Group();
    this.raster = new this.paper.Raster(this.imageId);
    this.root.addChild(this.raster);

    let scale = this.scaleToFit(this.canvas, this.raster);
    let matrix = new this.paper.Matrix();
    matrix.translate(this.paper.view.center);
    matrix.scale(scale);
    this.raster.transform(matrix);

    this.ppcm = getPpcm(imageMeta);
    this.ppcm = this.ppcm * scale;

    if (this.ppcm) this.disableAnnotations = false;

    this.paper.view.draw();

    this.modes = {
      line: new LineMode(this),
      grid05: new GridMode(this, 0.5),
      grid10: new GridMode(this, 1.0),
      grid20: new GridMode(this, 2.0),
      move: new MoveMode(this),
      crop: new CropMode(this)
    };

    this.handlers = {
      rotateL: this.handleRotateLeft,
      rotateR: this.handleRotateRight,
      trash: this.handleDelete,
      zoomIn: this.handleZoomIn,
      zoomOut: this.handleZoomOut,
      undo: this.handleUndo,
      redo: this.handleRedo
    };

    this.canvas.onwheel = function (event) {
      event.preventDefault();
      if (event.deltaY < 0)
        self.handleZoomIn({ x: event.offsetX, y: event.offsetY });
      else if (event.deltaY > 0) self.handleZoomOut();
    };

    this.paper.view.onKeyDown = function (event) {
      if (self.deleteKeys.indexOf(event.key) !== -1) self.handleUndo();
    };

    this.setState({
      isLoading: false
    });
  };

  activate = () => {
    if (activeAnnotationTool !== this) {
      if (activeAnnotationTool && activeAnnotationTool.activeMode) {
        activeAnnotationTool.activeMode.deactivate();
      }
      this.paper.activate();
      activeAnnotationTool = this;
    }
  };

  toggleMode = modeName => () => {
    this.activate();
    const { selectedMode } = this.state;

    if (this.handlers[modeName]) {
      // this isn't a mode but a handler, so no toggling:
      this.handlers[modeName]();
      return;
    }

    if (this.activeMode === this.modes[modeName]) {
      // toggle off
      this.activeMode.deactivate(true);
      this.activeMode = null;
    } else {
      if (this.activeMode !== null) {
        this.activeMode.deactivate(false);
      }
      this.modes[modeName] && this.modes[modeName].activate();
    }

    if (isToggleable(modeName)) {
      this.setState({
        selectedMode: modeName === selectedMode ? "none" : modeName
      });
    }
  };

  transform = matrix => {
    this.root.transform(matrix);
    this.ppcm = this.ppcm * matrix.scaling.x;

    for (const listener of this.transformListeners) {
      listener(matrix);
    }
  };

  addTransformListener = func => {
    this.transformListeners.push(func);
  };

  createUndoState = groupName => {
    return {
      groupName: groupName,
      vars: {
        lines: Array.from(this.lines),
        grids: Array.from(this.grids),
        root: this.root.clone({ insert: false }),
        ppcm: this.ppcm,
        transformListeners: Array.from(this.transformListeners)
      }
    };
  };

  saveUndoState = groupName => {
    let undoStates = Array.from(this.state.undoStates);
    if (
      undoStates.length !== 0 &&
      groupName !== undefined &&
      undoStates[undoStates.length - 1].groupName === groupName
    )
      return;

    undoStates.push(this.createUndoState(groupName));
    this.setState({
      undoStates: undoStates,
      redoStates: []
    });
  };

  applyUndoState = state => {
    this.root.remove();
    Object.assign(this, state.vars);
    this.raster = this.root.children[0];
    this.paper.project.activeLayer.addChild(this.root);
  };

  handleUndo = () => {
    let undoStates = Array.from(this.state.undoStates);
    let redoStates = Array.from(this.state.redoStates);
    if (undoStates.length === 0) return;
    let state = undoStates.pop();
    redoStates.push(this.createUndoState(state.groupName));
    this.setState({
      undoStates: undoStates,
      redoStates: redoStates
    });
    this.applyUndoState(state);
  };

  handleRedo = () => {
    let undoStates = Array.from(this.state.undoStates);
    let redoStates = Array.from(this.state.redoStates);
    if (redoStates.length === 0) return;
    let state = redoStates.pop();
    undoStates.push(this.createUndoState(state.groupName));
    this.setState({
      undoStates: undoStates,
      redoStates: redoStates
    });
    this.applyUndoState(state);
  };

  handleRotateLeft = () => {
    this.saveUndoState("rotate");
    let matrix = new this.paper.Matrix();
    matrix.rotate(-90, this.raster.position);
    this.transform(matrix);
  };

  handleRotateRight = () => {
    this.saveUndoState("rotate");
    let matrix = new this.paper.Matrix();
    matrix.rotate(90, this.raster.position);
    this.transform(matrix);
  };

  handleZoomIn = position => {
    let center = position && position.x ? position : this.raster.position;
    let matrix = new this.paper.Matrix();
    matrix.scale(1.25, center);
    this.transform(matrix);
  };

  handleZoomOut = () => {
    let wratio = this.raster.bounds.width / this.paper.view.bounds.width;
    let hratio = this.raster.bounds.height / this.paper.view.bounds.height;
    if (wratio < 0.5 && hratio < 0.5) return;
    let matrix = new this.paper.Matrix();
    matrix.scale(0.8, this.paper.view.center);
    this.transform(matrix);
  };

  handleDelete = () => {
    this.props.onDelete();
  };

  resetAnnotations = () => {
    this.modes["line"].deleteAll();
  };

  toggleAnnotations = () => {
    this.modes["line"].toggleAnnotations();
  };

  zoomToFit = () => {
    let image = this.raster;
    let view = this.paper.view;
    let matrix = new this.paper.Matrix();
    let scale = 1;

    if (
      image.bounds.height / image.bounds.width >
      view.bounds.height / view.bounds.width
    )
      scale = view.bounds.height / image.bounds.height;
    else scale = view.bounds.width / image.bounds.width;

    matrix.translate(view.bounds.center.subtract(image.position));
    this.transform(matrix);

    matrix = new this.paper.Matrix();
    matrix.scale(scale, view.bounds.center);
    this.transform(matrix);
  };

  saveImage = () => {
    const { onSave } = this.props;

    // We zoom to fit here because we want the side effect of
    // adjusting measurement line scalings to the when the image is
    // 'zoomed out'.  Through zoomToFit we'll end up triggering the
    // Line's transform listener.  Without this, the the measurement
    // lines and labels would remain scaled at whatever zoom level the
    // user was looking at when they hit save:

    this.zoomToFit();

    let output = this.root.clone({ insert: false });
    let scale = 1 / output.children[0].scaling.x;
    let outputRaster = output.rasterize(72 * scale, false);
    outputRaster = outputRaster.scale(scale);
    output.scale(scale);
    output.removeChildren(1, 4);

    let rasterBounds = outputRaster.bounds.clone();
    let roiBounds = output.bounds.clone();

    roiBounds.x -= rasterBounds.x;
    roiBounds.y -= rasterBounds.y;

    let outputSubRaster = outputRaster.getSubRaster(roiBounds);
    let imageData = outputSubRaster.getImageData();

    ImageDataToBlob(imageData).then(blob => {
      onSave(blob);

      // For debugging purposes, this is how we'd display the
      // resulting image in the browser instead:
      //
      // let src = window.URL.createObjectURL(blob);
      // document.getElementById("saveDummy").src = src;
    });
  };

  render() {
    const { isLoading, selectedMode, undoStates, redoStates } = this.state;
    const {
      classes,
      image: { filename, imageType, exhibitNumber },
      isSensitive,
      onDiscard
    } = this.props;
    const {
      canvasId,
      disableAnnotations,
      handleZoomIn,
      handleZoomOut,
      image: { resourceURLs },
      imageId,
      init,
      resetAnnotations,
      saveImage,
      toggleAnnotations,
      toggleMode
    } = this;

    const numberFilename = `${exhibitNumber} ${filename}`;

    return (
      <>
        <AnnotationHeader
          allowRedo={redoStates.length > 0}
          allowUndo={undoStates.length > 0}
          disableAnnotations={disableAnnotations}
          disabled={isLoading}
          imageType={imageType}
          isLoading={isLoading}
          isSensitive={isSensitive}
          selectedMode={selectedMode}
          toggleMode={toggleMode}
        />

        <img
          id={imageId}
          src={resourceURLs.image}
          alt=""
          onLoad={init}
          onError={() => window.location.reload()}
          className={classes.hidden}
          crossOrigin="anonymous"
        />

        <CanvasWrapper
          disabled={isLoading}
          filename={numberFilename}
          isSensitive={isSensitive}
          onZoomIn={handleZoomIn}
          onZoomOut={handleZoomOut}
          resetAnnotations={resetAnnotations}
          toggleAnnotations={toggleAnnotations}
        >
          <canvas id={canvasId} className={classes.canvas} />
        </CanvasWrapper>

        <AnnotationFooter
          allowSave={undoStates.length > 0}
          discardAnnotations={onDiscard}
          isLoading={isLoading}
          saveImage={saveImage}
        />
      </>
    );
  }
}

export default AnnotationTool;
