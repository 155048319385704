import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";

// Config
import { getRoute } from "../../config/routes";

// Components
import CaseMediaGallery from "../CaseMediaGallery/CaseMediaGallery";

const GalleryHandler = ({ data, defaultRoute, detailRoute, ...rest }) => {
  const { itemId, caseId } = useParams();
  const history = useHistory();

  const currentIndex = data.findIndex(({ id }) => id === itemId);
  const selectedData = data[currentIndex];

  useEffect(() => {
    if (!selectedData) {
      history.push(getRoute(defaultRoute, { caseId }));
    }
  }, [caseId, defaultRoute, history, selectedData]);

  if (!itemId || !selectedData) return null;

  const handleNavigate = forward => () => {
    let nextIndex = forward ? currentIndex + 1 : currentIndex - 1;
    if (nextIndex < 0) nextIndex = data.length - 1;
    if (nextIndex === data.length) nextIndex = 0;

    history.push(getRoute(detailRoute, { caseId, itemId: data[nextIndex].id }));
  };

  const handleClose = () => history.push(getRoute(defaultRoute, { caseId }));

  return (
    <CaseMediaGallery
      caseId={caseId}
      data={selectedData}
      onClose={handleClose}
      onNext={handleNavigate(true)}
      onPrev={handleNavigate(false)}
      open={Boolean(itemId)}
      {...rest}
    />
  );
};

GalleryHandler.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string
    })
  ).isRequired,
  defaultRoute: PropTypes.string.isRequired,
  detailRoute: PropTypes.string.isRequired
};

export default GalleryHandler;
