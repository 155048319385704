import React from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";

// Styles
import colors from "../../config/colors";
import styles from "../../config/styles";

// Config
import { truncateText } from "../../utils/functions";

// Components
import Close from "../Close";
import Link from "../Link";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    top: styles.values.headerHeight,
    marginTop: theme.spacing(2)
  },
  paper: {
    position: "relative",
    padding: 0
  },
  link: {
    padding: theme.spacing(2, 8, 2, 2),
    maxWidth: 450,
    display: "flex",
    flexDirection: "column"
  },
  subtitleLight: {
    textTransform: "uppercase",
    marginBottom: theme.spacing(1),
    fontSize: 10,
    color: colors.riverBed
  },
  heading: {
    marginBottom: theme.spacing(1)
  },
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    color: colors.riverBed
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2)
  }
}));

const NewMessageSnackbar = ({
  open,
  onClose,
  data: { message, username, to = "" }
}) => {
  const classes = useStyles();

  const handleClose = (e, reason) => {
    if (reason !== "clickaway") onClose();
  };

  return (
    <Snackbar
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
      autoHideDuration={60000}
      classes={{ anchorOriginTopRight: classes.root }}
      onClose={handleClose}
      open={open}
      TransitionComponent={Slide}
      TransitionProps={{ direction: "left" }}
    >
      <Paper className={classes.paper} elevation={2}>
        <Link className={classes.link} onClick={onClose} to={to}>
          <Text
            className={classes.subtitleLight}
            type="status"
            text="header.newMessageSnackbar.messageReceived"
          />
          <Text type="heading3" className={classes.heading}>
            {username}
          </Text>
          {message && (
            <div className={classes.content}>
              <Text type="subtitle1Small">{truncateText(message, 120)}</Text>
            </div>
          )}
        </Link>
        <IconButton
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <Close />
        </IconButton>
      </Paper>
    </Snackbar>
  );
};

NewMessageSnackbar.propTypes = {
  data: PropTypes.shape({
    to: PropTypes.string,
    username: PropTypes.string,
    message: PropTypes.string
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default NewMessageSnackbar;
