import { SeverityLevel } from "@microsoft/applicationinsights-web";

import {
  REQUEST_LOGIN,
  SET_ERROR,
  SET_MSAL_STATE,
  SET_TOKEN,
  SET_USER,
  REQUEST_USER_DETAILS,
  REQUEST_USER_DETAILS_SUCCESS,
  REQUEST_USER_DETAILS_FAIL,
  ACQUIRE_TOKEN_FAIL,
  SET_LOCALE
} from "./actionTypes";

// Utils
import {
  LOGIN,
  msalApp,
  requiresInteraction,
  TOKEN_CONFIG
} from "../../utils/auth-utils";
import { getAppInsights } from "../../utils/telemetry/TelemetryService";
import errors from "../../utils/errors";

// Config
import { apiVersion, getMeUrl } from "../../config/apiConfig";

const LOGIN_CANCELED = "AADB2C90091";

export const requestLogin = payload => ({
  type: REQUEST_LOGIN,
  payload
});

export const setUser = payload => ({
  type: SET_USER,
  payload
});

export const setError = payload => ({
  type: SET_ERROR,
  payload
});

export const setAccessToken = payload => ({
  type: SET_TOKEN,
  payload
});

export const setMsalState = payload => ({
  type: SET_MSAL_STATE,
  payload
});

export const acquireTokenFail = payload => ({
  type: ACQUIRE_TOKEN_FAIL,
  payload
});

export const initialize = cbk => dispatch => {
  const app = msalApp.getInstance();
  const ai = getAppInsights();

  app
    .handleRedirectPromise()
    .then(tokenResponse => {
      if (tokenResponse && tokenResponse !== null) {
        dispatch(setUser(tokenResponse.account));
        dispatch(setAccessToken(tokenResponse.accessToken));
        if (tokenResponse.state)
          dispatch(setMsalState(JSON.parse(tokenResponse.state)));
      } else {
        const currentAccounts = app.getAllAccounts();

        if (!currentAccounts || currentAccounts.length === 0) {
          app.loginRedirect({
            ...LOGIN,
            state: JSON.stringify({ route: window.location.pathname })
          });
        } else if (currentAccounts.length > 1) {
          // Multiple accounts flow reached. Force user to log out.
          localStorage.clear();
          app.logout();
        } else dispatch(setUser(currentAccounts[0]));
      }
      cbk();
    })
    .catch(error => {
      if (ai)
        ai.trackException({
          error: new Error(`Redirect Error: ${JSON.stringify(error)}`),
          severityLevel: SeverityLevel.Error
        });

      if (error.errorMessage.includes(LOGIN_CANCELED)) {
        app.loginRedirect({
          ...LOGIN,
          state: JSON.stringify({ route: window.location.pathname })
        });
      }

      dispatch(acquireTokenFail(error));
      cbk();
    });
};

export const getTokenCallback =
  (callback, msalState) => async (dispatch, getState) => {
    const app = msalApp.getInstance();
    const { currentUser, accessToken } = getState().user;
    const ai = getAppInsights();

    const redirectConfig = {
      ...TOKEN_CONFIG,
      state: msalState ? JSON.parse(msalState) : ""
    };

    let token = accessToken || localStorage.getItem("msal.idtoken");

    await app
      .acquireTokenSilent({ ...TOKEN_CONFIG, account: currentUser })
      .then(response => {
        if (response && response.accessToken) {
          token = response.accessToken;
          dispatch(setAccessToken(token));
        }
      })
      .catch(error => {
        if (requiresInteraction(error.errorCode)) {
          app.acquireTokenRedirect(redirectConfig);
        } else {
          if (ai) {
            ai.trackException({
              error: new Error(
                `Acquire Token Silent Error: ${JSON.stringify(error)}`
              ),
              severityLevel: SeverityLevel.Error
            });
          }
          dispatch(acquireTokenFail(error));
        }
      });

    if (token) callback(`Bearer ${token}`, token);
    else app.acquireTokenRedirect(redirectConfig);
  };

export const parseFetchOptions = (cbk, msalState) => dispatch => {
  dispatch(
    getTokenCallback(
      Authorization =>
        cbk({
          headers: {
            Authorization,
            "api-version": apiVersion
          }
        }),
      msalState
    )
  );
};

export const onSignIn = () => async dispatch => {
  dispatch(requestLogin());
  return msalApp.getInstance().loginRedirect(LOGIN);
};

export const onSignOut = () => dispatch => {
  dispatch(requestLogin());
  msalApp.getInstance().logout();
};

// Details
const requestUserDetails = () => ({
  type: REQUEST_USER_DETAILS
});

const requestUserDetailsSuccess = payload => ({
  type: REQUEST_USER_DETAILS_SUCCESS,
  payload
});

const requestUserDetailsFail = payload => ({
  type: REQUEST_USER_DETAILS_FAIL,
  payload
});

export const getUserDetails = () => dispatch => {
  dispatch(requestUserDetails());

  const onFail = () => {
    const ai = getAppInsights();
    if (ai)
      ai.trackException({
        error: new Error(`Failed to get user details`),
        severityLevel: SeverityLevel.Error
      });
  };

  dispatch(
    parseFetchOptions(async options => {
      const response = await fetch(getMeUrl, options).catch(error => {
        onFail();
        requestUserDetailsFail(error);
      });

      if (response && response.ok) {
        const data = await response.json();
        dispatch(requestUserDetailsSuccess(data));
      } else {
        onFail();
        dispatch(requestUserDetailsFail(errors.responseError(response)));
      }
    })
  );
};

// Locale
export const setLocale = payload => {
  localStorage.setItem("icLocale", payload);
  return {
    type: SET_LOCALE,
    payload
  };
};
