import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Formik - Material
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";

// Config
import styles from "../../config/styles";

// Components
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    flex: "1 1",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  fakeInput: {
    ...styles.mixins.inputRoot,
    minHeight: 46,
    justifyContent: "center",
    padding: theme.spacing(0, 2)
  },
  valueLabel: {
    marginBottom: theme.spacing(1.5)
  }
}));

const FakeInput = ({
  className,
  disabled,
  label,
  icon,
  name,
  type,
  ...props
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      {label && (
        <Text type="valueLabel" className={classes.valueLabel}>
          {label}
        </Text>
      )}
      <TextField
        className={clsx(classes.fakeInput, className)}
        name={name}
        type={type}
        {...{ disabled, ...props }}
        InputProps={{
          endAdornment: icon && (
            <InputAdornment position="end">
              <img src={icon} alt="" />
            </InputAdornment>
          )
        }}
      />
    </div>
  );
};

FakeInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  icon: PropTypes.string
};

FakeInput.defaultProps = {
  className: "",
  disabled: false,
  label: null,
  type: "text",
  icon: undefined
};

export default React.memo(FakeInput);
