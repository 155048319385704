export const REQUEST_INV = "invites/REQUEST_INV";
export const REQUEST_INV_SUCCESS = "invites/REQUEST_INV_SUCCESS";
export const REQUEST_INV_FAIL = "invites/REQUEST_INV_FAIL";

export const REQUEST_ACCEPT_INV = "invites/REQUEST_ACCEPT_INV";
export const REQUEST_ACCEPT_INV_SUCCESS = "invites/REQUEST_ACCEPT_INV_SUCCESS";
export const REQUEST_ACCEPT_INV_FAIL = "invites/REQUEST_ACCEPT_INV_FAIL";

export const REQUEST_REJECT_INV = "invites/REQUEST_REJECT_INV";
export const REQUEST_REJECT_INV_SUCCESS = "invites/REQUEST_REJECT_INV_SUCCESS";
export const REQUEST_REJECT_INV_FAIL = "invites/REQUEST_REJECT_INV_FAIL";

export const REQUEST_SEND_INV = "invites/REQUEST_SEND_INV";
export const REQUEST_SEND_INV_SUCCESS = "invites/REQUEST_SEND_INV_SUCCESS";
export const REQUEST_SEND_INV_FAIL = "invites/REQUEST_SEND_INV_FAIL";
