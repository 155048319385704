import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Field } from "formik";
import ReactCountryFlag from "react-country-flag";

// Config
import styles from "../../config/styles";
import countries from "../../config/countries";

// Components
import FormikAutocomplete from "../FormikAutocomplete";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  phone: {
    minWidth: 148,
    position: "relative",
    height: 48,
    marginRight: theme.spacing(1.5)
  },
  flag: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: theme.spacing(2),
    fontSize: "18px !important"
  },
  optionText: {
    fontSize: 15
  },
  optionFlag: {
    marginRight: theme.spacing(1)
  },
  phoneTextField: {
    border: styles.values.border,
    borderRadius: theme.shape.borderRadius,
    height: 48,
    display: "flex",
    justifyContent: "center"
  },
  phoneInput: {
    paddingLeft: theme.spacing(8),
    fontWeight: theme.typography.fontWeightMedium
  },
  listbox: {
    "&::-webkit-scrollbar": {
      width: 0
    }
  }
}));

const CountryField = ({ selectedCountryCode, ...rest }) => {
  const classes = useStyles();

  return (
    <Field
      classes={{
        listbox: classes.listbox
      }}
      className={classes.phone}
      component={FormikAutocomplete}
      disableClearable
      getOptionLabel={option => `+${option.phone}`}
      name="country"
      options={countries}
      valueKey="code"
      textFieldProps={{
        fullWidth: true,
        className: classes.phoneTextField,
        InputProps: {
          classes: { root: classes.phoneInput }
        }
      }}
      icon={
        <ReactCountryFlag
          className={classes.flag}
          countryCode={selectedCountryCode}
        />
      }
      renderOption={option => (
        <Text type="body" className={classes.optionText}>
          <ReactCountryFlag
            className={classes.optionFlag}
            countryCode={option.code}
          />
          {`(${option.code}) +${option.phone}`}
        </Text>
      )}
      {...rest}
    />
  );
};

CountryField.propTypes = {
  selectedCountryCode: PropTypes.string.isRequired
};

export default CountryField;
