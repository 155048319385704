/* eslint-disable jsx-a11y/media-has-caption */
import React from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

// Images
import errorIcon from "../../assets/icons/missing-id-alert.svg";

// Hooks
import { useFileUpload } from "./FileUploadProvider";
import useTranslate from "../../hooks/useTranslate";
import useWebcam from "../../hooks/useWebcam";

// Config
import colors from "../../config/colors";

// Components
import Button from "../Button";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(3, 5),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 300
  },
  errorWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2),
    maxWidth: 400
  },
  videoWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    overflow: "hidden",
    "&::after": {
      content: `""`,
      height: 310,
      aspectRatio: "1 / 1",
      borderRadius: "100%",
      border: "2px dashed white",
      display: "flex",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: " translate(-50%, -50%)"
    }
  },
  video: {
    width: ({ width }) => width || 300,
    borderRadius: theme.shape.borderRadius
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: theme.spacing(4.5),
    backgroundColor: colors.wildLand
  },
  cancelButton: {
    backgroundColor: colors.white,
    marginRight: theme.spacing(1.5),
    width: 180
  },
  uploadButton: {
    width: 180
  }
}));

const NOT_ALLOWED_ERROR = "NotAllowedError";

const WebcamInput = ({ onTakePicture, width }) => {
  const classes = useStyles({ width });
  const getText = useTranslate();

  const { video, takePicture, videoSrc, hasUserMedia, error } = useWebcam();
  const { onClose } = useFileUpload();

  const handleTakePicture = () => takePicture(onTakePicture);

  return (
    <div>
      <div className={classes.content}>
        {!hasUserMedia && !error && (
          <CircularProgress size={70} thickness={3} />
        )}
        {error && (
          <div className={classes.errorWrapper}>
            <img src={errorIcon} alt="" />
            {error.name === NOT_ALLOWED_ERROR ? (
              <>
                <Text text="permissionRequest.title" type="subtitleBold" />
                <Text
                  align="center"
                  text="permissionRequest.camera.permissionDenied"
                  type="valueLabel"
                />
              </>
            ) : (
              <Text text="errors.generic" type="subtitleBold" />
            )}
          </div>
        )}
        <div className={classes.videoWrapper}>
          <video
            height={!hasUserMedia ? 0 : undefined}
            className={classes.video}
            src={videoSrc}
            autoPlay
            ref={video}
          />
        </div>
      </div>

      <footer className={classes.footer}>
        <Button
          className={classes.cancelButton}
          disableElevation
          onClick={onClose}
          variant="outlined"
        >
          {getText("common.prompts.cancel")}
        </Button>
        <Button
          className={classes.uploadButton}
          color="primary"
          disabled={!hasUserMedia || error}
          disableElevation
          onClick={handleTakePicture}
          variant="contained"
        >
          {getText("common.prompts.takePicture")}
        </Button>
      </footer>
    </div>
  );
};

WebcamInput.propTypes = {
  onTakePicture: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired
};

export default WebcamInput;
