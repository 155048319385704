import React from "react";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";

// Config
import styles from "../../../config/styles";

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: styles.values.border
  },
  cell: {
    padding: theme.spacing(2.5, 2),
    display: "flex",
    flexDirection: "row"
  },
  circle: {
    marginRight: theme.spacing(1.5)
  },
  text: {
    width: "100%"
  },
  textHalf: {
    width: "calc(100% - 50px)"
  }
}));

const items = [1, 2, 3, 4, 5];

const LoadingEntries = () => {
  const classes = useStyles();
  return items.map(key => (
    <Grid key={key} className={classes.root} container>
      <Grid className={classes.cell} item xs={4}>
        <Skeleton
          className={classes.circle}
          animation="wave"
          variant="circle"
          width={38}
          height={38}
        />
        <Skeleton
          className={classes.textHalf}
          animation="wave"
          variant="text"
        />
      </Grid>
      <Grid className={classes.cell} item xs={3}>
        <Skeleton className={classes.text} animation="wave" variant="text" />
      </Grid>
      <Grid className={classes.cell} item xs={5}>
        <Skeleton className={classes.text} animation="wave" variant="text" />
      </Grid>
    </Grid>
  ));
};

export default LoadingEntries;
