import React from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

// Third Party
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/core/styles";

// Images
import cropIcon from "../../assets/icons/crop.svg";
import moveIcon from "../../assets/icons/ionic-ios-move.svg";
import rotateCcwIcon from "../../assets/icons/rotate-ccw.svg";
import rotateCwIcon from "../../assets/icons/rotate-cw.svg";
import undoIcon from "../../assets/icons/undo.svg";
import redoIcon from "../../assets/icons/redo.svg";
import rulerIcon from "../../assets/icons/ruler.svg";
import trashIcon from "../../assets/icons/trash.svg";

// Grid Icons
import grid05Icon from "../../assets/icons/grid05Icon.svg";
import grid05IconDefault from "../../assets/icons/grid05IconDefault.svg";
import grid10Icon from "../../assets/icons/grid10Icon.svg";
import grid10IconDefault from "../../assets/icons/grid10IconDefault.svg";
import grid20Icon from "../../assets/icons/grid20Icon.svg";
import grid20IconDefault from "../../assets/icons/grid20IconDefault.svg";
import noGridIcon from "../../assets/icons/noGridIcon.svg";

// Config
import { getRoute, urls } from "../../config/routes";
import { isAnnotable, isAnnotated } from "../../utils/images";
import { useSensitiveImage } from "../../context/SensitiveImageProvider";
import colors from "../../config/colors";
import styles from "../../config/styles";

// Components
import StatusBar from "../StatusBar";
import Text from "../Text";
import ToolButton from "./ToolButton";

const useStyles = makeStyles(theme => ({
  statusBar: {
    backgroundColor: colors.white,
    margin: 0,
    position: "fixed",
    top: styles.values.topBarHeight,
    left: 0,
    width: "100%",
    boxShadow: styles.values.boxShadow,
    padding: theme.spacing(0, 3),
    maxWidth: "unset"
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  chevron: {
    marginRight: theme.spacing(4),
    fontSize: 10
  },
  buttonsWrapper: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }
}));

const getButtonsList = ({
  allowDelete,
  allowRedo,
  allowUndo,
  disableAnnotations
}) => [
  {
    key: "undo",
    icon: undoIcon,
    isDisabled: !allowUndo
  },
  {
    key: "redo",
    icon: redoIcon,
    isDisabled: !allowRedo
  },
  {
    type: "divider",
    key: "divider1"
  },
  {
    key: "move",
    icon: moveIcon
  },
  {
    key: "line",
    icon: rulerIcon,
    isDisabled: disableAnnotations
  },
  {
    key: "rotateL",
    icon: rotateCcwIcon
  },
  {
    key: "rotateR",
    icon: rotateCwIcon
  },
  {
    key: "crop",
    icon: cropIcon
  },
  {
    type: "divider",
    key: "divider2"
  },
  {
    key: "grid",
    isDisabled: disableAnnotations,
    modes: ["grid05", "grid10", "grid20"],
    steps: [
      {
        icon: noGridIcon,
        next: "grid05"
      },
      {
        current: "grid05",
        icon: grid05IconDefault,
        activeIcon: grid05Icon,
        next: "grid10"
      },
      {
        current: "grid10",
        icon: grid10IconDefault,
        activeIcon: grid10Icon,
        next: "grid20"
      },
      {
        current: "grid20",
        icon: grid20IconDefault,
        activeIcon: grid20Icon,
        next: "grid20"
      }
    ]
  },
  {
    key: "trash",
    icon: trashIcon,
    isDisabled: !allowDelete
  }
];

const getItemsCount = (arr, type) => {
  let count = 0;
  arr.map(item => {
    if (item.imageType === type) count += 1;
    return null;
  });
  return count;
};

const AnnotationHeader = ({
  allowRedo,
  allowUndo,
  disableAnnotations,
  disabled,
  isSensitive,
  selectedMode,
  toggleMode,
  imageType
}) => {
  const classes = useStyles();
  const { caseId, injuryId, imgIndex } = useParams();
  const { clickedThumbnail } = useSensitiveImage();
  const { data } = useSelector(state => state.injuries);

  const disableButtons =
    disabled || (isSensitive && imgIndex !== clickedThumbnail);

  const isAlreadyAnnotated = isAnnotated(imageType);
  const allowDelete =
    !isAnnotable(imageType) || getItemsCount(data.images, imageType) > 1;

  return (
    <StatusBar className={classes.statusBar}>
      <Link
        className={classes.titleWrapper}
        to={getRoute(urls.injuryDetail, { caseId, injuryId })}
      >
        <FontAwesomeIcon className={classes.chevron} icon={faChevronLeft} />
        <Text text={`common.bodySection.${data.bodySection}`} type="heading3" />
      </Link>
      <div className={classes.buttonsWrapper}>
        {getButtonsList({
          allowDelete,
          allowRedo,
          allowUndo,
          disableAnnotations: isAlreadyAnnotated || disableAnnotations
        }).map(item => (
          <ToolButton
            disabled={disableButtons}
            item={item}
            key={item.key}
            onClick={toggleMode}
            selectedMode={selectedMode}
          />
        ))}
      </div>
    </StatusBar>
  );
};

AnnotationHeader.propTypes = {
  allowRedo: PropTypes.bool.isRequired,
  allowUndo: PropTypes.bool.isRequired,
  disableAnnotations: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  imageType: PropTypes.string.isRequired,
  isSensitive: PropTypes.bool.isRequired,
  selectedMode: PropTypes.string.isRequired,
  toggleMode: PropTypes.func.isRequired
};

export default AnnotationHeader;
