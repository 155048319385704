import React from "react";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Config
import colors from "../../config/colors";

// Icons
import victimIdIcon from "../../assets/icons/victim-id-icon.svg";

// Components
import CaptureVictimButton from "../CaptureVictimButton";
import Disclaimer from "../Disclaimer";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: colors.riverBed,
    padding: theme.spacing(2.75, 3),
    marginBottom: theme.spacing(1.5)
  },
  text: {
    maxWidth: 465,
    lineHeight: 1.2
  },
  button: {
    color: colors.white,
    borderColor: colors.white,
    marginLeft: "auto",
    marginRight: theme.spacing(1.5),
    padding: 0,
    margin: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 38,
    width: 138
  }
}));

const CaptureVictimDisclaimer = () => {
  const classes = useStyles();

  return (
    <>
      <Disclaimer
        button={<CaptureVictimButton className={classes.button} />}
        className={classes.root}
        icon={victimIdIcon}
        text="caseDetailPage.captureVictim.part1"
        textClassName={classes.text}
      />
    </>
  );
};

export default CaptureVictimDisclaimer;
