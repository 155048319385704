import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

// Config
import { getRoute, urls } from "../../config/routes";

// Components
import CaseLogTable from "./components/CaseLogTable";
import SectionWrapper from "../../components/SectionWrapper";

const CaseLogPage = () => {
  const { data } = useSelector(s => s.caseDetail);
  const { caseId } = useParams();
  const history = useHistory();

  const isPolice = data && data.userCapacity === "PoliceOfficer";

  useEffect(() => {
    if (!isPolice) {
      history.push(getRoute(urls.caseDetail, { caseId }));
    }
  }, [isPolice]);

  return <SectionWrapper>{isPolice && <CaseLogTable />}</SectionWrapper>;
};

export default CaseLogPage;
