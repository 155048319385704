import en from "./en.json";
import es from "./es.json";
import pt from "./pt.json";

export const languages = {
  en,
  es,
  pt
};

export const supportedLanguages = Object.keys(languages);

export const languageOptions = [
  {
    value: "es",
    code: "ES"
  },
  {
    value: "en",
    code: "GB"
  },
  {
    value: "pt",
    code: "PT"
  }
];
