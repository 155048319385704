import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

// Config
import { urls, getRoute } from "../../config/routes";
import styles from "../../config/styles";
import useTranslate from "../../hooks/useTranslate";

// Context
import { useFeatureFlags } from "../../context/FeatureFlagsProvider";

// Components
import LinkItem from "../LinkItem";
import Text from "../Text";
import featureFlagKeys from "../../config/featureFlagKeys";

const useStyles = makeStyles(theme => ({
  root: {
    border: styles.values.border,
    marginBottom: theme.spacing(1)
  },
  header: {
    padding: theme.spacing(3, 3.5)
  },
  title: {
    textTransform: "uppercase"
  }
}));

const linkList = [
  {
    key: "incident",
    textKey: "incident"
  },
  {
    key: "medicalRecords",
    textKey: "medicalRecords",
    hiddenToWitnesses: true
  },
  {
    key: "caseMedia",
    textKey: "media"
  },
  {
    key: "caseNotes",
    textKey: "notes",
    featureFlag: featureFlagKeys.STATEMENTS
  },
  {
    key: "infoRequests",
    textKey: "infoRequests"
  }
];

const CaseSummary = ({ caseId, isDraft, isWitness }) => {
  const { featureFlags } = useFeatureFlags();
  const { data } = useSelector(s => s.caseDetail);
  const classes = useStyles();
  const getText = useTranslate();

  return (
    <>
      <Paper elevation={0} className={classes.root}>
        <div className={classes.header}>
          <Text
            className={classes.title}
            text="caseMenu.caseSummary.title"
            type="contentLabel"
          />
        </div>
        {linkList.map(
          (
            {
              hiddenToWitnesses,
              key,
              featureFlag,
              submittedOnly,
              textKey,
              ...linkData
            },
            index
          ) =>
            (!featureFlag || featureFlags[featureFlag]) &&
            (!submittedOnly || !isDraft) &&
            (!hiddenToWitnesses || !isWitness) && (
              <LinkItem
                {...linkData}
                text={getText(`caseMenu.caseSummary.${textKey}.title`)}
                key={key}
                to={getRoute(urls[key], { caseId })}
                isLast={index === linkList.length - 1}
              />
            )
        )}
      </Paper>
      {data && data.userCapacity === "PoliceOfficer" && (
        <Paper elevation={0} className={classes.root}>
          <div className={classes.header}>
            <Text
              className={classes.title}
              text="pageTitles.caseLog"
              type="contentLabel"
            />
          </div>
          <LinkItem
            text={getText("caseMenu.caseActivity.log.title")}
            to={getRoute(urls.caseLog, { caseId })}
            isLast
          />
        </Paper>
      )}
    </>
  );
};

CaseSummary.propTypes = {
  caseId: PropTypes.string.isRequired,
  isDraft: PropTypes.bool.isRequired,
  isWitness: PropTypes.bool.isRequired
};

export default CaseSummary;
