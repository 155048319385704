import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

// Config
import { snackbarTypes } from "../../config/snackbar";
import { urls } from "../../config/routes";
import { userTypes } from "../../config/values";
import colors from "../../config/colors";
import styles from "../../config/styles";

// Actions
import { clearCaseDetail, getCaseDetail } from "../../state/caseDetail/actions";
import { getInvitations } from "../../state/invitations/actions";
import { toggleSnack } from "../../state/snackbar/actions";

// Hooks
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import useShowSteps from "../../hooks/useShowSteps";

// Components
import CaseContributors from "../CaseContributors";
import CaseDetail from "../CaseDetail";
import CaseSummary from "../CaseSummary";
import GoBackLink from "../GoBackLink";
import LoadingSection from "../LoadingSection";
import MainSection from "../MainSection";
import VictimSteps from "../VictimSteps";

const useStyles = makeStyles(theme => ({
  root: {
    ...styles.mixins.mainSection,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    paddingBottom: theme.spacing(5)
  },
  section: styles.mixins.sectionContainer,
  goBack: {
    padding: theme.spacing(2.5, 0, 1.5),
    width: "100%"
  },
  goBackLink: {
    textTransform: "uppercase"
  },
  chevron: {
    marginRight: theme.spacing(2)
  },
  goBackText: {
    color: colors.cloudBurst
  },
  leftContent: {
    paddingRight: theme.spacing(2)
  },
  rightContent: {
    paddingLeft: theme.spacing(2)
  }
}));

const SectionWrapper = ({ children, isNew }) => {
  const { caseId } = useParams();
  const { trackException } = useAppInsights();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { data, isLoading, error } = useSelector(s => s.caseDetail);
  const invitations = useSelector(s => s.invitations);
  const showCaseSteps = useShowSteps(caseId);

  useEffect(() => {
    if (!invitations.data && !invitations.isLoading && !invitations.error) {
      dispatch(
        getInvitations(() => trackException("Failed to get invitations"))
      );
    }
  }, [
    dispatch,
    invitations.data,
    invitations.error,
    invitations.isLoading,
    trackException
  ]);

  useEffect(() => {
    if (data && data.id !== caseId) {
      dispatch(clearCaseDetail);
    }

    if (error) {
      history.push(urls.cases);
    } else if (!isNew) {
      if (!caseId || caseId === "undefined") {
        history.push(urls.cases);
        return;
      }

      const shouldGetData = !error && (!data || data.id !== caseId);
      const isReady = invitations.data && !isLoading;

      if (shouldGetData && isReady) {
        const onFail = callback => {
          if (invitations.data.findIndex(i => i.id === caseId) !== -1) {
            dispatch(
              toggleSnack({
                type: snackbarTypes.ERROR,
                text: "caseMenu.pleaseAcceptInvitation"
              })
            );
          } else {
            trackException("Failed to get case details");
            if (callback) callback();
          }
          history.push(urls.cases);
        };

        dispatch(getCaseDetail({ avoidError: true, caseId, onFail }));
      }
    }
  }, [
    caseId,
    data,
    dispatch,
    error,
    history,
    invitations.data,
    isLoading,
    isNew,
    trackException
  ]);

  const content = isNew ? {} : data;
  const isWitness = !!data && data.userCapacity === userTypes.Witness;

  if (data && data.id === caseId && data.isLocked) {
    history.push(urls.cases);
    return null;
  }

  return !isNew && (isLoading || !content) ? (
    <MainSection>
      <LoadingSection />
    </MainSection>
  ) : (
    <MainSection>
      <Grid container className={classes.section}>
        <GoBackLink />
        <Grid item xs={4} className={classes.leftContent}>
          <CaseDetail
            data={content}
            isNew={isNew}
            isWitness={isWitness}
            showSteps={showCaseSteps}
          />
          {!isNew && !showCaseSteps && (
            <>
              <CaseSummary
                caseId={caseId}
                isDraft={data.isDraft}
                isWitness={isWitness}
              />
              {data && (
                <CaseContributors
                  capacity={data.userCapacity}
                  caseId={caseId}
                  creatorId={data.creatorId}
                  isDraft={data.isDraft}
                  isWitness={isWitness}
                />
              )}
            </>
          )}
        </Grid>
        <Grid item xs={8} className={classes.rightContent}>
          {showCaseSteps ? <VictimSteps caseId={caseId} /> : children}
        </Grid>
      </Grid>
    </MainSection>
  );
};

SectionWrapper.propTypes = {
  children: PropTypes.node,
  isNew: PropTypes.bool
};

SectionWrapper.defaultProps = {
  children: null,
  isNew: false
};

export default SectionWrapper;
