import axios from "axios";

import {
  REQUEST_MEDIA,
  REQUEST_MEDIA_SUCCESS,
  REQUEST_MEDIA_FAIL,
  REQUEST_DEL_MEDIA,
  REQUEST_DEL_MEDIA_SUCCESS,
  REQUEST_DEL_MEDIA_FAIL,
  REQUEST_ADD_MEDIA,
  REQUEST_ADD_MEDIA_SUCCESS,
  REQUEST_ADD_MEDIA_FAIL,
  RESET_MEDIA_UPLOADS,
  UPDATE_MEDIA_PROGRESS,
  REQUEST_MEDIA_ITEM,
  REQUEST_MEDIA_ITEM_SUCCESS,
  REQUEST_MEDIA_ITEM_FAIL,
  CLEAR_MEDIA_ERROR
} from "./actionTypes";

import { getMediaUrl, mediaItemUrl } from "../../config/apiConfig";
import { parseFetchOptions } from "../user/actions";
import errors from "../../utils/errors";

// Data
const requestMedia = payload => ({
  type: REQUEST_MEDIA,
  payload
});

const requestMediaSuccess = payload => ({
  type: REQUEST_MEDIA_SUCCESS,
  payload
});

const requestMediaFail = payload => ({
  type: REQUEST_MEDIA_FAIL,
  payload
});

export const getMedia =
  ({ caseId, onFail }) =>
  async dispatch => {
    dispatch(requestMedia(caseId));

    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(getMediaUrl(caseId), {
          method: "GET",
          headers: options.headers
        }).catch(error => {
          if (onFail) onFail();
          dispatch(requestMediaFail(error));
        });

        if (response && response.ok) {
          const data = await response.json();
          dispatch(requestMediaSuccess(data));
        } else {
          dispatch(requestMediaFail(errors.responseError(response)));
          if (onFail) onFail();
        }
      })
    );
  };

// Get Media Item
const requestMediaItem = () => ({
  type: REQUEST_MEDIA_ITEM
});

const requestMediaItemSuccess = payload => ({
  type: REQUEST_MEDIA_ITEM_SUCCESS,
  payload
});

const requestMediaItemFail = payload => ({
  type: REQUEST_MEDIA_ITEM_FAIL,
  payload
});

export const getMediaItem =
  ({ caseId, mediaId, onFail }) =>
  async dispatch => {
    dispatch(requestMediaItem());

    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(mediaItemUrl(caseId, mediaId), {
          method: "GET",
          headers: options.headers
        }).catch(error => {
          if (onFail) onFail();
          dispatch(requestMediaItemFail(error));
        });

        if (response && response.ok) {
          const data = await response.json();
          dispatch(requestMediaItemSuccess(data));
        } else {
          if (onFail) onFail();
          dispatch(requestMediaItemFail(errors.responseError(response)));
        }
      })
    );
  };

// Image Upload
const requestAddMedia = payload => ({
  type: REQUEST_ADD_MEDIA,
  payload
});

const requestAddMediaSuccess = payload => ({
  type: REQUEST_ADD_MEDIA_SUCCESS,
  payload
});

const requestAddMediaFail = payload => ({
  type: REQUEST_ADD_MEDIA_FAIL,
  payload
});

export const updateMediaProgress = payload => ({
  type: UPDATE_MEDIA_PROGRESS,
  payload
});

export const resetMediaUploads = () => ({ type: RESET_MEDIA_UPLOADS });

export const addMedia =
  ({ caseId, description, id, image, onFail, onUploadProgress, query = "" }) =>
  async dispatch => {
    dispatch(requestAddMedia(id));

    dispatch(
      parseFetchOptions(async options => {
        const requestResponse = await fetch(`${getMediaUrl(caseId)}${query}`, {
          method: "POST",
          headers: {
            ...options.headers,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            filename: image.name,
            description
          })
        });

        if (requestResponse && requestResponse.ok) {
          const { uploadURL, id: mediaId } = await requestResponse.json();

          axios
            .request({
              method: "PUT",
              headers: {
                "x-ms-blob-type": "BlockBlob",
                "Content-Type": image.type
              },
              data: image,
              url: uploadURL,
              onUploadProgress
            })
            .then(() => {
              dispatch(requestAddMediaSuccess(id));
              dispatch(getMediaItem({ caseId, mediaId }));
            })
            .catch(() => {
              if (onFail) onFail();
              dispatch(requestAddMediaFail(id));
            });
        } else {
          if (onFail) onFail();
          dispatch(requestAddMediaFail(id));
        }
      })
    );
  };

export const retryAddMedia =
  ({ caseId, id, image, onFail, url }) =>
  async dispatch => {
    dispatch(requestAddMedia(id));

    axios
      .request({
        method: "PUT",
        headers: {
          "x-ms-blob-type": "BlockBlob",
          "Content-Type": image.type
        },
        data: image,
        url,
        onUploadProgress: progress =>
          dispatch(updateMediaProgress({ index: 0, progress }))
      })
      .then(() => {
        dispatch(requestAddMediaSuccess(id));
        dispatch(getMediaItem({ caseId, mediaId: id }));
      })
      .catch(() => {
        if (onFail) onFail();
        dispatch(requestAddMediaFail(id));
      });
  };

// Delete
const requestDeleteMedia = payload => ({
  type: REQUEST_DEL_MEDIA,
  payload
});

const requestDeleteMediaSuccess = payload => ({
  type: REQUEST_DEL_MEDIA_SUCCESS,
  payload
});

const requestDeleteMediaFail = payload => ({
  type: REQUEST_DEL_MEDIA_FAIL,
  payload
});

export const deleteMedia =
  ({ caseId, itemId, onFail }) =>
  async dispatch => {
    dispatch(requestDeleteMedia(itemId));

    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(mediaItemUrl(caseId, itemId), {
          method: "DELETE",
          headers: options.headers
        }).catch(error => {
          if (onFail) onFail();
          dispatch(requestDeleteMediaFail(error));
        });

        if (response && response.ok) {
          dispatch(requestDeleteMediaSuccess(itemId));
        } else {
          if (onFail) onFail();
          dispatch(requestDeleteMediaFail(errors.responseError(response)));
        }
      })
    );
  };

// Clear Error
export const clearMediaError = () => dispatch => {
  dispatch({
    type: CLEAR_MEDIA_ERROR
  });
};
