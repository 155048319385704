import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../config/styles";

const useStyles = makeStyles(theme => ({
  header: {
    padding: noPadding => (noPadding ? 0 : theme.spacing(0, 4)),
    borderBottom: styles.values.border,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: 77
  }
}));

const ContentCardHeader = ({ children, noPadding }) => {
  const classes = useStyles(noPadding);
  return <div className={classes.header}>{children}</div>;
};

ContentCardHeader.propTypes = {
  children: PropTypes.node.isRequired,
  noPadding: PropTypes.bool
};

ContentCardHeader.defaultProps = {
  noPadding: false
};

export default ContentCardHeader;
