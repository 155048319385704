import { combineReducers } from "redux";
import constant from "lodash-es/constant";

import createReducer from "../../utils/createReducer";

import {
  REQUEST_MEDIA,
  REQUEST_MEDIA_SUCCESS,
  REQUEST_MEDIA_FAIL,
  REQUEST_DEL_MEDIA,
  REQUEST_DEL_MEDIA_SUCCESS,
  REQUEST_DEL_MEDIA_FAIL,
  REQUEST_ADD_MEDIA,
  REQUEST_ADD_MEDIA_SUCCESS,
  REQUEST_ADD_MEDIA_FAIL,
  RESET_MEDIA_UPLOADS,
  UPDATE_MEDIA_PROGRESS,
  REQUEST_MEDIA_ITEM_SUCCESS,
  REQUEST_MEDIA_ITEM_FAIL,
  CLEAR_MEDIA_ERROR
} from "./actionTypes";
import { CLEAR_MEDIA_UPLOAD_PROGRESS } from "../caseDetail/actionTypes";

// Current
const currentCase = createReducer(null, {
  [REQUEST_MEDIA]: (state, { payload }) => payload,
  [CLEAR_MEDIA_ERROR]: constant(null)
});

// Data
const data = createReducer(null, {
  [REQUEST_MEDIA_SUCCESS]: (state, { payload }) => payload,
  [REQUEST_MEDIA]: constant(null),
  [CLEAR_MEDIA_ERROR]: constant(null),
  [REQUEST_MEDIA_ITEM_SUCCESS]: (state, { payload }) => {
    if (state) {
      const status = {};
      const alreadyExists = state.find(({ id, isProcessing }, index) => {
        if (id === payload.id) {
          status.shouldReplace = isProcessing !== payload.isProcessing;
          status.index = index;
          return true;
        }
        return false;
      });

      if (alreadyExists) {
        if (status.shouldReplace) {
          const newState = [...state];
          newState[status.index] = payload;
          return newState;
        }
        return state;
      }

      return [...state, payload];
    }

    return [payload];
  },
  [REQUEST_DEL_MEDIA_SUCCESS]: (state, { payload }) =>
    state.filter(({ id }) => id !== payload)
});

const galleryItems = createReducer(null, {
  [REQUEST_MEDIA_ITEM_SUCCESS]: (state, { payload }) => ({
    ...state,
    [payload.id]: payload
  })
});

const error = createReducer(null, {
  [REQUEST_MEDIA]: constant(null),
  [CLEAR_MEDIA_ERROR]: constant(null),
  [REQUEST_MEDIA_FAIL]: (state, { payload }) => payload,
  [REQUEST_MEDIA_ITEM_FAIL]: (state, { payload }) => payload
});

const isLoading = createReducer(false, {
  [REQUEST_MEDIA]: constant(true),
  [REQUEST_MEDIA_SUCCESS]: constant(false),
  [REQUEST_MEDIA_FAIL]: constant(false)
});

const isDeleting = createReducer(null, {
  [REQUEST_DEL_MEDIA]: (state, { payload }) => payload,
  [REQUEST_DEL_MEDIA_SUCCESS]: constant(null),
  [REQUEST_DEL_MEDIA_FAIL]: constant(null)
});

const uploadProgress = createReducer(null, {
  [UPDATE_MEDIA_PROGRESS]: (state, { payload }) => ({
    ...state,
    [payload.index]: (payload.progress.loaded / payload.progress.total) * 100
  }),
  [REQUEST_ADD_MEDIA]: (state, { payload }) => ({
    ...state,
    [payload]: 0
  }),
  [REQUEST_ADD_MEDIA_SUCCESS]: (state, { payload }) => ({
    ...state,
    [payload]: 100
  }),
  [REQUEST_ADD_MEDIA_FAIL]: (state, { payload }) => ({
    ...state,
    [payload]: 0
  }),
  [CLEAR_MEDIA_UPLOAD_PROGRESS]: constant(null),
  [RESET_MEDIA_UPLOADS]: constant(null)
});

const uploadErrors = createReducer(null, {
  [REQUEST_ADD_MEDIA_FAIL]: (state, { payload }) => ({
    ...state,
    [payload]: true
  }),
  [RESET_MEDIA_UPLOADS]: constant(null)
});

export default combineReducers({
  currentCase,
  data,
  error,
  isLoading,
  isDeleting,
  uploadProgress,
  uploadErrors,
  galleryItems
});
