/* eslint-disable no-console */
import * as msal from "@azure/msal-browser";

export const requiresInteraction = errorMessage => {
  if (!errorMessage || !errorMessage.length) {
    return false;
  }

  return (
    errorMessage.indexOf("consent_required") > -1 ||
    errorMessage.indexOf("interaction_required") > -1 ||
    errorMessage.indexOf("login_required") > -1
  );
};

export const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ") > -1;
  const msie11 = ua.indexOf("Trident/") > -1;

  // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
  // const isEdge = ua.indexOf("Edge/") > -1;

  return msie || msie11;
};

const environments = {
  ngrok: "https://injurycapture-webapp.sa.ngrok.io",
  development: "http://localhost:3000",
  staging: "https://stageapp.kulpacloud.com"
};

const baseUrl =
  process.env.REACT_APP_APP_URL ||
  environments[process.env.REACT_APP_NGROK || process.env.NODE_ENV] ||
  environments.staging;

const authDomain =
  process.env.REACT_APP_AD_DOMAIN || "injurycapturestage.onmicrosoft.com";

export const TOKEN_CONFIG = {
  scopes: [`https://${authDomain}/ic-api/Cases.ReadWrite`]
};

export const LOGIN = {
  scopes: [`https://${authDomain}/ic-api/Cases.ReadWrite`]
};

const clientId =
  process.env.REACT_APP_CLIENT_ID || "388e39b7-fc94-4124-8a31-30666ddbe4eb";

const authority =
  process.env.REACT_APP_AUTHORITY ||
  "https://injurycapturestage.b2clogin.com/tfp/injurycapturestage.onmicrosoft.com/B2C_1A_signup_signin_v2";

const knownAuthorities = [
  "https://injurycapturestage.b2clogin.com",
  "https://injurycapture.b2clogin.com"
];

const validateAuthority = false;
const navigateToLoginRequestUrl = false;
const auth = {
  clientId,
  authority,
  validateAuthority,
  postLogoutRedirectUri: `${baseUrl}/logout`,
  navigateToLoginRequestUrl,
  redirectUri: `${baseUrl}/login`,
  knownAuthorities
};

const config = {
  auth,
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: isIE()
  },
  system: {
    navigateFrameWait: 0,
    loadFrameTimeout: 10000,
    logger: {
      error: console.error,
      errorPii: console.error,
      info: console.log,
      infoPii: console.log,
      verbose: console.log,
      verbosePii: console.log,
      warning: console.warn,
      warningPii: console.warn
    }
  }
};

const msalInstance = new msal.PublicClientApplication(config);

export const msalApp = (() => {
  let instance;

  const createInstance = () => msalInstance;

  return {
    getInstance: () => {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    }
  };
})();
