import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Material
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";

// FontAwesome
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Text from "../Text/Text";
import colors from "../../config/colors";
import styles from "../../config/styles";

const useStyles = makeStyles(theme => ({
  iconButton: {
    height: 40,
    width: 40
  },
  faIcon: {
    fontSize: 15
  },
  menuHeading: {
    color: colors.cloudBurst,
    fontWeight: theme.typography.fontWeightMedium
  },
  toolbar: {
    minHeight: "auto",
    padding: theme.spacing(2, 3),
    borderBottom: styles.values.border,
    width: 305,
    "&:focus": {
      outline: "none"
    }
  },
  menuItem: {
    padding: theme.spacing(2, 3)
  },
  itemIcon: {
    width: 12,
    marginRight: theme.spacing(2.5),
    color: colors.lynch
  },
  itemText: {
    color: colors.cloudBurst
  }
}));

const SimpleMenu = ({ iconClassName, options, title }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = e => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleClick = onClick => () => {
    setAnchorEl(null);
    if (onClick) onClick();
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleOpen}
        className={clsx(classes.iconButton, iconClassName)}
      >
        <FontAwesomeIcon className={classes.faIcon} icon={faEllipsisV} />
      </IconButton>
      <Menu
        aria-controls="simple-menu"
        aria-haspopup="true"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        <Toolbar className={classes.toolbar}>
          <Text text={title} type="subtitle2" className={classes.menuHeading} />
        </Toolbar>
        {options.map(({ onClick, text, key, icon }) => (
          <MenuItem
            className={classes.menuItem}
            key={key}
            onClick={handleClick(onClick)}
          >
            <img className={classes.itemIcon} src={icon} alt="" />
            <Text text={text} type="subtitle2" className={classes.itemText} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

SimpleMenu.propTypes = {
  iconClassName: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string
    })
  ).isRequired,
  title: PropTypes.string.isRequired
};

SimpleMenu.defaultProps = {
  iconClassName: ""
};

export default SimpleMenu;
