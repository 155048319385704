import React from "react";
import { useSelector } from "react-redux";

// lodash
import get from "lodash-es/get";
import isEmpty from "lodash-es/isEmpty";
import isObject from "lodash-es/isObject";

// locales
import { languages } from "../locales/localeConfig";

// utils
import reactStringReplace from "../utils/reactStringReplace";

const ONE = "one";
const OTHER = "other";
const variableRegex = /%{(\d)}/g;
const englishLocale = languages.en;

const useTranslate = () => {
  const { locale } = useSelector(s => s.user);
  const [texts, setText] = React.useState(languages[locale] || englishLocale);

  React.useEffect(() => {
    setText(languages[locale]);
  }, [locale]);

  const parseText = (route, values, defaultText = "", textSource = texts) => {
    let text = get(textSource, route, defaultText);

    if (isObject(text)) {
      if (values && typeof values.value === "number") {
        // replace object units using %d
        return values.value === 1
          ? get(text, ONE, defaultText).replace("%d", values.value)
          : get(text, OTHER, defaultText).replace("%d", values.value);
      }
      return text;
    }

    if (values && !isEmpty(values)) {
      const { countKey, unit, unitKey, value, isComponent, ...rest } = values;

      if (isComponent) {
        return reactStringReplace(text, variableRegex, match => values[match]);
      }

      let object = rest;

      if (countKey && unit && unitKey) {
        // Replace single units
        const quantity = values[countKey] === 1 ? ONE : OTHER;
        object = {
          ...object,
          [unitKey]: get(texts, `common.units.${unit}.${quantity}`)
        };
      }

      Object.keys(object).forEach(key => {
        text = text.replace(`%{${key}}`, object[key]);
      });
    }

    return text;
  };

  const getText = (route, values, defaultText) => {
    // get translated text
    const result = parseText(route, values, defaultText);

    // return english text if not found
    return !result || result === ""
      ? parseText(route, values, defaultText, englishLocale)
      : result;
  };

  return getText;
};

export default useTranslate;
