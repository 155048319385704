import get from "lodash-es/get";

// Icons
import missingIdIcon from "../assets/icons/missing-id-alert.svg";
import send from "../assets/icons/send.svg";
import injuryHeadIcon from "../assets/icons/injury_head.svg";

// Config
import { useFeatureFlags } from "../context/FeatureFlagsProvider";
import featureFlagKeys from "../config/featureFlagKeys";
import useTranslate from "./useTranslate";

const dialogProps = {
  captureVictimId: {
    texts: [
      {
        key: 1,
        text: "caseDetailPage.captureVictim.part1"
      }
    ],
    title: "caseDetailPage.captureVictim.title",
    button: "caseDetailPage.captureVictim.button",
    icon: missingIdIcon
  },
  provideDetails: {
    texts: [
      {
        key: 2,
        text: "caseDetailPage.provideDetails.part2"
      }
    ],
    title: "caseDetailPage.provideDetails.title",
    button: "caseDetailPage.provideDetails.button",
    icon: injuryHeadIcon
  },
  submit: {
    texts: [
      {
        key: 1,
        text: "caseDetailPage.submit.body"
      }
    ],
    title: "caseDetailPage.submit.title",
    button: "caseDetailPage.submit.button",
    buttonsColumn: true,
    icon: send
  }
};

const useDialog = () => {
  const { featureFlags } = useFeatureFlags();
  const getText = useTranslate();

  const submitContact = featureFlags[featureFlagKeys.SUBMIT_CONTACT];

  const parseTextArray = a =>
    a.map(({ text, ...item }) => ({
      ...item,
      text: getText(text)
    }));

  const getDialogProps = (data, functions, title) => {
    const values = {};

    if (data) {
      values.isMissingId = !get(data, "patient.profilePhotoURL");
      values.isMissingLocation = !get(data, "incident.location.name");
      values.isMissingDate = !get(data, "incident.dateTime");
      values.isMissingContact = !get(data, "contactDetails");
      values.isMissingDescriptions =
        get(data, "incident.descriptions", []).length < 1;
    }

    const displayId = values.isMissingId;
    const displayDetails =
      (submitContact && values.isMissingContact) ||
      values.isMissingDate ||
      values.isMissingDescriptions ||
      values.isMissingLocation;

    const validations = [displayId, displayDetails, true];

    const current = Object.keys(dialogProps)[validations.indexOf(true)];

    const onConfirm = functions[current];
    const currentProps = dialogProps[current];

    const modalProps = {
      ...currentProps,
      title: getText(currentProps.title),
      button: getText(currentProps.button)
    };

    if (modalProps.texts) modalProps.texts = parseTextArray(modalProps.texts);

    return {
      dialog: {
        ...modalProps,
        type: "submit",
        cancel: getText("common.valueLabels.backTo", {
          1: title || getText("caseMenu.caseSummary.title")
        })
      },
      onConfirm
    };
  };

  return getDialogProps;
};

export default useDialog;
