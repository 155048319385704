import React from "react";
import PropTypes from "prop-types";

const FileUploadTabPanel = ({ children, value, index, ...other }) => {
  if (value !== index) return null;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`file-upload-tabpanel-${index}`}
      aria-labelledby={`file-upload-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
};

FileUploadTabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export default FileUploadTabPanel;
