import React from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

// Config
import styles from "../../../config/styles";

// Components
import Text from "../../Text";

const useStyles = makeStyles(theme => ({
  root: {
    ...styles.mixins.inputRoot,
    padding: theme.spacing(0, 1.5, 0, 2.25),
    position: "relative",
    overflow: "hidden",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 48
  },
  text: {
    opacity: 0.5,
    zIndex: 999
  },
  progressBar: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    opacity: 0.2,
    width: ({ progress }) => `${progress}%`,
    transition: "width .2s ease-in-out"
  },
  errorBar: {
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    opacity: 0.2
  }
}));

const FileUploadProgress = ({ filename, progress, error }) => {
  const classes = useStyles({ progress });

  return error ? (
    <div className={classes.root}>
      <Text
        className={classes.text}
        text="common.errors.request"
        type="contentLabel2"
      />
      <LinearProgress
        className={classes.errorBar}
        color="secondary"
        value={100}
        variant="determinate"
      />
    </div>
  ) : (
    <div className={classes.root}>
      <Text className={classes.text} type="contentLabel2">
        {filename}
      </Text>
      <Text className={classes.text} type="contentLabel2">
        {progress.toFixed(0)}%
      </Text>
      <LinearProgress
        className={classes.progressBar}
        value={100}
        variant={progress === 100 ? "determinate" : "indeterminate"}
      />
    </div>
  );
};

FileUploadProgress.propTypes = {
  error: PropTypes.bool,
  filename: PropTypes.string,
  progress: PropTypes.number
};

FileUploadProgress.defaultProps = {
  error: false,
  filename: "",
  progress: 0
};

export default FileUploadProgress;
