import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as signalR from "@microsoft/signalr";

// Config
import { snackbarTypes } from "../config/snackbar";

// Actions
import { getTokenCallback } from "../state/user/actions";
import { toggleSnack } from "../state/snackbar/actions";
import { apiVersion } from "../config/apiConfig";

const getAuthHeaders = () => ({
  "api-version": apiVersion
});

// source: https://stackoverflow.com/questions/59652214/how-do-i-add-custom-headers-to-signalrs-typescript-client
class CustomHttpClient extends signalR.DefaultHttpClient {
  constructor() {
    super(console); // the base class wants an signalR.ILogger, I'm not sure if you're supposed to put *the console* into it, but I did and it seemed to work
  }

  // So far I have only overriden this method, and all seems to work well
  async send(request) {
    const authHeaders = getAuthHeaders(); // These are the extra headers I needed to put in
    request.headers = { ...request.headers, ...authHeaders };
    // Now we have manipulated the request how we want we can just call the base class method
    return super.send(request);
  }
}

const useConnection = ({ functions, infoRequestId, url, updateFunctions }) => {
  const [connection, setConnection] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const connect = newConnection => {
    if (newConnection) {
      Object.keys(functions).forEach(key => {
        newConnection.on(key, functions[key]);
      });
    }
    setConnection(newConnection);
  };

  useEffect(() => {
    if (updateFunctions && connection) {
      Object.keys(functions).forEach(key => {
        connection.off(key);
        connection.on(key, functions[key]);
      });
    }
  }, [updateFunctions, functions]);

  useEffect(() => {
    let mounted = true;
    return () => {
      if (!mounted) setConnection(null);
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;

    if (!connection && !error) {
      dispatch(
        getTokenCallback(async (_, token) => {
          const newConnection = new signalR.HubConnectionBuilder()
            .withUrl(url, {
              accessTokenFactory: () => token,
              httpClient: new CustomHttpClient()
            })
            .configureLogging(signalR.LogLevel.Information)
            .build();

          const start = async () => {
            try {
              await newConnection.start();
              if (infoRequestId) {
                newConnection.invoke("joinInfoRequestChat", { infoRequestId });
              } else if (mounted) connect(newConnection);
            } catch (err) {
              dispatch(toggleSnack({ type: snackbarTypes.ERROR }));
              setError(err);
            }
            if (mounted) setInitialized(true);
          };

          newConnection.on("acknowledgeGroupJoin", () => {
            if (mounted) connect(newConnection);
          });

          newConnection.onclose(async () => {
            await start();
          });

          start();
        })
      );
    }
    return () => {
      mounted = false;
    };
  }, [connection, dispatch, error, infoRequestId]);

  return [connection, initialized];
};

export default useConnection;
