import React from "react";
import { useDispatch } from "react-redux";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

// Config
import { devices } from "../../config/values";
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import useTranslate from "../../hooks/useTranslate";

// Images
import appstore from "../../assets/icons/appstoreBig.svg";
import playstore from "../../assets/icons/playstoreBig.svg";

// Actions
import { sendStoreMessage } from "../../state/actions/actions";

// Components
import Button from "../Button";

const useStyles = makeStyles(theme => ({
  storeContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(3)
  },
  radio: {
    marginRight: theme.spacing(0.5)
  },
  storeImg: {
    marginRight: theme.spacing(1.25)
  },
  button: {
    width: 297,
    textTransform: "uppercase"
  }
}));

export default () => {
  const [device, setDevice] = React.useState(null);
  const { trackException } = useAppInsights();
  const classes = useStyles();
  const dispatch = useDispatch();
  const getText = useTranslate();

  const handleChange = ({ target }) => setDevice(target.value);
  const handleGetLink = () =>
    dispatch(
      sendStoreMessage({
        platform: device,
        onFail: () => trackException(`Fail to get appstore link for ${device}`)
      })
    );

  return (
    <>
      <RadioGroup
        aria-label="gender"
        className={classes.storeContainer}
        name="category"
        onChange={handleChange}
        value={device}
      >
        <Radio className={classes.radio} color="primary" value={devices.IOS} />
        <img className={classes.storeImg} src={appstore} alt="AppStore" />
        <Radio
          className={classes.radio}
          color="primary"
          value={devices.ANDROID}
        />
        <img className={classes.storeImg} src={playstore} alt="PlayStore" />
      </RadioGroup>
      <div>
        <Button
          className={classes.button}
          disabled={!device}
          disableElevation
          onClick={handleGetLink}
          variant="outlined"
        >
          {getText("header.appStores.button")}
        </Button>
      </div>
    </>
  );
};
