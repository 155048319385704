import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

// Config
import colors from "../../config/colors";
import styles from "../../config/styles";
import useTranslate from "../../hooks/useTranslate";

// Components
import Button from "../Button/Button";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(7),
    width: "100%",
    borderBottom: styles.values.border,
    "&:last-child": {
      border: "none"
    }
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "0 auto"
  },
  numberContainer: {
    marginRight: theme.spacing(2.5)
  },
  number: {
    borderRadius: "100%",
    color: colors.white,
    backgroundColor: colors.pictonBlue,
    fontSize: 40,
    fontFamily: ["Gibson SemiBold", "sans-serif"].join(","),
    width: 60,
    height: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  content: {
    display: "flex",
    flexDirection: "column"
  },
  title: {
    marginBottom: theme.spacing(1.5)
  },
  subtitle1: {
    marginBottom: theme.spacing(0.75),
    lineHeight: 1.4
  },
  subtitle: {
    marginBottom: theme.spacing(3),
    lineHeight: 1.4
  },
  storeContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(3)
  },
  radio: {
    marginRight: theme.spacing(0.5)
  },
  storeImg: {
    marginRight: theme.spacing(1.25)
  },
  button: {
    width: 297
  }
}));

const Step = ({
  buttonText,
  children,
  disableButtons,
  isLast,
  number,
  onClick,
  subtitle,
  subtitle1,
  title
}) => {
  const classes = useStyles();
  const getText = useTranslate();

  return (
    <div className={classes.root}>
      <div className={classes.contentWrapper}>
        <div className={classes.numberContainer}>
          <span className={classes.number}>{number}</span>
        </div>
        <div className={classes.content}>
          <Text type="heading2" className={classes.title}>
            {title}
          </Text>
          {subtitle1 && (
            <Text type="subtitle1Small" className={classes.subtitle1}>
              {subtitle1}
            </Text>
          )}
          <Text type="subtitle1Small" className={classes.subtitle}>
            {subtitle}
          </Text>

          {onClick && (
            <div className={classes.buttonContainer}>
              <Button
                disableElevation
                className={classes.button}
                variant={isLast ? "contained" : "outlined"}
                color={isLast ? "primary" : undefined}
                disabled={disableButtons}
                onClick={onClick}
              >
                {isLast ? getText("common.prompts.done") : buttonText}
              </Button>
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

Step.propTypes = {
  buttonText: PropTypes.string,
  children: PropTypes.node,
  disableButtons: PropTypes.bool,
  isLast: PropTypes.bool,
  number: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  subtitle: PropTypes.node.isRequired,
  subtitle1: PropTypes.node,
  title: PropTypes.string.isRequired
};

Step.defaultProps = {
  buttonText: "",
  children: null,
  disableButtons: false,
  isLast: false,
  onClick: null,
  subtitle1: null
};

export default Step;
