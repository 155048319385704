export const REQUEST_CASES = "cases/REQUEST_CASES";
export const REQUEST_CASES_SUCCESS = "cases/REQUEST_CASES_SUCCESS";
export const REQUEST_CASES_FAIL = "cases/REQUEST_CASES_FAIL";

export const REQUEST_CREATE_CASE = "cases/REQUEST_CREATE_CASE";
export const REQUEST_CREATE_CASE_SUCCESS = "cases/REQUEST_CREATE_CASE_SUCCESS";
export const REQUEST_CREATE_CASE_FAIL = "cases/REQUEST_CREATE_CASE_FAIL";

export const RESET_TOGGLED_CASE = "cases/RESET_TOGGLED_CASE";
export const TOGGLE_CASE = "cases/TOGGLE_CASE";
