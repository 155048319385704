import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import PropTypes from "prop-types";

// Config
import { getPersonAffectedIdUrl } from "../../config/apiConfig";

// Actions
import { getCaseDetail } from "../../state/caseDetail/actions";
import { parseFetchOptions } from "../../state/user/actions";

// Hooks
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import useTranslate from "../../hooks/useTranslate";

// Components
import Button from "../Button";
import FileUpload from "../FileUpload";

export const useCaptureVictimButton = caseId => {
  const { trackException } = useAppInsights();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(null);
  const [errors, setErrors] = useState(null);

  const toggleSubmitPicture = value => () => setOpen(value);

  const handleSubmitPicture = data => {
    const [{ file }] = data;

    dispatch(
      parseFetchOptions(async ({ headers: { Authorization, ...headers } }) => {
        const url = getPersonAffectedIdUrl(caseId);
        const options = {
          method: "PUT",
          headers: {
            ...headers,
            Authorization,
            "Content-Type": "application/json"
          }
        };

        const requestResponse = await fetch(url, options);

        if (requestResponse && requestResponse.ok) {
          const { uploadURL } = await requestResponse.json();

          axios
            .request({
              method: "PUT",
              headers: {
                ...headers,
                "Content-Type": "image/png",
                "x-ms-blob-type": "BlockBlob"
              },
              data: file,
              url: uploadURL,
              onUploadProgress: ({ loaded, total }) => {
                setProgress({ 0: (loaded / total) * 100 });
              }
            })
            .then(() => {
              setOpen(false);
              dispatch(getCaseDetail({ caseId }));
            })
            .catch(e => {
              trackException("Failed to upload victim ID");
              setErrors({ 0: e });
            });
        } else {
          trackException("Failed to get victim id upload url");
          setErrors({ 0: true });
        }
      })
    );
  };

  const fileUploadProps = {
    accept: "image/*",
    allowWebcam: true,
    noDescriptions: true,
    onClose: toggleSubmitPicture(false),
    onSubmit: handleSubmitPicture,
    open,
    uploadErrors: errors,
    uploadProgress: progress,
    titleText: "caseDetailPage.captureVictim.button"
  };

  return {
    fileUploadProps,
    openVictimUpload: toggleSubmitPicture(true)
  };
};

const CaptureVictimButton = ({ className }) => {
  const { data } = useSelector(s => s.caseDetail);
  const { openVictimUpload, fileUploadProps } = useCaptureVictimButton(data.id);
  const getText = useTranslate();

  return (
    <>
      <Button
        className={className}
        disableElevation
        onClick={openVictimUpload}
        variant="outlined"
      >
        {getText("caseDetailPage.captureVictim.button")}
      </Button>
      <FileUpload {...fileUploadProps} />
    </>
  );
};

CaptureVictimButton.propTypes = {
  className: PropTypes.string
};

CaptureVictimButton.defaultProps = {
  className: null
};

export default CaptureVictimButton;
