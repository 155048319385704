import axios from "axios";

import {
  REQUEST_MED,
  REQUEST_MED_SUCCESS,
  REQUEST_MED_FAIL,
  REQUEST_DEL_MED,
  REQUEST_DEL_MED_SUCCESS,
  REQUEST_DEL_MED_FAIL,
  REQUEST_ADD_MED,
  REQUEST_ADD_MED_SUCCESS,
  REQUEST_ADD_MED_FAIL,
  RESET_MED_UPLOADS,
  UPDATE_MED_PROGRESS,
  REQUEST_MED_ITEM,
  REQUEST_MED_ITEM_SUCCESS,
  REQUEST_MED_ITEM_FAIL
} from "./actionTypes";

import {
  getMedicalRecordsUrl,
  medicalRecordItemUrl
} from "../../config/apiConfig";
import { parseFetchOptions } from "../user/actions";
import errors from "../../utils/errors";

// Data
const requestMedicalRecords = payload => ({
  type: REQUEST_MED,
  payload
});

const requestMedicalRecordsSuccess = payload => ({
  type: REQUEST_MED_SUCCESS,
  payload
});

const requestMedicalRecordsFail = payload => ({
  type: REQUEST_MED_FAIL,
  payload
});

export const getMedicalRecords =
  ({ caseId, onFail }) =>
  async dispatch => {
    dispatch(requestMedicalRecords(caseId));

    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(getMedicalRecordsUrl(caseId), {
          method: "GET",
          headers: options.headers
        }).catch(error => {
          if (onFail) onFail();
          dispatch(requestMedicalRecordsFail(error));
        });

        if (response && response.ok) {
          const data = await response.json();
          dispatch(requestMedicalRecordsSuccess(data));
        } else {
          if (onFail) onFail();
          dispatch(requestMedicalRecordsFail(errors.responseError(response)));
        }
      })
    );
  };

// Get Medical Item
const requestMedicalRecordsItem = () => ({
  type: REQUEST_MED_ITEM
});

const requestMedicalRecordsItemSuccess = payload => ({
  type: REQUEST_MED_ITEM_SUCCESS,
  payload
});

const requestMedicalRecordsItemFail = payload => ({
  type: REQUEST_MED_ITEM_FAIL,
  payload
});

export const getMedicalRecordsItem =
  ({ caseId, recordId, onFail }) =>
  async dispatch => {
    dispatch(requestMedicalRecordsItem());

    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(medicalRecordItemUrl(caseId, recordId), {
          method: "GET",
          headers: options.headers
        }).catch(error => {
          if (onFail) onFail();
          dispatch(requestMedicalRecordsItemFail(error));
        });

        if (response && response.ok) {
          const data = await response.json();
          dispatch(requestMedicalRecordsItemSuccess(data));
        } else {
          if (onFail) onFail();
          dispatch(
            requestMedicalRecordsItemFail(errors.responseError(response))
          );
        }
      })
    );
  };

// Image Upload
const requestAddMedicalRecord = payload => ({
  type: REQUEST_ADD_MED,
  payload
});

const requestAddMedicalRecordSuccess = payload => ({
  type: REQUEST_ADD_MED_SUCCESS,
  payload
});

const requestAddMedicalRecordFail = payload => ({
  type: REQUEST_ADD_MED_FAIL,
  payload
});

export const updateMedicalProgress = payload => ({
  type: UPDATE_MED_PROGRESS,
  payload
});

export const resetMedicalUploads = () => ({ type: RESET_MED_UPLOADS });

export const addMedicalRecord =
  ({ caseId, description, file, id, onFail, onUploadProgress, query = "" }) =>
  async dispatch => {
    dispatch(requestAddMedicalRecord(id));

    dispatch(
      parseFetchOptions(async options => {
        const requestResponse = await fetch(
          `${getMedicalRecordsUrl(caseId)}${query}`,
          {
            method: "POST",
            headers: {
              ...options.headers,
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              filename: file.name,
              description
            })
          }
        );

        if (requestResponse && requestResponse.ok) {
          const { uploadURL, id: recordId } = await requestResponse.json();

          axios
            .request({
              method: "PUT",
              headers: {
                "x-ms-blob-type": "BlockBlob",
                "Content-Type": file.type
              },
              data: file,
              url: uploadURL,
              onUploadProgress
            })
            .then(() => {
              dispatch(requestAddMedicalRecordSuccess(id));
              dispatch(getMedicalRecordsItem({ caseId, recordId }));
            })
            .catch(() => {
              if (onFail) onFail();
              dispatch(requestAddMedicalRecordFail(id));
            });
        } else {
          if (onFail) onFail();
          dispatch(requestAddMedicalRecordFail(id));
        }
      })
    );
  };

// Delete
const requestDeleteMedRecord = payload => ({
  type: REQUEST_DEL_MED,
  payload
});

const requestDeleteMedRecordSuccess = payload => ({
  type: REQUEST_DEL_MED_SUCCESS,
  payload
});

const requestDeleteMedRecordFail = payload => ({
  type: REQUEST_DEL_MED_FAIL,
  payload
});

export const deleteMedicalRecord =
  ({ caseId, itemId, onFail }) =>
  async dispatch => {
    dispatch(requestDeleteMedRecord(itemId));

    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(medicalRecordItemUrl(caseId, itemId), {
          method: "DELETE",
          headers: options.headers
        }).catch(error => {
          if (onFail) onFail();
          dispatch(requestDeleteMedRecordFail(error));
        });

        if (response && response.ok) {
          dispatch(requestDeleteMedRecordSuccess(itemId));
        } else {
          if (onFail) onFail();
          dispatch(requestDeleteMedRecordFail(errors.responseError(response)));
        }
      })
    );
  };
