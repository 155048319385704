import React from "react";
import { useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams
} from "react-router-dom";

// Material
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

// Config
import { getRoute, urls } from "../../config/routes";
import { userTypes } from "../../config/values";
import styles from "../../config/styles";
import useTranslate from "../../hooks/useTranslate";

// Icons
import close from "../../assets/icons/close-24px.svg";

// Components
import ContentCardHeader from "../../components/ContentCardHeader";
import Link from "../../components/Link";
import SectionWrapper from "../../components/SectionWrapper";
import Text from "../../components/Text";

// Tabs
import CivilFormatTab from "./components/CivilFormatTab";
import CriminalFormatTab from "./components/CriminalFormatTab";
import SendEvidenceDropdown from "./components/SendEvidenceDropdown";

const useStyles = makeStyles(theme => ({
  root: {
    border: styles.values.border,
    marginBottom: theme.spacing(1.5)
  },
  closeIcon: {
    height: 24
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(5, 4.5, 8)
  },
  text: {
    lineHeight: 1.5,
    marginBottom: theme.spacing(2),
    "&:last-of-type": {
      marginBottom: theme.spacing(3.5)
    }
  },
  label: {
    marginBottom: theme.spacing(2)
  },
  phoneInputWrapper: {
    display: "flex",
    flexDirection: "row"
  },
  buttonLinkActive: {
    fontFamily: ["Gibson SemiBold", "sans-serif"].join(","),
    "&:after": {
      width: "100%"
    }
  },
  tabs: {
    marginBottom: theme.spacing(5)
  },
  tab: {
    fontFamily: ["Gibson", "sans-serif"].join(","),
    minHeight: 0,
    minWidth: 0,
    width: 148,
    padding: "18px 0"
  },
  tabSelected: {
    fontFamily: ["Gibson", "sans-serif"].join(",")
  },
  tabText: {
    fontSize: 16,
    color: theme.palette.common.black,
    textTransform: "capitalize",
    letterSpacing: 0
  },
  tabIndicator: {
    height: 6
  },
  disclaimer: {
    padding: theme.spacing(0, 3)
  }
}));

const a11yProps = index => ({
  id: `tab-${index}`,
  "aria-controls": `tabpanel-${index}`
});

const subroutes = ["/civil", "/criminal"];

const SendEvidencePage = () => {
  const { caseId } = useParams();
  const { userDetails } = useSelector(s => s.user);
  const { data } = useSelector(s => s.caseDetail);
  const classes = useStyles();

  const { userCapacity, creatorId } = data || {};

  const getText = useTranslate();
  const history = useHistory();

  const civilRoute = `${urls.caseDownload}/civil`;
  const criminalRoute = `${urls.caseDownload}/criminal`;

  const handleChange = (_, value) => {
    history.push(
      getRoute(`${urls.caseDownload}${subroutes[value]}`, { caseId })
    );
  };

  const tabClasses = {
    root: classes.tab,
    wrapper: classes.tabText,
    selected: classes.tabSelected
  };

  const selectedTab = subroutes.findIndex(r =>
    history.location.pathname.includes(r)
  );

  const isCreator = !!userDetails && userDetails.id === creatorId;
  const isVictim = userCapacity === userTypes.Victim;
  const isPolice = userCapacity === "PoliceOfficer";

  const canSubmit = isCreator;
  const canRequest = isCreator || isVictim || isPolice;

  return (
    <SectionWrapper>
      <Paper elevation={0} className={classes.root}>
        <ContentCardHeader>
          <Text text="downloadCasePage.title" type="heading2" />
          <IconButton
            component={Link}
            to={getRoute(urls.caseDetail, { caseId })}
          >
            <img className={classes.closeIcon} src={close} alt="" />
          </IconButton>
        </ContentCardHeader>
        <div className={classes.content}>
          <Tabs
            aria-label="notes tabs"
            indicatorColor="primary"
            onChange={handleChange}
            value={selectedTab > -1 ? selectedTab : 0}
            className={classes.tabs}
            classes={{ indicator: classes.tabIndicator }}
          >
            <Tab
              classes={tabClasses}
              label={getText("downloadCasePage.civilFormat.title")}
              {...a11yProps(0)}
            />
            <Tab
              classes={tabClasses}
              label={getText("downloadCasePage.criminalFormat.title")}
              {...a11yProps(1)}
            />
          </Tabs>
          <Switch>
            <Route path={civilRoute}>
              <CivilFormatTab canRequest={canRequest} />
            </Route>
            <Route path={criminalRoute}>
              <CriminalFormatTab
                canRequest={canRequest}
                canSubmit={canSubmit}
              />
            </Route>
            <Route>
              <Redirect to={getRoute(civilRoute, { caseId })} />
            </Route>
          </Switch>
        </div>
      </Paper>
      <Route path={civilRoute}>
        <>
          <Paper elevation={0} className={classes.root}>
            <SendEvidenceDropdown canRequest={canRequest} />
          </Paper>
          <Text
            className={classes.disclaimer}
            type="valueLabel"
            text="downloadCasePage.civilFormat.disclaimer"
          />
        </>
      </Route>
    </SectionWrapper>
  );
};

export default SendEvidencePage;
