import React from "react";
import { format } from "date-fns";
import { NavLink, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";

// Icons
import chevron from "../../../assets/icons/chevron.svg";

// Config
import { getRoute } from "../../../config/routes";
import colors from "../../../config/colors";
import styles from "../../../config/styles";

// Utils
import { truncateText } from "../../../utils/functions";
import useTranslate from "../../../hooks/useTranslate";

// Components
import Avatar from "../../../components/Avatar";
import Text from "../../../components/Text";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    borderBottom: styles.values.border
  },
  selected: {
    backgroundColor: colors.wildLand
  },
  topWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingRight: theme.spacing(3),
    position: "relative",
    marginBottom: theme.spacing(1),
    width: "100%"
  },
  avatar: {
    height: 24,
    width: 24,
    fontSize: 14
  },
  dateWrapper: {
    padding: theme.spacing(0, 0.75),
    color: colors.cadetBlue
  },
  byUser: {
    fontSize: 13,
    lineHeight: 1
  },
  date: {
    fontSize: 10
  },
  chip: {
    height: 20,
    fontSize: 8,
    borderRadius: 4,
    textTransform: "uppercase",
    backgroundColor: colors.silver,
    color: colors.white,
    marginLeft: "auto",
    padding: theme.spacing(0.5)
  },
  chipLabel: {
    padding: 0
  },
  chevron: {
    transform: "rotate(90deg) translateX(-25%)",
    marginLeft: "auto",
    position: "absolute",
    right: 0,
    top: "50%"
  },
  text: {
    marginBottom: theme.spacing(1),
    lineHeight: 1
  }
}));

const NoteItem = ({ data, draft, to }) => {
  const { caseId } = useParams();
  const { userDetails } = useSelector(s => s.user);
  const classes = useStyles();
  const getText = useTranslate();

  if (!data || !userDetails) return null;

  const { id, profilePictureURL, fullName } = data.creator || {};

  const isCreator = id === userDetails.id;
  const date = format(
    new Date(data.updateDateTime || data.creationDateTime),
    getText("common.dateFormat.onShortDate", null, "dd/MM/yy")
  );
  const texts = getText("notesPage.noteItem");

  const [displayName, withTooltip] =
    draft || isCreator ? [texts.you, false] : truncateText(fullName, 15);

  const nameTag = (
    <Text type="heading6">
      {texts.by} {displayName}
    </Text>
  );

  return (
    <ButtonBase
      component={NavLink}
      activeClassName={classes.selected}
      className={classes.root}
      to={getRoute(to, {
        caseId,
        itemId: data.id
      })}
    >
      <div className={classes.topWrapper}>
        <Avatar src={profilePictureURL} className={classes.avatar}>
          {fullName.charAt(0)}
        </Avatar>
        <div className={classes.dateWrapper}>
          {withTooltip ? (
            <Tooltip title={fullName} placement="top" arrow>
              {nameTag}
            </Tooltip>
          ) : (
            nameTag
          )}
          <Text className={classes.date} type="heading6">
            {date}
          </Text>
        </div>
        {draft && (
          <Chip
            label={texts.private}
            className={classes.chip}
            classes={{ label: classes.chipLabel }}
          />
        )}
        <img src={chevron} alt="" className={classes.chevron} />
      </div>
      <Text type="heading6" className={classes.text}>
        {data.title}
      </Text>
      <Text type="subtitle1" className={classes.text}>
        {truncateText(data.content, 50)[0]}
      </Text>
    </ButtonBase>
  );
};

NoteItem.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.string,
    creationDateTime: PropTypes.string,
    creator: PropTypes.shape({
      fullName: PropTypes.string,
      id: PropTypes.string,
      isCreator: PropTypes.bool,
      profilePictureURL: PropTypes.string
    }),
    id: PropTypes.string,
    title: PropTypes.string,
    updateDateTime: PropTypes.string
  }).isRequired,
  draft: PropTypes.bool,
  to: PropTypes.string.isRequired
};

NoteItem.defaultProps = {
  draft: false
};

export default NoteItem;
