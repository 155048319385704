import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

// Config
import { getRoute, urls } from "../../config/routes";

// Actions
import { submitCase, getCaseDetail } from "../../state/caseDetail/actions";

// Hooks
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import useDialog from "../../hooks/useDialog";
import useTranslate from "../../hooks/useTranslate";

// Components
import ConfirmationDialog from "../ConfirmationDialog";
import { useCaptureVictimButton } from "../CaptureVictimButton/CaptureVictimButton";
import FileUpload from "../FileUpload/FileUpload";

const SubmitDialog = ({ caseId, onClose, open, isLoading }) => {
  const { openVictimUpload, fileUploadProps } = useCaptureVictimButton(caseId);
  const { trackException } = useAppInsights();
  const dispatch = useDispatch();
  const getDialogProps = useDialog();
  const getText = useTranslate();
  const history = useHistory();

  const {
    data,
    isLoading: isLoadingCases,
    error
  } = useSelector(state => state.caseDetail);

  React.useEffect(() => {
    if (!error)
      dispatch(
        getCaseDetail({
          caseId,
          onFail: () =>
            trackException(`Failed to get details from case: ${caseId}`)
        })
      );
  }, [caseId, dispatch, error, trackException]);

  const modalProps = data
    ? getDialogProps(
        data,
        {
          captureVictimId: openVictimUpload,
          selectLocation: () =>
            history.push(getRoute(urls.incident, { caseId })),
          addInjury: () => history.push(getRoute(urls.addInjury, { caseId })),
          provideDetails: () =>
            history.push(getRoute(urls.submitIncident, { caseId })),
          submit: () =>
            dispatch(
              submitCase({
                caseId,
                onFail: () => trackException(`Failed to submit case: ${caseId}`)
              })
            )
        },
        getText("pageTitles.caseList")
      )
    : {
        onConfirm: () => {}
      };

  return (
    <>
      <ConfirmationDialog
        onClose={onClose}
        open={open}
        isLoading={!data || isLoading || isLoadingCases}
        {...modalProps}
        {...modalProps.dialog}
      />
      <FileUpload {...fileUploadProps} />
    </>
  );
};

SubmitDialog.propTypes = {
  caseId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default SubmitDialog;
