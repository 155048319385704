import React from "react";
import { useSelector } from "react-redux";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Skeleton from "@material-ui/lab/Skeleton";

// Config
import { urls } from "../../config/routes";
import useTranslate from "../../hooks/useTranslate";

// Components
import Link from "../Link";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row"
  },
  avatar: {
    height: 48,
    width: 48
  },
  image: {
    height: "100%"
  },
  display: {
    padding: theme.spacing(0, 2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  name: {
    marginBottom: theme.spacing(1)
  }
}));

const UserInfo = () => {
  const classes = useStyles();
  const getText = useTranslate();

  const { isLoading, userDetails } = useSelector(s => s.user);

  if (isLoading) {
    return (
      <div className={classes.root}>
        <Skeleton variant="circle" width={48} height={48} />

        <div className={classes.display}>
          <Skeleton
            variant="rect"
            width={190}
            height={20}
            className={classes.name}
          />
          <Skeleton width={100} height={15} variant="rect" />
        </div>
      </div>
    );
  }

  if (!userDetails) return null;

  return (
    <div className={classes.root}>
      <Link to={urls.settings}>
        <Avatar src={userDetails.profilePhotoURL} className={classes.avatar}>
          {userDetails.fullName.charAt(0)}
        </Avatar>
      </Link>
      <div className={classes.display}>
        <Text type="heading3" className={classes.name}>
          {userDetails.fullName}
        </Text>
        <Text type="subtitle1" className={classes.role}>
          {getText(`common.userTypes.${userDetails.userType}`)}
        </Text>
      </div>
    </div>
  );
};

export default UserInfo;
