import React from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

// Config
import colors from "../../config/colors";

// Components
import Text from "../Text";

const useStyles = makeStyles(() => ({
  errorContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  textErrorWrapper: {
    display: "flex",
    flexDirection: "column",
    color: colors.rose
  },
  xmarkIcon: {
    color: colors.rose,
    marginTop: 3
  },
  replacementText: {
    color: colors.rose,
    textTransform: "uppercase",
    textDecoration: "underline"
  }
}));

const ImageItemReupload = ({ className, onClick }) => {
  const classes = useStyles();

  return (
    <>
      <ButtonBase onClick={onClick} className={className}>
        <div className={classes.errorContainer}>
          <div className={classes.textErrorWrapper}>
            <FontAwesomeIcon
              className={classes.xmarkIcon}
              icon={faCircleXmark}
            />
            <Text type="body" text="mediaPage.uploadFailed" />
            <Text
              type="body"
              className={classes.replacementText}
              text="common.valueLabels.uploadReplacement"
            />
          </div>
        </div>
      </ButtonBase>
    </>
  );
};

ImageItemReupload.propTypes = {
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default ImageItemReupload;
