import React from "react";
import PropTypes from "prop-types";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";

// Components
import Close from "../../Close";
import ConfirmationButton from "../../ConfirmationButton";
import CustomField from "../../CustomField";

const useStyles = makeStyles({
  input: {
    position: "relative"
  },
  close: {
    position: "absolute",
    right: 0,
    top: -10,
    cursor: "pointer"
  }
});

const InputListItem = ({
  disableDelete,
  isLoading,
  onRemove,
  removeCallback,
  index,
  ...rest
}) => {
  const classes = useStyles();
  const onClick = onRemove(index, () => removeCallback(index));
  return (
    <div className={classes.input}>
      <CustomField {...rest} useFastField />
      <Fade in timeout={1000}>
        <ConfirmationButton
          className={classes.close}
          onClick={onClick}
          type="icon"
          disabled={isLoading || disableDelete}
        >
          {isLoading ? <CircularProgress size={24} /> : <Close />}
        </ConfirmationButton>
      </Fade>
    </div>
  );
};

InputListItem.propTypes = {
  disableDelete: PropTypes.bool,
  index: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  onRemove: PropTypes.func.isRequired,
  removeCallback: PropTypes.func.isRequired
};

InputListItem.defaultProps = {
  disableDelete: false,
  isLoading: false
};

export default React.memo(InputListItem);
