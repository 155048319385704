import React from "react";
import PropTypes from "prop-types";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { fieldToTextField } from "formik-material-ui";

const FormikAutocomplete = ({
  onChange,
  onBlur,
  textFieldProps,
  valueKey,
  icon,
  error,
  helperText,
  ...props
}) => {
  const {
    form: { setTouched, setFieldValue, touched }
  } = props;
  const {
    helperText: hText,
    error: err,
    ...field
  } = fieldToTextField({
    ...props
  });
  const { name } = field;

  const handleChange = (event, value) => {
    if (onChange) onChange(event, value);
    else setFieldValue(name, value);
  };

  const handleBlur = event => {
    if (onBlur) onBlur(event);
    else setTouched({ [name]: true });
  };

  const isTouched = touched[name];

  const renderInput = renderProps => (
    <>
      {icon}
      <TextField
        {...renderProps}
        {...textFieldProps}
        helperText={isTouched && helperText}
        error={isTouched && error}
        InputProps={{
          ...renderProps.InputProps,
          ...textFieldProps.InputProps,
          disableUnderline: true
        }}
      />
    </>
  );

  return (
    <Autocomplete
      {...props}
      {...field}
      onChange={handleChange}
      onBlur={handleBlur}
      renderInput={renderInput}
    />
  );
};

FormikAutocomplete.propTypes = {
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  form: PropTypes.shape({
    touched: PropTypes.shape({}),
    setTouched: PropTypes.func,
    setFieldValue: PropTypes.func
  }).isRequired,
  icon: PropTypes.node,
  textFieldProps: PropTypes.shape({
    InputProps: PropTypes.shape({})
  }),
  valueKey: PropTypes.string
};

FormikAutocomplete.defaultProps = {
  error: false,
  helperText: "",
  icon: null,
  onBlur: undefined,
  onChange: undefined,
  textFieldProps: {},
  valueKey: ""
};

export default FormikAutocomplete;
