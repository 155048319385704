export const REQUEST_MED = "medical/REQUEST_MED";
export const REQUEST_MED_SUCCESS = "medical/REQUEST_MED_SUCCESS";
export const REQUEST_MED_FAIL = "medical/REQUEST_MED_FAIL";

export const REQUEST_MED_ITEM = "medical/REQUEST_MED_ITEM";
export const REQUEST_MED_ITEM_SUCCESS = "medical/REQUEST_MED_ITEM_SUCCESS";
export const REQUEST_MED_ITEM_FAIL = "medical/REQUEST_MED_ITEM_FAIL";

export const REQUEST_ADD_MED = "medical/REQUEST_ADD_MED";
export const REQUEST_ADD_MED_SUCCESS = "medical/REQUEST_ADD_MED_SUCCESS";
export const REQUEST_ADD_MED_FAIL = "medical/REQUEST_ADD_MED_FAIL";

export const REQUEST_DEL_MED = "medical/REQUEST_DEL_MED";
export const REQUEST_DEL_MED_SUCCESS = "medical/REQUEST_DEL_MED_SUCCESS";
export const REQUEST_DEL_MED_FAIL = "medical/REQUEST_DEL_MED_FAIL";

export const RESET_MED_UPLOADS = "medical/RESET_MED_UPLOADS";
export const UPDATE_MED_PROGRESS = "medical/UPDATE_MED_PROGRESS";
