import React from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";

// FontAwesome
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Config
import { getRoute } from "../../config/routes";
import colors from "../../config/colors";

// Components
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  heading: {
    marginBottom: theme.spacing(2.5),
    textTransform: "uppercase"
  },
  content: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  action: {
    color: colors.denim,
    margin: theme.spacing(0, 5, 2, 0),
    display: "flex",
    flexDirection: "row",

    "&:hover": {
      textDecoration: "underline"
    }
  },
  linkText: {
    marginRight: theme.spacing(1.5)
  }
}));

const PendingActions = ({ data }) => {
  const { caseId } = useParams();
  const classes = useStyles();

  return (
    <>
      <Text
        className={classes.heading}
        text="caseDetailPage.caseInfoCard.pendingActionsTitle"
        type="contentLabel"
      />
      <div className={classes.content}>
        {data.map(({ key, url }) => (
          <Link
            className={classes.action}
            key={key}
            to={getRoute(url, { caseId })}
          >
            <Text
              className={classes.linkText}
              text={`caseDetailPage.caseInfoCard.pendingActions.${key}`}
              type="link"
            />
            <FontAwesomeIcon icon={faLongArrowAltRight} />
          </Link>
        ))}
      </div>
    </>
  );
};

PendingActions.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired
};

export default PendingActions;
