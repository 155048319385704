import React from "react";
import { useSelector, useDispatch } from "react-redux";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";
import Snackbar from "@material-ui/core/Snackbar";

// Actions
import { resetSnack } from "../../state/snackbar/actions";

// Config
import { snackbarTypes } from "../../config/snackbar";

// Styles
import colors from "../../config/colors";
import styles from "../../config/styles";

// Components
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    top: styles.values.headerHeight,
    marginTop: theme.spacing(6)
  },
  progress: {
    color: colors.white
  }
}));

const alertProps = {
  [snackbarTypes.ERROR]: {
    severity: "error"
  },
  [snackbarTypes.UPLOADING]: {
    severity: "info",
    isLoading: true
  },
  [snackbarTypes.LOADING]: {
    severity: "info",
    isLoading: true
  },
  [snackbarTypes.SUCCESS]: {
    severity: "success"
  },
  [snackbarTypes.DELETING]: {
    severity: "info",
    isLoading: true
  },

  // Customs
  // Case
  [snackbarTypes.ANNOTATION_SAVED]: {
    severity: "success"
  },
  [snackbarTypes.INCIDENT_SAVED]: {
    severity: "success"
  },
  [snackbarTypes.CASE_NOT_FOUND]: {
    severity: "error"
  },
  [snackbarTypes.CASE_CREATED]: {
    severity: "success"
  },
  [snackbarTypes.CASE_DELETED]: {
    severity: "success"
  },
  [snackbarTypes.CONTRIBUTOR_DELETED]: {
    severity: "success"
  },

  // Injuries
  [snackbarTypes.INJURY_DELETED]: {
    severity: "success"
  },

  // Requests
  [snackbarTypes.INVITATION_SENT]: {
    severity: "success"
  },
  [snackbarTypes.SENDING_MESSAGE]: {
    severity: "info",
    isLoading: true
  },
  [snackbarTypes.SMS_SENT]: {
    severity: "success"
  },
  [snackbarTypes.REQUEST_SENT]: {
    severity: "success"
  },

  // Media
  [snackbarTypes.MEDIA_DELETED]: {
    severity: "success"
  }
};

const SnackbarHandler = () => {
  const { text, type } = useSelector(s => s.snackbar);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClose = (e, reason) => {
    if (reason !== "clickaway") dispatch(resetSnack());
  };

  const currentType = alertProps[type];

  if (!currentType) return null;

  const timeout =
    ![snackbarTypes.UPLOADING, snackbarTypes.LOADING].includes(type) && 6000;

  return (
    <Snackbar
      anchorOrigin={{
        horizontal: "center",
        vertical: "top"
      }}
      classes={{
        anchorOriginTopCenter: classes.root
      }}
      open={Boolean(currentType)}
      autoHideDuration={timeout || undefined}
      onClose={handleClose}
      TransitionComponent={Fade}
    >
      <Alert
        severity={currentType.severity}
        icon={
          currentType.isLoading && (
            <CircularProgress
              thickness={2}
              size={36}
              className={classes.progress}
            />
          )
        }
      >
        <Text text={text || `header.snackbar.${type}`} type="heading5" />
      </Alert>
    </Snackbar>
  );
};

export default SnackbarHandler;
