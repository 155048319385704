import React from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Icons
import icon from "../../assets/icons/illustration_3.svg";

// Config
import { getRoute, urls } from "../../config/routes";
import colors from "../../config/colors";
import useTranslate from "../../hooks/useTranslate";

// Components
import Button from "../Button";
import Link from "../Link";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%"
  },
  image: {
    marginBottom: theme.spacing(1.5)
  },
  text: {
    color: colors.riverBed,
    opacity: 0.4,
    marginBottom: theme.spacing(2.25),
    lineHeight: 1.3,
    width: 222,
    textAlign: "center",
    fontSize: 20
  },
  button: {
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 235,
    height: 42
  }
}));

const ContributorsPlaceholder = ({ caseId }) => {
  const classes = useStyles();
  const getText = useTranslate();

  const text = getText("pageTitles.inviteContributors");

  return (
    <div className={classes.root}>
      <img className={classes.image} src={icon} alt="" />
      <Text type="subtitle1" className={classes.text}>
        {text}
      </Text>
      <Button
        className={classes.button}
        color="primary"
        component={Link}
        disableElevation
        to={getRoute(urls.inviteContributor, { caseId })}
        variant="contained"
      >
        {text}
      </Button>
    </div>
  );
};

ContributorsPlaceholder.propTypes = {
  caseId: PropTypes.string.isRequired
};

export default ContributorsPlaceholder;
