import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";

// Material
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import ButtonBase from "@material-ui/core/ButtonBase";

// Icons
import defaultIcon from "../../assets/icons/user-default.svg";
import medIcon from "../../assets/icons/user-med.svg";
import policeIcon from "../../assets/icons/user-police.svg";
import victimIcon from "../../assets/icons/user-victim.svg";

// Config
import { userTypes } from "../../config/values";
import styles from "../../config/styles";
import useTranslate from "../../hooks/useTranslate";

// Components
import Button from "../Button";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(7, 8, 4),
    display: "flex",
    flexDirection: "column",
    minWidth: 470
  },
  title: {
    marginBottom: theme.spacing(3)
  },
  subtitle: {
    marginBottom: theme.spacing(3),
    fontSize: 22
  },
  optionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: theme.spacing(3)
  },
  optionWrapper: {
    display: "flex",
    justifyContent: "start",
    flexDirection: "row",
    width: "48%",
    padding: theme.spacing(2, 4),
    marginBottom: theme.spacing(2.25),
    border: styles.values.border,
    borderRadius: 12
  },
  selected: {
    backgroundColor: "#D4F5F9"
  },
  radio: {
    marginRight: theme.spacing(2.5)
  },
  userIcon: {
    marginRight: theme.spacing(2.5),
    width: 30
  },
  optionInfo: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left"
  },
  heading: {
    marginBottom: theme.spacing(0.75),
    fontWeight: "bold"
  },
  text: {},
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  button: {
    minWidth: 144,
    width: "48%",
    borderRadius: 12
  }
}));

const optionsText = "createCasePage.userTypeDialog.options";
const options = [
  {
    userCapacity: "MedicalProfessional",
    icon: medIcon
  },
  {
    userCapacity: "PoliceOfficer",
    icon: policeIcon
  },
  {
    userCapacity: "Victim",
    icon: victimIcon,
    allowAll: true
  },
  {
    userCapacity: "Relative",
    icon: defaultIcon,
    allowAll: true
  },
  {
    userCapacity: "Witness",
    icon: defaultIcon,
    allowAll: true
  }
];

const UserTypeForm = ({ handleNext, handleSelect, userType, value }) => {
  const [sexualAssault, setSexualAssault] = useState(false);
  const classes = useStyles();
  const getText = useTranslate();
  const history = useHistory();

  const onNext = () => {
    if (value === userTypes.Victim) {
      setSexualAssault(true);
    } else {
      handleNext();
    }
  };

  const onSelect = newValue => {
    if (newValue !== value) {
      setSexualAssault(false);
    }
    handleSelect(newValue);
  };

  return (
    <div className={classes.root}>
      <Text
        align="left"
        className={classes.title}
        text="createCasePage.userTypeDialog.title"
        type="heading1"
      />
      <Text
        align="left"
        className={classes.subtitle}
        text="createCasePage.userTypeDialog.description"
        type="subtitle1Big"
      />
      <FormControl component="fieldset">
        <div
          aria-label="userType"
          className={classes.optionContainer}
          name="userType"
        >
          {options.map(
            ({ allowAll, icon, userCapacity }) =>
              (allowAll || userType === userCapacity) && (
                <ButtonBase
                  key={userCapacity}
                  className={clsx(classes.optionWrapper, {
                    [classes.selected]: value === userCapacity
                  })}
                  onClick={() => onSelect(userCapacity)}
                >
                  <img className={classes.userIcon} src={icon} alt="" />
                  <div className={classes.optionInfo}>
                    <Text
                      className={classes.heading}
                      text={`${optionsText}.${userCapacity}.title`}
                      type="subtitle1Big"
                    />
                    <Text
                      className={classes.text}
                      text={`${optionsText}.${userCapacity}.description`}
                      type="subtitle1Small"
                    />
                  </div>
                </ButtonBase>
              )
          )}
        </div>
      </FormControl>
      {sexualAssault ? (
        <>
          <Text
            align="left"
            className={classes.subtitle}
            text="createCasePage.userTypeDialog.sexualAssault.title"
            type="subtitle1Big"
          />
          <div className={classes.buttonsContainer}>
            <Button
              className={classes.button}
              disabled={value === "none"}
              disableElevation
              onClick={() => handleNext({ isSexualAssault: true })}
              variant="outlined"
            >
              {getText("createCasePage.userTypeDialog.sexualAssault.yesButton")}
            </Button>
            <Button
              className={classes.button}
              color="primary"
              disableElevation
              onClick={() => handleNext({ isSexualAssault: false })}
              variant="contained"
            >
              {getText("createCasePage.userTypeDialog.sexualAssault.noButton")}
            </Button>
          </div>
        </>
      ) : (
        <div className={classes.buttonsContainer}>
          <Button
            className={classes.button}
            disableElevation
            onClick={history.goBack}
            variant="outlined"
          >
            {getText("common.prompts.cancel")}
          </Button>
          <Button
            className={classes.button}
            color="primary"
            disabled={value === "none"}
            disableElevation
            onClick={onNext}
            variant="contained"
          >
            {getText("common.prompts.next")}
          </Button>
        </div>
      )}
    </div>
  );
};

UserTypeForm.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export default UserTypeForm;
