import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash-es/get";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

// Config
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import styles from "../../config/styles";

// Actions
import {
  getInfoRequests,
  createInfoRequest
} from "../../state/infoRequests/actions";

// Common Components
import ContentCardHeader from "../ContentCardHeader";
import LoadingSection from "../LoadingSection";
import Text from "../Text";

// Components
import Chat from "./Chat";
import ContactItem from "./ContactItem";
import ContributorsPlaceholder from "./ContributorsPlaceholder";
import NewRequest from "./NewRequest";
import NoRequestsPlaceholder from "./NoRequestsPlaceholder";
import PlaceholderContact from "./PlaceholderContact";

const useStyles = makeStyles(theme => ({
  root: {
    border: styles.values.border
  },
  content: {
    display: "flex",
    flexDirection: "row",
    minHeight: 500
  },
  contentLeft: {
    display: "flex",
    flexDirection: "column",
    borderRight: styles.values.border,
    width: 288,
    minWidth: 288
  },
  contentRight: {
    display: "flex",
    flexDirection: "column",
    width: "calc(100% - 288px)"
  },
  headingWrapper: {
    padding: theme.spacing(4),
    borderBottom: styles.values.border,
    width: "100%"
  },
  heading: {
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "uppercase"
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between"
  },
  contactList: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    maxHeight: 410,
    scrollBehavior: "smooth",
    ...styles.mixins.scrollBar
  }
}));

const FurtherInfoPanel = ({ contributors, infoRequestId, caseId }) => {
  const { trackException } = useAppInsights();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { data, isCreating, isLoading, loadingMessages, messages } =
    useSelector(state => state.infoRequests);

  const [selected, setSelected] = useState(infoRequestId);
  const ref = useRef();

  useEffect(() => {
    dispatch(
      getInfoRequests({
        caseId,
        onFail: () =>
          trackException(`Failed to get info request for case: ${caseId}`)
      })
    );
  }, [caseId, dispatch, trackException]);

  useEffect(() => {
    if (isCreating) ref.current.scrollTo(0, ref.current.scrollHeight);
  }, [isCreating]);

  if (isLoading) {
    return (
      <Paper elevation={0} className={classes.root}>
        <LoadingSection normalHeight />
      </Paper>
    );
  }

  const handleSelectChat = id => () => setSelected(id);

  const handleNewRequest = body => {
    dispatch(
      createInfoRequest({
        caseId,
        body,
        onFinish: (success, res) => {
          if (success) {
            setSelected(res.id);
            ref.current.scrollTo(0, ref.current.scrollHeight);
          } else {
            trackException(`Failed to create info request at case: ${caseId}`);
          }
        }
      })
    );
  };

  return (
    <Paper elevation={0} className={classes.root}>
      <ContentCardHeader>
        <Text text="furtherInfoPage.title" type="heading2" />
      </ContentCardHeader>
      <div className={classes.content}>
        <div className={classes.contentLeft}>
          <div className={classes.headingWrapper}>
            <Text
              className={classes.heading}
              text="furtherInfoPage.requestToTitle"
              type="body2"
            />
          </div>
          <div className={classes.contentWrapper}>
            {contributors && contributors.length > 0 ? (
              <>
                <div ref={ref} className={classes.contactList}>
                  {data &&
                    data.map(({ id, parties }) => (
                      <ContactItem
                        key={id}
                        onClick={handleSelectChat(id)}
                        parties={parties}
                        selected={selected === id}
                      />
                    ))}
                  {isCreating && <PlaceholderContact />}
                </div>
                {data && data.length > 0 && (
                  <NewRequest
                    contributors={contributors}
                    onSubmit={handleNewRequest}
                  />
                )}
              </>
            ) : (
              <ContributorsPlaceholder caseId={caseId} />
            )}
          </div>
        </div>
        <div className={classes.contentRight}>
          <div className={classes.headingWrapper}>
            <Text
              className={classes.heading}
              text="furtherInfoPage.sentRequestsTitle"
              type="body2"
            />
          </div>
          <div className={classes.contentWrapper}>
            {selected && data && (
              <Chat
                data={get(messages, `${selected}.messages`, [])}
                infoRequestId={selected}
                isLoading={loadingMessages}
                parties={get(
                  data.find(item => item.id === selected),
                  "parties"
                )}
              />
            )}
            {(!data || data.length === 0) && (
              <NoRequestsPlaceholder>
                <NewRequest
                  contributors={contributors}
                  onSubmit={handleNewRequest}
                  width={339}
                />
              </NoRequestsPlaceholder>
            )}
          </div>
        </div>
      </div>
    </Paper>
  );
};

FurtherInfoPanel.propTypes = {
  caseId: PropTypes.string.isRequired,
  contributors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  infoRequestId: PropTypes.string
};

FurtherInfoPanel.defaultProps = {
  infoRequestId: null
};

export default FurtherInfoPanel;
