import {
  REQUEST_INFO_REQUESTS,
  REQUEST_INFO_REQUESTS_SUCCESS,
  REQUEST_INFO_REQUESTS_FAIL,
  REQUEST_MESSAGES,
  REQUEST_MESSAGES_SUCCESS,
  REQUEST_MESSAGES_FAIL,
  REQUEST_NEW_INFO,
  REQUEST_NEW_INFO_SUCCESS,
  REQUEST_NEW_INFO_FAIL
} from "./actionTypes";

import { parseFetchOptions } from "../user/actions";
import { infoRequestsUrl, infoReqMessagesUrl } from "../../config/apiConfig";
import errors from "../../utils/errors";

// Data
const requestInfoRequests = () => ({
  type: REQUEST_INFO_REQUESTS
});

const requestInfoRequestsSuccess = payload => ({
  type: REQUEST_INFO_REQUESTS_SUCCESS,
  payload
});

const requestInfoRequestsFail = payload => ({
  type: REQUEST_INFO_REQUESTS_FAIL,
  payload
});

export const getInfoRequests =
  ({ caseId, onFail }) =>
  async dispatch => {
    dispatch(requestInfoRequests());

    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(infoRequestsUrl(caseId), {
          method: "GET",
          headers: options.headers
        }).catch(error => {
          if (onFail) onFail();
          dispatch(requestInfoRequestsFail(error));
        });

        if (response && response.ok) {
          const data = await response.json();
          dispatch(requestInfoRequestsSuccess(data));
        } else {
          if (onFail) onFail();
          dispatch(requestInfoRequestsFail(errors.responseError(response)));
        }
      })
    );
  };

// Messages
const requestMessages = () => ({
  type: REQUEST_MESSAGES
});

const requestMessagesSuccess = payload => ({
  type: REQUEST_MESSAGES_SUCCESS,
  payload
});

const requestMessagesFail = payload => ({
  type: REQUEST_MESSAGES_FAIL,
  payload
});

export const getMessages =
  ({ caseId, infoRequestId, onFail }) =>
  async dispatch => {
    dispatch(requestMessages());

    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(
          infoReqMessagesUrl(caseId, infoRequestId),
          {
            method: "GET",
            headers: options.headers
          }
        ).catch(error => {
          if (onFail) onFail();
          dispatch(
            requestMessagesFail({
              [infoRequestId]: error
            })
          );
        });

        if (response && response.ok) {
          const data = await response.json();
          dispatch(requestMessagesSuccess({ [infoRequestId]: data }));
        } else {
          if (onFail) onFail();
          dispatch(
            requestMessagesFail({
              [infoRequestId]: errors.responseError(response)
            })
          );
        }
      })
    );
  };

// New Request
const requestNewInfo = () => ({
  type: REQUEST_NEW_INFO
});

const requestNewInfoSuccess = payload => ({
  type: REQUEST_NEW_INFO_SUCCESS,
  payload
});

const requestNewInfoFail = payload => ({
  type: REQUEST_NEW_INFO_FAIL,
  payload
});

export const createInfoRequest =
  ({ caseId, body, onFinish }) =>
  async dispatch => {
    dispatch(requestNewInfo());

    dispatch(
      parseFetchOptions(async options => {
        const response = await fetch(infoRequestsUrl(caseId), {
          method: "POST",
          headers: {
            ...options.headers,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(body)
        }).catch(error => {
          if (onFinish) onFinish(false);
          dispatch(requestNewInfoFail(error));
        });

        if (response && response.ok) {
          const data = await response.json();
          dispatch(requestNewInfoSuccess(data));
          if (onFinish) onFinish(true, data);
        } else {
          dispatch(requestNewInfoFail());
          if (onFinish) onFinish(false);
        }
      })
    );
  };
