import React from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";

// FontAwesome
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Actions
import { onSignOut } from "../../state/user/actions";

// Config
import { urls, externalRoutes } from "../../config/routes";
import colors from "../../config/colors";
import styles from "../../config/styles";
import useTranslate from "../../hooks/useTranslate";

// Images
import mainLogo from "../../assets/logos/main-logo.png";
import settingsIcon from "../../assets/icons/settings.svg";

// Components
import GetTheApp from "../GetTheApp";
import Link from "../Link";
import Notifications from "../Notifications";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 70,
    padding: theme.spacing(0, 3),
    boxShadow: styles.values.boxShadow
  },
  branding: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: theme.spacing(3)
  },
  image: {
    height: 28
  },
  appTitle: {
    color: colors.tuna,
    fontWeight: theme.typography.fontWeightMedium
  },
  navigation: {
    display: "flex",
    flexDirection: "row",
    borderLeft: "1px solid",
    borderColor: colors.mystic,
    paddingLeft: theme.spacing(4)
  },
  link: {
    marginRight: theme.spacing(6),
    fontWeight: theme.typography.fontWeightRegular,
    color: colors.shuttleGray
  },
  activeLink: {
    color: colors.denim,
    fontWeight: theme.typography.fontWeightMedium
  },
  text: {
    color: "inherit",
    fontWeight: "inherit",
    fontSize: 14
  },
  buttonContainer: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  storeContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: theme.spacing(0, 1, 0, 2),
    borderRight: styles.values.border,
    paddingRight: theme.spacing(1)
  },
  storeLink: {
    marginRight: theme.spacing(1)
  },
  storeImg: {
    display: "block"
  },
  button: {
    padding: theme.spacing(1),
    minWidth: "auto"
  },
  icon: {
    fontSize: 20,
    color: colors.santasGray
  },
  selectedButton: {
    backgroundColor: colors.pictonBlue,
    "& *": {
      color: colors.white,
      "&.fill": {
        color: colors.white
      }
    },
    "& img": {
      filter: "brightness(2)"
    }
  }
}));

const TopBar = ({ isPublic }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const getText = useTranslate();

  const handleSignOut = () => dispatch(onSignOut());

  return (
    <Toolbar className={classes.root}>
      <NavLink to={urls.cases} className={classes.branding}>
        <img className={classes.image} src={mainLogo} alt="Kulpa" />
      </NavLink>
      {!isPublic && (
        <nav className={classes.navigation}>
          <NavLink
            activeClassName={classes.activeLink}
            className={classes.link}
            to={urls.cases}
          >
            <Text
              className={classes.text}
              text="header.cases"
              type="valueLabel"
            />
          </NavLink>
          <Link
            className={classes.link}
            target="_blank"
            to={externalRoutes.faq}
          >
            <Text
              className={classes.text}
              text="header.faq"
              type="valueLabel"
            />
          </Link>
          <Link
            className={classes.link}
            target="_blank"
            to={externalRoutes.victimSupport}
          >
            <Text
              className={classes.text}
              text="header.victimSupport"
              type="valueLabel"
            />
          </Link>
          <Link
            className={classes.link}
            target="_blank"
            to={externalRoutes.contact}
          >
            <Text
              className={classes.text}
              text="header.technicalSupport"
              type="valueLabel"
            />
          </Link>
        </nav>
      )}
      <GetTheApp />
      {!isPublic && (
        <>
          <Tooltip title={getText("header.signOut")}>
            <Button className={classes.button} onClick={handleSignOut}>
              <FontAwesomeIcon className={classes.icon} icon={faSignOutAlt} />
            </Button>
          </Tooltip>
          <Button
            activeClassName={classes.selectedButton}
            className={classes.button}
            component={NavLink}
            to={urls.settings}
          >
            <img className={classes.icon} src={settingsIcon} alt="settings" />
          </Button>
          <Notifications classes={classes} />
        </>
      )}
    </Toolbar>
  );
};

TopBar.propTypes = {
  isPublic: PropTypes.bool.isRequired
};

export default TopBar;
