import React from "react";
import PropTypes from "prop-types";

// Material
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";

const PopperMenu = ({
  anchorEl,
  ariaId,
  children,
  classes,
  onClose,
  onKeyDown,
  open,
  placement
}) => (
  <Popper
    anchorEl={anchorEl}
    disablePortal
    open={open}
    placement={placement}
    role={undefined}
    transition
  >
    {({ TransitionProps }) => (
      <Grow {...TransitionProps}>
        <Paper className={classes.paper} elevation={3}>
          <ClickAwayListener onClickAway={onClose}>
            <MenuList
              autoFocusItem={open}
              className={classes.list}
              id={ariaId}
              onKeyDown={onKeyDown}
            >
              {children}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Grow>
    )}
  </Popper>
);

PopperMenu.propTypes = {
  anchorEl: PropTypes.shape({}),
  ariaId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({
    list: PropTypes.string,
    paper: PropTypes.string
  }),
  onClose: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  placement: PropTypes.string
};

PopperMenu.defaultProps = {
  anchorEl: null,
  classes: {},
  placement: "top-start"
};

export default PopperMenu;
