import React from "react";
import { useDispatch, useSelector } from "react-redux";
import format from "date-fns/format";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";

// Actions
import { toggleSnack } from "../../state/snackbar/actions";

// Config
import { snackbarTypes } from "../../config/snackbar";
import { submissionStatus, userTypes } from "../../config/values";
import { urls } from "../../config/routes";
import styles from "../../config/styles";

// Icons
import chevron from "../../assets/icons/chevron.svg";
import clock from "../../assets/icons/clock.svg";
import copy from "../../assets/icons/copy.svg";

// Components
import CaseLog from "./CaseLog";
import CaseLogPopper from "../CaseLogPopper";
import ContentCardHeader from "../ContentCardHeader";
import LightTooltip from "../LightTooltip";
import PendingActions from "./PendingActions";
import StatusChip, { chipType } from "../StatusChip";
import Text from "../Text";

// Hooks
import useLogs from "../../hooks/useLogs";
import useTranslate from "../../hooks/useTranslate";

const useStyles = makeStyles(theme => ({
  root: {
    border: styles.values.border
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2, 0)
  },
  title: {
    fontSize: 18
  },
  valueWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1)
  },
  titleValue: {
    fontSize: 14
  },
  chevron: {
    transform: "rotate(180deg)",
    marginLeft: theme.spacing(0.5)
  },
  iconButton: {
    padding: 8
  },
  buttonIcon: {
    height: 14,
    width: 14
  },
  updated: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  date: {
    margin: theme.spacing(0, 0.5)
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(5, 4, 0)
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    borderBottom: styles.values.border,
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2.5),
    "&:last-of-type": {
      border: "none"
    }
  },
  heading: {
    marginBottom: theme.spacing(2.5),
    textTransform: "uppercase"
  },
  valuesWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2)
  },
  chip: {
    borderRadius: theme.shape.borderRadius,
    marginLeft: "auto",
    height: 20
  }
}));

const CaseInfoCard = ({ data }) => {
  const [logs, loading] = useLogs(5);
  const { contributors, data: caseData } = useSelector(s => s.caseDetail);
  const classes = useStyles();
  const getText = useTranslate();
  const dispatch = useDispatch();

  const isWitness = caseData && caseData.userCapacity === userTypes.Witness;

  const pendingActions = React.useMemo(() => {
    const pendingItems = [
      {
        key: "IncidentDetails",
        url: urls.incident
      },
      {
        key: "Injuries",
        url: urls.addInjury,
        hideToWitnesses: true
      },
      {
        key: "MedicalRecords",
        url: urls.medicalRecords,
        hideToWitnesses: true
      },
      {
        key: "ComplementaryMedia",
        url: urls.caseMedia
      },
      {
        key: "FurtherInfo",
        url: urls.infoRequests,
        hideInDraft: true
      },
      {
        key: "Contributors",
        url: urls.inviteContributor,
        hideInDraft: true,
        hideToWitnesses: true
      }
    ];
    const items = [];
    pendingItems.forEach(({ hideInDraft, hideToWitnesses, key, url }) => {
      if (
        !(
          data.completedSections.indexOf(key) > -1 ||
          (key === "Contributors" && contributors.length < 1) ||
          (key === "Injuries" && data.numberOfMediaItems > 0) ||
          (hideToWitnesses && isWitness) ||
          (hideInDraft && data.isDraft)
        )
      ) {
        items.push({ key, url });
      }
    });
    return items;
  }, [
    contributors.length,
    data.numberOfMediaItems,
    data.completedSections,
    data.isDraft,
    isWitness
  ]);

  const handleClickCopy = value => () => {
    navigator.clipboard.writeText(value);
    dispatch(
      toggleSnack({
        type: snackbarTypes.SUCCESS,
        text: "common.prompts.copiedToClipboard"
      })
    );
  };

  const renderCaseRefValue = value =>
    value ? (
      <>
        <Text type="body" className={classes.titleValue}>
          {value}
        </Text>
        <Tooltip
          title={getText("common.prompts.copyReference")}
          placement="right"
        >
          <IconButton
            className={classes.iconButton}
            onClick={handleClickCopy(value)}
          >
            <img className={classes.buttonIcon} src={copy} alt="" />
          </IconButton>
        </Tooltip>
      </>
    ) : (
      <Text
        type="body"
        className={classes.titleValue}
        text="caseMenu.caseActivity.auditLogSection.notSubmitted"
      />
    );

  return (
    <Paper elevation={0} className={classes.root}>
      <ContentCardHeader>
        <div className={classes.titleWrapper}>
          <Text type="heading2" className={classes.title}>
            {getText("common.units.cases.one")} {data.number}
          </Text>
          <div className={classes.valueWrapper}>
            <Text type="body" className={classes.titleValueLabel}>
              {getText("caseMenu.caseActivity.auditLogSection.policeReference")}
            </Text>
            {renderCaseRefValue(data.criminalCase.referenceNumber)}
          </div>
          <div className={classes.valueWrapper}>
            <Text type="body" className={classes.titleValueLabel}>
              {getText(
                "caseMenu.caseActivity.auditLogSection.civilCaseReference"
              )}
            </Text>
            {renderCaseRefValue(data.civilCase.referenceNumber)}
          </div>
        </div>
        <LightTooltip
          placement="bottom-end"
          arrow
          interactive
          maxWidth={500}
          padding={0}
          title={<CaseLogPopper loading={loading} logs={logs} />}
        >
          <div className={classes.updated}>
            <img src={clock} alt="" />
            <Text
              className={classes.date}
              text="caseDetailPage.caseInfoCard.lastUpdatedLabel"
              type="body"
            />
            <Text type="contentSmall" className={classes.dateValue}>
              {format(
                new Date(data.updateDateTime || data.creationDateTime),
                "dd/MM/yyyy"
              )}
            </Text>
            <img src={chevron} alt="" className={classes.chevron} />
          </div>
        </LightTooltip>
      </ContentCardHeader>
      <div className={classes.contentContainer}>
        <div className={classes.contentWrapper}>
          <Text
            className={classes.heading}
            text="caseDetailPage.caseInfoCard.summaryTitle"
            type="contentLabel"
          />
          <div className={classes.valuesWrapper}>
            <Text
              text="common.valueLabels.evidenceCaptured"
              type="contentLabel3"
            />
            <Text type="status">
              {data.numberOfMediaItems || getText("common.prompts.none")}
            </Text>
          </div>
          <div className={classes.valuesWrapper}>
            <Text text="common.valueLabels.status" type="contentLabel3" />
            {data.civilCase.status === submissionStatus.NONE &&
            data.criminalCase.status === submissionStatus.NONE ? (
              <Text type="status">{getText("common.prompts.none")}</Text>
            ) : (
              <div className={classes.chipWrapper}>
                <StatusChip data={data.criminalCase} type={chipType.POLICE} />
                <StatusChip data={data.civilCase} type={chipType.CIVIL} />
              </div>
            )}
          </div>
        </div>
        {data.userCapacity === "PoliceOfficer" && (
          <div className={classes.contentWrapper}>
            <CaseLog caseId={data.id} />
          </div>
        )}
        {pendingActions.length > 0 && (
          <div className={classes.contentWrapper}>
            <PendingActions data={pendingActions} />
          </div>
        )}
      </div>
    </Paper>
  );
};

CaseInfoCard.propTypes = {
  data: PropTypes.shape({
    completedSections: PropTypes.arrayOf(PropTypes.string),
    creationDateTime: PropTypes.string,
    id: PropTypes.string,
    isDraft: PropTypes.bool,
    number: PropTypes.string,
    updateDateTime: PropTypes.string,
    userCapacity: PropTypes.string,
    numberOfMediaItems: PropTypes.number,
    civilCase: PropTypes.shape({
      status: PropTypes.string,
      referenceNumber: PropTypes.string
    }),
    criminalCase: PropTypes.shape({
      status: PropTypes.string,
      referenceNumber: PropTypes.string
    })
  }).isRequired
};

export default CaseInfoCard;
