import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

// Styles
import styles from "../../config/styles";

// Components
import Button from "../Button";
import useTranslate from "../../hooks/useTranslate";

const useStyles = makeStyles(theme => ({
  footer: {
    height: styles.values.annotationFooterHeight,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: theme.spacing(0, 3),
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    backgroundColor: "white"
  },
  loading: {
    marginRight: "auto"
  },
  discardButton: {
    width: 190
  },
  saveButton: {
    width: 360,
    marginLeft: theme.spacing(3)
  }
}));

const AnnotationFooter = ({
  allowSave,
  discardAnnotations,
  isLoading,
  saveImage
}) => {
  const classes = useStyles();
  const getText = useTranslate();
  const { isLoading: isLoadingInjury, isUploadingAnnotated } = useSelector(
    state => state.injuries
  );

  return (
    <footer className={classes.footer}>
      {(isLoading || isLoadingInjury) && (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}
      <Button
        className={classes.discardButton}
        disabled={isLoading || isUploadingAnnotated}
        onClick={discardAnnotations}
        variant="outlined"
      >
        {getText("common.prompts.discard")}
      </Button>
      <Button
        className={classes.saveButton}
        color="primary"
        disabled={isLoading || isUploadingAnnotated || !allowSave}
        disableElevation
        onClick={saveImage}
        variant="contained"
      >
        {getText("toolPage.saveButton")}
      </Button>
    </footer>
  );
};

AnnotationFooter.propTypes = {
  allowSave: PropTypes.bool.isRequired,
  discardAnnotations: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  saveImage: PropTypes.func.isRequired
};

export default AnnotationFooter;
