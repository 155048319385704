import React from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Components
import Text from "../Text";

// Assets
import arrow from "../../assets/icons/poligon.svg";

const useStyles = makeStyles(theme => ({
  side: {
    position: "absolute",
    left: ({ leftPos }) => (!leftPos ? "initial" : theme.spacing(2)),
    right: ({ leftPos }) => (leftPos ? "initial" : theme.spacing(2)),
    textAlign: ({ leftPos }) => (leftPos ? "left" : "right"),
    width: 70,
    bottom: theme.spacing(2)
  },
  arrow: {
    transform: ({ leftPos }) => `rotate(${leftPos ? 180 : 0}deg)`
  }
}));

const Side = ({ isRight = false, isBack = false, ...rest }) => {
  const leftPos = (!isRight && isBack) || (!isBack && isRight);
  const classes = useStyles({ leftPos });
  const side = isRight ? "rightSide" : "leftSide";

  return (
    <div className={classes.side} {...rest}>
      <img src={arrow} className={classes.arrow} alt="arrow" />
      <Text text="common.userTypes.Victim" type="body2" />
      <Text text={`common.valueLabels.${side}`} type="body2" />
    </div>
  );
};

Side.propTypes = {
  isRight: PropTypes.bool,
  isBack: PropTypes.bool.isRequired
};

Side.defaultProps = {
  isRight: false
};

export default Side;
