import React, { useRef, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

// Mk Player
import "@mediakind/mkplayer/mkplayer-ui.css";
import * as MKPlayerSDK from "@mediakind/mkplayer";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Utils
import { useAppInsights } from "../../utils/telemetry/AppInsights";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    width: "70vw",
    height: "39.375vw",
    maxHeight: "90vh",
    objectFit: "cover",
    borderRadius: "4px",
    display: "flex",
    overflow: "hidden",
    backgroundColor: "#000"
  },
  video: {
    height: "100%",
    objectFit: "cover",
    margin: "0 auto",
    "& video": {
      height: "100%",
      width: "100%",
      objectFit: "cover"
    }
  }
}));

const playerConfig = {
  key: process.env.REACT_APP_MKPLAYER_KEY,
  ui: true,
  playback: {
    muted: false,
    autoplay: false
  }
};

const VideoPlayer = ({ className, hls, dash, onError }) => {
  const { trackException } = useAppInsights();
  const classes = useStyles();
  const vContainerRef = useRef(null);
  let player = null;

  const sourceConfig = { hls, dash };

  const load = async () => {
    if (MKPlayerSDK) {
      if (player) {
        await player?.unload?.();
        player = null;
      }

      try {
        player = new MKPlayerSDK.MKPlayer(vContainerRef.current, playerConfig);
        await player.load(sourceConfig);
        try {
          player.play();
        } catch (playError) {
          trackException(`Failed to play MK Player: ${playError}`);
          onError();
        }
      } catch (loadError) {
        trackException(`Failed to load MK Player config source: ${loadError}`);
        onError();
      }
    }
  };

  useEffect(() => {
    load();
    return () => {
      player?.destroy();
    };
  });

  return (
    <div className={classes.root}>
      <div
        ref={vContainerRef}
        className={clsx(className, classes.video)}
        id="video-container"
      />
    </div>
  );
};

VideoPlayer.propTypes = {
  className: PropTypes.string,
  hls: PropTypes.string,
  dash: PropTypes.string,
  onError: PropTypes.func.isRequired
};

VideoPlayer.defaultProps = {
  className: "",
  hls: "",
  dash: ""
};

export default VideoPlayer;
