import { combineReducers } from "redux";
import constant from "lodash-es/constant";

import createReducer from "../../utils/createReducer";

import {
  REQUEST_MED,
  REQUEST_MED_SUCCESS,
  REQUEST_MED_FAIL,
  REQUEST_DEL_MED,
  REQUEST_DEL_MED_SUCCESS,
  REQUEST_DEL_MED_FAIL,
  REQUEST_ADD_MED,
  REQUEST_ADD_MED_SUCCESS,
  REQUEST_ADD_MED_FAIL,
  RESET_MED_UPLOADS,
  UPDATE_MED_PROGRESS,
  REQUEST_MED_ITEM_SUCCESS,
  REQUEST_MED_ITEM_FAIL
} from "./actionTypes";
import { CLEAR_MEDIA_UPLOAD_PROGRESS } from "../caseDetail/actionTypes";

// Current
const currentCase = createReducer(null, {
  [REQUEST_MED]: (state, { payload }) => payload
});

// Data
const data = createReducer(null, {
  [REQUEST_MED_SUCCESS]: (state, { payload }) => payload,
  [REQUEST_MED]: constant(null),
  [REQUEST_MED_ITEM_SUCCESS]: (state, { payload }) => {
    if (state) {
      const status = {};
      const alreadyExists = state.find(({ id, isProcessing }, index) => {
        if (id === payload.id) {
          status.shouldReplace = isProcessing !== payload.isProcessing;
          status.index = index;
          return true;
        }
        return false;
      });

      if (alreadyExists) {
        if (status.shouldReplace) {
          const newState = [...state];
          newState[status.index] = payload;
          return newState;
        }
        return state;
      }

      return [...state, payload];
    }

    return [payload];
  },
  [REQUEST_DEL_MED_SUCCESS]: (state, { payload }) =>
    state.filter(({ id }) => id !== payload)
});

const galleryItems = createReducer(null, {
  [REQUEST_MED_ITEM_SUCCESS]: (state, { payload }) => ({
    ...state,
    [payload.id]: payload
  })
});

const error = createReducer(null, {
  [REQUEST_MED]: constant(null),
  [REQUEST_MED_FAIL]: (state, { payload }) => payload,
  [REQUEST_MED_ITEM_FAIL]: (state, { payload }) => payload
});

const isLoading = createReducer(false, {
  [REQUEST_MED]: constant(true),
  [REQUEST_MED_SUCCESS]: constant(false),
  [REQUEST_MED_FAIL]: constant(false)
});

const isDeleting = createReducer(null, {
  [REQUEST_DEL_MED]: (state, { payload }) => payload,
  [REQUEST_DEL_MED_SUCCESS]: constant(null),
  [REQUEST_DEL_MED_FAIL]: constant(null)
});

const uploadProgress = createReducer(null, {
  [UPDATE_MED_PROGRESS]: (state, { payload }) => ({
    ...state,
    [payload.index]: (payload.progress.loaded / payload.progress.total) * 100
  }),
  [REQUEST_ADD_MED]: (state, { payload }) => ({
    ...state,
    [payload]: 0
  }),
  [REQUEST_ADD_MED_SUCCESS]: (state, { payload }) => ({
    ...state,
    [payload]: 100
  }),
  [REQUEST_ADD_MED_FAIL]: (state, { payload }) => ({
    ...state,
    [payload]: 0
  }),
  [CLEAR_MEDIA_UPLOAD_PROGRESS]: constant(null),
  [RESET_MED_UPLOADS]: constant(null)
});

const uploadErrors = createReducer(null, {
  [REQUEST_ADD_MED_FAIL]: (state, { payload }) => ({
    ...state,
    [payload]: true
  }),
  [RESET_MED_UPLOADS]: constant(null)
});

export default combineReducers({
  currentCase,
  data,
  error,
  isLoading,
  isDeleting,
  uploadProgress,
  uploadErrors,
  galleryItems
});
