import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Hooks
import { useAppInsights } from "../utils/telemetry/AppInsights";

// Actions
import { getCases } from "../state/cases/actions";
import { getInvitations } from "../state/invitations/actions";
import { resetErrors } from "../state/caseDetail/actions";

const useCases = userExists => {
  const cases = useSelector(s => s.cases);
  const { error: caseError } = useSelector(s => s.caseDetail);
  const { trackException } = useAppInsights();
  const dispatch = useDispatch();
  const invitations = useSelector(s => s.invitations);

  const { data, isLoading, error } = cases;

  useEffect(() => {
    if (!userExists) return;
    if (!error) {
      if (!data && !isLoading) {
        dispatch(getCases(() => trackException("Failed to get cases")));
      } else if (data && !invitations.data && !invitations.isLoading) {
        dispatch(
          getInvitations(() => trackException("Failed to get invitations"))
        );
      }
    }
    if (caseError) dispatch(resetErrors());
  }, [
    userExists,
    caseError,
    data,
    dispatch,
    error,
    invitations.data,
    invitations.isLoading,
    isLoading,
    trackException
  ]);
};

export default useCases;
