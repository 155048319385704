import React from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

// Config
import styles from "../../config/styles";

const useStyles = makeStyles(theme => ({
  textField: {
    width: 48,
    height: 48,
    marginRight: theme.spacing(1)
  },
  inputRoot: styles.mixins.inputRoot,
  input: {
    ...styles.mixins.input,
    textAlign: "center"
  },
  focused: {
    borderColor: theme.palette.primary.main
  }
}));

const isValidNumber = s => /[0-9]/.test(s);

const PincodeInput = React.forwardRef(({ onChange, ...props }, ref) => {
  const classes = useStyles();

  const handleChange = e => {
    const lastCharacter = e.target.value.slice(-1);

    if (!isValidNumber(lastCharacter)) e.preventDefault();
    else onChange(lastCharacter);
  };

  return (
    <TextField
      autoComplete="off"
      variant="outlined"
      className={classes.textField}
      InputProps={{
        classes: {
          root: classes.inputRoot,
          input: classes.input,
          focused: classes.focused,
          error: classes.error
        }
      }}
      inputRef={ref}
      onChange={handleChange}
      type="password"
      {...props}
    />
  );
});

PincodeInput.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default PincodeInput;
