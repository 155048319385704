import { getResourceObject } from "./values";

export const landingPageUrl =
  process.env.REACT_APP_LANDING_URL || "https://stage.kulpacloud.com";

export const externalRoutes = {
  googlePlay: "https://play.google.com/store/apps/details?id=io.kulpa.kulpa",
  appStore: "https://itunes.apple.com/app/id1516538458",
  faq: `${landingPageUrl}/faqs`,
  victimSupport: `${landingPageUrl}/victimSupport`,
  helpVideos: `${landingPageUrl}/howtocapture`,
  contact: `${landingPageUrl}/contactSupport`,
  terms: userType =>
    `${landingPageUrl}/termsAndConditions/${userType}#termsAndConditions`,
  privacy: userType =>
    `${landingPageUrl}/termsAndConditions/${userType}#policies`,
  kpcc: "https://police.kulpacloud.com/"
};

export const urls = {
  login: "/login",
  logout: "/logout",
  settings: "/settings",
  cases: "/cases",
  createCase: "/cases/new",
  caseDetail: "/cases/:caseId",
  notFound: "/404",

  // Case Options
  caseLog: "/cases/:caseId/logs",
  caseDownload: "/cases/:caseId/download",
  caseMedia: "/cases/:caseId/media",
  caseMediaDetail: "/cases/:caseId/media/:itemId",
  incident: "/cases/:caseId/incident",
  submitIncident: "/cases/:caseId/submitIncident",
  medicalRecords: "/cases/:caseId/records",
  medicalRecordDetail: "/cases/:caseId/records/:itemId?",
  infoRequests: "/cases/:caseId/infoRequests",
  infoRequestDetail: "/cases/:caseId/infoRequests/:infoRequestId",
  inviteContributor: "/cases/:caseId/invite",
  contributorDetail: "/cases/:caseId/contributor/:contributorId",

  // Notes
  caseNotes: "/cases/:caseId/notes",
  newNote: "/cases/:caseId/notes/new",
  newStatement: "/cases/:caseId/notes/newStatement",
  draftNotes: "/cases/:caseId/notes/draft",
  noteDetail: "/cases/:caseId/notes/draft/:itemId",
  editNote: "/cases/:caseId/notes/edit/:itemId",
  publishedNotes: "/cases/:caseId/notes/published",
  statementDetail: "/cases/:caseId/notes/published/:itemId",

  // Download Report
  downloadReport: "/downloadReport/:reportId",

  // Injury
  addInjury: "/cases/:caseId/injury/new",
  editInjury: "/cases/:caseId/injury/:injuryId/edit",
  annotationTool: "/cases/:caseId/injury/:injuryId/:imgIndex",
  injuryDetail: "/cases/:caseId/injury/:injuryId",

  // AppStore
  downloadApp: "/download"
};

// Internal Routes
export const getRoute = (url, values) => {
  const mapValues = values && {
    ":caseId": values.caseId,
    ":injuryId": values.injuryId,
    ":imgIndex": values.index,
    ":infoRequestId": values.infoRequestId,
    ":contributorId": values.contributorId,
    ":reportId": values.reportId,
    ":itemId": values.itemId,
    ":itemId?": values.itemId
  };
  const path = url.replace(
    /:caseId|:injuryId|:imgIndex|:infoRequestId|:contributorId|:reportId|:itemId\??/gi,
    matched => mapValues[matched] || ""
  );

  return `${path}${(values && values.query) || ""}`;
};

const mapResources = {
  injuries: urls.injuryDetail,
  media: urls.caseMedia,
  medicalRecords: urls.medicalRecords
};

export const getResourceRoute = resId => {
  const routeKey = Object.keys(mapResources).find(e => resId.includes(e));
  const resObject = getResourceObject(resId);

  return getRoute(mapResources[routeKey], resObject);
};
