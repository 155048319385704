import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Components
import Avatar from "../Avatar";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 2),
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  avatar: {
    height: theme.spacing(2),
    width: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));

const UserTyping = ({ partyId }) => {
  const { contributors } = useSelector(s => s.caseDetail);
  const { userDetails } = useSelector(s => s.user);
  const classes = useStyles();

  if (!userDetails || partyId === userDetails.id) return null;

  const { fullName, profilePictureURL } =
    contributors.find(({ id }) => partyId === id) || {};

  return (
    <div className={classes.root}>
      <Avatar className={classes.avatar} src={profilePictureURL}>
        {fullName.charAt(0)}
      </Avatar>
      <Text type="small">{fullName} is typing...</Text>
    </div>
  );
};

UserTyping.propTypes = {
  partyId: PropTypes.string.isRequired
};

export default UserTyping;
