import React, { useEffect, useMemo, useState } from "react";
import {
  Redirect,
  useHistory,
  useParams,
  useRouteMatch
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Material
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";

// Actions
import { getNotes } from "../../state/notes/actions";

// Config
import { getRoute, urls } from "../../config/routes";
import styles from "../../config/styles";

// Hooks
import { useAppInsights } from "../../utils/telemetry/AppInsights";

// Icons
import lightBulb from "../../assets/icons/light_bulb.svg";

// Common Components
import ContentCardHeader from "../../components/ContentCardHeader";
import Routes from "../../routes/Routes";
import SectionWrapper from "../../components/SectionWrapper";

// Inner Components
import EditNotePanel from "./components/EditNotePanel";
import NewNotePanel from "./components/NewNotePanel";
import NewStatementPanel from "./components/NewStatementPanel";
import NotesPanel from "./components/NotesPanel";
import NotesTabs from "./components/NotesTabs";
import NotesTopTips from "./components/NotesTopTips";
import StatementsPanel from "./components/StatementsPanel";

const useStyles = makeStyles(theme => ({
  root: {
    border: styles.values.border
  },
  content: {
    padding: theme.spacing(5),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  openTipsButton: {
    marginRight: theme.spacing(2)
  }
}));

const CaseNotesPage = () => {
  const { caseId } = useParams();
  const { loadedCaseId, data, isRequesting, error } = useSelector(s => s.notes);
  const { trackException } = useAppInsights();
  const classes = useStyles();
  const defaultRoute = getRoute(urls.draftNotes, { caseId });
  const defaultStatementsRoute = getRoute(urls.publishedNotes, { caseId });
  const dispatch = useDispatch();
  const history = useHistory();

  const isEditing = useRouteMatch(urls.editNote);
  const newNote = useRouteMatch(urls.newNote);
  const newStat = useRouteMatch(urls.newStatement);
  const isAdding = newNote || newStat;
  const isDraft = useRouteMatch(urls.draftNotes);
  const isNote = useRouteMatch(urls.noteDetail);

  const [value, setValue] = useState(0);
  const [showTips, setShowTips] = useState(false);

  useEffect(() => {
    if (!isDraft && !isNote) setValue(1);
    else setValue(0);
  }, [isDraft, isNote, setValue]);

  const handleShowTips = () => setShowTips(true);
  const handleCloseTips = () => setShowTips(false);

  const routes = useMemo(
    () => [
      // Tab Panels
      {
        Component: NotesPanel,
        title: "notes",
        id: "caseNotes",
        path: urls.draftNotes,
        props: {
          handleShowTips
        }
      },
      {
        Component: StatementsPanel,
        title: "statements",
        id: "statements",
        path: urls.publishedNotes,
        props: {
          handleShowTips
        }
      },
      // Non-tab panels
      {
        isExact: true,
        Component: NewNotePanel,
        title: "newNote",
        id: "newNote",
        path: urls.newNote,
        props: {
          defaultRoute
        }
      },
      {
        isExact: true,
        Component: NewStatementPanel,
        title: "newStatement",
        id: "newStatement",
        path: urls.newStatement,
        props: {
          defaultRoute: defaultStatementsRoute
        }
      },
      {
        isExact: true,
        Component: EditNotePanel,
        title: "editNote",
        id: "editNote",
        path: urls.editNote
      },
      {
        Component: Redirect,
        title: "redirect",
        id: "Redirect",
        props: { to: defaultRoute }
      }
    ],
    [defaultRoute, defaultStatementsRoute]
  );

  const handleChange = (event, newValue) => {
    if (newValue !== value) {
      setValue(newValue);
      history.push(getRoute(routes[newValue].path, { caseId }));
    }
  };

  useEffect(() => {
    if (!isRequesting && !error && (loadedCaseId !== caseId || !data))
      dispatch(
        getNotes({
          caseId,
          onFinish: () => {
            trackException(`Failed to get notes at case: ${caseId}`);
          }
        })
      );
  }, [
    caseId,
    data,
    dispatch,
    error,
    isRequesting,
    loadedCaseId,
    trackException
  ]);

  return (
    <>
      <SectionWrapper>
        <Paper elevation={0} className={classes.root}>
          {!isAdding && !isEditing && (
            <ContentCardHeader noPadding>
              <NotesTabs value={value} onChange={handleChange} />
              <IconButton
                onClick={handleShowTips}
                className={classes.openTipsButton}
              >
                <img src={lightBulb} alt="" />
              </IconButton>
            </ContentCardHeader>
          )}
          <Routes routes={routes} />
        </Paper>
      </SectionWrapper>
      <NotesTopTips open={showTips} onClose={handleCloseTips} />
    </>
  );
};

export default CaseNotesPage;
