import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

// Utils
import { useAppInsights } from "../utils/telemetry/AppInsights";

// Config
import { snackbarTypes } from "../config/snackbar";

// Actions
import { downloadCase } from "../state/caseDetail/actions";
import { toggleSnack } from "../state/snackbar/actions";
import { getRoute, urls } from "../config/routes";

const CaseDownloadContext = createContext();

export const useCaseDownload = () => useContext(CaseDownloadContext);

const CaseDownloadProvider = ({ children }) => {
  const [formValues, setFormValues] = useState(null);
  const { data } = useSelector(s => s.caseDetail);
  const { trackException } = useAppInsights();
  const dispatch = useDispatch();
  const history = useHistory();

  const { isDraft } = data || {};

  const holdSubmitValues = values => {
    setFormValues(values);
  };

  const requestCaseDownload = useCallback(
    (body, onFinish) => {
      dispatch(
        downloadCase({
          body,
          onFinish,
          onFail: () =>
            trackException(`Failed to download case: ${body.caseId}`)
        })
      );
    },
    [dispatch, trackException]
  );

  useEffect(() => {
    if (!isDraft && formValues && data && data.id === formValues.caseId) {
      requestCaseDownload(formValues, () => {
        dispatch(
          toggleSnack({
            type: snackbarTypes.SUCCESS,
            text: "downloadCasePage.emailSent"
          })
        );
        history.push(getRoute(urls.caseDetail, { caseId: formValues.caseId }));
      });
      setFormValues(null);
    }
  }, [data, dispatch, formValues, history, isDraft, requestCaseDownload]);

  return (
    <CaseDownloadContext.Provider
      value={{ requestCaseDownload, holdSubmitValues }}
    >
      {children}
    </CaseDownloadContext.Provider>
  );
};

CaseDownloadProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default CaseDownloadProvider;
