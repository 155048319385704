import React from "react";
import { useSelector } from "react-redux";
import get from "lodash-es/get";

// Components
import CaptureVictimDisclaimer from "../../components/CaptureVictimDisclaimer";
import CaseInfoCard from "../../components/CaseInfoCard";
import Disclaimer from "../../components/Disclaimer";
import SectionWrapper from "../../components/SectionWrapper";
import VictimInfoCard from "../../components/VictimInfoCard";

const renderBanner = hasPicture =>
  hasPicture ? (
    <Disclaimer text="caseDetailPage.disclaimer" />
  ) : (
    <CaptureVictimDisclaimer />
  );

const CaseDetailPage = () => {
  const { data } = useSelector(state => state.caseDetail);

  return (
    <SectionWrapper>
      {data && (
        <>
          {data.isDraft && renderBanner(get(data, "patient.profilePhotoURL"))}
          <VictimInfoCard data={data} />
          <CaseInfoCard data={data} />
        </>
      )}
    </SectionWrapper>
  );
};

export default CaseDetailPage;
