import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";

// Config
import { userTypes } from "../../config/values";

// Components
import UserTypeForm from "./UserTypeForm";
import SafeSpace from "./SafeSpace";
import EmergencyDialog from "./EmergencyDialog";

const useStyles = makeStyles(theme => ({
  loading: {
    padding: theme.spacing(4, 5),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: 470,
    width: 507
  },
  modal: {
    maxWidth: 835,
    borderRadius: 14
  }
}));

const validations = [
  userTypes.Victim,
  userTypes.Relative,
  userTypes.Witness,
  "PoliceOfficer"
];

const UserTypeDialog = ({ onSelect, value, open, setOpen }) => {
  const [showSafe, setShowSafe] = useState(false);
  const [showEmergency, setShowEmergency] = useState(false);
  const { userDetails } = useSelector(s => s.user);
  const classes = useStyles();

  const handleClose = () => setOpen(false);
  const handleNext = ({ isSexualAssault } = {}) => {
    if (isSexualAssault) {
      setShowEmergency(true);
    } else if (validations.includes(value)) setShowSafe(true);
    else handleClose();
  };

  if (!userDetails)
    return (
      <Dialog PaperProps={{ classes: { root: classes.modal } }} open>
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      </Dialog>
    );
  let component = (
    <UserTypeForm
      handleNext={handleNext}
      handleSelect={onSelect}
      userType={userDetails.userType}
      value={value}
    />
  );
  if (showEmergency) {
    component = (
      <EmergencyDialog
        onNext={() => {
          setShowSafe(true);
          setShowEmergency(false);
        }}
      />
    );
  } else if (showSafe) {
    component = (
      <SafeSpace
        userType={value}
        onCancel={() => setShowSafe(false)}
        onClick={handleClose}
      />
    );
  }
  return (
    <Dialog PaperProps={{ classes: { root: classes.modal } }} open={open}>
      {component}
    </Dialog>
  );
};

UserTypeDialog.propTypes = {
  onSelect: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default UserTypeDialog;
