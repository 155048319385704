import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import isEmpty from "lodash-es/isEmpty";
import PropTypes from "prop-types";

// Config
import { urls } from "../../config/routes";
import { useFeatureFlags } from "../../context/FeatureFlagsProvider";
import featureFlagKeys from "../../config/featureFlagKeys";

// Components
import Header from "../Header";
import NotificationsHandler from "../NotificationsHandler";

const AppWrapper = ({ children }) => {
  const { pathname } = useLocation();
  const { currentUser } = useSelector(s => s.user);
  const { featureFlags } = useFeatureFlags();
  const userExists = currentUser && !isEmpty(currentUser);

  return (
    <>
      {pathname !== urls.downloadApp && (
        <>
          {userExists && featureFlags[featureFlagKeys.NOTIFICATIONS] && (
            <NotificationsHandler />
          )}
          <Header />
        </>
      )}
      {children}
    </>
  );
};

AppWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppWrapper;
