export const REQUEST_CASE_INCIDENT = "incident/REQUEST_CASE_INCIDENT";
export const REQUEST_CASE_INCIDENT_SUCCESS =
  "incident/REQUEST_CASE_INCIDENT_SUCCESS";
export const REQUEST_UPDATE_CASE_INCIDENT_FAIL =
  "incident/REQUEST_UPDATE_CASE_INCIDENT_FAIL";

export const REQUEST_DEL_ELEMENT = "incident/REQUEST_DEL_ELEMENT";
export const REQUEST_DEL_ELEMENT_SUCCESS =
  "incident/REQUEST_DEL_ELEMENT_SUCCESS";
export const REQUEST_DEL_ELEMENT_FAIL = "incident/REQUEST_DEL_ELEMENT_FAIL";
