import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";

// Assets
import lockIcon from "../../assets/icons/lockIcon.svg";

// Config
import { externalRoutes } from "../../config/routes";
import { setPinUrl } from "../../config/apiConfig";
import { snackbarTypes } from "../../config/snackbar";
import colors from "../../config/colors";
import styles from "../../config/styles";

// Hooks
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import useTranslate from "../../hooks/useTranslate";

// Actions
import { parseFetchOptions, onSignOut } from "../../state/user/actions";
import { toggleSnack } from "../../state/snackbar/actions";

// Components
import Button from "../../components/Button";
import ContentCardFooter from "../../components/ContentCardFooter";
import ContentCardHeader from "../../components/ContentCardHeader";
import GoBackLink from "../../components/GoBackLink";
import Link from "../../components/Link";
import PincodeField, { initialPincode } from "../../components/PincodeField";
import Text from "../../components/Text";

const useStyles = makeStyles(theme => ({
  root: {
    width: 730,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column"
  },
  paper: {
    border: styles.values.border
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(5, 10)
  },
  icon: {
    marginBottom: theme.spacing(2)
  },
  title: {
    padding: theme.spacing(1.5, 0, 1),
    color: colors.pictonBlue
  },
  description: {
    width: 500,
    lineHeight: 1,
    marginBottom: theme.spacing(2)
  },
  label: {
    marginBottom: theme.spacing(1)
  },
  pincodeField: {
    marginBottom: theme.spacing(2)
  },
  contactLink: {
    color: colors.riverBed,
    "&:hover": {
      textDecoration: "underline"
    }
  },
  input: styles.mixins.input,
  submitButton: {
    width: 216
  },
  link: {
    ...styles.mixins.link,
    textTransform: "lowercase"
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2)
  }
}));

const PinCreateScreen = ({ onAuthorize }) => {
  const [firstCode, setFirstCode] = useState(initialPincode);
  const [secondCode, setSecondCode] = useState(initialPincode);
  const [creating, setCreating] = useState(false);
  const { trackException } = useAppInsights();
  const classes = useStyles();
  const dispatch = useDispatch();
  const getText = useTranslate();

  const handleChangeFirst = value => setFirstCode(value);
  const handleChangeSecond = value => setSecondCode(value);
  const handleSignOut = () => dispatch(onSignOut());

  const handleSubmit = () => {
    setCreating(true);
    dispatch(
      parseFetchOptions(async ({ headers }) => {
        const response = await fetch(setPinUrl, {
          method: "PUT",
          headers: {
            ...headers,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            pin: firstCode.join("")
          })
        }).catch(() => {});

        if (response.ok) onAuthorize();
        else {
          setFirstCode(initialPincode);
          setSecondCode(initialPincode);
          trackException("Failed to set pin code");
          dispatch(toggleSnack({ type: snackbarTypes.ERROR }));
        }
        setCreating(false);
      })
    );
  };

  return (
    <div className={classes.root}>
      <GoBackLink
        onClick={handleSignOut}
        text="common.valueLabels.backTo"
        values={{
          1: getText("pageTitles.login")
        }}
      />
      <Paper elevation={0} className={classes.paper}>
        <ContentCardHeader>
          <Text text="createPasscodePage.cardTitle" type="heading2" />
        </ContentCardHeader>
        <form className={classes.contentContainer}>
          <img className={classes.icon} src={lockIcon} alt="" />
          <Text
            type="heading1"
            className={classes.title}
            text="createPasscodePage.title"
          />
          <Text
            className={classes.description}
            text="createPasscodePage.description"
            type="heading2"
          />
          <Text
            className={classes.label}
            text="createPasscodePage.title"
            type="contentLabel4"
          />
          <PincodeField
            className={classes.pincodeField}
            disabled={creating}
            onChange={handleChangeFirst}
            value={firstCode}
          />
          <Text
            className={classes.label}
            text="createPasscodePage.reEnter"
            type="contentLabel4"
          />
          <PincodeField
            className={classes.pincodeField}
            disabled={creating}
            onChange={handleChangeSecond}
            value={secondCode}
          />
          <div className={classes.buttonWrapper}>
            <Button
              onClick={handleSubmit}
              className={classes.submitButton}
              variant="contained"
              color="primary"
              disableElevation
              disabled={
                creating ||
                firstCode[0] === "" ||
                firstCode.join("") !== secondCode.join("")
              }
            >
              {getText("common.prompts.done")}
            </Button>
            {creating && <CircularProgress size={32} />}
          </div>
        </form>
        <ContentCardFooter>
          <Text
            type="subtitle1Small"
            align="center"
            text="downloadReportPage.havingIssues"
            values={{
              1: (
                <Link
                  key={1}
                  className={classes.link}
                  target="_blank"
                  to={externalRoutes.contact}
                >
                  {getText("downloadReportPage.contactSupportLink")}
                </Link>
              ),
              isComponent: true
            }}
          />
        </ContentCardFooter>
      </Paper>
    </div>
  );
};

PinCreateScreen.propTypes = {
  onAuthorize: PropTypes.func.isRequired
};

export default PinCreateScreen;
