import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

// Components
import FurtherInfoPanel from "../../components/FurtherInfoPanel";
import SectionWrapper from "../../components/SectionWrapper";

const InfoRequestsPage = () => {
  const { caseId, infoRequestId } = useParams();
  const { contributors, data } = useSelector(state => state.caseDetail);

  return (
    <SectionWrapper>
      {data && (
        <FurtherInfoPanel
          infoRequestId={infoRequestId}
          contributors={contributors}
          caseId={caseId}
        />
      )}
    </SectionWrapper>
  );
};

export default InfoRequestsPage;
