import {
  REQUEST_NOTIFICATIONS_SUCCESS,
  REQUEST_NOTIFICATIONS,
  REQUEST_NOTIFICATIONS_FAIL,
  REQUEST_READ,
  REQUEST_UPDATE_NOTIFICATIONS
} from "./actionTypes";

import { parseFetchOptions } from "../user/actions";
import { notificationsUrl, readNotificationUrl } from "../../config/apiConfig";
import errors from "../../utils/errors";

// Notifications
const requestNotifications = () => ({
  type: REQUEST_NOTIFICATIONS
});

const requestNotificationsSuccess = payload => ({
  type: REQUEST_NOTIFICATIONS_SUCCESS,
  payload
});

const requestNotificationsFail = payload => ({
  type: REQUEST_NOTIFICATIONS_FAIL,
  payload
});

export const getNotifications = onFail => dispatch => {
  dispatch(requestNotifications());

  dispatch(
    parseFetchOptions(async options => {
      const response = await fetch(notificationsUrl, {
        method: "GET",
        headers: options.headers
      }).catch(error => {
        if (onFail) onFail();
        dispatch(requestNotificationsFail(error));
      });

      if (response && response.ok) {
        const data = await response.json();
        dispatch(requestNotificationsSuccess(data));
      } else {
        if (onFail) onFail();
        dispatch(requestNotificationsFail(errors.responseError(response)));
      }
    })
  );
};

const requestRead = payload => ({
  type: REQUEST_READ,
  payload
});

export const readNotification =
  ({ id, index, onFail }) =>
  dispatch => {
    dispatch(requestRead(index));
    dispatch(
      parseFetchOptions(async options => {
        await fetch(readNotificationUrl(id), {
          method: "PUT",
          headers: options.headers
        }).catch(() => onFail());
      })
    );
  };

export const updateNotifications = payload => ({
  type: REQUEST_UPDATE_NOTIFICATIONS,
  payload
});
