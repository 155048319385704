import React, { useState } from "react";
import PropTypes from "prop-types";

// Formik
import { Form, Formik } from "formik";
import * as Yup from "yup";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Icons
import disclaimerIcon from "../../../assets/icons/disclaimer.svg";

// Config
import colors from "../../../config/colors";
import countries from "../../../config/countries";

// Hooks
import useTranslate from "../../../hooks/useTranslate";

// Components
import Button from "../../../components/Button";
import CountryField from "../../../components/CountryField";
import CustomField from "../../../components/CustomField";
import DownloadTypeSelector from "./DownloadTypeSelector";
import SuccessLabel from "../../../components/SuccessLabel";
import Text from "../../../components/Text";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3)
  },
  text: {
    lineHeight: 1.5,
    marginBottom: theme.spacing(2),
    "&:last-of-type": {
      marginBottom: theme.spacing(3.5)
    }
  },
  label: {
    marginBottom: theme.spacing(2)
  },
  phoneInputWrapper: {
    display: "flex",
    flexDirection: "row"
  },
  buttonWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    padding: theme.spacing(2),
    backgroundColor: "#C1C1C1",
    borderRadius: theme.shape.borderRadius
  },
  icon: {
    marginRight: theme.spacing(2)
  },
  infoLabel: {
    color: colors.white,
    lineHeight: 1.5,
    marginRight: theme.spacing(2),
    fontWeight: theme.typography.fontWeightRegular
  },
  submitButton: {
    minWidth: 327,
    marginLeft: "auto"
  }
}));

const getValidationSchema = texts =>
  Yup.object().shape({
    email: Yup.string().email(texts.invalidEmail).required(texts.required),
    phoneNumber: Yup.string()
      .matches(/^[0-9]*$/, { message: texts.phoneNumber.mustBe })
      .min(4, texts.phoneNumber.tooShort)
      .required(texts.required)
  });

const initialValues = {
  email: "",
  phoneNumber: "",
  country: countries[233]
};

const reportTypes = [
  {
    value: 0,
    text: "downloadCasePage.downloadTypeEnum.reportOnlyValue"
  },
  {
    value: 1,
    text: "downloadCasePage.downloadTypeEnum.reportAndMediaValue"
  }
];

const SendEvidenceForm = ({
  disabled,
  disableSubmit,
  onSubmit,
  showSubmitCaseButton,
  sent,
  onResetStatus
}) => {
  const [checked, setChecked] = useState(null);
  const classes = useStyles();
  const getText = useTranslate();

  const handleChange = value => () => {
    onResetStatus();
    setChecked(value);
  };

  const handleSubmit = (values, formik) => {
    onSubmit({ ...values, checked }, formik);
  };

  const renderSendButton = isSubmitting =>
    sent ? (
      <SuccessLabel
        buttonText="downloadCasePage.emailSent"
        descriptionText="downloadCasePage.emailSentLabel"
      />
    ) : (
      <Button
        className={classes.submitButton}
        color="primary"
        disabled={isSubmitting || checked === null || disabled}
        disableElevation
        type="submit"
        variant="contained"
      >
        {getText("common.prompts.send")}
      </Button>
    );

  const submitCaseButton = (
    <div className={classes.buttonWrapper}>
      <img className={classes.icon} src={disclaimerIcon} alt="" />
      <Text
        type="contentLabel2Bold"
        className={classes.infoLabel}
        text="downloadCasePage.criminalFormat.submitDownloadLabel"
      />
      <Button
        className={classes.submitButton}
        color="primary"
        disableElevation
        disabled={disableSubmit}
        type="submit"
        variant="contained"
      >
        {getText("downloadCasePage.criminalFormat.submitDownloadButton")}
      </Button>
    </div>
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={getValidationSchema(getText("common.validationSchema"))}
    >
      {({ values, errors, isSubmitting }) => (
        <Form className={classes.root}>
          <CustomField
            error={errors.email}
            disabled={disabled}
            label={getText("common.valueLabels.email")}
            name="email"
            placeholder={getText("inviteContributorPage.emailPlaceholder")}
            value={values.email}
          />
          <Text
            className={classes.label}
            text="common.valueLabels.phoneNumber"
            type="valueLabel"
          />
          <div className={classes.phoneInputWrapper}>
            <CountryField
              disabled={disabled}
              selectedCountryCode={values.country.code}
            />
            <CustomField
              disabled={disabled}
              error={errors.phoneNumber}
              name="phoneNumber"
              placeholder="(0) 777122435"
            />
          </div>
          <CustomField
            multiline
            error={errors.message}
            disabled={disabled}
            label={getText("downloadCasePage.messageLabel")}
            name="message"
            placeholder={getText("downloadCasePage.messagePlaceholder")}
            value={values.message}
          />
          <DownloadTypeSelector
            checked={checked}
            disabled={disabled}
            onChange={handleChange}
            types={reportTypes}
          />
          {showSubmitCaseButton
            ? submitCaseButton
            : renderSendButton(isSubmitting)}
        </Form>
      )}
    </Formik>
  );
};

SendEvidenceForm.propTypes = {
  disabled: PropTypes.bool,
  disableSubmit: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  showSubmitCaseButton: PropTypes.bool,
  sent: PropTypes.bool.isRequired,
  onResetStatus: PropTypes.func.isRequired
};

SendEvidenceForm.defaultProps = {
  disabled: false,
  disableSubmit: false,
  showSubmitCaseButton: false
};

export default SendEvidenceForm;
