import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

// Assets
import colors from "../../config/colors";
import icon from "../../assets/icons/empty_request.svg";

// Components
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%"
  },
  image: {
    marginBottom: theme.spacing(1.5),
    marginRight: 49
  },
  text: {
    color: colors.riverBed,
    opacity: 0.4,
    marginBottom: theme.spacing(2.25),
    lineHeight: 1.3,
    width: 210,
    textAlign: "center",
    fontSize: 20
  }
}));

const ContributorsPlaceholder = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img className={classes.image} src={icon} alt="" />
      <Text type="subtitle1" className={classes.text}>
        No further requests have currently been made
      </Text>
      {children}
    </div>
  );
};

ContributorsPlaceholder.propTypes = {
  children: PropTypes.node.isRequired
};

export default ContributorsPlaceholder;
