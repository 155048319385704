import React, { createContext, useContext, useState } from "react";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import PropTypes from "prop-types";

// Telemetry
import { getAppInsights } from "./TelemetryService";
import TelemetryProvider from "./TelemetryProvider";

const InsightsContext = createContext();

export const useAppInsights = () => {
  const context = useContext(InsightsContext);
  if (!context) {
    throw new Error("useAppInsights must be used within a AppInsightsProvider");
  }
  return context;
};

export const AppInsightsProvider = ({ children }) => {
  const [appInsights, setAppInsights] = useState(null);
  return (
    <TelemetryProvider after={() => setAppInsights(getAppInsights())}>
      {appInsights !== null && (
        <InsightsContext.Provider
          value={{
            appInsights,
            trackEvent: name => appInsights.trackEvent({ name }),
            trackException: error => {
              appInsights.trackException({
                error: new Error(error),
                severityLevel: SeverityLevel.Error
              });
            },
            trackTrace: message => {
              appInsights.trackTrace({
                message,
                severityLevel: SeverityLevel.Information
              });
            }
          }}
        >
          {children}
        </InsightsContext.Provider>
      )}
    </TelemetryProvider>
  );
};

AppInsightsProvider.propTypes = {
  children: PropTypes.node
};

AppInsightsProvider.defaultProps = {
  children: PropTypes.node
};
