import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import formatDistance from "date-fns/formatDistance";
import PropTypes from "prop-types";
import startCase from "lodash-es/startCase";

// Material
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";

// Config
import { urls, getRoute } from "../../config/routes";
import colors from "../../config/colors";
import styles from "../../config/styles";

// State
import { getInvitations } from "../../state/invitations/actions";
import { useAppInsights } from "../../utils/telemetry/AppInsights";

// Components
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2, 6, 0),
    justifyContent: "flex-start",
    alignItems: "flex-start",
    position: "relative"
  },
  unread: {
    position: "absolute",
    height: 10,
    width: 10,
    borderRadius: "100%",
    backgroundColor: colors.hotPink,
    top: 23,
    left: 26
  },
  firstRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%"
  },
  type: {
    textTransform: "lowercase",
    "&:first-letter": {
      textTransform: "uppercase"
    }
  },
  time: {
    fontSize: 11,
    color: colors.lynch
  },
  message: {
    fontSize: 14,
    color: colors.lynch,
    whiteSpace: "normal"
  },
  divider: {
    borderBottom: styles.values.border,
    width: "100%",
    marginTop: theme.spacing(2)
  }
}));

const typeRoute = {
  ContributorInvited: urls.cases,
  ContributorAcceptedInvitation: urls.caseDetail,
  ContributorRejectedInvitation: urls.caseDetail,
  CaseUpdated: urls.caseDetail,
  CaseSubmitted: urls.caseDetail,
  CaseSubmissionReminder: urls.caseDetail,
  InfoRequestCreated: urls.infoRequestDetail,
  InfoRequestMessageReceived: urls.infoRequestDetail
};

const NotificationItem = ({ data, onClose, index, onRead }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { trackException } = useAppInsights();

  const time = formatDistance(new Date(data.timestamp), new Date(), {
    addSuffix: true
  });

  const handleClick = () => {
    onClose();
    onRead(data.id, index);
    history.push(getRoute(typeRoute[data.notificationType], data));
    if (data.notificationType) {
      // fetch invitations when new invitation detected
      dispatch(
        getInvitations(() => trackException("Failed to get invitations"))
      );
    }
  };

  return (
    <MenuItem className={classes.root} onClick={handleClick}>
      {!data.isRead && <span className={classes.unread} />}
      <div className={classes.firstRow}>
        <Text type="heading6" className={classes.type}>
          {startCase(data.notificationType)}
        </Text>
        <Text type="heading6" className={classes.time}>
          {time}
        </Text>
      </div>
      <Text type="heading6" className={classes.message}>
        {data.message}
      </Text>
      <span className={classes.divider} />
    </MenuItem>
  );
};

NotificationItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    isRead: PropTypes.bool,
    message: PropTypes.string,
    notificationType: PropTypes.string,
    timestamp: PropTypes.string
  }).isRequired,
  index: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onRead: PropTypes.func.isRequired
};

export default NotificationItem;
