import React from "react";

// Components
import MedicalRecordsPanel from "../../components/MedicalRecordsPanel";
import SectionWrapper from "../../components/SectionWrapper";

const MedicalRecordsPage = () => (
  <SectionWrapper>
    <MedicalRecordsPanel />
  </SectionWrapper>
);

export default MedicalRecordsPage;
