import React from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import ButtonMaterial from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

// Icons
import emergencyCall from "../../assets/icons/callemergency.svg";

// Config
import colors from "../../config/colors";
import useTranslate from "../../hooks/useTranslate";

// Components
import Text from "../Text";
import useEmergencyContacts from "../../hooks/useEmergencyContacts";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(7, 5),
    display: "flex",
    flexDirection: "column",
    maxWidth: 450
  },
  defaultIcon: {
    width: 85,
    display: "flex",
    alignSelf: "center",
    marginBottom: theme.spacing(5)
  },
  title: {
    padding: theme.spacing(2, 1),
    fontSize: 24,
    lineHeight: 1.3
  },
  paragraph: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(0, 2),
    lineHeight: 1.5
  },
  link: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  buttons: {
    display: "flex",
    width: "100%",
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
    borderRadius: 24,
    height: 52
  },
  buttonIgnore: {
    border: "1px solid rgb(0, 0, 0)"
  },
  witnessButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(0, 3),
    fontFamily: ["Gibson SemiBold", "sans-serif"].join(","),
    borderColor: colors.shuttleGray,
    borderRadius: theme.shape.borderRadius,
    fontSize: 14,
    padding: theme.spacing(2),
    lineHeight: 1.2,
    textTransform: "inherit",
    minWidth: 50,
    color: colors.white,
    "&:first-child": {
      marginBottom: theme.spacing(2)
    }
  },
  phoneIcon: {
    filter: "brightness(0) invert(1)",
    marginRight: "1rem"
  },
  button: {
    margin: theme.spacing(0, 3),
    textTransform: "uppercase"
  },
  buttonsContainer: {
    flexDirection: "column"
  }
}));

const EmergencyDialog = ({ onNext }) => {
  const { emergencyContact, loading } = useEmergencyContacts();
  const classes = useStyles();
  const getText = useTranslate();

  return (
    <div className={classes.root}>
      <img alt="" className={classes.defaultIcon} src={emergencyCall} />
      <Text
        align="center"
        className={classes.title}
        text="createCasePage.emergencyDialog.title"
        type="heading1"
      />
      <Text
        align="center"
        className={classes.paragraph}
        text="createCasePage.emergencyDialog.description"
        type="subtitle1Big"
      />
      <div className={classes.buttonsContainer}>
        {loading || !emergencyContact ? (
          <ButtonMaterial
            className={classes.buttons}
            color="primary"
            disableElevation
            variant="contained"
          >
            <CircularProgress size={20} thickness={3} color="white" />
          </ButtonMaterial>
        ) : (
          <a
            href={`tel: ${emergencyContact.phoneNumber}`}
            className={classes.link}
          >
            <ButtonMaterial
              className={classes.buttons}
              color="primary"
              disableElevation
              variant="contained"
            >
              {getText("header.emergencyBanner.link", {
                1: emergencyContact.phoneNumber
              })}
            </ButtonMaterial>
          </a>
        )}
        <ButtonMaterial
          className={[classes.buttons, classes.buttonIgnore].join(" ")}
          disableElevation
          onClick={onNext}
          variant="outlined"
        >
          {getText("createCasePage.emergencyDialog.otherButton")}
        </ButtonMaterial>
      </div>
    </div>
  );
};

EmergencyDialog.propTypes = {
  onNext: PropTypes.func.isRequired
};

export default EmergencyDialog;
