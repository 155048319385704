import { combineReducers } from "redux";
import constant from "lodash-es/constant";
import createReducer from "../../utils/createReducer";
import {
  REQUEST_LOGIN,
  REQUEST_USER_DETAILS_FAIL,
  REQUEST_USER_DETAILS_SUCCESS,
  REQUEST_USER_DETAILS,
  SET_ERROR,
  SET_LOCALE,
  SET_MSAL_STATE,
  SET_TOKEN,
  SET_USER
} from "./actionTypes";

// Config
import { supportedLanguages } from "../../locales/localeConfig";

const currentUser = createReducer(null, {
  [SET_USER]: (state, { payload }) => payload
});

const accessToken = createReducer(null, {
  [SET_TOKEN]: (state, { payload }) => payload
});

const isLoading = createReducer(true, {
  [REQUEST_LOGIN]: constant(true),
  [SET_ERROR]: constant(false),
  [SET_USER]: constant(false)
});

const error = createReducer(null, {
  [SET_ERROR]: (state, { payload }) => payload
});

const msalState = createReducer(null, {
  [SET_MSAL_STATE]: (state, { payload }) => payload
});

const userDetails = createReducer(null, {
  [REQUEST_USER_DETAILS]: constant(null),
  [REQUEST_USER_DETAILS_SUCCESS]: (state, { payload }) => payload,
  [REQUEST_USER_DETAILS_FAIL]: constant(null)
});

const detailsError = createReducer(null, {
  [REQUEST_USER_DETAILS]: constant(null),
  [REQUEST_USER_DETAILS_FAIL]: (state, { payload }) => payload
});

const loadingDetails = createReducer(false, {
  [REQUEST_USER_DETAILS]: constant(true),
  [REQUEST_USER_DETAILS_SUCCESS]: constant(false),
  [REQUEST_USER_DETAILS_FAIL]: constant(false)
});

// Language
const icLocale = localStorage.getItem("icLocale");
const current = supportedLanguages.indexOf(icLocale) !== -1 ? icLocale : "en";

const locale = createReducer(current, {
  [SET_LOCALE]: (state, { payload }) => payload
});

export default combineReducers({
  accessToken,
  currentUser,
  detailsError,
  error,
  isLoading,
  loadingDetails,
  locale,
  msalState,
  userDetails
});
