import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";

// Formik
import { Formik, Form } from "formik";
import * as Yup from "yup";

// Hooks
import { useAppInsights } from "../../../utils/telemetry/AppInsights";
import useTranslate from "../../../hooks/useTranslate";

// Config
import { getRoute, urls } from "../../../config/routes";
import { switchEnterKey } from "../../../utils/functions";

// Actions
import { createNote } from "../../../state/notes/actions";

// Components
import Button from "../../../components/Button/Button";
import Close from "../../../components/Close";
import ContentCardHeader from "../../../components/ContentCardHeader";
import CustomField from "../../../components/CustomField";
import Link from "../../../components/Link";
import Text from "../../../components/Text";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3, 6, 6)
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  submit: {
    textTransform: "uppercase",
    minWidth: 160,
    marginRight: theme.spacing(2)
  }
}));

const getSchema = texts =>
  Yup.object().shape({
    title: Yup.string().required(texts.required),
    content: Yup.string().required(texts.required)
  });

const initialValues = {
  title: "",
  content: ""
};

const NewNotePanel = ({ defaultRoute }) => {
  const { caseId } = useParams();
  const { trackException } = useAppInsights();
  const classes = useStyles();
  const dispatch = useDispatch();
  const getText = useTranslate();
  const history = useHistory();

  const texts = getText("notesPage.newNote");

  const onSubmit = (body, actions) => {
    dispatch(
      createNote({
        caseId,
        body,
        onFinish: (success, data) => {
          if (success) {
            history.push(
              getRoute(urls.noteDetail, { caseId, itemId: data.id })
            );
          } else {
            trackException(`Failed to create note at case: ${caseId}`);
            actions.setSubmitting(false);
          }
        }
      })
    );
  };

  return (
    <>
      <ContentCardHeader>
        <Text text="pageTitles.newNote" type="heading2" />
        <IconButton component={Link} to={defaultRoute}>
          <Close />
        </IconButton>
      </ContentCardHeader>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validationSchema={getSchema(getText("common.validationSchema"))}
      >
        {({ errors, submitForm, isSubmitting }) => (
          <Form className={classes.root}>
            <CustomField
              autoComplete="off"
              disabled={isSubmitting}
              error={errors.crimeRef}
              label={texts.titleLabel}
              name="title"
              onKeyDown={switchEnterKey}
              placeholder={texts.titlePlaceholder}
            />
            <CustomField
              autoComplete="off"
              disabled={isSubmitting}
              error={errors.crimeRef}
              label={texts.noteLabel}
              multiline
              name="content"
              placeholder={texts.notePlaceholder}
              rows={20}
            />
            <div className={classes.buttonContainer}>
              <Button
                className={classes.submit}
                color="primary"
                disabled={isSubmitting}
                disableElevation
                onClick={submitForm}
                type="submit"
                variant="contained"
              >
                {texts.saveButton}
              </Button>
              {isSubmitting && <CircularProgress size={24} />}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

NewNotePanel.propTypes = {
  defaultRoute: PropTypes.string.isRequired
};

export default NewNotePanel;
