import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Material
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";

// Icons
import enableGridIcon from "../../assets/icons/enable-grid-white.svg";
import eyeOffIcon from "../../assets/icons/feather-eye-off.svg";

// Config
import colors from "../../config/colors";

// Utils
import { isAnnotable } from "../../utils/images";

// Components
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    height: 100,
    width: 122,
    overflow: "hidden",
    backgroundColor: colors.whisper
  },
  buttonContent: {
    height: "100%",
    width: "100%"
  },
  hide: {
    filter: "blur(10px)"
  },
  imageHolder: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "absolute",
    minHeight: "100%",
    minWidth: "100%",
    backgroundImage: ({ thumbnailURL }) => `url(${thumbnailURL}&something)`
  },
  hoverable: {
    transition: "transform .1s ease-in-out",
    "&:hover": {
      transform: "scale(1.2)"
    }
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%"
  },
  gridIcon: {
    position: "absolute",
    bottom: theme.spacing(1),
    left: theme.spacing(1),
    height: 14,
    width: 14
  },
  sensitiveLabel: {
    color: "white",
    zIndex: 99,
    position: "absolute"
  },
  eyeIcon: {
    filter: "brightness(10)"
  },
  sensitiveTitle: {
    fontSize: 9,
    fontFamily: ["Gibson SemiBold", "sans-serif"].join(",")
  },
  sensitiveSubtitle: {
    fontSize: 9,
    fontFamily: ["Gibson Regular", "sans-serif"].join(",")
  }
}));

const ImageItem = ({
  className,
  Component,
  componentProps,
  hideIcon,
  isSensitive,
  onClick,
  image: { downloadURLs, imageType }
}) => {
  const [hide, setHide] = useState(isSensitive);
  const classes = useStyles({
    thumbnailURL: downloadURLs && downloadURLs.thumbnailURL
  });
  const showIcon = isAnnotable(imageType) && !hideIcon;

  const handleClick = () => {
    if (hide) setHide(false);
    else onClick();
  };

  return (
    <>
      <ButtonBase
        className={clsx(classes.root, className)}
        onClick={handleClick}
      >
        <div
          className={clsx(classes.buttonContent, {
            [classes.hide]: hide
          })}
        >
          <div
            className={clsx(classes.imageHolder, {
              [classes.hoverable]: !hide
            })}
          />
          {showIcon && (
            <img src={enableGridIcon} alt="" className={classes.gridIcon} />
          )}
        </div>
        {hide && (
          <div className={classes.sensitiveLabel}>
            <img
              src={eyeOffIcon}
              alt=""
              height={22}
              className={classes.eyeIcon}
            />
            <Text
              className={classes.sensitiveTitle}
              text="common.prompts.sensitiveContent"
              type="body"
            />
            <Text
              className={classes.sensitiveSubtitle}
              text="common.prompts.tapToView"
              type="body"
            />
          </div>
        )}
      </ButtonBase>
      {Component && !hide && (
        <Component {...componentProps} isAnnotable={showIcon} />
      )}
    </>
  );
};

ImageItem.propTypes = {
  className: PropTypes.string.isRequired,
  Component: PropTypes.func,
  componentProps: PropTypes.shape({}),
  hideIcon: PropTypes.bool,
  imageHeaders: PropTypes.shape({}).isRequired,
  isSensitive: PropTypes.bool,
  loadingHeaders: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func.isRequired,
  image: PropTypes.shape({
    downloadURL: PropTypes.string,
    downloadURLs: PropTypes.shape({
      thumbnailURL: PropTypes.string
    }),
    filename: PropTypes.string,
    imageType: PropTypes.string
  }).isRequired
};

ImageItem.defaultProps = {
  Component: null,
  componentProps: null,
  hideIcon: false,
  isSensitive: false
};

export default ImageItem;
