import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import styles from "../../config/styles";

const useStyles = makeStyles(() => ({
  root: {
    ...styles.mixins.sectionContainer,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 102
  }
}));

const StatusBar = props => {
  const classes = useStyles();

  return <Toolbar classes={classes} {...props} />;
};

export default StatusBar;
