import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

// Config
import { externalRoutes } from "../../config/routes";
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import styles from "../../config/styles";
import useTranslate from "../../hooks/useTranslate";

// Actions
import { getCaseDetail } from "../../state/caseDetail/actions";
import { resetToggledCase } from "../../state/cases/actions";

// Components
import AppStoreButtons from "../AppStoreButtons";
import ContentCardHeader from "../ContentCardHeader";
import Step from "../Step";
import StepsFooter from "../StepsFooter/StepsFooter";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    border: styles.values.border,
    paddingBottom: theme.spacing(6.5)
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%"
  },
  link: styles.mixins.link
}));

const VictimSteps = ({ caseId }) => {
  const { isLoading } = useSelector(state => state.actions);
  const { trackException } = useAppInsights();
  const classes = useStyles();
  const dispatch = useDispatch();
  const getText = useTranslate();

  React.useEffect(() => () => dispatch(resetToggledCase())[dispatch]);

  const getCases = () => {
    dispatch(resetToggledCase());
    dispatch(
      getCaseDetail({
        caseId,
        onFail: () => trackException("Failed to get case details")
      })
    );
  };

  const howToCapture = () => {
    const win = window.open(externalRoutes.helpVideos, "_blank");
    win.focus();
  };

  return (
    <Paper elevation={0} className={classes.root}>
      <ContentCardHeader>
        <Text
          className={classes.title}
          text="mobileCapturePage.heading"
          type="heading2"
        />
      </ContentCardHeader>
      <div className={classes.content}>
        <Step
          number={1}
          title={getText("mobileCapturePage.step1.title")}
          subtitle1={getText("mobileCapturePage.step1.subtitle1")}
          subtitle={getText("mobileCapturePage.step1.subtitle2")}
          disableButtons={isLoading}
        >
          <AppStoreButtons />
        </Step>
        <Step
          number={2}
          title={getText("mobileCapturePage.step2.title")}
          subtitle={getText("mobileCapturePage.step2.subtitle1")}
          buttonText={getText("mobileCapturePage.step2.sendLinkButton")}
          onClick={howToCapture}
        />
        <Step
          number={3}
          title={getText("mobileCapturePage.step3.title")}
          subtitle={getText("mobileCapturePage.step3.subtitle1")}
          isLast
          onClick={getCases}
        />
      </div>
      <StepsFooter />
    </Paper>
  );
};

VictimSteps.propTypes = {
  caseId: PropTypes.string.isRequired
};

export default VictimSteps;
