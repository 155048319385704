import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./assets/fonts/Gibson-SemiBold.otf";
import init from "./helpscout";
import { baseUrl } from "./config/apiConfig";

init();

axios.defaults.baseURL = baseUrl;
axios.defaults.headers.common["api-version"] = "2022-03";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
