import React from "react";
import { useSelector } from "react-redux";
import format from "date-fns/format";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Utils
import useTranslate from "../../hooks/useTranslate";

// Config
import { getRoute, urls } from "../../config/routes";
import colors from "../../config/colors";

// Components
import Avatar from "../Avatar";
import Button from "../Button";
import Link from "../Link";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  heading: {
    marginBottom: theme.spacing(2.5),
    textTransform: "uppercase"
  },
  content: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(2)
  },
  userWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  avatar: {
    height: 26,
    width: 26,
    fontSize: 14,
    marginRight: theme.spacing(1.5)
  },
  lastActivity: {
    color: colors.pictonBlue
  },
  button: {
    marginLeft: "auto",
    minWidth: 121,
    height: 36,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black
  }
}));

const CaseLog = ({ caseId }) => {
  const classes = useStyles();
  const getText = useTranslate();

  const { data } = useSelector(s => s.caseDetail);
  const { actorDisplayName, actorProfilePhotoURL, dateTime } =
    data.lastAuditEntry || {};

  const date =
    dateTime &&
    format(new Date(dateTime), getText("common.dateFormat.shortDateAndTime"));

  return (
    <>
      <Text
        className={classes.heading}
        text="caseDetailPage.caseInfoCard.caseLogTitle"
        type="contentLabel"
      />
      <div className={classes.content}>
        {actorDisplayName && Boolean(dateTime) && (
          <div className={classes.userWrapper}>
            <Avatar className={classes.avatar} src={actorProfilePhotoURL}>
              {actorDisplayName.charAt(0)}
            </Avatar>
            <Text
              type="contentLabel2"
              className={classes.lastActivity}
              text="caseDetailPage.caseInfoCard.lastActivity"
              values={{
                1: actorDisplayName,
                2: date
              }}
            />
          </div>
        )}
        <Button
          className={classes.button}
          component={Link}
          disableElevation
          regular
          to={getRoute(urls.caseLog, { caseId })}
          variant="contained"
        >
          {getText("caseDetailPage.caseInfoCard.viewCaseLogButton")}
        </Button>
      </div>
    </>
  );
};

CaseLog.propTypes = {
  caseId: PropTypes.string.isRequired
};

export default CaseLog;
