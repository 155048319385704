import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

// Material
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";

// Formik
import { Formik, Form } from "formik";
import * as Yup from "yup";

// Hooks
import { useAppInsights } from "../../../utils/telemetry/AppInsights";
import useTranslate from "../../../hooks/useTranslate";

// Config
import { getRoute, urls } from "../../../config/routes";

// Actions
import { updateNote } from "../../../state/notes/actions";

// Components
import Button from "../../../components/Button/Button";
import Close from "../../../components/Close";
import ContentCardHeader from "../../../components/ContentCardHeader";
import CustomField from "../../../components/CustomField";
import Link from "../../../components/Link";
import LoadingSection from "../../../components/LoadingSection";
import Text from "../../../components/Text";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3, 6, 6)
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  submit: {
    textTransform: "uppercase",
    minWidth: 160,
    marginRight: theme.spacing(2)
  }
}));

const getSchema = texts =>
  Yup.object().shape({
    title: Yup.string().required(texts.required),
    content: Yup.string().required(texts.required)
  });

const EditNotePanel = () => {
  const { caseId, itemId } = useParams();
  const { data, isRequesting } = useSelector(s => s.notes);
  const { trackException } = useAppInsights();
  const classes = useStyles();
  const dispatch = useDispatch();
  const getText = useTranslate();
  const history = useHistory();

  const note = useMemo(
    () => data && data.find(({ id }) => id === itemId),
    [data, itemId]
  );

  if (isRequesting) return <LoadingSection normalHeight />;
  if (!note) return null;

  const texts = getText("notesPage.newNote");

  const onSubmit = (body, actions) => {
    dispatch(
      updateNote({
        caseId,
        itemId,
        body,
        onFinish: (success, resData) => {
          if (success) {
            history.push(
              getRoute(urls.noteDetail, { caseId, itemId: resData.id })
            );
          } else {
            trackException(`Failed to edit note: ${itemId} at case: ${caseId}`);
            actions.setSubmitting(false);
          }
        }
      })
    );
  };

  return (
    <>
      <ContentCardHeader>
        <Text text="pageTitles.editNote" type="heading2" />
        <IconButton
          component={Link}
          to={getRoute(urls.noteDetail, { caseId, itemId })}
        >
          <Close />
        </IconButton>
      </ContentCardHeader>
      <Formik
        initialValues={{
          title: note.title,
          content: note.content
        }}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validationSchema={getSchema(getText("common.validationSchema"))}
      >
        {({ errors, isSubmitting, submitForm, values }) => (
          <Form className={classes.root}>
            <CustomField
              autoComplete="off"
              disabled={isSubmitting}
              error={errors.crimeRef}
              label={texts.titleLabel}
              name="title"
              placeholder={texts.titlePlaceholder}
            />
            <CustomField
              autoComplete="off"
              disabled={isSubmitting}
              error={errors.crimeRef}
              label={texts.noteLabel}
              multiline
              name="content"
              placeholder={texts.notePlaceholder}
              rows={20}
            />
            <div className={classes.buttonContainer}>
              <Button
                className={classes.submit}
                color="primary"
                disabled={
                  isSubmitting ||
                  (values.title === note.title &&
                    values.content === note.content)
                }
                disableElevation
                onClick={submitForm}
                type="submit"
                variant="contained"
              >
                {texts.saveButton}
              </Button>
              {isSubmitting && <CircularProgress size={24} />}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EditNotePanel;
