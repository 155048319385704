import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../config/styles";

const useStyles = makeStyles(theme => ({
  footer: {
    padding: theme.spacing(0, 4),
    borderTop: styles.values.border,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 90
  }
}));

const ContentCardHeader = ({ children }) => (
  <footer className={useStyles().footer}>{children}</footer>
);

ContentCardHeader.propTypes = {
  children: PropTypes.node.isRequired
};

export default ContentCardHeader;
