import React from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import ReactCountryFlag from "react-country-flag";
import get from "lodash-es/get";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";

// Icon
import phonecall from "../../assets/icons/phonecall.svg";

// Config
import { userTypes } from "../../config/values";
import styles from "../../config/styles";

// Hooks
import useTranslate from "../../hooks/useTranslate";

// Components
import ContentCardHeader from "../ContentCardHeader";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    border: styles.values.border,
    marginBottom: theme.spacing(1)
  },
  title: {
    fontSize: 24
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(3, 4, 8)
  },
  avatar: {
    height: 252,
    width: 252,
    marginRight: theme.spacing(5)
  },
  avatarImg: {
    position: "absolute",
    minWidth: "1000%",
    minHeight: "1000%",
    left: "50%",
    top: "50%",
    transform: "translateX(-50%) translateY(-50%) scale(0.1)"
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1
  },
  heading: {
    marginBottom: theme.spacing(2)
  },
  victimName: {
    marginBottom: theme.spacing(2)
  },
  valuesWrapper: {
    marginBottom: theme.spacing(1)
  },
  value: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2)
  },
  placeOfExamination: {
    maxWidth: 160
  },
  phoneNumber: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  phoneNumberLabel: {
    marginBottom: theme.spacing(2)
  },
  flag: {
    fontSize: "18px !important",
    marginRight: theme.spacing(2)
  },
  areaCode: {
    marginRight: theme.spacing(1)
  },
  number: {
    marginRight: theme.spacing(2)
  },
  callIcon: {
    fontSize: 16
  }
}));

const VictimInfoCard = ({ data }) => {
  const classes = useStyles();

  const dateOfBirth = get(data, "patient.dateOfBirth", false);
  const birthDate = dateOfBirth && format(new Date(dateOfBirth), "dd/MM/yy");
  const isWitness = data.userCapacity === userTypes.Witness;

  return (
    <Paper elevation={0} className={classes.root}>
      <ContentCardHeader>
        <Text
          className={classes.title}
          text="caseDetailPage.victimInfoCard.title"
          type="heading2"
        />
      </ContentCardHeader>
      <div className={classes.contentContainer}>
        <Avatar
          src={get(data, "patient.profilePhotoURL", "")}
          variant="rounded"
          className={classes.avatar}
          imgProps={{ className: classes.avatarImg }}
        />
        <div className={classes.contentWrapper}>
          <Text
            className={classes.heading}
            text="caseDetailPage.victimInfoCard.title"
            type="contentLabel"
          />
          <Text type="large" className={classes.victimName}>
            {data.patientName}
          </Text>
          <div className={classes.valuesWrapper}>
            <div className={classes.value}>
              <Text text="common.valueLabels.birthDate" type="valueLabel2" />
              {birthDate && (
                <Text align="right" type="contentLabel2">
                  {birthDate}
                </Text>
              )}
            </div>
          </div>
          {!isWitness && (
            <>
              <Text
                className={classes.phoneNumberLabel}
                text="createCasePage.formContent.victimPhoneNumberLabel"
                type="contentLabel3"
              />
              <div className={classes.phoneNumber}>
                <ReactCountryFlag
                  className={classes.flag}
                  countryCode={get(
                    data,
                    "patient.phoneNumber.isoCountryCode",
                    ""
                  )}
                />
                <Text className={classes.areaCode} type="contentBold">
                  {get(data, "patient.phoneNumber.callingCountryCode", "")}
                </Text>
                <Text className={classes.number} type="contentBold">
                  {get(data, "patient.phoneNumber.nationalNumber", "")}
                </Text>
                <img src={phonecall} alt="Call" className={classes.callIcon} />
              </div>
            </>
          )}
        </div>
      </div>
    </Paper>
  );
};

VictimInfoCard.propTypes = {
  data: PropTypes.shape({
    dateTimeOfExamination: PropTypes.string,
    patient: PropTypes.shape({
      dateOfBirth: PropTypes.string,
      phoneNumber: PropTypes.shape({
        countryCode: PropTypes.string,
        number: PropTypes.string
      }),
      profilePhotoURL: PropTypes.string
    }),
    patientName: PropTypes.string,
    placeOfExamination: PropTypes.shape({
      name: PropTypes.string
    }),
    userCapacity: PropTypes.string
  }).isRequired
};

export default VictimInfoCard;
