import React, { useMemo } from "react";
import { Form } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

// Config
import styles from "../../config/styles";

// Hooks
import { useFeatureFlags } from "../../context/FeatureFlagsProvider";
import useTranslate from "../../hooks/useTranslate";

// Components
import Button from "../Button";
import Switch from "../Switch";
import Text from "../Text";

// Fields
import CountryField from "../CountryField";
import CustomField from "../CustomField";
// import LocationField from "../Input/LocationField";
import MiniMap from "../MiniMap/MiniMap";
import featureFlagKeys from "../../config/featureFlagKeys";

const useStyles = makeStyles(theme => ({
  formContent: {
    padding: theme.spacing(3.5, 5),
    display: "flex",
    flexDirection: "column",
    borderBottom: styles.values.border
  },
  label: {
    marginBottom: theme.spacing(1.5)
  },
  phoneInputWrapper: {
    display: "flex",
    flexDirection: "row"
  },
  crimeRefContainer: {
    display: "flex",
    flexDirection: "column"
  },
  crimeRefHeading: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1)
  },
  switch: {
    margin: 0
  },
  buttonContainer: {
    padding: theme.spacing(4, 5)
  },
  submit: {
    width: "100%"
  }
}));

const VictimFormContent = ({
  coord,
  errors,
  handleSwitch,
  isReported,
  isSubmitting,
  // setCoord,
  // setFieldValue,
  submitForm,
  values
}) => {
  const { featureFlags } = useFeatureFlags();
  const classes = useStyles();
  const getText = useTranslate();

  const fieldList = useMemo(
    () => [
      {
        label: getText("createCasePage.formContent.victimGivenNameLabel"),
        name: "givenName"
      },
      {
        label: getText("createCasePage.formContent.victimFamilyNameLabel"),
        name: "familyName"
      },
      {
        label: getText("createCasePage.formContent.victimBirthDateLabel"),
        name: "dateOfBirth",
        type: "date"
      }
    ],
    []
  );

  return (
    <Form>
      <div className={classes.formContent}>
        {fieldList.map(field => (
          <CustomField key={field.name} {...field} />
        ))}

        {/* <LocationField
          error={errors.place}
          label={getText("common.valueLabels.locationCapture")}
          name="place"
          setCoord={setCoord}
          setFieldValue={setFieldValue}
          value={values.place}
        /> */}
        {featureFlags[featureFlagKeys.MINIMAP] && coord && (
          <MiniMap {...coord} />
        )}
        <Text
          className={classes.label}
          text="createCasePage.formContent.victimPhoneNumberLabel"
          type="valueLabel"
        />
        <div className={classes.phoneInputWrapper}>
          <CountryField selectedCountryCode={values.country.code} />
          <CustomField
            error={errors.number}
            name="number"
            placeholder="(0) 777122435"
          />
        </div>

        <div className={classes.crimeRefContainer}>
          <div className={classes.crimeRefHeading}>
            <Text
              text="createCasePage.formContent.crimeReferenceTitle"
              type="subtitleBold"
            />
            <Switch
              checked={isReported}
              className={classes.switch}
              onChange={handleSwitch}
            />
          </div>
          <div>
            <CustomField
              disabled={!isReported}
              error={errors.crimeRef}
              label={getText("createCasePage.formContent.crimeReferenceLabel")}
              name="crimeReferenceNumber"
              placeholder={getText(
                "createCasePage.formContent.crimeReferencePlaceholder"
              )}
            />
          </div>
        </div>
      </div>

      <div className={classes.buttonContainer}>
        <Button
          className={classes.submit}
          color="secondary"
          disabled={isSubmitting}
          disableElevation
          onClick={submitForm}
          type="submit"
          variant="contained"
        >
          {getText("common.prompts.done")}
        </Button>
      </div>
    </Form>
  );
};

VictimFormContent.propTypes = {
  errors: PropTypes.shape({
    crimeRef: PropTypes.string,
    place: PropTypes.string,
    number: PropTypes.string
  }).isRequired,
  handleSwitch: PropTypes.func.isRequired,
  isReported: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setCoord: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  values: PropTypes.shape({
    country: PropTypes.shape({
      code: PropTypes.string
    }),
    place: PropTypes.string
  }).isRequired,
  coord: PropTypes.shape({})
};

VictimFormContent.defaultProps = {
  coord: null
};

export default VictimFormContent;
