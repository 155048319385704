import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

// Config
import { externalRoutes } from "../../config/routes";
import styles from "../../config/styles";

// Hooks
import useTranslate from "../../hooks/useTranslate";

// Actions
import { onSignOut } from "../../state/user/actions";

// Components
import ContentCardFooter from "../../components/ContentCardFooter";
import ContentCardHeader from "../../components/ContentCardHeader";
import GoBackLink from "../../components/GoBackLink";
import Link from "../../components/Link";
import PincodeForm from "../../components/PincodeForm";
import Text from "../../components/Text";

const useStyles = makeStyles({
  root: {
    width: 730,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column"
  },
  paper: {
    border: styles.values.border
  },
  link: {
    ...styles.mixins.link,
    textTransform: "lowercase"
  }
});

const PinLockScreen = ({ onAuthorize }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const getText = useTranslate();

  const handleSignOut = () => dispatch(onSignOut());
  const handleSubmit = () => onAuthorize();

  return (
    <div className={classes.root}>
      <GoBackLink
        onClick={handleSignOut}
        text="common.valueLabels.backTo"
        values={{
          1: getText("pageTitles.login")
        }}
      />
      <Paper elevation={0} className={classes.paper}>
        <ContentCardHeader>
          <Text text="enterPasscodePage.title" type="heading2" />
        </ContentCardHeader>
        <PincodeForm onSubmit={handleSubmit} />
        <ContentCardFooter>
          <Text
            type="subtitle1Small"
            align="center"
            text="downloadReportPage.havingIssues"
            values={{
              1: (
                <Link
                  key={1}
                  className={classes.link}
                  target="_blank"
                  to={externalRoutes.contact}
                >
                  {getText("downloadReportPage.contactSupportLink")}
                </Link>
              ),
              isComponent: true
            }}
          />
        </ContentCardFooter>
      </Paper>
    </div>
  );
};

PinLockScreen.propTypes = {
  onAuthorize: PropTypes.func.isRequired
};

export default PinLockScreen;
