import React, { useState, useCallback } from "react";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

// Config
import colors from "../../../config/colors";
import styles from "../../../config/styles";

// Components
import ContentCardHeader from "../../../components/ContentCardHeader";
import Text from "../../../components/Text";

// Table
import LoadingEntries from "./LoadingEntries";
import LogEntryDetails from "./LogEntryDetails";
import LogItemsHandler from "./LogItemsHandler";

// Hook
import useLogs from "../../../hooks/useLogs";

const useStyles = makeStyles(theme => ({
  root: {
    border: styles.values.border,
    marginBottom: theme.spacing(10)
  },
  row: {
    borderBottom: styles.values.border,
    position: "relative"
  },
  cell: {
    padding: theme.spacing(2.5, 2),
    display: "flex"
  },
  title: {
    textTransform: "uppercase"
  },
  avatar: {
    height: 38,
    width: 38,
    marginRight: theme.spacing(1)
  },
  operationValue: {
    color: colors.riverBed
  },
  iconButton: {
    position: "absolute",
    top: theme.spacing(2.5),
    right: theme.spacing(2),
    borderColor: theme.palette.common.black,
    padding: 0,
    height: 28,
    width: 28,
    minWidth: "auto"
  }
}));

const CaseLogTable = () => {
  const [logs, loading] = useLogs();
  const [selected, setSelected] = useState(null);
  const classes = useStyles();

  const toggleDrawer = useCallback(id => () => setSelected(id), []);

  return (
    <>
      <Paper elevation={0} className={classes.root}>
        <ContentCardHeader>
          <Text
            className={classes.title}
            text="caseLogPage.title"
            type="heading2"
          />
        </ContentCardHeader>
        <Grid className={classes.row} container>
          <Grid className={classes.cell} item xs={4}>
            <Text
              className={classes.title}
              text="caseLogPage.actor"
              type="small"
            />
          </Grid>
          <Grid className={classes.cell} item xs={3}>
            <Text
              className={classes.title}
              text="caseLogPage.operation"
              type="small"
            />
          </Grid>
          <Grid className={classes.cell} item xs={5}>
            <Text
              className={classes.title}
              text="caseLogPage.details"
              type="small"
            />
          </Grid>
        </Grid>
        <LogItemsHandler
          itemClasses={{ row: classes.row, cell: classes.cell }}
          logs={logs}
          toggleDrawer={toggleDrawer}
        />
        {loading && <LoadingEntries />}
      </Paper>
      <LogEntryDetails entryId={selected} onClose={toggleDrawer(null)} />
    </>
  );
};

export default CaseLogTable;
