export const REQUEST_NOTES = "notes/REQUEST_NOTES";
export const REQUEST_NOTES_SUCCESS = "notes/REQUEST_NOTES_SUCCESS";
export const REQUEST_NOTES_FAIL = "notes/REQUEST_NOTES_FAIL";

export const REQUEST_CREATE_NOTE = "notes/REQUEST_CREATE_NOTE";
export const REQUEST_CREATE_NOTE_SUCCESS = "notes/REQUEST_CREATE_NOTE_SUCCESS";
export const REQUEST_CREATE_NOTE_FAIL = "notes/REQUEST_CREATE_NOTE_FAIL";

export const REQUEST_DELETE_NOTE = "notes/REQUEST_DELETE_NOTE";
export const REQUEST_DELETE_NOTE_SUCCESS = "notes/REQUEST_DELETE_NOTE_SUCCESS";
export const REQUEST_DELETE_NOTE_FAIL = "notes/REQUEST_DELETE_NOTE_FAIL";

export const REQUEST_PUBLISH_NOTE = "notes/REQUEST_PUBLISH_NOTE";
export const REQUEST_PUBLISH_NOTE_SUCCESS =
  "notes/REQUEST_PUBLISH_NOTE_SUCCESS";
export const REQUEST_PUBLISH_NOTE_FAIL = "notes/REQUEST_PUBLISH_NOTE_FAIL";

export const REQUEST_UPDATE_NOTE = "notes/REQUEST_UPDATE_NOTE";
export const REQUEST_UPDATE_NOTE_SUCCESS = "notes/REQUEST_UPDATE_NOTE_SUCCESS";
export const REQUEST_UPDATE_NOTE_FAIL = "notes/REQUEST_UPDATE_NOTE_FAIL";
