import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import format from "date-fns/format";
import PropTypes from "prop-types";
import clsx from "clsx";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

// Config
import { getRoute, urls } from "../../config/routes";
import { userTypes } from "../../config/values";
import colors from "../../config/colors";
import styles from "../../config/styles";

// Icons
import downloadIcon from "../../assets/icons/download.svg";
import reportIcon from "../../assets/icons/reportIcon.svg";
import submitIcon from "../../assets/icons/submit_ico.svg";
import trashIcon from "../../assets/icons/trash.svg";
import lockIcon from "../../assets/icons/restrictedIcon.svg";

// Actions
import { deleteCase } from "../../state/caseDetail/actions";

// Hooks
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import useShowSteps from "../../hooks/useShowSteps";
import useTranslate from "../../hooks/useTranslate";

// Components
import CardFooter from "./CardFooter";
import CardHeader from "../CardHeader";
import CardSummary from "./CardSummary";
import ConfirmationDialog from "../ConfirmationDialog";
import PinPasscodeDialog from "../PinPasscodeDialog";
import SubmitDialog from "./SubmitDialog";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    border: styles.values.border,
    padding: 0
  },
  header: {
    borderBottom: styles.values.border,
    padding: theme.spacing(3.5, 4, 3),
    position: "relative"
  },
  headerLocked: {
    backgroundColor: colors.lockedBackground
  },
  title: {
    color: "inherit",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  titleLocked: {
    color: colors.lockedCardText,
    wordBreak: "break-word"
  },
  lockedText: {
    fontSize: "13px",
    lineHeight: 1.5,
    color: colors.lockedCardText,
    marginTop: theme.spacing(1)
  },
  lockIcon: {
    position: "absolute",
    top: theme.spacing(3.5),
    right: theme.spacing(4),
    width: "20px",
    height: "20px"
  }
}));

const CaseCard = ({ data, isCollab }) => {
  const [showPasscode, setShowPasscode] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const { isSubmitting, isDeleting } = useSelector(s => s.caseDetail);
  const { trackException } = useAppInsights();
  const classes = useStyles();
  const dispatch = useDispatch();
  const getText = useTranslate();
  const history = useHistory();

  const caseId = data.id;
  const showSteps = useShowSteps(caseId);

  const isDeletingCase = isDeleting === caseId;
  const isSubmittingCase = isSubmitting === caseId;

  const creationDate = format(new Date(data.creationDateTime), "MMM d y");

  const menuOptions = useMemo(() => {
    const result = [];

    if (data.isDraft && !isCollab) {
      if (!showSteps) {
        result.push({
          key: 1,
          text: "casesPage.caseCard.menu.submit",
          icon: submitIcon,
          onClick: () => setShowSubmit(true)
        });
      }

      result.push({
        key: 2,
        text: "casesPage.caseCard.menu.delete",
        icon: trashIcon,
        onClick: () => setShowDelete(true)
      });
    }

    result.push({
      key: 3,
      text: "casesPage.caseCard.menu.download",
      icon: downloadIcon,
      onClick: () => history.push(getRoute(urls.caseDownload, { caseId }))
    });

    if (data.userCapacity === "PoliceOfficer") {
      result.push({
        key: 4,
        text: "casesPage.caseCard.menu.caseLog",
        icon: reportIcon,
        onClick: () => history.push(getRoute(urls.caseLog, { caseId }))
      });
    }

    return result;
  }, [caseId, data.isDraft, history, showSteps]);

  const handleClose = () => {
    setShowDelete(false);
    setShowSubmit(false);
    setShowPasscode(false);
  };

  const handleOpenPasscode = () => {
    setShowDelete(false);
    setShowPasscode(true);
  };

  const handleDelete = () => {
    setShowPasscode(false);
    setShowDelete(true);
    dispatch(
      deleteCase({
        caseId,
        onFail: () => trackException(`Failed to delete case: ${caseId}`)
      })
    );
  };

  const title = `${getText("common.units.files.one")} ${data.number}`;

  return (
    <>
      <Card elevation={0} className={classes.root}>
        <CardContent
          className={clsx(classes.header, {
            [classes.headerLocked]: data.isLocked
          })}
        >
          <CardHeader
            date={creationDate}
            isLocked={data.isLocked}
            showSteps={showSteps}
            to={getRoute(urls.caseDetail, { caseId })}
            caseNumber={data.number}
            title={
              data.isLocked ? (
                <span className={classes.titleLocked}>{title}</span>
              ) : (
                <Link
                  className={classes.title}
                  to={getRoute(urls.caseDetail, { caseId })}
                >
                  {title}
                </Link>
              )
            }
            options={menuOptions}
          />
          {data.isLocked && (
            <>
              <img src={lockIcon} alt="" className={classes.lockIcon} />
              <Text
                className={classes.lockedText}
                text="casesPage.caseCard.lockedCase.label"
                type="body"
              />
            </>
          )}
        </CardContent>
        <CardSummary
          civilCase={data.civilCase}
          criminalCase={data.criminalCase}
          incidentDate={data.incidentDateTime}
          mediaItems={data.numberOfMediaItems}
          patient={data.patientName}
          users={data.contributors}
        />
        <CardFooter
          caseId={caseId}
          showMedicalRecords={data.capacity !== userTypes.Witness}
          disabled={data.isLocked}
        />
      </Card>
      <ConfirmationDialog
        onClose={handleClose}
        onConfirm={handleOpenPasscode}
        open={showDelete}
        texts={[
          { key: 1, text: getText("deleteCaseDialog.title") },
          { key: 2, text: getText("deleteCaseDialog.description") }
        ]}
        type="delete"
        button={getText("common.prompts.delete").toUpperCase()}
        cancel={getText("common.prompts.cancel").toUpperCase()}
        isLoading={isDeletingCase}
      />
      <PinPasscodeDialog
        onClose={handleClose}
        onSubmit={handleDelete}
        open={showPasscode}
      />
      {showSubmit && (
        <SubmitDialog
          caseId={caseId}
          open={showSubmit}
          isLoading={isSubmittingCase}
          onClose={handleClose}
        />
      )}
    </>
  );
};

CaseCard.propTypes = {
  data: PropTypes.shape({
    capacity: PropTypes.string,
    civilCase: PropTypes.shape({}),
    contributors: PropTypes.arrayOf(PropTypes.shape({})),
    creationDateTime: PropTypes.string,
    incidentDateTime: PropTypes.string,
    criminalCase: PropTypes.shape({}),
    id: PropTypes.string,
    isDraft: PropTypes.bool,
    number: PropTypes.string,
    numberOfMediaItems: PropTypes.number,
    patientName: PropTypes.string,
    userCapacity: PropTypes.string,
    isLocked: PropTypes.bool
  }).isRequired,
  isCollab: PropTypes.bool
};

CaseCard.defaultProps = {
  isCollab: false
};

export default CaseCard;
