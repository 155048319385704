import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Material
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import mainLogo from "../../assets/logos/kulpa-logo.svg";
import styles from "../../config/styles";

const useStyles = makeStyles(theme => ({
  root: {
    ...styles.mixins.mainSection,
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    paddingTop: 0
  },
  logo: {
    position: "absolute",
    height: 40,
    opacity: 0.8
  },
  normal: {
    minHeight: "auto",
    padding: theme.spacing(5, 0)
  },
  noBg: {
    backgroundColor: "transparent"
  }
}));

const LoadingSection = ({ normalHeight, noBackground, className }) => {
  const classes = useStyles();

  return (
    <section
      className={clsx(classes.root, className, {
        [classes.normal]: normalHeight,
        [classes.noBg]: noBackground
      })}
    >
      <CircularProgress size={130} thickness={1} />
      <img className={classes.logo} src={mainLogo} alt="" />
    </section>
  );
};

LoadingSection.propTypes = {
  className: PropTypes.string,
  noBackground: PropTypes.bool,
  normalHeight: PropTypes.bool
};

LoadingSection.defaultProps = {
  className: "",
  noBackground: false,
  normalHeight: false
};

export default LoadingSection;
