import React from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";

// Config
import { useSensitiveImage } from "../../context/SensitiveImageProvider";
import colors from "../../config/colors";
import styles from "../../config/styles";

// Icons
import eyeOff from "../../assets/icons/feather-eye-off.svg";
import zoomIn from "../../assets/icons/feather-zoom-in.svg";
import zoomOut from "../../assets/icons/feather-zoom-out.svg";

// Components
import Text from "../Text";
import Button from "../Button";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: colors.black,
    display: "flex",
    justifyContent: "center",
    margin: `${styles.values.headerHeight}px 0 ${styles.values.annotationFooterHeight}px`,
    position: "relative",
    overflow: "hidden"
  },
  sensitiveLabel: {
    color: "white",
    zIndex: 90,
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    backgroundColor: "rgba(0,0,0,0.5)"
  },
  eyeIcon: {
    filter: "brightness(10)",
    marginBottom: theme.spacing(1)
  },
  sensitiveTitle: {
    fontFamily: ["Gibson SemiBold", "sans-serif"].join(",")
  },
  sensitiveSubtitle: {
    fontFamily: ["Gibson Regular", "sans-serif"].join(",")
  },
  filename: {
    color: colors.white,
    fontSize: 20,
    position: "absolute",
    top: theme.spacing(3),
    left: theme.spacing(3),
    zIndex: 99
  },
  topRight: {
    position: "absolute",
    top: theme.spacing(3),
    right: theme.spacing(3),
    zIndex: 80
  },
  floatingButton: {
    marginLeft: theme.spacing(1),
    padding: 0,
    minHeight: 30,
    minWidth: 33,
    backgroundColor: colors.white,
    zIndex: 80
  },
  disabledButton: {
    backgroundColor: `${colors.santasGray} !important`
  },
  clearAnnotations: {
    color: colors.riverBed,
    fontFamily: ["Gibson Regular", "sans-serif"].join(","),
    fontSize: 14,
    width: 153,
    zIndex: 80
  },
  bottomRight: {
    position: "absolute",
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    zIndex: 80
  }
}));

const CanvasWrapper = ({
  children,
  disabled,
  filename,
  isSensitive,
  onZoomIn,
  onZoomOut,
  resetAnnotations,
  toggleAnnotations
}) => {
  const classes = useStyles();
  const { clickThumbnail, clickedThumbnail } = useSensitiveImage();
  const { imgIndex } = useParams();

  const toggleSensitive = () => clickThumbnail(imgIndex);

  const displayWarning = isSensitive && imgIndex !== clickedThumbnail;

  return (
    <>
      <div className={classes.root}>
        {displayWarning && (
          <ButtonBase
            className={classes.sensitiveLabel}
            onClick={toggleSensitive}
          >
            <img src={eyeOff} alt="" height={40} className={classes.eyeIcon} />
            <Text
              className={classes.sensitiveTitle}
              text="common.valueLabels.sensitiveContent"
              type="heading6"
            />
            <Text
              className={classes.sensitiveSubtitle}
              text="common.prompts.tapToView"
              type="subtitle1Big"
            />
          </ButtonBase>
        )}

        <Text className={classes.filename}>{filename}</Text>
        <div className={classes.topRight}>
          <Button
            disabled={disabled}
            className={classes.floatingButton}
            disabledClassName={classes.disabledButton}
            disableElevation
            variant="contained"
            onClick={toggleAnnotations}
          >
            <img src={eyeOff} alt="" />
          </Button>
          <Button
            disabled={disabled}
            className={classes.floatingButton}
            disabledClassName={classes.disabledButton}
            disableElevation
            variant="contained"
            onClick={resetAnnotations}
          >
            <Text
              className={classes.clearAnnotations}
              component="span"
              text="toolPage.clearAnnotationsButton"
            />
          </Button>
        </div>

        {children}

        <div className={classes.bottomRight}>
          <Button
            disabled={disabled}
            className={classes.floatingButton}
            disabledClassName={classes.disabledButton}
            disableElevation
            variant="contained"
            onClick={onZoomIn}
          >
            <img height={19} src={zoomIn} alt="" />
          </Button>
          <Button
            disabled={disabled}
            className={classes.floatingButton}
            disabledClassName={classes.disabledButton}
            disableElevation
            variant="contained"
            onClick={onZoomOut}
          >
            <img height={19} src={zoomOut} alt="" />
          </Button>
        </div>
      </div>
    </>
  );
};

CanvasWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool.isRequired,
  filename: PropTypes.node.isRequired,
  onZoomIn: PropTypes.func.isRequired,
  onZoomOut: PropTypes.func.isRequired,
  resetAnnotations: PropTypes.func.isRequired,
  toggleAnnotations: PropTypes.func.isRequired,
  isSensitive: PropTypes.bool.isRequired
};

export default CanvasWrapper;
