import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Form } from "formik";
import { format } from "date-fns";
import { useParams, Prompt } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import get from "lodash-es/get";
import isEmpty from "lodash-es/isEmpty";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import FormHelperText from "@material-ui/core/FormHelperText";

// Styles
import calendar from "../../../assets/icons/calendar.svg";
import styles from "../../../config/styles";

// Utils
import incidents, {
  fromIncidentToForm,
  getDurationDisplay
} from "../../../utils/incidents";
import { snackbarTypes } from "../../../config/snackbar";

// Actions
import {
  deleteIncidentItem,
  updateCaseIncident
} from "../../../state/incident/actions";
import { getCaseDetail } from "../../../state/caseDetail/actions";
import { toggleSnack } from "../../../state/snackbar/actions";

// Hooks
import useTranslate from "../../../hooks/useTranslate";

// Components
import Button from "../../../components/Button";
import ContentCardHeader from "../../../components/ContentCardHeader";
import DurationInputModal from "../../../components/DurationInput/DurationInputModal";
import FakeInput from "../../../components/FakeInput/FakeInput";
import FormikHandler from "../../../components/FormikHandler";
import InputList from "../../../components/Input/InputList";
import LoadingSection from "../../../components/LoadingSection";
import SectionWrapper from "../../../components/SectionWrapper";
import Text from "../../../components/Text";

// Inner Components
import IncidentInfo from "./IncidentInfo";

const useStyles = makeStyles(theme => ({
  root: {
    border: styles.values.border
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2, 3)
  },
  formRow: {
    display: "flex",
    flexDirection: "row"
  },
  timeError: {
    flex: "1 1",
    position: "relative"
  },
  dateTimeError: {
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(2)
  }
}));

const CaseIncidentsEdit = ({ onSuccess }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { caseId } = useParams();
  // Used to mark if the user is still editing, false when they click finish
  const [isEditing, setEditing] = useState(true);
  const [coord, setCoord] = useState(null);
  const [dateTimeError, setDateTimeError] = useState("");
  const [durationDialog, setDurationDialog] = useState(false);
  const { userDetails } = useSelector(state => state.user);
  const { data, isLoading } = useSelector(state => state.caseDetail);
  const { isDraft = true } = data || {};

  const editMode = isEditing || !(data && data.incident);
  const incident = data && data.incident ? data.incident : {};

  const getText = useTranslate();

  const maxDateError =
    data &&
    getText("common.validationSchema.examinationDateMax", {
      date: format(new Date(data.dateTimeOfExamination), "dd/MM/yy HH:mm")
    });

  useEffect(() => {
    if (data && data.incident) {
      const cor = get(incident, "location.coordinate");
      setCoord(cor);
    }
  }, [data, editMode, incident]);

  if (isLoading || !userDetails) {
    return (
      <SectionWrapper>
        <Paper elevation={0} className={classes.root}>
          <LoadingSection normalHeight />
        </Paper>
      </SectionWrapper>
    );
  }

  const isCreator = userDetails && data && userDetails.id === data.creatorId;

  const onSubmit = (values, { setSubmitting }) => {
    let error = false;
    const sentValues = incidents({
      coord,
      incident,
      isCreator,
      isDraft,
      values
    });
    const maxDate = new Date(data.dateTimeOfExamination);
    if (values.dateTime > maxDate) {
      setDateTimeError(maxDateError);
      error = true;
    }

    if (error) {
      setSubmitting(false);
      return;
    }

    if (isEmpty(sentValues)) {
      setEditing(false);
      setSubmitting(false);
      onSuccess();
    } else {
      dispatch(
        updateCaseIncident({
          caseId,
          incident: sentValues,
          isDraft,
          author: {
            ...userDetails,
            profilePictureURL: userDetails.profilePhotoURL
          },
          onFinish: wasSuccess => {
            setSubmitting(false);
            if (wasSuccess) {
              setEditing(false);
              dispatch(getCaseDetail({ caseId, isUpdate: true }));
              onSuccess();
              dispatch(toggleSnack({ type: snackbarTypes.INCIDENT_SAVED }));
            } else {
              dispatch(toggleSnack({ type: snackbarTypes.ERROR }));
            }
          }
        })
      );
    }
  };

  const openDurationInput = useCallback(() => setDurationDialog(true));
  const closeDurationInput = useCallback(() => setDurationDialog(false));

  const handleDeleteElement = useCallback(
    type => (itemId, callback) => {
      dispatch(
        deleteIncidentItem({
          caseId,
          itemId,
          onFinish: success => {
            callback(success);
            if (!success) dispatch(toggleSnack({ type: snackbarTypes.ERROR }));
          },
          type
        })
      );
    },
    [caseId]
  );

  const deleteHandlers = useMemo(
    () => ({
      descriptions: handleDeleteElement("descriptions"),
      witnesses: handleDeleteElement("witnesses"),
      perpetrators: handleDeleteElement("perpetrators")
    }),
    [handleDeleteElement]
  );

  const getCommonProps = name => ({
    canAddMore: true,
    multiline: true,
    name
  });

  const initialValues = fromIncidentToForm(
    incident,
    isDraft,
    isCreator,
    userDetails.id
  );

  const disableIndex = useMemo(() => [!isCreator], [isCreator]);

  return (
    <Paper elevation={0} className={classes.root}>
      <ContentCardHeader>
        <Text text="caseIncidentsPage.title" type="heading2" />
      </ContentCardHeader>
      <Prompt
        when={isEditing}
        message={() => getText("caseIncidentsPage.exitPrompt")}
      />
      <FormikHandler
        formik={{
          initialValues,
          onSubmit,
          validateOnBlur: false
        }}
        render={({ errors, isSubmitting, setFieldValue, values }) => (
          <Form className={classes.contentContainer}>
            {isCreator && (
              <>
                <IncidentInfo
                  useFastField
                  label={getText("caseIncidentsPage.locationLabel")}
                  name="location"
                  type="location"
                  setFieldValue={setFieldValue}
                  setCoord={setCoord}
                  error={errors.location}
                  editing
                  autoFocus={editMode === "location"}
                  disabled={
                    incident.location &&
                    incident.location.name !== "" &&
                    !isDraft
                  }
                />
                <div className={classes.formRow}>
                  <div className={classes.timeError}>
                    <IncidentInfo
                      useFastField
                      title={getText(
                        "caseDetailPage.requiredIncidentDetails.dateTimeSubtitle"
                      )}
                      label={getText(
                        "caseDetailPage.requiredIncidentDetails.dateTimeSubtitle"
                      )}
                      name="dateTime"
                      type="dateTime"
                      editing
                      maxDate={data.dateTimeOfExamination}
                      disabled={incident.dateTime && !isDraft}
                      error={
                        dateTimeError && (
                          <FormHelperText
                            className={classes.dateTimeError}
                            error
                          >
                            {dateTimeError}
                          </FormHelperText>
                        )
                      }
                      initialFocusedDate={data.dateTimeOfExamination}
                    />
                  </div>

                  <FakeInput
                    icon={calendar}
                    name="duration"
                    label={getText("caseIncidentsPage.durationLabel")}
                    value={getDurationDisplay(values)}
                    onClick={openDurationInput}
                    disabled={typeof incident.duration === "number" && !isDraft}
                  />

                  <DurationInputModal
                    open={durationDialog}
                    onClose={closeDurationInput}
                    setFieldValue={setFieldValue}
                    values={values}
                    disabled={typeof incident.duration === "number" && !isDraft}
                    label={getText("caseIncidentsPage.durationLabel")}
                  />
                </div>
              </>
            )}
            <InputList
              {...getCommonProps("description")}
              idArray={get(values, `editingId.descriptions`)}
              setFieldValue={setFieldValue}
              title={getText("caseIncidentsPage.descriptionLabel")}
              label={getText("caseIncidentsPage.descriptionLabel")}
              placeholder={getText("caseIncidentsPage.descriptionPlaceholder")}
              addButtonText={getText("caseIncidentsPage.descriptionAddButton")}
              disableIndex={disableIndex}
              error={errors.descriptions}
              name="descriptions"
              onDelete={deleteHandlers.descriptions}
              values={values.descriptions}
              canAddMore={
                (incident.descriptions && incident.descriptions.length > 0) ||
                isCreator
              }
            />
            <InputList
              {...getCommonProps("witnesses")}
              idArray={get(values, `editingId.witnesses`)}
              setFieldValue={setFieldValue}
              title={getText("caseIncidentsPage.witnessesLabel")}
              label={getText("caseIncidentsPage.witnessesLabel")}
              placeholder={getText("caseIncidentsPage.witnessesPlaceholder")}
              addButtonText={getText("caseIncidentsPage.witnessesAddButton")}
              error={errors.witnesses}
              onDelete={deleteHandlers.witnesses}
              subTitle={getText("common.userTypes.Witness")}
              values={values.witnesses}
            />
            <InputList
              {...getCommonProps("perpetrators")}
              idArray={get(values, `editingId.perpetrators`)}
              setFieldValue={setFieldValue}
              title={getText("caseIncidentsPage.perpetratorsLabel")}
              label={getText("caseIncidentsPage.perpetratorsLabel")}
              placeholder={getText("caseIncidentsPage.perpetratorsPlaceholder")}
              addButtonText={getText("caseIncidentsPage.perpetratorsAddButton")}
              error={errors.perpetrators}
              onDelete={deleteHandlers.perpetrators}
              subTitle={getText("caseIncidentsPage.perpetratorLabel")}
              values={values.perpetrators}
            />
            <Button
              color="primary"
              disabled={isSubmitting}
              disableElevation
              type="submit"
              variant="contained"
            >
              {getText("common.prompts.done")}
            </Button>
          </Form>
        )}
      />
    </Paper>
  );
};

CaseIncidentsEdit.propTypes = {
  onSuccess: PropTypes.func
};

CaseIncidentsEdit.defaultProps = {
  onSuccess: () => {}
};

export default CaseIncidentsEdit;
