import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";

// Icons
import alert from "../../assets/icons/alert.svg";
import warning from "../../assets/icons/warning.svg";

// Config
import colors from "../../config/colors";
import useTranslate from "../../hooks/useTranslate";

// Components
import Button from "../Button";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(6, 3, 5),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: 465
  },
  icon: {
    marginBottom: theme.spacing(2),
    color: "orange"
  },
  heading: {
    marginBottom: theme.spacing(2),
    textAlign: "center"
  },
  content: {
    marginBottom: theme.spacing(2)
  },
  dialogText: {
    textAlign: "center",
    opacity: 0.6,
    lineHeight: 1.5
  },
  buttons: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: ({ reverseButtons }) =>
      reverseButtons ? "row-reverse" : "row",
    justifyContent: "space-evenly",
    alignItems: "flex-end",
    width: "100%"
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  buttonLabel: {
    marginBottom: theme.spacing(1),
    color: colors.riverBed,
    textAlign: "center",
    opacity: 0.6,
    lineHeight: 1.2
  },
  button: {
    minWidth: 144,
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    marginBottom: theme.spacing(1.5)
  },
  italic: {
    fontStyle: "italic"
  },
  spacing: {
    marginBottom: theme.spacing(1)
  },
  fullWidth: {
    width: "100%"
  },
  column: {
    flexDirection: ({ reverseButtons }) =>
      reverseButtons ? "column" : "column-reverse",
    width: "auto"
  }
}));

const types = {
  delete: {
    color: "secondary",
    defaultIcon: warning
  },
  discard: {
    color: "secondary",
    defaultIcon: warning
  },
  reject: {
    color: "secondary",
    defaultIcon: warning
  },
  submit: {
    color: "primary",
    defaultIcon: alert
  }
};

const ConfirmationDialog = ({
  button,
  buttonsColumn,
  cancel,
  cancelLabel,
  confirmLabel,
  hideCancel,
  hideConfirm,
  icon,
  isLoading,
  onCancel,
  onClose,
  onConfirm,
  open,
  reverseButtons,
  textKeys,
  texts,
  title,
  type
}) => {
  const classes = useStyles({ reverseButtons });
  const { color, defaultIcon } = types[type] || types.delete;
  const getText = useTranslate();

  const handleCancel = () => {
    if (onCancel) onCancel();
    onClose();
  };

  return isLoading ? (
    <Dialog open>
      <div className={classes.root}>
        <CircularProgress size={50} thickness={3} color={color} />
      </div>
    </Dialog>
  ) : (
    <Dialog onClose={onClose} open={open}>
      <div className={classes.root}>
        <img
          height={48}
          src={icon || defaultIcon}
          alt=""
          className={classes.icon}
        />
        <Text type="heading3" className={classes.heading}>
          {title || getText("common.prompts.areYouSure")}
        </Text>

        {texts.length > 0 && (
          <div className={classes.content}>
            {texts.map(({ key, text, italic, spacing }) => (
              <Text
                className={clsx(classes.dialogText, {
                  [classes.italic]: italic,
                  [classes.spacing]: spacing
                })}
                key={key}
                gutterBottom
                type="subtitle3"
              >
                {text}
              </Text>
            ))}
          </div>
        )}

        {textKeys.length > 0 && (
          <div className={classes.content}>
            {textKeys.map(key => (
              <Text
                className={classes.dialogText}
                gutterBottom
                key={key}
                text={key}
                type="subtitle3"
              />
            ))}
          </div>
        )}

        <div
          className={clsx(classes.buttons, {
            [classes.column]: buttonsColumn
          })}
        >
          {!hideCancel && (
            <div
              className={clsx(classes.buttonWrapper, {
                [classes.fullWidth]: buttonsColumn
              })}
            >
              {cancelLabel && (
                <Text type="subtitle2" className={classes.buttonLabel}>
                  {cancelLabel}
                </Text>
              )}
              <Button
                className={clsx(classes.button, {
                  [classes.fullWidth]: buttonsColumn
                })}
                disableElevation
                onClick={handleCancel}
                variant="outlined"
              >
                {cancel || getText("common.prompts.cancel")}
              </Button>
            </div>
          )}
          {!hideConfirm && (
            <div
              className={clsx(classes.buttonWrapper, {
                [classes.fullWidth]: buttonsColumn
              })}
            >
              {confirmLabel && (
                <Text type="subtitle2" className={classes.buttonLabel}>
                  {confirmLabel}
                </Text>
              )}
              <Button
                className={clsx(classes.button, {
                  [classes.fullWidth]: buttonsColumn
                })}
                color={color}
                disableElevation
                onClick={onConfirm}
                variant="contained"
              >
                {button ||
                  getText(`common.prompts.${type}`) ||
                  getText("common.prompts.delete")}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  button: PropTypes.string,
  buttonsColumn: PropTypes.bool,
  cancel: PropTypes.string,
  confirmLabel: PropTypes.string,
  hideCancel: PropTypes.bool,
  hideConfirm: PropTypes.bool,
  icon: PropTypes.node,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  open: PropTypes.bool.isRequired,
  reverseButtons: PropTypes.bool,
  textKeys: PropTypes.arrayOf(PropTypes.string),
  texts: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any,
      text: PropTypes.string,
      italic: PropTypes.bool,
      spacing: PropTypes.bool
    })
  ),
  title: PropTypes.string,
  type: PropTypes.string,
  cancelLabel: PropTypes.string
};

ConfirmationDialog.defaultProps = {
  button: "",
  buttonsColumn: false,
  cancel: null,
  cancelLabel: null,
  confirmLabel: null,
  hideCancel: false,
  hideConfirm: false,
  icon: null,
  isLoading: false,
  onCancel: null,
  onConfirm: () => {},
  reverseButtons: false,
  textKeys: [],
  texts: [],
  title: null,
  type: "warning"
};

export default ConfirmationDialog;
