import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";

// Config
import styles from "../../config/styles";

const useStyles = makeStyles(theme => ({
  root: {
    ...styles.mixins.mainSection,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    paddingBottom: theme.spacing(5)
  },
  noStatus: styles.mixins.topPadding
}));

const MainSection = ({ children, noStatus }) => {
  const classes = useStyles();
  return (
    <main
      className={clsx(classes.root, {
        [classes.noStatus]: noStatus
      })}
    >
      {children}
    </main>
  );
};

MainSection.propTypes = {
  children: PropTypes.node.isRequired,
  noStatus: PropTypes.bool
};

MainSection.defaultProps = {
  noStatus: false
};

export default MainSection;
