import React from "react";
import { useSelector } from "react-redux";
import { Switch } from "react-router-dom";
import isEmpty from "lodash-es/isEmpty";
import PropTypes from "prop-types";

// Hooks
import useCases from "../hooks/useCases";
import useAuthorizedContent from "../hooks/useAuthorizedContent";

// Context
import { useFeatureFlags } from "../context/FeatureFlagsProvider";

// Routes
import RouteHandler from "./RouteHandler";

const CasesRoutes = ({ routes }) => {
  const { featureFlags } = useFeatureFlags();
  const { currentUser } = useSelector(s => s.user);
  const userExists = currentUser && !isEmpty(currentUser);

  useCases(userExists);
  useAuthorizedContent();

  return (
    <Switch>
      {routes.map(route => {
        if (route.featureFlag && !featureFlags[route.featureFlag]) return null;
        return <RouteHandler key={route.id} {...route} />;
      })}
    </Switch>
  );
};

CasesRoutes.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default CasesRoutes;
