import React from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import CardActionArea from "@material-ui/core/CardActionArea";

// Icons
import chevron from "../../assets/icons/chevron.svg";

// Config
import colors from "../../config/colors";
import styles from "../../config/styles";

// Components
import ContributorItem from "../ContributorItem";
import ContributorGroup from "./ContributorGroup";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 3, 3, 4),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: styles.values.border
  },
  selected: {
    backgroundColor: colors.wildLand
  },
  chevron: {
    transform: "rotate(90deg)"
  }
}));

const ContactItem = ({ parties, onClick, selected }) => {
  const classes = useStyles();
  const { userDetails } = useSelector(state => state.user);

  if (!userDetails) return null;

  const recievers = parties.filter(({ id }) => id !== userDetails.id);

  return (
    <CardActionArea
      className={clsx(classes.root, {
        [classes.selected]: selected
      })}
      onClick={onClick}
    >
      {recievers.length === 1 ? (
        <ContributorItem
          boldName={Boolean(selected)}
          hidePending
          user={recievers[0]}
        />
      ) : (
        <ContributorGroup
          parties={recievers}
          user={recievers[0]}
          boldName={Boolean(selected)}
        />
      )}
      <img src={chevron} alt="" className={classes.chevron} />
    </CardActionArea>
  );
};

ContactItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  parties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selected: PropTypes.bool.isRequired
};

export default ContactItem;
