// Local Config
export const imageRequestInterval = 10000;
export const featureFlagsRequestInterval =
  process.env.REACT_FLAGS_REQUEST_INTERVAL || 1800000; // 30 min = 1000 * 60 * 30

// Azure
export const baseUrl = `${
  process.env.REACT_APP_API_URL || "https://stageapi.kulpa.io"
}/core`;
export const apiVersion = process.env.REACT_API_VERSION || "2022-03";

export const instrumentationKey =
  process.env.REACT_APP_INSTRUMENTATION_KEY ||
  "fdc7fd3b-1dba-4ad5-b5ce-c39605555992";

// Cases
export const getCasesUrl = query => `${baseUrl}/cases${query || ""}`;
export const getCaseDetailUrl = caseId => `${baseUrl}/cases/${caseId}`;

// Submit
export const submitCaseUrl = caseId => `${getCaseDetailUrl(caseId)}/submit`;

// Download
export const requestReportUrl = `/caseReportRequests`;

export const getDownloadReportUrl = reportId =>
  `${baseUrl}/caseReportRequests/${reportId}`;
export const downloadReportUrl = reportId =>
  `${getDownloadReportUrl(reportId)}/location`;

// Contributors
export const getCaseContributorsUrl = caseId =>
  `${getCaseDetailUrl(caseId)}/contributors`;
export const contributorItemUrl = (caseId, contributorId) =>
  `${getCaseContributorsUrl(caseId)}/${contributorId}`;

// Notes
export const getNotesUrl = caseId => `${getCaseDetailUrl(caseId)}/notes`;
export const getNotesDetailUrl = (caseId, noteId) =>
  `${getNotesUrl(caseId)}/${noteId}`;

export const submitNoteUrl = (caseId, noteId) =>
  `${getNotesDetailUrl(caseId, noteId)}/submit`;

// Audit Trails
export const getCaseLog = caseId => `${getCaseDetailUrl(caseId)}/audit`;
export const getCaseLogEntry = ({ caseId, entryId }) =>
  `${getCaseLog(caseId)}/${entryId}`;

// Person Affected Id
export const getPersonAffectedIdUrl = caseId =>
  `${getCaseDetailUrl(caseId)}/patient/photo`;

// Media
export const getMediaUrl = caseId => `${getCaseDetailUrl(caseId)}/media`;
export const mediaItemUrl = (caseId, mediaId) =>
  `${getCaseDetailUrl(caseId)}/media/${mediaId}`;

export const mediaItemUploadUrl = (caseId, mediaId) =>
  `${getCaseDetailUrl(caseId)}/media/${mediaId}/upload`;

// Further Info (Info Requests)
export const infoRequestsUrl = caseId =>
  `${getCaseDetailUrl(caseId)}/infoRequests`;

export const infoReqMessagesUrl = (caseId, infoRequestId) =>
  `${getCaseDetailUrl(caseId)}/infoRequests/${infoRequestId}`;

export const getMessageUrl = (caseId, infoRequestId, infoRequestMessageId) =>
  `${infoReqMessagesUrl(
    caseId,
    infoRequestId
  )}/messages/${infoRequestMessageId}`;

// SignalR
export const signalRChatUrl = `${baseUrl}/infoRequests/chat`;
export const signalRNotificationsUrl = `${baseUrl}/notifications/stream`;

// Medical Records
export const getMedicalRecordsUrl = caseId =>
  `${getCaseDetailUrl(caseId)}/medicalRecords`;

export const medicalRecordItemUrl = (caseId, recordId) =>
  `${getCaseDetailUrl(caseId)}/medicalRecords/${recordId}`;

export const medicalRecordUploadUrl = (caseId, recordId) =>
  `${getCaseDetailUrl(caseId)}/medicalRecords/${recordId}/upload`;

// Injuries
export const getInjuryUrl = (caseId, injuryId) =>
  `${getCaseDetailUrl(caseId)}/injuries/${injuryId}`;

export const triggerNotificationsUrl = caseId =>
  `${getCaseDetailUrl(caseId)}/injuries/triggerNotification`;

// Incidents
export const getCaseIncidentUrl = caseId =>
  `${getCaseDetailUrl(caseId)}/incident`;

export const getIncidentElementUrl = ({ caseId, type, itemId }) =>
  `${getCaseIncidentUrl(caseId)}/${type}/${itemId}`;

// Images
export const getImgUploadUrl = (caseId, injuryId) =>
  `${getInjuryUrl(caseId, injuryId)}/images`;

export const getImgReuploadUrl = (caseId, injuryId, filename) =>
  `${getInjuryUrl(caseId, injuryId)}/images/${filename}/upload`;

// Treatments
export const getTreatmentsUrl = (caseId, injuryId) =>
  `${getInjuryUrl(caseId, injuryId)}/treatments/`;

// Descriptions
export const getDescriptionsUrl = (caseId, injuryId) =>
  `${getInjuryUrl(caseId, injuryId)}/descriptions/`;

export const getImgUrl = (caseId, injuryId, filename) =>
  `${getImgUploadUrl(caseId, injuryId)}/${filename}`;

// Invitations
export const getInvitationsUrl = () => `${baseUrl}/invitations`;
export const caseInvitationUrl = caseId => `${baseUrl}/invitations/${caseId}`;
export const caseForceInvitationUrl = (caseId, invitationId) =>
  `${caseInvitationUrl(caseId)}/${invitationId}/confirm`;

export const acceptInvitationUrl = caseId =>
  `${baseUrl}/invitations/${caseId}/accept`;

export const rejectInvitationUrl = caseId =>
  `${baseUrl}/invitations/${caseId}/reject`;

// Shortlink
export const getShortlinkUrl = code => `${baseUrl}/shortlinks/${code}`;

// Device
export const sendStoreLinkUrl = () => `${baseUrl}/messageAppStoreLink`;

// Notifications
export const notificationsUrl = `${baseUrl}/notifications`;
export const readNotificationUrl = id => `${baseUrl}/notifications/${id}/read`;

// Users
export const getMeUrl = `${baseUrl}/users/me`;
export const getSettingsUrl = `${getMeUrl}/settings`;
export const userPhotoUrl = `${getMeUrl}/photo`;

// User Pin
export const setPinUrl = `${getMeUrl}/pin`;
export const verifyPinUrl = `${setPinUrl}/match`;
export const checkPinUrl = `${setPinUrl}/checkExistence`;

// Emergency Banner
export const getEmergencyContacts = (latitude, longitude) => {
  if (latitude && longitude)
    return `${baseUrl}/emergencyContacts?latitude=${latitude}&longitude=${longitude}`;
  return `${baseUrl}/emergencyContacts`;
};

// Places
export const getPlacesUrl = `${baseUrl}/places`;
export const getPlacesFuzzySearchUrl = (query, latitude, longitude) =>
  `${getPlacesUrl}/fuzzySearch?query=${query}${
    latitude && longitude ? `&latitude=${latitude}&longitude=${longitude}` : ""
  }`;

// Feature Flags
export const getFeatureFlagsUrl = `${baseUrl}/featureFlags`;
