import React, { useState } from "react";
import { Route, useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// Material
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";

// Config
import { getRoute, urls } from "../../config/routes";
import styles from "../../config/styles";

// Actions
import {
  deleteMedicalRecord,
  getMedicalRecords,
  getMedicalRecordsItem
} from "../../state/medicalRecords/actions";

// Hooks
import { useAppInsights } from "../../utils/telemetry/AppInsights";
import useHideToWitnesses from "../../hooks/useHideToWitnesses";

// Components
import AddMedicalRecords from "../AddMedicalRecords";
import CaseMediaTips from "../CaseMediaTips";
import CaseMediaWhatsNew from "../CaseMediaWhatsNew";
import ContentCardHeader from "../ContentCardHeader";
import GalleryHandler from "../GalleryHandler";
import LoadingSection from "../LoadingSection";
import MediaItemList from "../MediaItemList";
import Text from "../Text";

// Icons
import lightBulb from "../../assets/icons/light_bulb.svg";

const useStyles = makeStyles(theme => ({
  root: {
    border: styles.values.border
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(5),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap"
  }
}));

const MedicalRecordsPanel = () => {
  const { caseId } = useParams();
  const { currentCase, data, isDeleting, isLoading, error } = useSelector(
    s => s.medicalRecords
  );
  const { data: caseData } = useSelector(s => s.caseDetail);
  const { trackException } = useAppInsights();
  const [showTips, setShowTips] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  useHideToWitnesses(caseData);

  React.useEffect(() => {
    if ((currentCase !== caseId || !data) && !error && !isLoading)
      dispatch(
        getMedicalRecords({
          caseId,
          onFail: () =>
            trackException(`Failed to get Medical Records at case: ${caseId}`)
        })
      );
  }, [caseId, currentCase, data, dispatch, isLoading, trackException, error]);

  if (isLoading) {
    return (
      <Paper elevation={0} className={classes.root}>
        <LoadingSection normalHeight />
      </Paper>
    );
  }

  const handleSelect = id => () => {
    history.push(getRoute(urls.medicalRecordDetail, { caseId, itemId: id }));
  };

  const handleDelete = record => () => dispatch(deleteMedicalRecord(record));
  const handleFetchImage = recordId => () =>
    dispatch(
      getMedicalRecordsItem({
        caseId,
        recordId,
        onFail: () =>
          trackException(
            `Failed to get medical record item: ${recordId} at case: ${caseId}`
          )
      })
    );

  const handleShowTips = () => setShowTips(true);
  const handleCloseTips = () => setShowTips(false);

  return (
    <>
      <Paper elevation={0} className={classes.root}>
        <ContentCardHeader>
          <Text text="medicalRecordsPage.title" type="heading2" />
          <div className={classes.buttonWrapper}>
            <IconButton
              onClick={handleShowTips}
              className={classes.openTipsButton}
            >
              <img src={lightBulb} alt="" />
            </IconButton>
            <AddMedicalRecords caseId={caseId} />
          </div>
        </ContentCardHeader>
        {data && (
          <div className={classes.content}>
            <MediaItemList
              caseId={caseId}
              data={data}
              mediaItemProps={{ isDeleting, showDelete: caseData.isDraft }}
              onDelete={handleDelete}
              onFetchImage={handleFetchImage}
              onSelect={handleSelect}
              page="medicalRecordsPage"
            />
          </div>
        )}
      </Paper>

      <CaseMediaTips
        onClose={handleCloseTips}
        open={showTips}
        page="medicalRecordsPage"
      />
      {data && <CaseMediaWhatsNew defaultClosed={data.length > 0} />}
      <Route
        path={urls.medicalRecordDetail}
        exact
        render={renderProps =>
          data &&
          data.length > 0 && (
            <GalleryHandler
              {...renderProps}
              data={data}
              defaultRoute={urls.medicalRecords}
              detailRoute={urls.medicalRecordDetail}
              view="medicalRecords"
            />
          )
        }
      />
    </>
  );
};

export default MedicalRecordsPanel;
