import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getRoute, urls } from "../config/routes";
import { userTypes } from "../config/values";

export default data => {
  const history = useHistory();
  useEffect(() => {
    const isWitness = data && data.userCapacity === userTypes.Witness;
    if (isWitness) history.push(getRoute(urls.caseDetail, { caseId: data.id }));
  }, [data, history]);
};
