import { combineReducers } from "redux";
import constant from "lodash-es/constant";

import createReducer from "../../utils/createReducer";

import {
  REQUEST_SEARCH_PLACES,
  REQUEST_SEARCH_PLACES_SUCCESS,
  REQUEST_SEARCH_PLACES_FAIL,
  REQUEST_SEARCH_PLACES_RESET
} from "./actionTypes";

// Places Data
const searchData = createReducer(null, {
  [REQUEST_SEARCH_PLACES]: constant(null),
  [REQUEST_SEARCH_PLACES_SUCCESS]: (state, { payload }) => payload,
  [REQUEST_SEARCH_PLACES_FAIL]: constant(null),
  [REQUEST_SEARCH_PLACES_RESET]: constant(null),
});

const error = createReducer(null, {
  [REQUEST_SEARCH_PLACES]: constant(null),
  [REQUEST_SEARCH_PLACES_SUCCESS]: constant(null),
  [REQUEST_SEARCH_PLACES_FAIL]: (state, { payload }) => payload,
  [REQUEST_SEARCH_PLACES_RESET]: constant(null)
});

const isLoading = createReducer(false, {
  [REQUEST_SEARCH_PLACES]: constant(true),
  [REQUEST_SEARCH_PLACES_SUCCESS]: constant(false),
  [REQUEST_SEARCH_PLACES_FAIL]: constant(false),
  [REQUEST_SEARCH_PLACES_RESET]: constant(false)
});

export default combineReducers({
  searchData,
  error,
  isLoading,
});
