import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

// Components
import PincodeInput from "./PincodeInput";

// source: https://daily-dev-tips.com/posts/vanilla-javascript-four-digit-pincode-field/

export const initialPincode = ["", "", "", ""];

const PincodeField = ({ onChange, value, error, ...props }) => {
  const one = useRef();
  const two = useRef();
  const three = useRef();
  const four = useRef();

  const inputs = [one, two, three, four];

  useEffect(() => {
    const handleFocus = () => {
      if (
        document.visibilityState === "visible" &&
        four.current &&
        four.current.value === "" &&
        one.current &&
        document.activeElement !== one.current
      ) {
        one.current.focus();
      }
    };

    window.addEventListener("click", handleFocus);
    document.addEventListener("visibilitychange", handleFocus);
    handleFocus();

    return () => {
      window.removeEventListener("click", handleFocus);
      document.removeEventListener("visibilitychange", handleFocus);
    };
  }, []);

  const handleClick = () => {
    onChange(initialPincode);
    one.current.focus();
  };

  const handleChange = index => digit => {
    const nextIndex = index + 1;

    if (nextIndex === inputs.length) {
      four.current.blur();
    } else inputs[nextIndex].current.focus();

    if (digit) {
      const newValue = [...value];
      newValue[index] = digit;
      onChange(newValue);
    }
  };

  return (
    <div {...props}>
      <PincodeInput
        error={error}
        onChange={handleChange(0)}
        onClick={handleClick}
        ref={one}
        value={value[0]}
      />
      <PincodeInput
        error={error}
        onChange={handleChange(1)}
        onClick={handleClick}
        ref={two}
        value={value[1]}
      />
      <PincodeInput
        error={error}
        onChange={handleChange(2)}
        onClick={handleClick}
        ref={three}
        value={value[2]}
      />
      <PincodeInput
        error={error}
        onChange={handleChange(3)}
        onClick={handleClick}
        ref={four}
        value={value[3]}
      />
    </div>
  );
};

PincodeField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  error: PropTypes.bool
};

PincodeField.defaultProps = {
  error: false
};

export default PincodeField;
