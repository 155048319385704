import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { ai } from "./TelemetryService";

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */

class TelemetryProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialized: false
    };
  }

  componentDidMount() {
    const { after, history, currentUser } = this.props;
    const { initialized } = this.state;
    if (!initialized && Boolean(history)) {
      ai.initialize(history, currentUser);
      this.setState({ initialized: true });
    }

    after();
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

TelemetryProvider.propTypes = {
  after: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  history: PropTypes.shape({}).isRequired,
  currentUser: PropTypes.shape({})
};

TelemetryProvider.defaultProps = {
  currentUser: {}
};

export default withRouter(withAITracking(ai.reactPlugin, TelemetryProvider));
