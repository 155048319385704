export const REQUEST_LOGIN = "auth/REQUEST_LOGIN";
export const SET_ERROR = "auth/SET_ERROR";
export const SET_MSAL_STATE = "auth/SET_MSAL_STATE";
export const SET_TOKEN = "auth/SET_TOKEN";
export const SET_USER = "auth/SET_USER";

export const REQUEST_USER_DETAILS = "auth/REQUEST_USER_DETAILS";
export const REQUEST_USER_DETAILS_SUCCESS = "auth/REQUEST_USER_DETAILS_SUCCESS";
export const REQUEST_USER_DETAILS_FAIL = "auth/REQUEST_USER_DETAILS_FAIL";

export const ACQUIRE_TOKEN_FAIL = "msal/ACQUIRE_TOKEN_FAIL";

export const SET_LOCALE = "user/SET_LOCALE";
