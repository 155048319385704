import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

// Config
import colors from "../../config/colors";

// Components
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  chip: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 42,
    width: 200,
    backgroundColor: colors.pastelGreen,
    borderRadius: 21,
    marginRight: theme.spacing(2)
  },
  text: {
    color: colors.white
  },
  label: {
    color: colors.pastelGreen,
    lineHeight: 1.3
  }
}));

const SuccesLabel = ({ buttonText, descriptionText }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.chip}>
        <Text className={classes.text} text={buttonText} type="heading6" />
      </div>
      <Text
        className={classes.label}
        text={descriptionText}
        type="subtitle1Small"
      />
    </div>
  );
};

SuccesLabel.propTypes = {
  buttonText: PropTypes.string.isRequired,
  descriptionText: PropTypes.string.isRequired
};

export default SuccesLabel;
