import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";

// SOURCES

// External link validation
// https://github.com/ReactTraining/react-router/issues/1147#issuecomment-362964577

// Material UI componsition guide for React.forwardRef():
// https://material-ui.com/guides/composition/

const Link = React.forwardRef(({ to, children, ...props }, ref) => {
  if (!to) return <span {...props}>{children}</span>;

  if (/^https?:\/\//.test(to))
    return (
      <a href={to} {...props}>
        {children}
      </a>
    );

  return (
    <RouterLink innerRef={ref} to={to} {...props}>
      {children}
    </RouterLink>
  );
});

Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired
};

export default Link;
